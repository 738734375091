import styled, { css } from 'styled-components';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';

export const MediumPromoStyled = styled.div`
	align-items: flex-start;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	grid-area: medium;
	height: 212px;
	justify-content: flex-start;
	overflow: hidden;
	padding: 24px;
	position: relative;
	width: 212px;

	h5 {
		margin: 0;
		text-transform: uppercase;
		z-index: 3;
	}
`;
export const BigPromoStyled = styled.div`
	align-items: flex-start;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	grid-area: big;
	height: 212px;
	justify-content: flex-end;
	overflow: hidden;
	padding: 24px;
	position: relative;
	width: 440px;

	h4,
	h6 {
		margin: 0;
		z-index: 3;
	}

	h4 {
		padding-bottom: 8px;
		text-transform: uppercase;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${calcStyle({ a: '1060px', b: viewportPadding })}) {
				width: 350px;
			}
		`,
	)};
`;
