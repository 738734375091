import { FC, PropsWithChildren } from 'react';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { CurrencySymbol } from '@legacyApp/client/components/currencySymbol/currencySymbol';
import { TokenSymbol } from '@legacyApp/client/components/currencySymbol/tokenSymbol';

import styles from '@common/components/number/CurrencyValue.module.scss';

export const CurrencyValue: FC<
	PropsWithChildren<{
		currency?: CurrencyType;
		isCurrencyInFront?: boolean;
		isToken?: boolean;
		className?: string;
	}>
> = ({ children, currency, isCurrencyInFront, isToken, className }) => {
	return (
		<div
			className={`${CSSClassNameCurrencyValue} ${styles.currencyValue} ${
				isCurrencyInFront ? styles['currencyValue--font'] : ''
			} ${className}`}
		>
			{currency &&
				(isToken ? (
					<TokenSymbol token={currency} />
				) : (
					<CurrencySymbol currency={currency} />
				))}
			{children}
		</div>
	);
};

export const CSSClassNameCurrencyValue = 'balance__currency--wrapper';
