import { mathService } from '../math/mathService';
import { sentryError } from './sentryService';
import { parseTimestampId } from './appService';

export const cardsColorsArray = ['♦', '♥', '♠', '♣'];
export const cardsValuesArray = [
	'A',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'J',
	'Q',
	'K',
];

export const cardsArray = [];

cardsColorsArray.forEach((color) => {
	cardsValuesArray.forEach((value) => {
		cardsArray.push(color + value);
	});
});

// console.log({cardsArray});

export const countCardsType = 13;
export const countCardsTypeNumber = 9;
export const countCardsTypeLetter = countCardsType - countCardsTypeNumber;

export const getCardById = (id) => {
	return cardsArray[id];
};

export const getCardId = (card) => {
	if (!card) {
		return card;
	}
	const index = cardsArray.indexOf(parseCard(card));
	if (index === -1) {
		sentryError(new Error(`Invalid card symbol ${card}`), {
			card,
			parsed: parseCard(card),
			index,
		});
	}
	return index;
};

export const parseCard = parseTimestampId;

export const isRedCard = (code) => {
	if (!code) {
		return false;
	}
	return ['♦', '♥'].indexOf(getColorFromCardCode(code)) > -1;
};

export const getRandomCard = (current) => {
	// return '♦J';
	const card = getCardById(
		mathService.randomIntInRange(0, cardsArray.length - 1),
	);
	if (card === current) {
		return getRandomCard(current);
	}
	return card;
};

export const getColorFromCardCode = (code) => {
	if (!code) {
		return false;
	}
	return code[0];
};

export const getValueFromCardCode = (code) => {
	if (!code) {
		return false;
	}
	return parseCard(code).replace(code[0], '');
};
