import { actionSimple } from '@legacyApp/client/modules/app/actionService';
import {
	SPORTS_BET_SLIP_SET_ACCEPT_TYPE,
	SPORTS_BET_SLIP_SET_BETS,
	SPORTS_BET_SLIP_SET_BETS_LIST,
	SPORTS_BET_SLIP_SET_FORM_ERROR,
	SPORTS_BET_SLIP_SET_PLACED_BET,
	SPORTS_BET_SLIP_SET_PLACING_BET,
	SPORTS_BET_SLIP_SET_TYPE,
	SPORTS_BET_SLIP_UPDATE_BETS_LIST_ELEMENT,
} from './sportsBetSlip.constants';

export const sportsBetSlipSetBets = actionSimple(SPORTS_BET_SLIP_SET_BETS);
export const sportsBetSlipSetBetsList = actionSimple(
	SPORTS_BET_SLIP_SET_BETS_LIST,
);
export const sportsBetSlipUpdateBetsListElement = actionSimple(
	SPORTS_BET_SLIP_UPDATE_BETS_LIST_ELEMENT,
);
export const sportsBetSlipSetTypeAction = actionSimple(
	SPORTS_BET_SLIP_SET_TYPE,
);
export const sportsBetSlipSetPlacingBet = actionSimple(
	SPORTS_BET_SLIP_SET_PLACING_BET,
);
export const sportsBetSlipSetPlacedBet = actionSimple(
	SPORTS_BET_SLIP_SET_PLACED_BET,
);
export const sportsBetSlipSetAcceptType = actionSimple(
	SPORTS_BET_SLIP_SET_ACCEPT_TYPE,
);
export const sportsBetSlipSetFormError = actionSimple(
	SPORTS_BET_SLIP_SET_FORM_ERROR,
);
