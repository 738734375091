export const UPDATE_PASSWORD_VALUE = 'UPDATE_PASSWORD_VALUE';
export const UPDATE_PASSWORD_CONFIRM_VALUE = 'UPDATE_PASSWORD_CONFIRM_VALUE';
export const UPDATE_PASSWORD_VALIDATE = 'UPDATE_PASSWORD_VALIDATE';

export const UPDATE_USERNAME_VALUE = 'UPDATE_USERNAME_VALUE';
export const UPDATE_USERNAME_VALIDATE = 'UPDATE_USERNAME_VALIDATE';
export const UPDATE_FORM = 'UPDATE_FORM';

export const UPDATE_CAMPAIGN_NAME = 'UPDATE_CAMPAIGN_NAME';
export const UPDATE_CAMPAIGN_CODE = 'UPDATE_CAMPAIGN_CODE';

export const SET_CLIENT_SEED = 'SET_CLIENT_SEED';
export const SET_SERVER_SEED = 'SET_SERVER_SEED';
export const SET_NONCE = 'SET_NONCE';
export const FORM_VERIFICATION_SET_GAME = 'FORM_VERIFICATION_SET_GAME';

export const RESET_FORMS = 'RESET_FORMS';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const RESET_USERNAME_FORM = 'RESET_USERNAME_FORM';
export const RESET_VERIFICATION_FORM = 'RESET_VERIFICATION_FORM';
export const RESET_CAMPAIGN_FORM = 'RESET_CAMPAIGN_FORM';
