export enum TransNamespace {
	COMMON = 'common',
	SPORTS = 'sports',
	SLOTS = 'slots',
	HOME_PAGE = 'homePage',
	VIP_BONUS_CODE = 'vipBonusCode',
	VIP = 'vip',
	USER = 'user',
	SPORTS_PAGE_CONTENT = 'sportsPageContent',
	HOME_SLOTS_PAGE_CONTENT = 'homeSlotsPageContent',
	DICE_PAGE_CONTENT = 'dicePageContent',
	HILO_PAGE_CONTENT = 'hiloPageContent',
	LIMBO_PAGE_CONTENT = 'limboPageContent',
	PLINKO_PAGE_CONTENT = 'plinkoPageContent',
	HOME_PAGE_CONTENT = 'homePageContent',
	VALIDATION = 'validation',
	AFFILIATE = 'affiliate',
	FEATURES = 'features',
	CASINO = 'casino',
	REDIRECT_PAGE = 'redirectPage',
	PROMOTIONS_PAGE = 'promotionsPage',
	FAQ_PAGE = 'faqPage',
	PROVABLY_FAIR_PAGE = 'provablyFairPage',
	REGISTER_PAGE = 'registerPage',
	WELCOME_LAYOUT = 'welcomeLayout',
	LOGIN_PAGE = 'loginPage',
	WALLET = 'wallet',
	RACE = 'race',
	CONTENT_MODAL = 'contentModal',
	CHAT = 'chat',
}
