import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { config } from '../../config';
import { getLimits } from '../../../methods/currency/getLimits';
import { game_config } from '../../game_config';
import {
	getPayout,
	getPayoutDecimal,
	getProfit,
	getWinChanceDecimal,
	getWinChanceFromPayout,
	isInputActive,
	validateBetAmount,
	validateProfitAmount,
} from './gameService';
import { getHiloWinChance } from './hiloService';

export const getMaxWinChance = () => {
	return config.games.maxWinChance;
};

export const getMinWinChance = (game) => {
	return getWinChanceFromPayout(game_config[game].maxPayout, game);
};

export const getMaxPayout = (game = 'dice') => {
	if (!game) {
		throw new Error('game undefined');
	}
	return game_config[game].maxPayout;
};

export const getMarginMultiplier = (game = 'dice') => {
	const parameter = 100;
	return roundNumber((parameter - game_config[game].houseEdge) / parameter, 2);
};

export const validateWinChance = (value, game, exclude = []) => {
	if (!game) {
		throw new Error('game undefined');
	}
	value = roundNumber(value, getWinChanceDecimal(game));
	if (value === '') {
		return {
			valid: false,
			amount: '',
			error: { label: 'Please, type win chance value' },
		};
	}
	const maxWinChance = getMaxWinChance();
	if (value && value > maxWinChance && exclude.indexOf('max') === -1) {
		return {
			valid: false,
			amount: maxWinChance,
			error: {
				label: 'Win chance must be lower or equal to {{value}}',
				options: {
					value: maxWinChance,
				},
			},
		};
	}
	const minWinChance = getMinWinChance(game);
	const excludeMin = exclude.indexOf('min') > -1;
	if (!value || value < 0 || (value < minWinChance && !excludeMin)) {
		// console.log('validateWinChance', {
		//   value,
		//   minWinChance,
		// });
		return {
			valid: false,
			amount: excludeMin ? 0 : minWinChance,
			error: {
				label: excludeMin
					? 'Win chance must be greater than {{value}}'
					: 'Win chance must be greater or equal {{value}}',
				options: {
					value: excludeMin ? 0 : minWinChance,
				},
			},
		};
	}
	return {
		valid: true,
		amount: value,
		error: false,
	};
};

export const validatePayout = (value, game, exclude = []) => {
	if (!game) {
		throw new Error('game undefined');
	}
	value = roundNumber(value, getPayoutDecimal(game));
	if (!game_config[game].maxPayout && !value) {
		return {
			valid: true,
			amount: value,
			error: false,
		};
	}
	const min = roundNumber(
		getPayout(getMaxWinChance(), game),
		getPayoutDecimal(game),
	);
	const max = getMaxPayout(game);
	if (value === '') {
		return {
			valid: false,
			amount: '',
			error: { label: 'Please, type payout value' },
		};
	}
	if (value && value > max && exclude.indexOf('max') === -1) {
		return {
			valid: false,
			amount: max,
			error: {
				label: 'Payout must be lower or equal to {{max}}',
				options: {
					max,
				},
			},
		};
	}
	const excludeMin = exclude.indexOf('min') > -1;
	if (!value || value < 0 || (value < min && !excludeMin)) {
		return {
			valid: false,
			amount: excludeMin ? 0 : min,
			error: {
				label: excludeMin
					? 'Payout must be greater than {{min}}'
					: 'Payout must be greater or equal to {{min}}',
				options: {
					min: excludeMin ? 0 : min,
				},
			},
		};
	}
	return {
		valid: true,
		amount: value,
		error: false,
	};
};

export const isFormError = (state, game, mode = null, checkBalance = true) => {
	const gameState = state.game;
	// console.log('isFormError', { state, game, gameState, mode, checkBalance });
	const isChanceValidate = ['hilo'].indexOf(game) === -1;
	if (gameState?.betAmount[game] === '') {
		console.log(
			{
				state,
				game,
				checkBalance,
			},
			gameState?.betAmount[game],
		);
		return {
			type: 'betAmount',
			error: isInputActive('betAmount', state)
				? 'Please, check your bet amount'
				: true,
		};
	}
	if (gameState?.payoutValue[game] === '' && isChanceValidate) {
		// console.log({state, game, checkBalance}, gameState?.payoutValue[game]);
		return {
			type: 'payoutValue',
			error: isInputActive('payoutValue', state)
				? 'Please, type payout value'
				: true,
		};
	}
	if (gameState?.winChanceValue[game] === '' && isChanceValidate) {
		// console.log({state, game, checkBalance}, gameState?.winChanceValue[game]);
		return {
			type: 'winChanceValue',
			error: isInputActive('winChanceValue', state)
				? 'Please, type win chance value'
				: true,
		};
	}
	if (state.gameValidation?.profitError[game] && isChanceValidate) {
		// console.log({state, game, checkBalance}, state.gameValidation?.profitError[game]);
		return {
			type: 'profitValue',
			error: state.gameValidation?.profitError[game],
		};
	}
	const validateWinChanceResult = isChanceValidate
		? validateWinChance(gameState?.winChanceValue[game], game)
		: {
				valid: true,
		  };
	const validateBetAmountResult = validateBetAmount({
		value: gameState?.betAmount[game],
		balances: state.balance.balances,
		isLogged: state.user.isLogged,
		currency: state.user.activeCurrency,
		checkBalance,
		game,
	});
	const validatePayoutResult = isChanceValidate
		? validatePayout(gameState?.payoutValue[game], game)
		: {
				valid: true,
		  };
	if (
		!validateWinChanceResult.valid ||
		!validatePayoutResult.valid ||
		validateBetAmountResult.error
	) {
		return {
			validators: {
				winChance: validateWinChanceResult.valid
					? false
					: validateWinChanceResult,
				payout: validatePayoutResult.valid ? false : validatePayoutResult,
				betAmount: validateBetAmountResult.error
					? validateBetAmountResult
					: false,
			},
			error: true,
		};
	}
	if (mode === 'flash') {
		if (
			state.gameAutobet?.rolls < config.games.flashbet.minRolls ||
			state.gameAutobet?.rolls > config.games.flashbet.maxRolls ||
			!state.gameAutobet?.rolls
		) {
			return {
				type: 'rolls',
				error: true,
			};
		}
	}
	if (
		mode === 'auto' &&
		state.gameAutobet.rolls > config.games.autobet.maxRolls
	) {
		return {
			type: 'rolls',
			error: true,
		};
	}
	const maxProfit = getLimits({
		currency: state.user.activeCurrency,
		game,
	})?.profit_maximum;
	// console.log({
	// 	maxProfit,
	// 	currency: state.user.activeCurrency,
	// 	game,
	// 	limits: getLimits({ currency: state.user.activeCurrency, game }),
	// });
	if (maxProfit) {
		if (game === 'hilo') {
			const bool = ['high', 'low', 'red', 'black', 'number', 'letter'].every(
				(el) =>
					getProfit(
						state.game?.betAmount[game],
						getPayout(getHiloWinChance(el, state.hilo?.activeCard), game),
					) > maxProfit,
			);
			if (bool) {
				return {
					type: 'profit',
					error: {
						label:
							'Possible profit on every option exceeded max profit ({{value}} {{currency}})',
						options: {
							value: maxProfit,
							currency: state.user.activeCurrency.toUpperCase(),
						},
					},
				};
			}
		} else {
			const validateProfitResult = validateProfitAmount({
				value: getProfit(
					state.game?.betAmount[game],
					gameState?.payoutValue[game],
				),
				game,
				currency: state.user.activeCurrency,
			});
			if (validateProfitResult?.error) {
				return {
					type: 'profit',
					error: {
						label: 'Exceeds max profit for this game',
					},
				};
			}
		}
	}
	return {
		error: false,
	};
};
