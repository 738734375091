import { createContext, FC, PropsWithChildren } from 'react';
import { useInsaneBonusCode } from '../../hooks/useInsaneBonusCode';

export const InsaneBonusCodeContext = createContext<
	ReturnType<typeof useInsaneBonusCode>
>({
	check: null,
	lastCommand: null,
});

export const InsaneBonusCodeWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const result = useInsaneBonusCode();
	return (
		<InsaneBonusCodeContext.Provider value={result}>
			{children}
		</InsaneBonusCodeContext.Provider>
	);
};
