import Redux, { createStore as _createStore } from 'redux';
import { createEpicManager, createReducerManager } from './storeManagers';

export let reducerManager = null;
export let epicManager = null;

export const createStore = (
	staticReducers: unknown,
	initialState = {},
	middleware: Redux.StoreEnhancer,
): Redux.Store => {
	reducerManager = createReducerManager(staticReducers);
	epicManager = createEpicManager();
	return _createStore(reducerManager.reduce, initialState, middleware);
};
