export const checkIsLocaleInPathname = (
	pathname: string,
	locale: string,
): boolean => {
	const localeString = `/${locale}`;
	const isMultipleSlashes = pathname.split('/').length > 2;
	if (!isMultipleSlashes) {
		return pathname === localeString;
	}
	return pathname.indexOf(`/${locale}`) === 0;
};
