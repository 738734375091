import { combineReducers } from 'redux';
import { setReducer } from '@legacyApp/methods/store/setReducer';
import {
	PWA_SET_ACTIVE,
	PWA_SET_CALLBACK,
	PWA_SET_MODE,
} from './pwa.constants';

const installActiveReducer = setReducer(false, PWA_SET_ACTIVE);
const callbackReducer = setReducer(false, PWA_SET_CALLBACK);
const modeReducer = setReducer(false, PWA_SET_MODE);

const pwaReducer = combineReducers({
	installActive: installActiveReducer,
	callback: callbackReducer,
	mode: modeReducer,
});

export { pwaReducer };
