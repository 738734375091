import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Trans } from '@legacyApp/client/modules/translation/translate';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import {
	PriceConverter,
	PriceConverterStyled,
} from '../priceConverter/PriceConverter';
import {
	FooterColumnStyle,
	FooterLabelStyled,
	FooterRowStyle,
} from './Footer.styled';
import { CurrenciesCarousel } from './CurrenciesCarousel';

export const FooterTopRow: FC = () => {
	return (
		<FooterTopRowStyled>
			<FooterColumnStyle>
				<FooterLabelStyled>
					<Trans label={'Conversion'} />
				</FooterLabelStyled>
				<PriceConverter />
			</FooterColumnStyle>
			<FooterColumnStyle>
				<FooterLabelStyled>
					<Trans label={'Accepted currencies'} />
				</FooterLabelStyled>
				<CurrenciesCarousel />
			</FooterColumnStyle>
		</FooterTopRowStyled>
	);
};

export const FooterTopRowStyled = styled(FooterRowStyle)`
	display: grid;
	gap: 40px;
	grid-template-columns: calc((100% - 40px) / 2) calc((100% - 40px) / 2);

	${PriceConverterStyled} {
		width: 100%;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_tablet_medium,
						b: viewportPadding,
					})}) {
				grid-template-columns: 100%;
			}
		`,
	)}
`;
