import { simpleTrans } from '../../client/modules/translation/translationService';
import { useAddNamespace } from './useAddNamespace';

export const useStringTrans = (namespace) => {
	useAddNamespace({ namespace });

	return (string: string, data?: object) => {
		// console.log('trans', {res: simpleTrans(string, data, namespace), string, data, namespace});
		return simpleTrans(string, data, namespace);
	};
};
