import { BetSlipTypesType } from '../types/BetSlip/BetSlipTypesType';
import { BetSlipAcceptTypeType } from '../types/BetSlip/BetSlipAcceptTypeType';

export const BETSLIP_MULTI_LIMIT = 20;

export const BETSLIP_TYPE = {
	SINGLE: 'single',
	MULTI: 'multi',
} as const;

export const BETSLIP_TYPES: BetSlipTypesType[] = [
	{
		id: BETSLIP_TYPE.SINGLE,
		name: 'Single',
	},
	{
		id: BETSLIP_TYPE.MULTI,
		name: 'Multi',
	},
];

export const BETSLIP_ACCEPT_TYPES: BetSlipAcceptTypeType[] = [
	{
		id: 0,
		name: 'no odds changes accepted',
	},
	{
		id: 1,
		name: 'accept only higher odds',
	},
	{
		id: 2,
		name: 'accept any odds',
	},
];

export const OFFER_CLOSED_ERROR = 'Offer closed';
export const OFFER_SUSPENDED_ERROR = 'Suspended';
