import {
	PREFERENCES_INIT,
	PREFERENCES_SET,
	PREFERENCES_UPDATE,
} from './preferences.constants';

export const preferencesSet = (data_type, payload) => ({
	type: PREFERENCES_SET,
	payload,
	data_type,
});

export const preferencesUpdate = (data_type, payload) => ({
	type: PREFERENCES_UPDATE,
	payload,
	data_type,
});

export const preferencesInit = (data_type, payload) => ({
	type: PREFERENCES_INIT,
	payload,
	data_type,
});
