import Cookies from 'js-cookie';
import { jsonToString } from '@common/methods/jsonToString';
import { cookieConfig } from '@server/config/cookie.config';
import { isFunction } from '../methods/isFunction';
import { isTrueOrZero } from '../methods/isTrueOrZero';

class CookieClass {
	getCtxCookies = (ctx) => {
		console.log('getCtxCookiesFunc', ctx);
		return ctx?.req?.cookies || {};
	};

	get = (
		key: string,
		defaultValue: any = null,
		ctx = undefined,
		parse?: (value: string | boolean) => string | boolean,
	) => {
		if (ctx?.res) {
			const ctxCookies = this.getCtxCookies(ctx);
			console.log('cookieGet', key, ctxCookies[key] || defaultValue);
			return ctxCookies[key] || defaultValue;
		}
		let value: string | boolean = Cookies.get(key);
		// console.log('cookieGet', key, value, defaultValue);
		if (
			['undefined', 'null', 'false', undefined, null].indexOf(value as string) >
			-1
		) {
			return defaultValue;
		}
		if (value === 'true') {
			value = true;
		}
		if (isFunction(parse)) {
			return parse(value);
		}
		return value;
	};

	set = (
		key: string,
		data: any,
		ctx?: any,
		expires?: number,
		options?: Cookies.CookieAttributes,
	) => {
		const attrs: Cookies.CookieAttributes = {
			expires:
				expires ||
				(ctx?.res ? new Date(Date.now() + 1000 * 60 * 60 * 24 * 30) : 30),
			...cookieConfig,
			...(options || {}),
		};
		if (ctx?.res) {
			ctx.res.cookie(key, jsonToString(data), attrs);
		}
		if (!isTrueOrZero(data) && data !== false) {
			return this.clear(key);
		}
		Cookies.set(key, data, attrs);
	};

	clear = (key: string, ctx?: any) => {
		if (ctx?.res) {
			console.log('cookieClear', key);
			ctx.res.clearCookie(key);
		}
		Cookies.remove(key);
	};
}

export const cookieService = new CookieClass();
