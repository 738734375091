import {
	FontAwesomeIcon,
	// eslint-disable-next-line import/named
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

interface AwesomeIconProps extends FontAwesomeIconProps {
	icon: any;
	className?: string;
}

export const AwesomeIcon = (props: AwesomeIconProps) => {
	if (!props.icon) {
		return null;
	}
	return <FontAwesomeIcon {...props} />;
};
