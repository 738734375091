import React, { FC } from 'react';
import { Redirect } from '@common/components/default/Redirect/Redirect';
import { Image, parseImageSrc } from '@common/components/default/Image/Image';
import { isFeatureAvailable } from '@legacyApp/client/modules/app/featureService';
import { FEATURES_TYPE } from '@legacyApp/client/config';

export const RngLicence: FC = () => {
	if (!isFeatureAvailable(FEATURES_TYPE.LICENSE)) {
		return null;
	}
	return (
		<Redirect
			href={parseImageSrc(
				'/documents/RNG_Certificate_HDSTechnologiesNV_UK_03Jul2019.pdf',
			)}
			noLocale={true}
			target="_blank"
		>
			<Image src={'/img/rng/rng_new.png'} alt="RNG Certificate" />
		</Redirect>
	);
};
