import { LoginNameType } from '@legacyApp/types/user/LoginNameType';

export enum ChatBotTypes {
	TIP = 'tip',
	INFO = 'info',
	RAIN = 'rain',
}

export type ChatBotLogin = `${ChatBotTypes}bot`;

export const createBotLogin = (type: ChatBotTypes): ChatBotLogin => {
	return `${type}bot`;
};

export const CHAT_BOTS = Object.keys(ChatBotTypes).map((key: ChatBotTypes) =>
	createBotLogin(ChatBotTypes[key]),
);

export const getBotType = (username: LoginNameType): ChatBotTypes => {
	const botType = String(username).replace('bot', '');
	return botType as ChatBotTypes;
};

export const checkIsBotLogin = (username: LoginNameType): boolean => {
	return CHAT_BOTS.indexOf(String(username) as ChatBotLogin) > -1;
};
