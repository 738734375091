import { combineReducers } from 'redux';
import { openSlice } from '@modules/support/store/slices/open.slice';
import { unreadSlice } from '@modules/support/store/slices/unread.slice';
import { errorSlice } from '@modules/support/store/slices/error.slice';

const supportReducer = combineReducers({
	open: openSlice.reducer,
	unread: unreadSlice.reducer,
	error: errorSlice.reducer,
});

export { supportReducer };
