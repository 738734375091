import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { cookieService } from '@common/controllers/cookieService';
import { stringToJson } from '@common/methods/stringToJson';
import { handleDeprecatedBrowserData } from '@legacyApp/client/modules/app/appService';
import { createRootReducer } from '@legacyApp/client/store/root/root.reducer';
import { CookiesKeys } from '@modules/cookies/types/cookiesKeys.type';

export const reducer = (data = {}) => {
	if (NEXT_CONFIG.RELEASE_VERSION) {
		cookieService.set(CookiesKeys.VER_28, NEXT_CONFIG.RELEASE_VERSION);
	}
	let preloadedState = cookieService.get(CookiesKeys.PRELOADED_STATE, {});
	preloadedState = preloadedState
		? stringToJson(decodeURIComponent(preloadedState), {})
		: {};
	// console.log('preloaded_state cookie', preloadedState, {
	// 	...preloadedState,
	// 	...data.serverData || {}
	// });
	cookieService.clear(CookiesKeys.PRELOADED_STATE);
	return createRootReducer({
		...data,
		...handleDeprecatedBrowserData(),
		serverData: {
			...preloadedState,
			...(data.serverData || {}),
		},
	});
};
