import { IconProps } from '@icons/all-bets';
import { FC } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Logout: FC<IconProps> = ({ fill }) => {
	const color = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M10.875 5.375L13.5 8L10.875 10.625"
						fill="transparent"
						stroke={color}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6.5 8H13.5"
						fill="transparent"
						stroke={color}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6.5 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H6.5"
						fill="transparent"
						stroke={color}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			}
			width={16}
			height={16}
		/>
	);
};
