import React from 'react';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { config } from '../../config';

export const initWhyDidYouRender = () => {
	if (NEXT_CONFIG.DEV && process.browser) {
		if (typeof window !== 'undefined') {
			const whyDidYouRender = require('@welldone-software/why-did-you-render');
			whyDidYouRender(React);
		}
	}
};

const whyDidYouRenderOptions = (component, name) => {
	if (name.indexOf('EventContent') === -1) {
		return component;
	}
	// console.log('whyDidYouRenderOptions', name);
	if (NEXT_CONFIG.DEV) {
		component.whyDidYouRender = {
			logOnDifferentValues: false,
			customName: name || undefined,
		};
	}
	return component;
};

const logger = (...args) => {
	if (config.logger) {
		console.log(...args);
	}
};

export { whyDidYouRenderOptions, logger };
