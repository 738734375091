import { createGlobalStyle, css } from 'styled-components';
import { ThemeType } from '@legacyApp/client/modules/style/theme';
import { homeBackground } from '@legacyApp/client/modules/style/mixins/homeBackground';
import { transactionsBackground } from '@legacyApp/client/modules/style/mixins/transactionsBackground';
import { StyledTextWrapper } from '../../hoc/pageEnhancer';
import { StyledContentWrapper } from '../text/Content/Content.styled';

export const HomePageBackgroundMixin = css`
	${homeBackground(
		'/img/background-main-v2.svg',
		css`linear-gradient(180deg,var(--color-dark-900),var(--color-dark-800) 90%)`,
	)};

	&.tab-bets {
		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			${transactionsBackground};
		}
	}

	&:after {
		background-size: 110%;
	}

	${StyledTextWrapper} {
		&:before {
			background: transparent;
		}
	}

	${StyledContentWrapper} {
		&:after {
			background: linear-gradient(
				180deg,
				transparent,
				var(--color-dark-800) 90%
			);
		}
	}
`;

export const StyledHomeGlobal = createGlobalStyle<{ theme: ThemeType }>`
	.page-home {
		${HomePageBackgroundMixin};
	}
`;
