import queryString from 'query-string';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { isInitModal, resetModal } from '@modules/modals/store/modal.actions';
import { PRIORITY_MODAL_CONSTANTS } from '@common/constants/modal/priority.modal.constants';
import { balanceFetch } from '@modules/balance/store/balance.actions';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { fetchApi } from '../../fetch/fetch.thunk';
import { oneSignalInit } from '../../onesignal/onesignal.actions';
import { USER_GET_DATA } from '../user.constants';
import { setAffiliateCode } from '../../affiliate/affiliate.actions';
import FetchService from '../../../modules/app/fetchService';
import { handleWithdraws } from '../../transactions/transactions.actions';
import {
	checkAmIUnique,
	getMyBets,
	hasEmailToVerify,
	hasKycToVerify,
	hasPasswordToSet,
	loggedUser,
	setEmail,
	setHasAvailableBonusCode,
	setJoinedDate,
	setSocketPrivateChannel,
	setUserAdmin,
	setUserHighRoller,
	setUserId,
	setUserModerator,
	setUserName,
	setUserRaceBadge,
	userSetWithdrawBlockedTimestamp,
} from '../user.actions';
import { OPTIONS } from '../../options/options.constants';
import { setTipMinValues } from '../../tip/tip.actions';
import { privateMessagesChannelId } from '../../privateMessages/privateMessages.actions';
import { preferencesInit } from '../../preferences/preferences.actions';
import { activate2fa } from '../../twoFactorAuthentication/twoFactorAuthentication.actions';
import { appInitialized } from '../../app/app.actions';
import { getTransactionsThunk } from '../../transactions/thunks/getTransactions.thunk';
import { optionChangeThunk } from '../../options/thunks/optionChange.thunk';
import { UserDataType } from '../types/userData.type';
import { userHandleInitModal } from './user.thunk';

export interface GetUserDataThunkProps {
	enableLoader?: boolean;
	noError?: boolean;
}

export interface UserPreferencesPrivacy {
	public_total_profit: boolean;
	public_total_wagered: boolean;
	public_bets: boolean;
}

export interface UserPreferencesCommunity {
	allow_messages_from_all: boolean;
	block_all_friend_requests: boolean;
	block_friend_requests_notifications: boolean;
	show_messages_notifications: boolean;
}

export interface UserPreferences {
	privacy: UserPreferencesPrivacy;
	community: UserPreferencesCommunity;
}

export interface UserRaceBadge {
	position: number;
	date: string; //"2023-04-18T15:10:09.000000";
}

export interface UserProfileResponse {
	user: UserDataType;
}

export const getUserDataThunk = ({
	enableLoader,
	noError,
}: GetUserDataThunkProps): AppThunk<Promise<void>> => {
	return async (dispatch, getState) => {
		const loader = enableLoader && 'getUserData';

		const data: UserProfileResponse = await dispatch(
			fetchApi({
				url: '/user/profile',
				parameters: {
					Authorization: true,
				},
				disableErrorHandler: noError,
				loaderId: loader,
			}),
		);

		const state = getState();

		const result = [];
		// Cookies.remove('csrf_token');
		console.log('USER_GET_DATA-start', {
			state: state,
			result,
			data,
		});

		if (data.user) {
			dispatch(oneSignalInit({ externalUserId: data.user.uuid }));
			if (
				!isInitModal(
					queryString.parse(state.router.location.search).modal ||
						state.modal?.id,
				)
			) {
				// @ts-expect-error invalid type
				if (PRIORITY_MODAL_CONSTANTS.indexOf(state.modal?.id) === -1) {
					dispatch(resetModal(true, USER_GET_DATA));
				}
			}
			if (data.user.channel !== state.user.socketChannel) {
				dispatch(setSocketPrivateChannel(data.user.channel));
			}
			if (state.affiliate.code) {
				dispatch(setAffiliateCode(false));
			}
			if (!FetchService.wasFetched('/user/deposits')) {
				dispatch(
					getTransactionsThunk({
						id: TABLE_ID.depositHistory,
						refresh: true,
					}),
				);
				dispatch(handleWithdraws());
			}

			if (data.user.bcheck) {
				dispatch(checkAmIUnique());
			}
			if (data.user.is_admin !== state.user.isAdmin) {
				dispatch(setUserAdmin(data.user.is_admin));
			}
			if (data.user.is_moderator !== state.user.isModerator) {
				dispatch(setUserModerator(data.user.is_moderator));
			}
			if (data.user.is_high_roller !== state.user.isHighRoller) {
				dispatch(setUserHighRoller(data.user.is_high_roller));
			}
			if (
				data.user.race_badge !== undefined &&
				data.user.race_badge !== state.user.raceBadge
			) {
				dispatch(setUserRaceBadge(data.user.race_badge));
			}
			if (data.user.uuid !== state.user.id) {
				// initInspectLet(data.user);
				dispatch(setUserId(data.user.uuid));
			}

			if (
				data.user.can_tip !== undefined &&
				!!data.user.can_tip === !state.options.tip
			) {
				dispatch(
					optionChangeThunk(OPTIONS.TIP, !!data.user.can_tip, {
						noAlert: true,
					}),
				);
			}

			if (
				data.user.withdraw_blocked !== undefined &&
				data.user.withdraw_blocked !== state.user.withdrawBlockedTimestamp
			) {
				dispatch(
					userSetWithdrawBlockedTimestamp(data.user.withdraw_blocked * 1000),
				);
			}

			if (!!data.user.tip_min_values === !state.options.tip) {
				dispatch(
					optionChangeThunk(OPTIONS.TIP, !!data.user.tip_min_values, {
						noAlert: true,
					}),
				);
			}

			if (
				data.user.tip_min_values &&
				typeof data.user.tip_min_values === 'object'
			) {
				dispatch(setTipMinValues(data.user.tip_min_values));
			}
			if (
				data.user.private_chat_channel &&
				data.user.private_chat_channel !== state.privateMessages.channelId
			) {
				dispatch(privateMessagesChannelId(data.user.private_chat_channel));
			}
			if (data.user.login !== state.user.name) {
				dispatch(setUserName(data.user.login));
			}
			if (data.user.joined !== state.user.joinedDate) {
				dispatch(setJoinedDate(data.user.joined));
			}
			if (data.user.preferences) {
				if (!data.user.preferences.privacy) {
					dispatch(preferencesInit('privacy', data.user.preferences));
				} else {
					dispatch(preferencesInit('privacy', data.user.preferences.privacy));
					dispatch(
						preferencesInit('community', data.user.preferences.community),
					);
				}
			}
			if (data.user.email !== state.user.email) {
				dispatch(setEmail(data.user.email));
			}

			if (data.user.two_factor_authentication !== state.twoFA.isActive) {
				dispatch(activate2fa());
			}

			if (data.user.has_email_to_verify !== state.user.hasEmailToVerify) {
				dispatch(hasEmailToVerify(data.user.has_email_to_verify));
			}

			if (data.user.has_kyc_to_verify !== state.user.hasKycToVerify) {
				dispatch(hasKycToVerify(data.user.has_kyc_to_verify));
			}

			if (data.user.has_password_to_set !== state.user.hasPasswordToSet) {
				dispatch(hasPasswordToSet(data.user.has_password_to_set));
			}

			if (data.user.has_available_bonus_code) {
				dispatch(setHasAvailableBonusCode(data.user.has_available_bonus_code));
			}

			if (!state.user.isLogged) {
				dispatch(loggedUser(true));
			}
			dispatch(balanceFetch());
		}

		if (data) {
			dispatch(userHandleInitModal(data, !!state.app.initialized));
			if (!state.app.initialized) {
				dispatch(appInitialized('getUserDataEpic'));
			}
		}

		dispatch(getMyBets(1, true));
	};
};
