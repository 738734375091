import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { showAlert } from '@legacyApp/client/store/alerts/alerts.actions';
import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';
import { getSportsApiUrl } from '@legacyApp/client/modules/app/sportsService';
import { parseApiInfo } from '@legacyApp/client/modules/app/errorsService';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { loop } from '@legacyApp/client/modules/app/appService';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { sportsSetSideContent } from '../sports/sports.thunk';
import { updateBalance } from '../../../balance/store/balance.actions';
import { BETSLIP_MULTI_LIMIT, BETSLIP_TYPE } from '../../constants/betSlip';
import { chatActions } from '../../../chat/store/chat.actions';
import { BetSlipBet } from '../../types/BetSlip/BetSlipBet';
import { isBetSlipToOpen } from '../../methods/isBetSlipToOpen';
import { BetSlipTypeType } from '../../types/BetSlip/BetSlipType';
import {
	sportsBetSlipSetBets,
	sportsBetSlipSetBetsList,
	sportsBetSlipSetFormError,
	sportsBetSlipSetPlacedBet,
	sportsBetSlipSetPlacingBet,
	sportsBetSlipSetTypeAction,
} from './sportsBetSlip.actions';

export const sportsBetSlipUpdate = (data): AppThunk => {
	return (dispatch, getState) => {
		const list = [];
		Object.keys(data).forEach((matchId) => {
			Object.keys(data[matchId].outcome).forEach((outcomeId) => {
				const betsList = getState().sportsBetSlip.betsList;
				const bet = betsList.find(
					(el) => el.matchId === matchId && outcomeId === el.outcomeId,
				);
				// console.log('sportsBetSlipUpdate', {
				// 	bet,
				// 	betsList,
				// 	matchId,
				// 	outcomeId,
				// });
				list.push({
					...(bet || {}),
					matchId,
					outcomeId,
					info: bet?.info || null,
					stake: bet?.stake || 0,
					selected_at: data[matchId].outcome[outcomeId].selected_at,
				});
			});
		});
		if (typeof data === 'object' && !Object.keys(data).length) {
			dispatch(sportsBetSlipSetFormError({ type: null, message: null }));
		}
		dispatch(sportsBetSlipSetBets(data));
		dispatch(validateBetSlipList({ list }));
	};
};

export const sportsBetSlipToggleBet = ({ data, outcome }): AppThunk => {
	return (dispatch, getState) => {
		const state = getState();
		const { bets, betsList } = state.sportsBetSlip;
		const matchId = data?.id;
		const outcomeId = outcome?.id;

		if (!matchId) {
			dispatch(sportsBetSlipOpen());
			return dispatch(showAlert('error', 'Toggle outcome - missing match id'));
		}
		const newState = { ...bets };
		if (!outcomeId) {
			delete newState[matchId];
			return dispatch(sportsBetSlipUpdate(newState));
		}
		if (matchId && outcomeId && newState[matchId]?.outcome?.[outcomeId]) {
			delete newState[matchId].outcome[outcomeId];
			if (!Object.keys(newState[matchId].outcome).length) {
				delete newState[matchId];
			}
		} else if (
			betsList.length >= BETSLIP_MULTI_LIMIT &&
			getState().sportsBetSlip.type.id === 'multi'
		) {
			return dispatch(
				showAlert('info', 'Exceeded max events amount on single multi bet'),
			);
		} else if (matchId && outcomeId && newState[matchId]) {
			dispatch(sportsBetSlipOpen());
			newState[matchId].outcome[outcomeId] = {
				...outcome,
				selected_at: getTimestamp(),
			};
		} else if (matchId && outcomeId) {
			dispatch(sportsBetSlipOpen());
			newState[matchId] = {
				data: { ...data },
				outcome: {
					[outcomeId]: {
						...outcome,
						selected_at: getTimestamp(),
					},
				},
			};
		} else {
			return;
		}
		return dispatch(sportsBetSlipUpdate(newState));
	};
};

export const sportsBetSlipOpen = () => {
	return (dispatch, getState) => {
		const state = getState();
		if (isBetSlipToOpen(state)) {
			// TODO: chat store usage
			if (!state?.chat?.visible) {
				dispatch(chatActions.setVisible({ isVisible: true }));
			}
			dispatch(sportsSetSideContent('bet_slip'));
		}
	};
};

export const sportsBetSlipDeleteBet = (matchId, outcomeId): AppThunk => {
	return (dispatch) => {
		return dispatch(
			sportsBetSlipToggleBet({
				data: { id: matchId },
				outcome: { id: outcomeId },
			}),
		);
	};
};

export const sportsBetSlipSubmit = (stake: number): AppThunk => {
	return (dispatch, getState) => {
		const state = getState();
		if (!state.user.isLogged) {
			return dispatch(showAlert('error', 'Please, login to place bet'));
		}

		const betsList = state.sportsBetSlip.betsList;

		if (!betsList.length) {
			return dispatch(showAlert('error', 'Invalid bet slip'));
		}

		if (getState().sportsBetSlip.error.type) {
			dispatch(
				sportsBetSlipSetFormError({
					type: null,
					message: null,
				}),
			);
		}

		if (state.sportsBetSlip.type.id === 'single') {
			return dispatch(placeSingleBetSlip(betsList));
		} else if (state.sportsBetSlip.type.id === 'multi') {
			return dispatch(placeMultiBetSlip(betsList, stake));
		}
		return null;
	};
};

const placeSingleBetSlip = (betsList): AppThunk => {
	return (dispatch, getState) => {
		dispatch(sportsBetSlipSetPlacingBet(betsList.length));
		betsList.forEach((bet) => {
			const betData =
				getState().sportsBetSlip.bets[bet.matchId].outcome[bet.outcomeId];
			dispatch(placeSingleBet(bet, betData));
		});
	};
};

const placeSingleBet = (bet, betData): AppThunk => {
	return (dispatch, getState) => {
		console.log('placeSingleBetSlip - single', { betData });
		dispatch(
			fetchApi({
				url: getSportsApiUrl(`/betslips/place?id=${bet.outcomeId}`),
				method: 'POST',
				disableErrorHandler: true,
				parameters: {
					body: {
						currency: getState().user.activeCurrency,
						amount: bet.stake,
						odds_accept: getState().sportsBetSlip.acceptType.id,
						type: 1, // single
						events: [
							{
								odds: betData.decimal_odds,
								offer_id: betData.offer_id,
								outcome_id: bet.outcomeId,
							},
						],
					},
				},
				loaderId: 'postBetslipsPlace',
			}),
		)
			.then((response) => {
				const placed_at = getTimestamp();
				if (response.userBalance) {
					dispatch(updateBalance(response.userBalance, 'placeBetSlip'));
					dispatch(
						sportsBetSlipSetBetsList(
							getState().sportsBetSlip.betsList.map((el) => {
								if (el.outcomeId === bet.outcomeId) {
									return {
										...el,
										info: null,
										error: null,
										placed_at,
										result_odds:
											response?.betslip?.betslip_events?.[0]?.decimal_odds ||
											null,
									};
								}
								return el;
							}),
						),
					);
				} else {
					dispatch(
						sportsBetSlipSetBetsList(
							getState().sportsBetSlip.betsList.map((el) => {
								return el.outcomeId === bet.outcomeId
									? {
											...el,
											error: null,
											info: parseApiInfo(response, undefined, 'sports'),
											placed_at,
									  }
									: el;
							}),
						),
					);
				}
				dispatch(sportsBetSlipSetPlacedBet());
			})
			.catch((error) => {
				const placed_at = getTimestamp();
				const betData =
					getState().sportsBetSlip.bets[bet.matchId].outcome[bet.outcomeId];
				sentryError(
					error,
					{
						bet,
						betData,
						type: 'single',
					},
					getState(),
				);
				dispatch(
					sportsBetSlipSetBetsList(
						getState().sportsBetSlip.betsList.map((el) => {
							return el.outcomeId !== bet.outcomeId
								? {
										...el,
										error: null,
										info: 'Connection error, please try again later',
										placed_at,
								  }
								: el;
						}),
					),
				);
				dispatch(sportsBetSlipSetPlacedBet());
			});
	};
};

const placeMultiBetSlip = (betsList, stake): AppThunk => {
	return (dispatch, getState) => {
		const events = betsList.map((bet) => {
			const betData =
				getState().sportsBetSlip.bets[bet.matchId].outcome[bet.outcomeId];
			return {
				odds: betData.decimal_odds,
				offer_id: betData.offer_id,
				outcome_id: bet.outcomeId,
			};
		});

		dispatch(sportsBetSlipSetPlacingBet(1));
		dispatch(
			fetchApi({
				url: getSportsApiUrl('/betslips/place'),
				method: 'POST',
				disableErrorHandler: true,
				parameters: {
					body: {
						currency: getState().user.activeCurrency,
						odds_accept: getState().sportsBetSlip.acceptType.id,
						amount: stake,
						type: 2, // multi
						events,
					},
				},
				loaderId: 'postBetslipsPlaceMulti',
			}),
		)
			.then((response) => {
				const placed_at = getTimestamp();
				if (response.userBalance) {
					dispatch(updateBalance(response.userBalance, 'placeMultiBetSlip'));
					dispatch(
						sportsBetSlipSetFormError({
							type: 'success',
							message: {
								default: {
									message: 'Bet slip placed!',
									namespace: 'sports',
								},
							},
						}),
					);
					dispatch(
						sportsBetSlipSetBetsList(
							getState().sportsBetSlip.betsList.map((el) => ({
								...el,
								error: null,
								info: null,
								placed_at,
								result_odds:
									response?.betslip?.betslip_events?.find(
										(event) => event.outcome.id === el.outcomeId,
									)?.decimal_odds || null,
							})),
						),
					);
				} else {
					dispatch(
						showAlert('error', parseApiInfo(response, undefined, 'sports')),
					);
					dispatch(
						sportsBetSlipSetBetsList(
							getState().sportsBetSlip.betsList.map((el) => ({
								...el,
								error: null,
								info: null,
							})),
						),
					);
				}
				dispatch(sportsBetSlipSetPlacedBet());
			})
			.catch(() => {
				dispatch(
					showAlert('error', 'Connection error, please try again later'),
				);
				dispatch(
					sportsBetSlipSetBetsList(
						getState().sportsBetSlip.betsList.map((el) => ({
							...el,
							error: null,
							info: null,
						})),
					),
				);
				dispatch(sportsBetSlipSetPlacedBet());
			});
	};
};

export const sportsBetSlipClearPlacedBets = (): AppThunk => {
	return (dispatch, getState) => {
		if (!getState().sportsBetSlip?.betsList?.length) {
			return;
		}
		const placedBets = getState().sportsBetSlip.betsList.filter(
			(el) => el.placed_at,
		);
		if (placedBets.length) {
			dispatch(sportsBetSlipSetFormError({ type: null, message: null }));
		}
		loop(placedBets, (bet) => {
			dispatch(
				sportsBetSlipToggleBet({
					data: { id: bet.matchId },
					outcome: { id: bet.outcomeId },
				}),
			);
		}).then(() => {
			const state = getState();
			// dispatch(validateBetSlipList());
			if (state.sportsBetSlip.error.type === 'success') {
				dispatch(
					sportsBetSlipSetFormError({
						type: null,
						message: null,
					}),
				);
			}
		});
	};
};

export const sportsBetSlipSetType = (type: BetSlipTypeType): AppThunk => {
	return (dispatch) => {
		dispatch(sportsBetSlipSetTypeAction(type));
		dispatch(sportsBetSlipClearPlacedBets());
		dispatch(validateBetSlipList({ betSlipType: type }));
	};
};

export const validateBetSlipList = ({
	list,
	betSlipType,
}: {
	list?: BetSlipBet[];
	betSlipType?: BetSlipTypeType;
}): AppThunk => {
	return (dispatch, getState) => {
		const type = betSlipType ?? getState().sportsBetSlip.type.id;
		let betsList = list ?? getState().sportsBetSlip.betsList;
		console.log('validateBetSlipList', {
			betsList,
			type,
		});
		betsList = betsList.map((el) => {
			const isMultiMatch = betsList.some(
				(bet) => el.matchId === bet.matchId && bet.outcomeId !== el.outcomeId,
			);
			if (type === BETSLIP_TYPE.SINGLE) {
				return {
					...el,
					error: {
						...el.error,
						multiMatch: null,
					},
				};
			}
			if (type === BETSLIP_TYPE.MULTI) {
				return {
					...el,
					error: {
						...el.error,
						multiMatch: isMultiMatch
							? {
									message:
										'Offers from the same match cannot be placed in multi mode',
									namespace: 'sports',
							  }
							: null,
					},
				};
			}
			return el;
		});
		dispatch(sportsBetSlipSetBetsList(betsList));
	};
};
