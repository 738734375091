import { useEffect } from 'react';
import { useAppLanguage } from '@common/selectors/app.selectors';
import { translationService } from '../../client/modules/translation/translationService';
import { TransNamespace } from '../../client/modules/translation/TransNamespace';

export const useCheckNamespaces = ({
	namespaces,
}: {
	namespaces: Array<TransNamespace>;
}) => {
	const language = useAppLanguage();
	useEffect(() => {
		if (!namespaces?.length) {
			return;
		}
		// console.log('useCheckNamespaces', { namespaces });
		namespaces.forEach((namespace) =>
			translationService.getTranslationObject(namespace, language),
		);
	}, [namespaces?.join(''), language]);
};
