import { combineEpics } from 'redux-observable';
import { catchError, map } from 'rxjs/operators';
import { from } from 'rxjs';
import { epic } from '../../modules/app/epicService';
import { FETCH_RESPONSE } from './fetch.constants';

const responseEpic = epic('responseEpic', {
	actions: (ofType) => ofType(FETCH_RESPONSE),
	callback: ({ action }) => {
		return from(action.payload.response).pipe(
			map(action.payload.callback),
			catchError(action.payload.catchCallback),
		);
	},
});

const fetchEpic = combineEpics(responseEpic);

export { fetchEpic };
