import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
import { config } from '@legacyApp/client/config';
import { Message } from '../../types/Message.type';
import { parseStream } from '../../methods/parseStream';

const initialState = {
	[config.chat.rooms[0].name]: [],
};

const NAME = 'stream';
export type ValueType = Record<string, Array<Message>>;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<{
		room: string;
		list: Array<Message>;
	}>
> = (state, action) => {
	return {
		...state,
		[action.payload.room]: parseStream(action.payload.list),
	};
};

const updateAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<{
		room: string;
		data: Message;
	}>
> = (state, action) => {
	const data = action.payload.data;
	return {
		...state,
		[action.payload.room]: parseStream([...state[action.payload.room], data]),
	};
};

export const streamSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setStream: setAction,
		updateStream: updateAction,
	},
});
