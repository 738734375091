import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { notificationEnable } from '@legacyApp/client/store/notification/notification.actions';
import { NOTIFICATION_ID } from '@legacyApp/client/store/notification/notification.constants';
import { supportUpdateUserThunk } from '@modules/support/store/thunks/supportUpdateUser.thunk';
import { VipLevel } from '../types/vip.types';
import { openModal } from '../../../modals/store/modal.actions';
import { vipStatusUpdateThunk } from './vipStatusUpdate.thunk';

export const vipIncreaseLevelThunk = (level: VipLevel['level']): AppThunk => {
	return (dispatch) => {
		if (!level) {
			return;
		}
		dispatch(vipStatusUpdateThunk(true));
		dispatch(notificationEnable(NOTIFICATION_ID.HAS_BONUS_CODE));
		dispatch(
			openModal(MODAL_ID.VIP_LEVEL_INCREASED, 'levelIncreaseEpic', {
				level,
			}),
		);
		dispatch(
			supportUpdateUserThunk({
				vip: level,
			}),
		);
	};
};
