import { combineEpics } from 'redux-observable';
import { delay, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { openModal, resetModal } from '@modules/modals/store/modal.actions';
import {
	resetAutoSession,
	resetGameSession,
} from '@modules/games/Game/store/gameSession/gameSession.actions';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import { clearSportsBookSession } from '@modules/sportsbook/methods/clearSportsBookSession';
import { supportUpdateUserThunk } from '@modules/support/store/thunks/supportUpdateUser.thunk';
import {
	updatePasswordConfirmValue,
	updatePasswordValue,
} from '../forms/forms.actions';
import {
	ACTIVATE_2FA,
	UNINSTALL_2FA,
} from '../twoFactorAuthentication/twoFactorAuthentication.constants';
import {
	setNewMyBets,
	transactionsSetMeta,
	transactionsUpdating,
} from '../transactions/transactions.actions';
import { appGoTo } from '../app/app.actions';
import { resetActiveCurrency } from '../../modules/app/currencyService';
import transactionService from '../../../../modules/transactions/transactionsService';
import { config, FEATURES_TYPE } from '../../config';
import { registerEvent } from '../gtm/gtm.actions';
import { getBcheck } from '../../modules/app/fingerprintService';
import { showAlert } from '../alerts/alerts.actions';
import { loaderModuleLoaded } from '../loader/loader.actions';
import { isWithdrawBlocked } from '../../modules/app/withdrawService';
import { epic, epicOldState } from '../../modules/app/epicService';
import { clearStatsLocalStorage } from '../../modules/app/gameSessionService';

import { isFeatureAvailable } from '../../modules/app/featureService';
import { fetchApiAction, fetchLocalAction } from '../fetch/fetch.thunk';
import { checkToken, tokenService } from '../../modules/app/tokenService';
import { sentryUpdateUser } from '../../modules/app/sentryService';
import { bindSocketPrivateChannelEvent } from '../websocket/websocket.actions';
import websocketService from '../../modules/app/websocketService';
import routingService from '../../modules/app/routingService';
import { WEBSOCKET_EVENTS } from '../websocket/websocket.constants';
import {
	CHANGE_CURRENCY,
	CHANGE_EMAIL,
	CHANGE_NAME,
	CHANGE_PASSWORD,
	CHECK_AM_I_UNIQUE,
	REFRESH_USER_DATA,
	RESEND_VERIFICATION_EMAIL,
	SEND_NEW_PASSWORD,
	SET_EMAIL,
	SET_HAS_AVAILABLE_BONUS_CODE,
	SET_NEW_PASSWORD,
	SET_TOKEN,
	SET_USER_ID,
	USER_GET_BETS,
	USER_GET_DATA,
	USER_HANDLE_LOGIN_RESPONSE,
	USER_HANDLE_REGISTER_RESPONSE,
	USER_HAS_EMAIL_TO_VERIFY,
	USER_LOGGED,
	USER_LOGGING,
	USER_LOGOUT,
	USER_REGISTER_SEND,
	USER_SESSION_ENDED,
	USER_SET_WITHDRAW_BLOCKED_TIMESTAMP,
} from './user.constants';
import {
	getUserData,
	hasPasswordToSet,
	loggedUser,
	logoutUser,
	notLoggedUser,
	refreshUserData,
	resetData,
	resetToken,
	setCurrency,
	setEmail,
	setFirstLogin,
	setLastRegisterTimestamp,
	setToken,
	setUserName,
	userHandleLoginResponse,
	userSetWithdrawBlocked,
} from './user.actions';
import { bonusInfo } from './thunks/user.thunk';
import { getUserDataThunk } from './thunks/getUserData.thunk';
import { newMyBetThunk } from './thunks/newMyBet.thunk';

const getUserDataEpic = epic('getUserDataEpic', {
	actions: (ofType) => ofType(USER_GET_DATA),
	callback: ({ action }) => {
		return of(getUserDataThunk(action));
	},
});

const changeCurrencyEpic = epic('changeCurrencyEpic', {
	actions: (ofType) => ofType(CHANGE_CURRENCY),
	callback: ({ action }) => {
		return of([setCurrency(action.payload), resetGameSession()]);
	},
});

const sessionEpic = epic('sessionEpic', {
	actions: (ofType) => ofType(USER_SESSION_ENDED),
	callback: ({ store$ }) => {
		// sentryError(new Error('User session ended'), {
		//   localStorage,
		//   state: store$.value,
		// });
		const actions = [];
		if (store$.value.modal?.id) {
			actions.push(resetModal(false, USER_SESSION_ENDED));
		}
		return of([
			...actions,
			resetToken(),
			logoutUser(false),
			openModal(MODAL_ID.SESSION_ENDED, 'user_session_ended'),
			// showAlert('error', 'Current session ended, please login again'),
		]);
	},
});

const myBetsEpic = epic('myBetsEpic', {
	actions: (ofType) => ofType(USER_GET_BETS),
	callback: ({ action, store$ }) => {
		if (config.disableFeatures[FEATURES_TYPE.MY_BETS]) {
			return of();
		}
		const isLogged = checkToken();
		if (!isLogged) {
			return of();
		}
		const page = action.page || 1;
		return of(
			fetchApiAction(
				{
					url: `/user/bets?page=${page}${
						action.perPage ? `&per_page=${action.perPage}` : ''
					}`,
					parameters: {
						Authorization: true,
					},
					loaderId: LOADING_IDS.GET_USER_BETS,
				},
				(data) => {
					const result = [];
					if (data.meta) {
						result.push(transactionsSetMeta(TABLE_ID.myBets, data.meta));
					}
					if (data.data) {
						const bets =
							page > 1
								? data.data
								: transactionService.concatMyBetsFromFetch(
										data.data,
										store$.value.user.id,
										data.meta.per_page,
								  );
						result.push(setNewMyBets(bets));
					}
					result.push(
						transactionsUpdating(TABLE_ID.myBets, true),
						bindSocketPrivateChannelEvent(
							WEBSOCKET_EVENTS.MY_BETS,
							(data, dispatch) =>
								dispatch(newMyBetThunk(websocketService.getMessage(data))),
						),
					);
					return result;
				},
			),
		);
	},
});

const loginUserEpic = epic('loginUserEpic', {
	actions: (ofType) => ofType(USER_LOGGING),
	callback: ({ action }) => {
		if (action.name.length && action.name.indexOf('@') > -1) {
			return of(showAlert('error', 'Invalid login'));
		}
		const request = getBcheck();
		return from(request).pipe(
			map((bcheck) => {
				return [
					fetchLocalAction(
						{
							url: '/login',
							parameters: {
								body: {
									login: action.name,
									password: action.password,
									bcheck,
								},
							},
							method: 'POST',
							loadingId: 'loginUser',
						},
						(data) => [userHandleLoginResponse(data)],
						() => of(notLoggedUser('error.login')),
					),
				];
			}),
		);
	},
});

const handleLoginResponseEpic = epic('handleLoginResponseEpic', {
	actions: (ofType) => ofType(USER_HANDLE_LOGIN_RESPONSE),
	callback: ({ action }) => {
		const data = action.payload;
		// console.log(data);
		if (
			data.error &&
			typeof data.error === 'string' &&
			data.error.indexOf(config.errors.tooManyFailedLogins) > -1
		) {
			return [appGoTo(ROUTING_ID.USER_ACCOUNT_BLOCKED)];
		}
		const result = [];
		if (data.access_token || data.value) {
			tokenService.set(data);
			result.push(
				setToken(data),
				resetModal(false, USER_HANDLE_LOGIN_RESPONSE),
			);
		}
		return result;
	},
});

const registerUserEpic = epic('registerUserEpic', {
	actions: (ofType) => ofType(USER_REGISTER_SEND),
	callback: ({ action, store$ }) => {
		const state = store$.value;
		// console.log('checkMulti', {
		//   last: state.user.lastRegisterTimestamp,
		//   curr: new Date().getTime(),
		//   calc: new Date().getTime() - config.lastRegisterTimeout,
		// });
		if (
			state.user.lastRegisterTimestamp >=
				new Date().getTime() - config.lastRegisterTimeout &&
			NEXT_CONFIG.PRODUCTION
		) {
			return of(
				loaderModuleLoaded('registerUser'),
				showAlert(
					'error',
					'Too many attempts. Multi accounts are not allowed.',
				),
			);
		}
		// console.log(NEXT_CONFIG.RELEASE_VERSION);
		const request = getBcheck();
		return from(request).pipe(
			map((bcheck) => {
				return [
					fetchLocalAction(
						{
							url: '/register',
							parameters: {
								body: {
									...action.data,
									login: state.forms.username.value,
									password: state.forms.password.value,
									password_confirm: state.forms.password.confirm,
									ref: state.affiliate.code,
									bcheck,
								},
							},
							method: 'POST',
							loadingId: 'registerUser',
						},
						(data) => {
							const result = [];
							if (data.access_token || data.value) {
								tokenService.set(data);
								result.push(
									setLastRegisterTimestamp(new Date().getTime()),
									registerEvent({
										login: state.forms.username.value,
									}),
									setFirstLogin(),
									setToken(data),
								);
							}
							return result;
						},
						() => {
							// console.log('error', error);
							return of();
						},
					),
				];
			}),
		);
	},
});

const handleRegisterResponseEpic = epic('handleRegisterResponseEpic', {
	actions: (ofType) => ofType(USER_HANDLE_REGISTER_RESPONSE),
	callback: ({ action, store$ }) => {
		const data = action.payload;
		if (data.access_token || data.value) {
			tokenService.set(data);
			return of([
				setLastRegisterTimestamp(new Date().getTime()),
				registerEvent({
					login: store$.value.forms.username.value,
				}),
				setFirstLogin(),
				setToken(data),
			]);
		}
		return of();
	},
});

const changePasswordEpic = epic('changePasswordEpic', {
	actions: (ofType) => ofType(CHANGE_PASSWORD),
	callback: ({ action }) => {
		return of(
			fetchApiAction(
				{
					url: '/user/change/password',
					parameters: {
						Authorization: true,
						body: {
							...action.payload,
							password_confirm: action.payload.confirm,
						},
					},
					method: 'POST',
					loaderId: 'changePassword',
					lockByModal: true,
				},
				(data) => {
					const result = [
						updatePasswordValue(''),
						updatePasswordConfirmValue(''),
					];
					if (!data.error) {
						result.push(hasPasswordToSet(false));
					}
					return result;
				},
			),
		);
	},
});

const changeNameEpic = epic('changeNameEpic', {
	actions: (ofType) => ofType(CHANGE_NAME),
	callback: ({ action }) => {
		return of(
			fetchApiAction(
				{
					url: '/user/change/login',
					parameters: {
						Authorization: true,
						body: {
							login: action.payload,
						},
					},
					method: 'POST',
					loaderId: 'changeName',
					lockByModal: true,
				},
				(data) => {
					const result = [];
					if (!data.errors && !data.error) {
						result.push(
							setUserName(action.payload),
							supportUpdateUserThunk({
								name: action.payload,
							}),
						);
					}
					return result;
				},
			),
		);
	},
});

const changeEmailEpic = epic('changeEmailEpic', {
	actions: (ofType) => ofType(CHANGE_EMAIL),
	callback: ({ action }) => {
		return of(
			fetchApiAction(
				{
					url: {
						uri: '/user/email/change',
						apiUrl: '',
					},
					parameters: {
						Authorization: true,
						body: {
							email: action.payload,
						},
					},
					method: 'POST',
					loaderId: 'changeEmail',
					lockByModal: true,
				},
				(data) => {
					let result = [];
					if (!data.errors && !data.error) {
						result.push(
							setEmail(action.payload),
							supportUpdateUserThunk({
								email: action.payload,
							}),
						);
					}
					result.push(getUserData());
					return result;
				},
			),
		);
	},
});

const resendVerificationEmailEpic = epic('resendVerificationEmailEpic', {
	actions: (ofType) => ofType(RESEND_VERIFICATION_EMAIL),
	callback: () => {
		return of(
			fetchApiAction({
				url: '/user/resend/email-verification',
				parameters: {
					Authorization: true,
				},
				loaderId: 'resendEmail',
				lockByModal: true,
			}),
		);
	},
});

const sendNewPasswordEpic = epic('sendNewPasswordEpic', {
	actions: (ofType) => ofType(SEND_NEW_PASSWORD),
	callback: ({ action }) => {
		return of(
			fetchApiAction({
				url: '/user/new-password',
				parameters: {
					body: {
						email: action.payload,
					},
				},
				method: 'POST',
				loaderId: 'remindPassword',
			}),
		);
	},
});

const setNewPasswordEpic = epic('setNewPasswordEpic', {
	actions: (ofType) => ofType(SET_NEW_PASSWORD),
	callback: ({ action, store$ }) => {
		const state = store$.value;
		return of(
			fetchApiAction(
				{
					url: '/user/set-new-password',
					parameters: {
						body: {
							password: state.forms.password.value,
							password_confirm: state.forms.password.confirm,
							token: action.payload,
						},
					},
					method: 'POST',
					loaderId: 'setNewPassword',
				},
				action.callback,
			),
		);
	},
});

const logoutUserEpic = epic('logoutUserEpic', {
	actions: (ofType) => ofType(USER_LOGOUT),
	callback: ({ store$, action }) => {
		if (!store$.value.user.isLogged && !action.sendRequest) {
			resetActiveCurrency();
			clearStatsLocalStorage();
			clearSportsBookSession();
			return of([
				resetData(),
				resetToken(),
				resetGameSession(),
				resetAutoSession(),
				loggedUser(false),
			]);
		}
		return of(
			fetchApiAction(
				{
					url: '/logout',
					parameters: {
						Authorization: true,
					},
					loaderId: 'logoutUser',
					disableErrorHandler: true,
				},
				() => {
					resetActiveCurrency();
					clearStatsLocalStorage();
					clearSportsBookSession();
					return [
						resetData(),
						resetToken(),
						resetGameSession(),
						resetAutoSession(),
						loggedUser(false),
					];
				},
			),
		);
	},
});

// const resetUserDataOnCloseEpic = epic('resetUserDataOnCloseEpic', {
// 	actions: ofType => ofType(APP_CLOSES),
// 	callback: ({store$}) => {
// 		if (store$.value.user.isLogged) return of();
// 		return of(resetData());
// 	}
// });

const tokenEpic = epicOldState('tokenEpic', {
	actions: (ofType) => ofType(SET_TOKEN),
	callback: ({ newState, oldState }) => {
		if (!tokenService.token.value) {
			return [logoutUser(false)];
		}
		if (newState.user.token.value === oldState.user.token.value) {
			return [];
		}
		return [getUserData({ enableLoader: true })];
	},
});

const refreshUserDataEpic = epic('refreshUserDataEpic', {
	actions: (ofType) => ofType(SET_USER_ID),
	callback: ({ store$ }) => {
		if (store$.value.user.refreshUserDataInitialized) {
			of();
		}
		return of(refreshUserData());
	},
});

const updateSentryUser = epic('updateSentryUser', {
	actions: (ofType) => ofType(USER_LOGGED),
	callback: ({ store$ }) => {
		sentryUpdateUser(store$.value);
		return of();
	},
});

const refreshUserIntervalDataEpic = epic('refreshUserIntervalDataEpic', {
	actions: (ofType) => ofType(REFRESH_USER_DATA),
	callback: () => {
		if (!isFeatureAvailable(FEATURES_TYPE.REFRESH_USER_DATA)) {
			return of();
		}
		return of([getUserData({ noError: true }), refreshUserData()]).pipe(
			delay(config.refreshUserDataTimeout),
		);
	},
});

const amIUniqueEpic = epic('amIUniqueEpic', {
	actions: (ofType) => ofType(CHECK_AM_I_UNIQUE),
	callback: () => {
		const request = getBcheck();
		return from(request).pipe(
			map((bcheck) => {
				return [
					fetchApiAction({
						url: '/user/browser/check',
						parameters: {
							Authorization: true,
							body: bcheck,
						},
						method: 'POST',
						loadingId: 'checkAmIUnique',
						disableErrorHandler: true,
					}),
				];
			}),
		);
	},
});

const withdrawBlockedEpic = epic('withdrawBlockedEpic', {
	actions: (ofType) =>
		ofType(
			USER_SET_WITHDRAW_BLOCKED_TIMESTAMP,
			USER_HAS_EMAIL_TO_VERIFY,
			SET_EMAIL,
			ACTIVATE_2FA,
			UNINSTALL_2FA,
		),
	callback: ({ store$ }) => {
		return of(userSetWithdrawBlocked(isWithdrawBlocked(store$.value)));
	},
});

const bonusAlertEpic = epicOldState('bonusAlertEpic', {
	actions: (ofType) => ofType(SET_HAS_AVAILABLE_BONUS_CODE),
	callback: ({ newState, oldState }) => {
		if (
			newState.router.location.pathname !==
				routingService.to(ROUTING_ID.USER_VIP_BONUS_CODE) &&
			newState.user.hasAvailableBonusCode !==
				oldState.user.hasAvailableBonusCode &&
			newState.user.hasAvailableBonusCode
		) {
			return [bonusInfo()];
		}
		return [];
	},
});

const userEpic = combineEpics(
	getUserDataEpic,
	logoutUserEpic,
	loginUserEpic,
	registerUserEpic,
	tokenEpic,
	myBetsEpic,
	changePasswordEpic,
	changeEmailEpic,
	changeNameEpic,
	resendVerificationEmailEpic,
	sendNewPasswordEpic,
	setNewPasswordEpic,
	refreshUserDataEpic,
	refreshUserIntervalDataEpic,
	changeCurrencyEpic,
	sessionEpic,
	amIUniqueEpic,
	withdrawBlockedEpic,
	handleLoginResponseEpic,
	handleRegisterResponseEpic,
	updateSentryUser,
	// resetUserDataOnCloseEpic,
	bonusAlertEpic,
);

export { userEpic };
