import { actionSimple } from '@legacyApp/client/modules/app/actionService';
import {
	ADD_LOSE_STREAK,
	ADD_PROFIT,
	ADD_PROFIT_HISTORY,
	ADD_PROFIT_HISTORY_AUTO,
	ADD_WAGERED,
	ADD_WIN_STREAK,
	BET_LOST,
	BET_WON,
	RESET_AUTO_SESSION,
	RESET_GAME_SESSION,
	SET_AUTO_INIT_PROFIT,
	SET_TOP_BET,
	SET_TOP_BET_AUTO,
	SET_TOP_LOSE_STREAK,
	SET_TOP_LOSE_STREAK_AUTO,
	SET_TOP_LOST,
	SET_TOP_LOST_AUTO,
	SET_TOP_WIN,
	SET_TOP_WIN_AUTO,
	SET_TOP_WIN_STREAK,
	SET_TOP_WIN_STREAK_AUTO,
} from './gameSession.constants';

export const betWon = actionSimple(BET_WON);

export const betLost = actionSimple(BET_LOST);

export const addWagered = actionSimple(ADD_WAGERED);

export const addProfit = actionSimple(ADD_PROFIT);

export const resetGameSession = actionSimple(RESET_GAME_SESSION);

export const resetAutoSession = actionSimple(RESET_AUTO_SESSION);

export const addProfitHistory = actionSimple(ADD_PROFIT_HISTORY);

export const addProfitAutoHistory = actionSimple(ADD_PROFIT_HISTORY_AUTO);

export const addWinStreak = actionSimple(ADD_WIN_STREAK);

export const addLoseStreak = actionSimple(ADD_LOSE_STREAK);

export const setTopWinStreak = actionSimple(SET_TOP_WIN_STREAK);

export const setTopLoseStreak = actionSimple(SET_TOP_LOSE_STREAK);

export const setTopWinStreakAuto = actionSimple(SET_TOP_WIN_STREAK_AUTO);

export const setTopLoseStreakAuto = actionSimple(SET_TOP_LOSE_STREAK_AUTO);

export const setTopBet = actionSimple(SET_TOP_BET);

export const setTopWin = actionSimple(SET_TOP_WIN);

export const setTopLost = actionSimple(SET_TOP_LOST);

export const setTopBetAuto = actionSimple(SET_TOP_BET_AUTO);

export const setTopWinAuto = actionSimple(SET_TOP_WIN_AUTO);

export const setTopLostAuto = actionSimple(SET_TOP_LOST_AUTO);

export const setAutoInitProfit = actionSimple(SET_AUTO_INIT_PROFIT);
