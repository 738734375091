import routingService from '../../client/modules/app/routingService';

export const checkAuth = (id, token) => {
	if (!id && id !== '') {
		return false;
	}

	const route = routingService.getRoute(id);

	// console.log('checkAuth', {
	// 	token,
	// 	id,
	// 	route,
	// });

	if (!route) {
		return token;
	}

	if (route.isPrivate && !token.value) {
		return false;
	}

	if (route.isOnlyPublic && token.value) {
		return false;
	}

	return token;
};
