import React from 'react';
import PropTypes from 'prop-types';
import { enhanceComponent } from '../../wrappers/enhanceComponent';

class ClickOutsideWrapper extends React.PureComponent {
	static propTypes = {
		children: PropTypes.any,
		onClickOutside: PropTypes.func.isRequired,
	};

	handleClickOutside(event) {
		this.props.onClickOutside(event);
	}

	render() {
		return <>{this.props.children}</>;
	}
}

ClickOutsideWrapper = enhanceComponent({
	ClickOutsideWrapper,
	props: {
		clickOutside: true,
	},
});

export { ClickOutsideWrapper };
