import { combineEpics } from 'redux-observable';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { USER_LOGGED } from '../user/user.constants';
import { config } from '../../config';
import { SET_VISIBLE } from '../app/app.constants';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { simpleTrans } from '../../modules/translation/translationService';
import { parseApiInfo } from '../../modules/app/errorsService';
import {
	addPendingAlert,
	closeAlert,
	deleteAlert,
	handleAlert,
	showAlert,
} from './alerts.actions';
import { CLOSE_ALERT, HANDLE_ALERT, SHOW_ALERT } from './alerts.constants';

const checkAlertsEpic = epic('checkAlertsEpic', {
	actions: (ofType) => ofType(USER_LOGGED),
	callback: ({ action }) => {
		if (!action.payload) {
			return of();
		}
		return of(
			fetchApiAction(
				{
					url: '/alerts',
					parameters: {
						Authorization: true,
					},
					method: 'GET',
					loaderId: 'getAlerts',
					lockByModal: true,
				},
				(data) => {
					if (!data.data) {
						return [];
					}
					if (typeof data.data === 'object' && data.data.length) {
						return data.data.map((el) => handleAlert(el));
					}
					return [];
				},
			),
		);
	},
});

const handleAlertsEpic = epic('handleAlertsEpic', {
	actions: (ofType) => ofType(HANDLE_ALERT),
	callback: ({ action }) => {
		if (action.payload.message) {
			return of(
				showAlert('info', action.payload.message, config.longAlertTimeout),
			);
		}
		switch (action.payload.id) {
			// case 'SERVER_SEED_CHANGED': {
			//   if (lsGet(`${action.payload.id}_2019-10-08`)) return of();
			//   lsSet(`${action.payload.id}_2019-10-08`, true);
			//   return of(
			//     toggleServerSeedAlert(true),
			//     showAlert('info', 'Due to security reasons, we had to change user and server seed on your account. The reason for that was our migration to a different, bigger, datacenter. It does not affect your game in any way. (2019-10-08)', config.longAlertTimeout)
			//   );
			// }
			default: {
				return of();
			}
		}
	},
});

const appAlertEpic = epic('appAlertEpic', {
	actions: (ofType) => ofType(SHOW_ALERT),
	callback: ({ action, store$ }) => {
		if (action.noTimeout && typeof action.noTimeout === 'boolean') {
			return of();
		}
		if (!action.noTimeout) {
			action.noTimeout = config.alertTimeout;
		}
		if (!store$.value.app.visible) {
			return of(
				addPendingAlert({
					id: action.id,
					timeout: action.noTimeout,
				}),
			);
		}
		return of(closeAlert(action.id)).pipe(delay(action.noTimeout));
	},
});

const tabAlertEpic = epic('tabAlertEpic', {
	actions: (ofType) => ofType(SHOW_ALERT),
	callback: ({ action, store$ }) => {
		if (!store$.value.app.visible) {
			let message = parseApiInfo(action.message);
			if (message?.props?.children?.props?.translateKey) {
				const props = message?.props?.children?.props;
				// components: null
				// namespace: "common"
				// noTranslationCheck: null
				// options: null
				// translateKey: "TEST ALERT"
				message = simpleTrans(
					props.translateKey,
					props.options,
					props.namespace,
				);
			}
			if (message && typeof message === 'string') {
				document.title = message;
			}
		}
		return of();
	},
});

const clearTabAlertEpic = epic('clearTabAlertEpic', {
	actions: (ofType) => ofType(SET_VISIBLE),
	callback: ({ action, store$ }) => {
		if (action.bool) {
			if (store$.value.alerts.pendingClose.length) {
				setTimeout(
					() =>
						(document.title =
							window.wolfMetaTitle ||
							`${config.appName} - Crypto Dice Game - Bitcoin Casino`),
					1000,
				);
				return of(
					store$.value.alerts.pendingClose.map((el) =>
						closeAlert(el.id, false, el.timeout),
					),
				);
			}
		}
		return of();
	},
});

const closeAlertEpic = epic('closeAlertEpic', {
	actions: (ofType) => ofType(CLOSE_ALERT),
	callback: ({ action }) => {
		if (action.timeout) {
			return of(deleteAlert(action.id, action.force)).pipe(
				delay(action.timeout),
			);
		}
		return of(deleteAlert(action.id, action.force));
	},
});

const alertsEpic = combineEpics(
	checkAlertsEpic,
	handleAlertsEpic,
	appAlertEpic,
	tabAlertEpic,
	clearTabAlertEpic,
	closeAlertEpic,
);

export { alertsEpic };
