const languageConfig = {
	default: 'en',
	available: [
		{
			id: 'en',
			name: 'english',
		},
		{
			id: 'ru',
			name: 'pусский',
		},
		{
			id: 'ph',
			name: 'filipino',
		},
		{
			id: 'id',
			name: 'Indonesia',
		},
		{
			id: 'jp',
			name: '日本の',
		},
		{
			id: 'es',
			name: 'Español',
		},
		{
			id: 'pt',
			name: 'Português',
		},
		{
			id: 'de',
			name: 'Deutsch',
		},
		{
			id: 'fr',
			name: 'Français',
		},
		{
			id: 'tr',
			name: 'Türk',
		},
	],
};

module.exports = {
	languageConfig,
};
