import { combineReducers } from 'redux';
import { SET_TIP_MIN_VALUES } from './tip.constants';

const minValueReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_TIP_MIN_VALUES: {
			return action.payload;
		}
		default: {
			return state;
		}
	}
};

const tipReducer = combineReducers({
	minValue: minValueReducer,
});

export { tipReducer };
