import { config } from '@legacyApp/client/config';
import { scientificToDecimal } from '@legacyApp/methods/math/scientificToDecimal';
import { roundNumber } from './roundNumber';

const roundingStringInt = (
	int: number | string,
	cut: number,
	direction = 'round',
	keepZeroOnEnd = true,
): string => {
	if (cut === 0) {
		return String(int);
	}
	const stringInt = String(int);
	if (stringInt.length <= cut) {
		return stringInt;
	}
	const precision = String(int).length - cut;
	const slicedInt = stringInt.slice(0, precision + 1);
	const valueToBig = slicedInt.slice(0, precision - 1);
	const roundSlidedInt = slicedInt.replace(valueToBig, '');
	const rounded = String(roundNumber(roundSlidedInt, -1, direction));
	let res = `${valueToBig}${rounded}`;
	if (!keepZeroOnEnd) {
		res = res.slice(0, precision);
	} else {
		while (res.length < stringInt.length) {
			res += '0';
		}
	}
	return res;
};

export const roundStringNumber = (
	num: number | string,
	precision: number = config.valueMaxPrecision,
	direction = 'round',
): string => {
	const num_ =
		String(num).indexOf('e-') > -1
			? scientificToDecimal(String(num))
			: String(num);
	const [int, decimal] = num_.split('.');
	if (precision === 0) {
		return int;
	}
	if (precision > 0) {
		if (!decimal) {
			return int;
		}
		return `${int}.${roundingStringInt(
			decimal,
			decimal.length - precision,
			direction,
			false,
		)}`;
	}
	return roundingStringInt(int, precision * -1, direction, true);
};
