import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { epic } from '../../modules/app/epicService';
import {
	REGISTRY_EPIC_INJECTED,
	REGISTRY_EPIC_REJECTED,
	REGISTRY_PACKAGE_INJECTED,
	REGISTRY_PACKAGE_REJECTED,
	REGISTRY_REDUCER_INJECTED,
	REGISTRY_REDUCER_REJECTED,
} from './registry.constants';

const packageRejectedEpic = epic('packageRejectedEpic', {
	actions: (ofType) =>
		ofType(REGISTRY_EPIC_REJECTED, REGISTRY_REDUCER_REJECTED),
	callback: ({ store$, action }) => {
		const packageData = store$.value.registry.packages[action.payload.package];
		const isRejected = Object.keys(packageData).filter(
			(module) => !packageData[module],
		).length;
		if (isRejected) {
			return of({
				type: REGISTRY_PACKAGE_REJECTED(action.payload.package.toUpperCase()),
			});
		}
		return of();
	},
});

const packageInjectedEpic = epic('packageInjectedEpic', {
	actions: (ofType) =>
		ofType(REGISTRY_EPIC_INJECTED, REGISTRY_REDUCER_INJECTED),
	callback: ({ store$, action }) => {
		const packageData = store$.value.registry.packages[action.payload.package];
		const isInjected = !Object.keys(packageData).filter(
			(module) => !packageData[module],
		).length;
		if (isInjected) {
			return of({
				type: REGISTRY_PACKAGE_INJECTED(action.payload.package.toUpperCase()),
			});
		}
		return of();
	},
});

const registryEpic = combineEpics(packageRejectedEpic, packageInjectedEpic);

export { registryEpic };
