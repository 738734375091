import { FC } from 'react';
import dynamic from 'next/dynamic';
import { useRegistryPackageLoaded } from '@common/selectors/registry.selectors';
import { PackageType } from '@legacyApp/client/store/registry/registry.constants';
import ModalStoreController from '../../../common/components/storeControllers/ModalStoreController';
import { useModalId } from '../store/modal.selectors';

const Modal_ = dynamic(() => import('./Modal'), {
	ssr: false,
});

export const Modal: FC = () => {
	const active = useRegistryPackageLoaded(PackageType.MODAL);
	const id = useModalId();

	return (
		<>
			<ModalStoreController />
			{active && id && <Modal_ />}
		</>
	);
};
