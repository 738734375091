import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { vipActions } from '../vip.actions';
import { statusSlice } from '../slices/status.slice';
import { levelSlice } from '../slices/level.slice';
import { loadedSlice } from '../slices/loaded.slice';
import { levelsSlice } from '../slices/levels.slice';

export const vipResetStateThunk = (): AppThunk => {
	return (dispatch) => {
		dispatch(vipActions.statusUpdate(statusSlice.getInitialState()));
		dispatch(vipActions.levelUpdate(levelSlice.getInitialState()));
		dispatch(vipActions.loadedUpdate(loadedSlice.getInitialState()));
		dispatch(vipActions.levelsUpdate(levelsSlice.getInitialState()));
	};
};
