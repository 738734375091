import styled from 'styled-components';
import classnames from 'classnames';
import { NOTIFICATION_ICON_CLASS_NAME } from '../../notificationIcon/NotificationIcon';

interface DropdownElementStyledProps {
	disabled?: boolean;
}

export const DropdownElementStyled = styled.a.attrs<DropdownElementStyledProps>(
	(props) => ({
		className: classnames(
			'dropdown-menu__element text-style-sm-regular',
			props.className,
		),
	}),
)<DropdownElementStyledProps>`
	display: flex;
	justify-content: space-between;
	padding: 5px 10px;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	transition: background 0.15s linear;
	white-space: nowrap;
	${(props) => (props.disabled ? 'opacity: 0.6;' : '')}

	> button {
		max-width: 50vw;

		.relative {
			display: flex;
			align-items: center;
		}

		img {
			height: 30px;
			width: 30px;
			margin-right: 10px;
		}

		span {
			max-width: inherit;
			display: inline-block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.${NOTIFICATION_ICON_CLASS_NAME} {
			top: 0 !important;
			right: unset !important;
			left: 3px !important;
		}
	}

	.dropdown-menu__element {
		&:first-child {
			border-radius: 5px 5px 0 0;
		}

		&:last-child {
			border-radius: 0 0 5px 5px;
		}

		&:hover {
			background: ${(props) => props.theme.colors.dropdownSelectBlue};
		}

		&_gray {
			color: #5390eb;
		}

		&_top-line {
			border-top: 1px rgba(255, 255, 255, 0.2) solid;
		}

		@media (max-width: ${(props) => props.theme.media.maxWidthDesktop}) {
			padding: 3px 7px;

			> button {
				img {
					margin-right: 7px;
				}
			}
		}
	}
`;
