import axios, { AxiosRequestConfig } from 'axios';
import { createHeaders } from '../../../methods/fetch/createHeaders';

const createConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
	return {
		...(config || {}),
		headers: {
			...createHeaders({ body: null }),
			...(config?.headers || {}),
		},
	};
};

export const post = async (
	url: string,
	data?: any,
	config?: AxiosRequestConfig,
) => {
	// console.log('axios - post', url, { data, config });
	return await axios.post(url, data, createConfig(config));
	// .then(result => {
	// 	console.log('axios - post - result', url, { data, config, result: result?.data });
	// 	return result;
	// });
};

export const get = async (url: string, config?: AxiosRequestConfig) => {
	// console.log('axios - get', url, config);
	return await axios.get(url, createConfig(config));
	// .then(result => {
	// 	console.log('axios - get - result', url, config, result?.data);
	// 	return result;
	// })
	// .catch(error => {
	// 	console.log('axios - get - error', url, { config, error });
	// 	throw error;
	// });
};
