import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { setOption } from '../../modules/app/optionsService';

export const isAudioActive = (): boolean => {
	return !!lsGet(LocalStorageKeys.OPTIONS, {}).sound;
};

export const setDisableSound = (bool: boolean) => {
	setOption('sound', !bool);
};
