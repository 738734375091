import { apiConfig } from '@server/config/api.config';

export const parseApiUrl = (uri) => {
	if (typeof uri === 'string' && uri.indexOf('http') > -1) {
		return uri;
	}
	const uriData = {
		uri: typeof uri !== 'string' ? uri.uri : uri,
		api: typeof uri !== 'string' ? uri.apiUrl : apiConfig.apiUrl,
	};
	uriData.api = uriData.api.replace('/v1', '');
	if (!['/v1/', '/v2/', '/v3'].some((el) => uriData.uri.indexOf(el) > -1)) {
		uriData.uri = `/v1${uriData.uri}`;
	}
	// console.log('parseApiUrl', {
	//   uriData,
	//   uri,
	//   regexResult: ['/v1/', '/v2/', '/v3'].findIndex(el => uriData.uri.indexOf(el) === -1) === -1,
	//   result: `${uriData.api}${uriData.uri}`,
	// });
	return `${uriData.api}${uriData.uri}`;
};
