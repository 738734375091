import Redux, { combineReducers } from 'redux';
import { TABLE_ID, TableIdType } from '@modules/transactions/constants/TableId';
import { TransactionsMetaType } from '@modules/transactions/constants/TransactionsResponse.type';
import transactionService from '../../../../modules/transactions/transactionsService';
import { config } from '../../config';
import { RESET_USER_DATA } from '../user/user.constants';
import { transactionReducer } from '../../modules/app/reducers/reducerService';
import { TABLE_MOCKS } from '../../../mocks/tableMocks';
import { DIRECTION } from '../../modules/app/sortService';
import {
	NEW_BET,
	NEW_DEPOSIT,
	NEW_DEPOSIT_DETECTED,
	NEW_HIGH_ROLL,
	NEW_MY_BET,
	NEW_RESULT,
	PENDING_TRANSACTIONS,
	RESET_PENDING_TRANSACTIONS,
	RESET_TRANSACTIONS,
	SET_ALL_BETS,
	SET_HIGH_ROLLS,
	SET_NEW_MY_BETS,
	TRANSACTIONS_ACTIVE,
	TRANSACTIONS_FULFILLED,
	TRANSACTIONS_SET,
	TRANSACTIONS_SET_META,
	TRANSACTIONS_UPDATE,
	TRANSACTIONS_UPDATING,
} from './transactions.constants';

const activeReducer = (
	state = {},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case TRANSACTIONS_ACTIVE: {
			return {
				...state,
				[action.id]: action.bool,
			};
		}
		default: {
			return state;
		}
	}
};

const metaReducer = (
	state: Partial<Record<TableIdType, TransactionsMetaType>> = {},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case TRANSACTIONS_SET_META: {
			return {
				...state,
				[action.id]: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};

const pendingReducer = (
	state = {},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case PENDING_TRANSACTIONS: {
			const data = state[action.id] ? [...state[action.id]] : [];
			data.push(action.data);
			return {
				...state,
				[action.id]: transactionService.sortTransactions(
					data,
					DIRECTION.DESC,
					config.transactionsPaginationLength,
				),
			};
		}
		case RESET_PENDING_TRANSACTIONS: {
			return {
				...state,
				[action.id]: null,
			};
		}
		default: {
			return state;
		}
	}
};

const updateReducer = (
	state = {},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case TRANSACTIONS_UPDATING: {
			return {
				...state,
				[action.id]: action.bool,
			};
		}
		case RESET_TRANSACTIONS: {
			return {
				...state,
				[action.id]: false,
			};
		}
		default: {
			return state;
		}
	}
};

const fulfilledReducer = (
	state = {},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case TRANSACTIONS_FULFILLED: {
			return {
				...state,
				[action.id]: action.bool,
			};
		}
		default: {
			return state;
		}
	}
};

const allBetsReducer = (
	state = TABLE_MOCKS.allBets,
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case NEW_BET: {
			if (!action.payload.length && action.payload.hide) {
				return state;
			}
			return transactionService.addOnlyLastToList({
				label: TABLE_ID.allBets,
				element: action.payload,
				list: state,
				direction: DIRECTION.DESC,
				limit: config.transactionsPaginationLength,
			});
		}
		case SET_ALL_BETS: {
			return transactionService.addNewToList({
				label: TABLE_ID.allBets,
				element: action.payload,
				list: [],
				direction: DIRECTION.DESC,
				limit: config.transactionsPaginationLength,
			});
		}
		default: {
			return state;
		}
	}
};

const highRollsReducer = (
	state = [],
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case NEW_HIGH_ROLL: {
			return transactionService.addOnlyLastToList({
				label: TABLE_ID.highRolls,
				element: action.payload,
				list: state,
				direction: DIRECTION.DESC,
				limit: config.transactionsPaginationLength,
			});
		}
		case SET_HIGH_ROLLS: {
			return transactionService.addNewToList({
				label: TABLE_ID.highRolls,
				element: action.payload,
				list: [],
				direction: DIRECTION.DESC,
				limit: config.transactionsPaginationLength,
			});
		}
		default: {
			return state;
		}
	}
};

const myBetsReducer = (
	state = [],
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case NEW_MY_BET: {
			return transactionService.addElementToList({
				label: TABLE_ID.myBets,
				element: action.payload,
				list: state,
				direction: DIRECTION.DESC,
				limit: action.limit,
				disableSort: true,
			});
		}
		case SET_NEW_MY_BETS: {
			return transactionService.addNewToList({
				label: TABLE_ID.myBets,
				element: action.payload,
				list: [],
			});
		}
		case RESET_USER_DATA:
			return [];
		default: {
			return state;
		}
	}
};

const resultsReducer = (
	state = {},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	const newState = {
		...state,
	};
	switch (action.type) {
		case NEW_RESULT: {
			if (!newState[action.payload.game]) {
				newState[action.payload.game] = [];
			}
			return {
				...newState,
				[action.payload.game]: transactionService.addElementToList({
					label: TABLE_ID.gameResults,
					element: action.payload.data,
					list: newState[action.payload.game],
					direction: DIRECTION.DESC,
					limit: config.transactionsPaginationLength,
					disableSort: true,
				}),
			};
		}
		case RESET_USER_DATA:
			return {};
		default: {
			return state;
		}
	}
};

const depositHistoryReducer = (
	state = [],
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case TRANSACTIONS_SET: {
			if (action.id !== TABLE_ID.depositHistory) {
				return state;
			}
			return transactionService.sortTransactions(
				action.payload.map((el) => ({
					...el,
					new: false,
				})),
				DIRECTION.DESC,
			);
		}
		case TRANSACTIONS_UPDATE: {
			if (action.id !== TABLE_ID.depositHistory) {
				return state;
			}
			return transactionService.addNewToList({
				label: TABLE_ID.depositHistory,
				element: action.payload.map((el) => ({
					...el,
					new: false,
				})),
				list: state,
				direction: DIRECTION.DESC,
				keys: transactionService.getTableCompareKeys(
					TABLE_ID.depositHistory,
					state,
				),
			});
		}
		case NEW_DEPOSIT: {
			return transactionService.addNewToList({
				label: TABLE_ID.depositHistory,
				element: action.payload,
				list: state,
				direction: DIRECTION.DESC,
				keys: transactionService.getTableCompareKeys(
					TABLE_ID.depositHistory,
					state,
				),
			});
		}
		case NEW_DEPOSIT_DETECTED: {
			return transactionService.addNewToList({
				label: TABLE_ID.depositHistory,
				element: action.payload,
				list: state,
				direction: DIRECTION.DESC,
				keys: transactionService.getTableCompareKeys(
					TABLE_ID.depositHistory,
					state,
				),
			});
		}
		case RESET_USER_DATA:
			return [];
		default: {
			return state;
		}
	}
};

const withdrawHistoryReducer = (
	state = [],
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case TRANSACTIONS_SET: {
			if (action.id !== TABLE_ID.withdrawHistory) {
				return state;
			}
			return transactionService.sortTransactions(
				action.payload,
				DIRECTION.DESC,
			);
		}
		case TRANSACTIONS_UPDATE: {
			if (action.id !== TABLE_ID.withdrawHistory) {
				return state;
			}
			return transactionService.addNewToList({
				label: TABLE_ID.withdrawHistory,
				element: action.payload,
				list: state,
				direction: DIRECTION.DESC,
				keys: transactionService.getTableCompareKeys(
					TABLE_ID.withdrawHistory,
					state,
				),
			});
		}
		case RESET_USER_DATA:
			return [];
		default: {
			return state;
		}
	}
};

const affiliateCampaignsReducer = transactionReducer(
	TABLE_ID.affiliateCampaigns,
	true,
);

const affiliateAnalyticsReducer = transactionReducer(
	TABLE_ID.affiliateAnalytics,
	true,
);

const affiliateFundsReducer = transactionReducer(TABLE_ID.affiliateFunds, true);

const affiliateCashoutHistoryReducer = transactionReducer(
	TABLE_ID.affiliateCashoutHistory,
	true,
);

const otherTransactionsReducer = transactionReducer(
	TABLE_ID.otherTransactions,
	true,
);

const vaultHistoryReducer = transactionReducer(TABLE_ID.vaultHistory, true);

const friendsIgnoredReducer = transactionReducer(TABLE_ID.friendsIgnored, true);

const sessionsReducer = transactionReducer(TABLE_ID.sessions, true);

const sessionHistoryReducer = transactionReducer(TABLE_ID.sessionHistory, true);

const transactionsReducer = combineReducers({
	active: activeReducer,
	update: updateReducer,
	fulfilled: fulfilledReducer,
	pending: pendingReducer,
	allBets: allBetsReducer,
	highRolls: highRollsReducer,
	myBets: myBetsReducer,
	depositHistory: depositHistoryReducer,
	withdrawHistory: withdrawHistoryReducer,
	results: resultsReducer,
	affiliateCampaigns: affiliateCampaignsReducer,
	affiliateAnalytics: affiliateAnalyticsReducer,
	affiliateFunds: affiliateFundsReducer,
	otherTransactions: otherTransactionsReducer,
	affiliateCashoutHistory: affiliateCashoutHistoryReducer,
	vaultHistory: vaultHistoryReducer,
	friendsIgnored: friendsIgnoredReducer,
	sessions: sessionsReducer,
	sessionHistory: sessionHistoryReducer,
	meta: metaReducer,
});

export { transactionsReducer };
