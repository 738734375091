import { regexStrings } from '../../client/modules/app/regexService';

export const scientificToDecimal = (value?: number | string): string => {
	if (!value && value !== 0) {
		return '';
	}
	const number = Number(value);
	const minus = number < 0;

	//if the number is in scientific notation remove it
	if (regexStrings.scientificNotationNumber.test(String(value))) {
		const zero = '0';
		const parts = String(value).toLowerCase().split('e'); //split into coeff and exponent
		const exponential = Number(parts.pop()); //store the exponential part
		let number_of_zeros = Math.abs(exponential); //get the number of zeros
		const direction = exponential / number_of_zeros; // use to determine the zeroes on the left or right
		const coeff_array = parts[0].split('.');

		if (direction === -1) {
			coeff_array[0] = String(Math.abs(Number(coeff_array[0])));
			value =
				zero +
				'.' +
				new Array(number_of_zeros).join(zero) +
				coeff_array.join('');
		} else {
			const dec = coeff_array[1];
			if (dec) {
				number_of_zeros = number_of_zeros - dec.length;
			}
			value =
				coeff_array.join('') +
				(Number.isInteger(number_of_zeros) && number_of_zeros > 0
					? new Array(number_of_zeros + 1).join(zero)
					: '');
		}

		if (minus) {
			value = `-${value}`;
		}
	}

	return `${value}`;
};
