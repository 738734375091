import styled from 'styled-components';
import { getLevelColor } from '@legacyApp/client/modules/style/mixins';

export const StyledVipLevel = styled.span<{
	$isColor?: boolean;
	$level: number;
	$isNoUnderline?: boolean;
}>`
	color: ${(props) =>
		props.$isColor ? getLevelColor(props.$level) : 'inherit'};
	${(props) => (!props.$isNoUnderline ? 'text-decoration: underline;' : '')};
`;
