import Redux, { combineReducers } from 'redux';
import {
	PackageType,
	REGISTRY_EPIC_INJECTED,
	REGISTRY_EPIC_REJECTED,
	REGISTRY_PACKAGE_REGISTER,
	REGISTRY_REDUCER_INJECTED,
	REGISTRY_REDUCER_REJECTED,
} from './registry.constants';

const packagesReducer = (state = {}, action: Redux.AnyAction) => {
	if (REGISTRY_PACKAGE_REGISTER === action.type) {
		const modules = {};
		action.modules.forEach((key) => (modules[key] = false));
		return {
			...state,
			[action.id]: {
				...modules,
				...(state[action.id] || {}),
			},
		};
	}
	if (REGISTRY_REDUCER_INJECTED === action.type) {
		return {
			...state,
			[action.payload.package]: {
				...(state[action.payload.package] || {}),
				[`reducer_${action.payload.key}`]: true,
			},
		};
	}
	if (REGISTRY_EPIC_INJECTED === action.type) {
		return {
			...state,
			[action.payload.package]: {
				...(state[action.payload.package] || {}),
				[`epic_${action.payload.key}`]: true,
			},
		};
	}
	if (REGISTRY_REDUCER_REJECTED === action.type) {
		return {
			...state,
			[action.payload.package]: {
				...(state[action.payload.package] || {}),
				[`reducer_${action.payload.key}`]: false,
			},
		};
	}
	if (REGISTRY_EPIC_REJECTED === action.type) {
		return {
			...state,
			[action.payload.package]: {
				...(state[action.payload.package] || {}),
				[`epic_${action.payload.key}`]: false,
			},
		};
	}
	return state;
};

export interface RegistryReducerType {
	packages: Record<PackageType, Record<string, boolean>>;
}

const registryReducer = combineReducers({
	packages: packagesReducer,
});

export default registryReducer;
