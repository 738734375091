import { combineReducers } from 'redux';
import { statusSlice } from './slices/status.slice';
import { levelSlice } from './slices/level.slice';
import { loadedSlice } from './slices/loaded.slice';
import { levelsSlice } from './slices/levels.slice';

const vipReducer = combineReducers({
	status: statusSlice.reducer,
	levels: levelsSlice.reducer,
	level: levelSlice.reducer,
	loaded: loadedSlice.reducer,
});

export { vipReducer };
