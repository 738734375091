import { isTrueOrZero } from './isTrueOrZero';

export const getValueWithElse = (
	value: number | string | boolean,
	elseValue: number | string | boolean,
	check?: (value: number | string | boolean) => boolean,
) => {
	if (!check) {
		check = isTrueOrZero;
	}
	return check(value) ? value : elseValue;
};
