import { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';

export const enhanceForwardRef = (Child) => {
	class ForwardRef extends Component {
		static propTypes = {
			forwardedRef: PropTypes.any,
		};

		render() {
			const { forwardedRef, ...rest } = this.props;

			// Assign the custom prop "forwardedRef" as a ref
			return <Child ref={forwardedRef} {...rest} />;
		}
	}

	// Note the second param "ref" provided by React.forwardRef.
	// We can pass it along to LogProps as a regular prop, e.g. "forwardedRef"
	// And it can then be attached to the Component.
	return forwardRef((props, ref) => {
		return <ForwardRef {...props} forwardedRef={ref} />;
	});
};
