import React, { FC, useEffect, useState } from 'react';
import { TabGroup, TabGroupProps } from '@ui/tab-group';
import { Sport } from '@icons/sport';
import { CasinoV2 } from '@icons/casino-v2';
import { Trans } from '@legacyApp/client/modules/translation/translate';
import { useRouterPathname } from '@common/selectors/router.selectors';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { appGoTo } from '@legacyApp/client/store/app/app.actions';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';

const TABS = (dispatch): TabGroupProps['tabs'] => [
	{
		id: 'casino',
		label: Trans({ label: 'Casino' }),
		icon: CasinoV2,
		onClick: () => dispatch(appGoTo(ROUTING_ID.HOME)),
	},
	{
		id: 'sports',
		label: Trans({ label: 'Sports' }),
		icon: Sport,
		onClick: (e, props) => {
			if (!props.active) {
				dispatch(appGoTo(ROUTING_ID.SPORTS_BOOK));
			}
		},
	},
];

export const SectionSwitch: FC = () => {
	const pathname = useRouterPathname();
	const prevPathname = usePrevious(pathname);
	const dispatch = useAppDispatch();

	const [activeTabId, setActiveTabId] = useState(null);

	const tabs = TABS(dispatch);

	useEffect(() => {
		if (pathname !== prevPathname || !activeTabId) {
			const newTab =
				pathname.indexOf(ROUTING_ID.SPORTS_BOOK) === -1
					? tabs[0].id
					: tabs[1].id;
			if (newTab !== activeTabId) {
				setActiveTabId(newTab);
			}
		}
	}, [activeTabId, pathname, prevPathname, tabs]);

	return <TabGroup tabs={tabs} activeTabId={activeTabId} small />;
};
