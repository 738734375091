import { MODAL_ID } from './ids.modal.constants';

export const QUERY_MODAL_CONSTANTS = [
	MODAL_ID.AUTOBET,
	MODAL_ID.SESSION,
	MODAL_ID.BET,
	MODAL_ID.VIP_WHEEL,
	MODAL_ID.CONTENT,
	MODAL_ID.WALLET,
];
