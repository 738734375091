import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Sport } from '@icons/sport';
import { Transactions } from '@icons/user-menu/transactions';
import { Trending } from '@icons/trending';
import { Options } from '@icons/bottom-menu/options';
import { Logout } from '@icons/user-menu/logout';
import { Wallet } from '@icons/wallet';
import { NOTIFICATION_ID } from '@legacyApp/client/store/notification/notification.constants';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import {
	MODAL_ID,
	ModalIdType,
} from '@common/constants/modal/ids.modal.constants';
import { useAppInitialized } from '@common/selectors/app.selectors';
import { hideMenu } from '@legacyApp/client/store/app/app.actions';
import { theme } from '@legacyApp/client/modules/style/theme';
import { openModal } from '../../modals/store/modal.actions';
import { MenuElement, MenuElementType } from './MenuElement';

const MENU: Array<MenuElementType> = [
	{
		id: MODAL_ID.WALLET,
		icon: <Wallet fill={theme.ui.colors['blue-dark']} />,
		name: () => 'Wallet',
		isModal: true,
	},
	{
		id: ROUTING_ID.USER_SPORTS_HISTORY,
		icon: <Sport fill={theme.ui.colors['blue-dark']} />,
		name: () => 'Sport Bets',
	},
	{
		id: ROUTING_ID.USER_ALL_TRANSACTIONS,
		icon: <Transactions fill={theme.ui.colors['blue-dark']} />,
		name: () => 'Transactions',
	},
	{
		id: MODAL_ID.USER_DETAILS,
		icon: <Trending fill={theme.ui.colors['blue-dark']} />,
		name: () => 'Statistics',
		isModal: true,
	},
	{
		id: ROUTING_ID.USER_SETTINGS,
		icon: <Options fill={theme.ui.colors['blue-dark']} />,
		name: () => 'Settings',
		checkNotification: [
			NOTIFICATION_ID.HAS_PASSWORD_TO_SET,
			NOTIFICATION_ID.HAS_EMAIL_TO_VERIFY,
			NOTIFICATION_ID.HAS_KYC_TO_VERIFY,
		],
	},
	{
		id: MODAL_ID.LOGOUT,
		icon: <Logout fill={theme.ui.colors['dark-200']} />,
		name: () => 'Logout',
		isModal: true,
	},
];

export const MenuList: FC = () => {
	const dispatch = useDispatch();

	const initialized = useAppInitialized();

	const hideMenu_ = useCallback(() => dispatch(hideMenu()), [dispatch]);
	const openModal_ = useCallback(
		(id: ModalIdType) => dispatch(openModal(id, 'menuList')),
		[dispatch],
	);

	if (!initialized) {
		return null;
	}

	return (
		<MenuListStyled>
			{MENU.map((menuElement) => (
				<MenuElement
					key={menuElement.id}
					menuElement={menuElement}
					openModalCallback={(id: ModalIdType) => {
						openModal_(id);
						hideMenu_();
					}}
					closeCallback={hideMenu_}
				/>
			))}
		</MenuListStyled>
	);
};

const MenuListStyled = styled.nav`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: flex-start;
`;
