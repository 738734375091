import { Button, ButtonProps } from '@ui/button';
import { Support } from '@icons/support';
import { FC, MouseEventHandler } from 'react';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import {
	trans,
	TransLabelType,
} from '@legacyApp/client/modules/translation/translate';
import { Loader } from '@legacyApp/client/components/loader/loader';
import { useSupportToggle } from '@modules/support/hooks/useSupportToggle';

export const SupportButton: FC<{
	size?: ButtonProps['size'];
	label?: TransLabelType;
	callback?: MouseEventHandler<HTMLButtonElement>;
}> = ({ size, label, callback }) => {
	const { toggle } = useSupportToggle();
	const toggleSupport = () => toggle();
	return (
		<Button
			onClick={callback || toggleSupport}
			size={size || Size.MEDIUM}
			iconBefore={<Support fill={'#4CC274'} />}
			styleType={ButtonStyleType.SECONDARY}
			label={trans({ label: label || 'Live support' })}
		>
			<Loader module={LOADING_IDS.SUPPORT_APP} />
		</Button>
	);
};
