import { roundNumber } from '@common/methods/roundNumber/roundNumber';

export const calcStyle = ({
	mode = '+',
	a = '0',
	b = '0',
	suffix = 'px',
}: {
	a: number | string;
	b: number | string;
	mode?: string;
	suffix?: string;
}) => {
	// console.log({mode, a, b, suffix});
	a = typeof a === 'string' ? parseFloat(a.replace(suffix, '')) : a;
	b = typeof b === 'string' ? parseFloat(b.replace(suffix, '')) : b;
	switch (mode) {
		case '+':
			return `${roundNumber(a + b, 4)}${suffix}`;
		case '-':
			return `${roundNumber(a - b, 4)}${suffix}`;
		case '*':
			return `${roundNumber(a * b, 4)}${suffix}`;
		case '/':
			return `${roundNumber(a / b, 4)}${suffix}`;
		default:
			return `${a}${suffix}`;
	}
};
