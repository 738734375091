import { updateUserSupport } from '@modules/support/methods/updateUserSupport';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import {
	getVendorUserData,
	VendorUserData,
} from '@common/methods/getVendorUserData';

export const supportUpdateUserThunk = (data: VendorUserData): AppThunk => {
	return async (dispatch, getState) => {
		updateUserSupport(getVendorUserData(getState(), data || {}));
	};
};
