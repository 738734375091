import React, { FC } from 'react';
import { Carousel } from '@ui/carousel';
import { second } from '@legacyApp/client/modules/app/time';
import { Image } from '@common/components/default/Image/Image';
import { useAppMobile } from '@common/selectors/app.selectors';
import { CurrencyIcon } from '@common/components/footer/CurrencyIcon.styled';
import { useGameActiveGame } from '@modules/games/Game/store/game/game.selectors';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { Currencies } from '@legacyApp/types/wallet/CurrencyType';

export const CURRENCIES_CAROUSEL = [
	Currencies.usdt,
	Currencies.btc,
	Currencies.eth,
	Currencies.xrp,
	Currencies.doge,
	Currencies.trx,
	Currencies.bnb,
];

export const CURRENCIES_CAROUSEL_LIST = CURRENCIES_CAROUSEL.map((token) => ({
	id: token,
	url: `/img/currencies/${token}.svg`,
})).map((el) => ({
	id: el.id,
	content: (
		<CurrencyIcon>
			<Image key={el.id} src={el.url} alt={el.id} />
		</CurrencyIcon>
	),
}));

export const CurrenciesCarousel: FC = () => {
	const isMobile = useAppMobile();
	const activeGame = useGameActiveGame();

	return (
		<Carousel
			slidesToShow={isMobile ? 2 : 4}
			autoplay={activeGame !== GameType.PLINKO}
			autoplayInterval={3 * second}
			dragging={true}
			enableKeyboardControls={false}
			swiping={false}
			withoutControls={true}
			wrapAround={true}
			list={CURRENCIES_CAROUSEL_LIST}
			cellSpacing={10}
		/>
	);
};
