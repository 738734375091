import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';
// import gif from './LoadingGifBase64';
import { FullscreenLoadingContext } from '@common/context/app/FullscreenLoading.context';
import { LoadingLogo } from '../loadingLogo/loading';
import { StyledLoader } from '../loader/Loader.styled';
import { config } from '../../config';

export const StyledFullscreenLoading = styled.div<{
	$active: boolean;
	$hide: boolean;
}>`
	align-items: center;
	background-color: ${(props) => props.theme.colors.background};
	display: ${(props) => (props.$hide ? 'flex' : 'none')};
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	opacity: ${(props) => (props.$active ? '1' : '0')};
	position: fixed;
	text-align: center;
	top: 0;
	transition: ${(props) => props.theme.layout.transition};
	width: 100%;
	z-index: 9999999; // 7

	.text-logo {
		margin-top: 150px;
		z-index: 0;
	}
`;

// const StyledLoadingGif = styled.div`
// 	background: url(${gif});
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	height: 150px;
// 	left: 50%;
// 	position: absolute;
// 	top: 50%;
// 	transform: translate(-50%, -50%);
// 	width: 150px;
//
// 	@media (max-width: ${props => props.theme.layout.maxWidthSmallTablet}) {
// 		width: 100px;
// 		height: 100px;
// 	}
// `;

export const FullscreenLoading: FC<PropsWithChildren<any>> = ({ children }) => {
	const [loaded, setLoaded] = useState(false);
	const [hide, setHide] = useState(false);
	const refTimeout = useRef(null);

	const setLoaded_ = useCallback(
		(loaded_: boolean) => {
			if (loaded !== loaded_) {
				setLoaded(loaded_);
				if (refTimeout.current) {
					clearTimeout(refTimeout.current);
				}
				refTimeout.current = setTimeout(() => {
					setHide(loaded_);
				}, 300);
			}
		},
		[loaded],
	);

	useEffect(
		() => () => {
			if (refTimeout.current) {
				clearTimeout(refTimeout.current);
			}
		},
		[],
	);

	return (
		<FullscreenLoadingContext.Provider
			value={{ loaded, setLoaded: setLoaded_ }}
		>
			{children}
			<StyledFullscreenLoading $hide={!hide} $active={!loaded}>
				<StyledLoader $active={true} $pointerEvents={false}>
					<LoadingLogo />
				</StyledLoader>
				<span className="text-logo show-desktop">{config.logo}</span>
				{/*<StyledLoadingGif/>*/}
			</StyledFullscreenLoading>
		</FullscreenLoadingContext.Provider>
	);
};
