import React, { FC, MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { NOTIFICATION_ICON_CLASS_NAME } from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import { useSideMenu } from '../hooks/useSideMenu';

export interface SideMenuListElement {
	href?: string;
	id: string;
	onClick?: MouseEventHandler<any>;
	label: ReactNode;
}

interface SideMenuListProps {
	list: Array<SideMenuListElement>;
	className?: string;
}

export const SideMenuList: FC<SideMenuListProps> = ({ list, className }) => {
	const { closeOnMobile } = useSideMenu();
	return (
		<SideMenuListStyled className={className}>
			{list
				.filter((el) => !!el)
				.map(({ href, label, onClick, id }) => {
					if (onClick) {
						return (
							<a key={id} id={id}>
								<div
									className={'text-style-sm-medium'}
									onClick={(event) => {
										onClick(event);
										closeOnMobile();
									}}
								>
									{label}
								</div>
							</a>
						);
					}
					if (href.indexOf('http') > -1) {
						return (
							<a
								href={href}
								key={id}
								id={id}
								target={'_blank'}
								rel="noreferrer"
								onClick={closeOnMobile}
							>
								<div className={'text-style-sm-medium'}>{label}</div>
							</a>
						);
					}
					return (
						<LinkTo href={href} key={id}>
							<a id={id}>
								<div onClick={closeOnMobile} className={'text-style-sm-medium'}>
									{label}
								</div>
							</a>
						</LinkTo>
					);
				})}
		</SideMenuListStyled>
	);
};

export const SideMenuListStyled = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	a {
		display: block;
		width: 100%;

		div {
			padding: 8px 0;
			position: relative;
			width: 100%;

			.${NOTIFICATION_ICON_CLASS_NAME} {
				left: -10px;
				right: unset;
				top: 1px !important;
			}
		}
	}
`;
