import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
import { VipLevel } from '../types/vip.types';

export type ValueType = Array<VipLevel>;
const NAME = 'levels';
const initialState: ValueType = [];

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const levelsSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		levelsUpdate: setAction,
	},
});
