import React, { FC } from 'react';
import { Button, CSSClassNameButton } from '@ui/button';
import { Close } from '@icons/ui/Close';
import styled, { css } from 'styled-components';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { SectionSwitch } from '../../header/SectionSwitch';
import { useSideMenu } from '../hooks/useSideMenu';

export const SideMenuNavigation: FC = () => {
	const { setIsOpen } = useSideMenu();

	return (
		<SideMenuNavigationStyled>
			<SectionSwitch />
			<Button
				name={'Close side menu'}
				styleType={ButtonStyleType.OUTLINE}
				iconBefore={<Close fill={'white'} />}
				onClick={() => setIsOpen(false)}
			/>
		</SideMenuNavigationStyled>
	);
};

export const SideMenuNavigationStyled = styled.div`
	display: none;
	justify-content: space-between;
	.${CSSClassNameButton} {
		float: right;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_tablet_large,
						b: viewportPadding,
					})}) {
				display: flex;
			}
		`,
	)}
`;
