import { shallowEqual } from 'react-redux';
import queryString from 'query-string';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';

export enum QueryKeys {
	TAB = 'tab',
	WALLET_TAB = 'walletTab',
}

export const useRouterQueryKey = (key: string) =>
	useAppSelector(
		(state) =>
			key
				? (queryString.parse(state.router.location.search)[key] as string) || ''
				: '',
		shallowEqual,
	);
export const useRouterQueryTab = () => useRouterQueryKey(QueryKeys.TAB);
export const useRouterPathname = () =>
	useAppSelector(
		(state) => (state.router.location.pathname as string) || '',
		shallowEqual,
	);
export const useRouterSearch = () =>
	useAppSelector(
		(state) => (state.router.location.search as string) || '',
		shallowEqual,
	);
