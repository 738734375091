import { useCallback, useState } from 'react';
import {
	CURRENCIES_SETTINGS_UPDATE_INTERVAL,
	currenciesSettings,
} from 'src/common/controllers/currency/currenciesSettings';
import { useInterval } from 'src/LegacyApp/hooks/app/useInterval';
import transactionService from 'src/modules/transactions/transactionsService';
import { CurrenciesSettings } from '@legacyApp/types/wallet/CurrenciesSettings';
import { CurrencyType, Token } from '@legacyApp/types/wallet/CurrencyType';

export const useCurrenciesSettings = () => {
	const [settings, setSettings] = useState<CurrenciesSettings>(
		currenciesSettings.getAllSettings.bind(currenciesSettings),
	);

	const updateSettings = useCallback(() => {
		const newData = currenciesSettings.getAllSettings();

		if (transactionService.isDataDifferent(settings, newData)) {
			setSettings(newData);
		}
	}, [settings]);

	const [list, setList] = useState<CurrencyType[]>(
		currenciesSettings.getList.bind(currenciesSettings),
	);

	const updateList = useCallback(() => {
		const newData = currenciesSettings.getList();

		if (transactionService.isListDifferent(list, newData)) {
			setList(newData);
		}
	}, [list]);

	const [tokens, setTokens] = useState<Token[]>(
		currenciesSettings.getTokens.bind(currenciesSettings),
	);

	const updateTokens = useCallback(() => {
		const newData = currenciesSettings.getTokens();

		if (transactionService.isListDifferent(tokens, newData)) {
			setTokens(newData);
		}
	}, [tokens]);

	useInterval({
		id: 'currencies_settings',
		interval: CURRENCIES_SETTINGS_UPDATE_INTERVAL,
		callback: () => {
			updateSettings();
			updateTokens();
			updateList();
		},
	});

	return {
		settings,
		list,
		tokens,
	};
};
