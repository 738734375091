import { GameType } from '@modules/games/Game/constants/availableGames';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { generateConfirmOptionKey } from '@modules/localStorage/types/localStorageKeys.type';
import { simpleTrans } from '@legacyApp/client/modules/translation/translationService';
import { optionsDisable, optionsEnable } from '../options.actions';
import { OptionsType } from '../options.constants';
import { setConfirm } from '../../confirm/confirm.actions';
import { OPTIONS_WITH_CONFIRMATION } from '../options.with.confirmation';
import { day, second } from '../../../modules/app/time';

export interface OptionChangeThunkOptions {
	game?: GameType;
	noAlert?: boolean;
}

export const optionChangeThunk =
	(id: OptionsType, value: boolean, options?: OptionChangeThunkOptions) =>
	(dispatch, getState) => {
		const enabled = getState().options[id];
		if (value === undefined) {
			value = !enabled;
		}
		const action = value ? optionsEnable : optionsDisable;
		const lastConfirmTimestamp = Number(lsGet(generateConfirmOptionKey(id)));
		const isLastConfirmValid =
			lastConfirmTimestamp && lastConfirmTimestamp + day > new Date().getTime();
		if (value && OPTIONS_WITH_CONFIRMATION?.[id] && !isLastConfirmValid) {
			if (options?.noAlert) {
				return;
			}
			return dispatch(
				setConfirm({
					question: simpleTrans(OPTIONS_WITH_CONFIRMATION[id].label as string, {
						game: options?.game,
						...(OPTIONS_WITH_CONFIRMATION[id]?.options || {}),
					}),
					lockConfirm: 3 * second,
					callback: () => {
						lsSet(generateConfirmOptionKey(id), new Date().getTime());
						dispatch(optionsEnable(id, options?.noAlert));
					},
				}),
			);
		}
		dispatch(action(id, options?.noAlert));
	};
