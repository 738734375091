import { modalOpenInfo } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';

export const responseModal = (data, requestData) => {
	return (dispatch) => {
		if (!data) {
			return;
		}
		dispatch(
			modalOpenInfo({
				id: MODAL_ID.RESPONSE_INFO,
				props: {
					message: {
						...data.modalInfo,
					},
					requestData,
					title: 'Information',
				},
			}),
		);
	};
};
