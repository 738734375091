import styled from 'styled-components';
import { CSSClassNameCurrencyValue } from '@common/components/number/CurrencyValue';
import { boxNoHeight } from '@legacyApp/client/modules/style/defaults';
import { StyledBorderBox } from '@legacyApp/client/components/strategy/conditionBlockEdit/ConditionBlockEdit.styled';
import { CSSClassNameCurrencyLogo } from '@legacyApp/client/components/currencySymbol/tokenSymbol';
import { CSSClassNameBalanceCurrencyValue } from '@common/components/number/balanceAmount/balanceAmount';


export const StyledExpertConfigTemplate = styled.span.attrs({
	className: 'text-style-caption-medium',
})`
	color: ${(props) => props.theme.colors.textDisabled};
	text-transform: uppercase;
`;

export const StyledExpertConfig = styled.div.attrs({
	className: 'expert-config',
})`
	${boxNoHeight};
	position: relative;
	padding: 8px;

	p {
		margin-top: 4px !important;
		margin-bottom: 4px;
	}

	span {
		display: inline-block;
		margin-right: 4px;

		.${CSSClassNameCurrencyLogo} {
			display: inline-flex !important;
			margin-right: 4px;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	.${CSSClassNameCurrencyValue} {
		display: inline-flex !important;
	}

	.${CSSClassNameBalanceCurrencyValue} {
		margin-right: 0;

		span {
			margin-right: 0;
		}
	}
`;

export const StyledExpertConfigDescription = styled.p.attrs({})`
	margin: 8px 0 0;
	line-height: 1.5;

	* {
		text-transform: lowercase;
	}

	&:first-of-type {
		margin: 0 !important;
	}
`;

export const StyledExpertBorderBox = styled(StyledBorderBox)`
	display: grid;
	grid-template-columns: 30px calc(100% - 60px) 30px;
`;
