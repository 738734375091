import { servicesConfig } from '@server/config/services.config';
import { fetchScript } from '@legacyApp/client/modules/app/fetchScript';

export const installRecaptcha = (callback, onerror, captchaKey?: string) => {
	fetchScript(
		`https://recaptcha.net/recaptcha/api.js?render=${
			captchaKey || servicesConfig.captchaSiteKey
		}`,
		{
			callback,
			onerror,
		},
	);
};
