import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import {
	hideSupport,
	showSupport,
} from '@modules/support/methods/supportService';
import { supportInit } from '@modules/support/store/thunks/supportInit.thunk';

export const supportToggleThunk = (isOpen?: boolean): AppThunk => {
	return async (dispatch, getState) => {
		const open = getState().support.open;
		const opened = isOpen !== undefined ? !isOpen : open;
		if (opened) {
			hideSupport();
		} else {
			return dispatch(supportInit(() => showSupport()));
		}
	};
};
