import { combineReducers } from 'redux';
import { DEFAULT_CURRENCY } from '@common/controllers/currency/currenciesSettings';
import { saveActiveCurrency } from '../../modules/app/currencyService';
import {
	getLastRegisterTimestampFromLocalStorage,
	setLastRegisterTimestampToLocalStorage,
} from '../../modules/app/userService';
import { setUserReducerOld } from '../../modules/app/reducers/reducerService';
import { APP_INITIALIZED } from '../app/app.constants';
import { checkToken, tokenService } from '../../modules/app/tokenService';
import {
	CLEAR_FIRST_LOGIN,
	REFRESH_USER_DATA,
	RESET_TOKEN,
	RESET_USER_DATA,
	SET_CURRENCY,
	SET_EMAIL,
	SET_FIRST_LOGIN,
	SET_HAS_AVAILABLE_BONUS_CODE,
	SET_JOINED_DATE,
	SET_LAST_REGISTER_TIMESTAMP,
	SET_SOCKET_PRIVATE_CHANNEL,
	SET_TOKEN,
	SET_USER_ADMIN,
	SET_USER_HIGH_ROLLER,
	SET_USER_ID,
	SET_USER_MODERATOR,
	SET_USER_RACE_BADGE,
	SET_USERNAME,
	USER_HAS_EMAIL_TO_VERIFY,
	USER_HAS_KYC_TO_VERIFY,
	USER_HAS_PASSWORD_TO_SET,
	USER_LOGGED,
	USER_LOGGING,
	USER_LOGOUT,
	USER_NOT_LOGGED,
	USER_SET_WITHDRAW_BLOCKED,
	USER_SET_WITHDRAW_BLOCKED_TIMESTAMP,
} from './user.constants';

const loginReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case USER_LOGGED:
			return action.isLogged;
		case USER_LOGOUT:
			return false;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const loggingReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case USER_LOGGING:
			return true;
		case USER_LOGGED:
			return false;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const loginErrorReducer = (
	state = null,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case USER_NOT_LOGGED: {
			return action.message;
		}
		case USER_LOGGED: {
			return null;
		}
		default: {
			return state;
		}
	}
};

const tokenReducer = (
	state = {
		value: false,
		expire: false,
	},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_TOKEN:
			tokenService.set(action.data);
			return checkToken(action.data);
		case RESET_TOKEN:
			tokenService.set(false);
			return checkToken(false);
		case RESET_USER_DATA:
			tokenService.set(false);
			return checkToken(false);
		default:
			return state;
	}
};

const nameReducer = (
	state = '',
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_USERNAME:
			if (window.__insp) {
				window.__insp.push(['identify', action.name]);
			}
			return action.name;
		case RESET_USER_DATA:
			return '';
		default:
			return state;
	}
};

const emailReducer = (
	state = '',
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_EMAIL:
			return action.payload;
		case RESET_USER_DATA:
			return '';
		default:
			return state;
	}
};

const currencyReducer = (
	state = DEFAULT_CURRENCY,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CURRENCY:
			saveActiveCurrency(action.payload);
			return action.payload;
		case RESET_USER_DATA:
			return DEFAULT_CURRENCY;
		default:
			return state;
	}
};

const verifyEmailReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case USER_HAS_EMAIL_TO_VERIFY:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const verifyKycReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case USER_HAS_KYC_TO_VERIFY:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const setPasswordReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case USER_HAS_PASSWORD_TO_SET:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const firstLoginReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_FIRST_LOGIN:
			return true;
		case CLEAR_FIRST_LOGIN:
			return false;
		default:
			return state;
	}
};

const socketChannelReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_SOCKET_PRIVATE_CHANNEL:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const joinedDateReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_JOINED_DATE:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const idReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_USER_ID:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const adminReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_USER_ADMIN:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const moderatorReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_USER_MODERATOR:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const highRollerReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_USER_HIGH_ROLLER:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const refreshUserDataInitializedReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case REFRESH_USER_DATA:
			return true;
		default:
			return state;
	}
};

const raceBadgeReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_USER_RACE_BADGE:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const lastRegisterTimestampReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case APP_INITIALIZED: {
			return getLastRegisterTimestampFromLocalStorage();
		}
		case SET_LAST_REGISTER_TIMESTAMP:
			setLastRegisterTimestampToLocalStorage(action.payload);
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const withdrawBlockedReducer = setUserReducerOld(
	false,
	USER_SET_WITHDRAW_BLOCKED,
);

const withdrawBlockedTimestampReducer = setUserReducerOld(
	null,
	USER_SET_WITHDRAW_BLOCKED_TIMESTAMP,
);

const hasAvailableBonusCodeReducer = setUserReducerOld(
	null,
	SET_HAS_AVAILABLE_BONUS_CODE,
);

const userReducer = combineReducers({
	isLogged: loginReducer,
	isLogging: loggingReducer,
	isAdmin: adminReducer,
	isModerator: moderatorReducer,
	isHighRoller: highRollerReducer,
	loginError: loginErrorReducer,
	token: tokenReducer,
	name: nameReducer,
	email: emailReducer,
	activeCurrency: currencyReducer,
	hasEmailToVerify: verifyEmailReducer,
	hasKycToVerify: verifyKycReducer,
	isFirstLogin: firstLoginReducer,
	socketChannel: socketChannelReducer,
	hasPasswordToSet: setPasswordReducer,
	joinedDate: joinedDateReducer,
	id: idReducer,
	refreshUserDataInitialized: refreshUserDataInitializedReducer,
	raceBadge: raceBadgeReducer,
	lastRegisterTimestamp: lastRegisterTimestampReducer,
	withdrawBlocked: withdrawBlockedReducer,
	withdrawBlockedTimestamp: withdrawBlockedTimestampReducer,
	hasAvailableBonusCode: hasAvailableBonusCodeReducer,
});

export { userReducer };
