import NextLink, { LinkProps } from 'next/link';
import { FC, PropsWithChildren, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { languageConfig } from '@server/config/language.config';
import { PageConfigContext } from '@modules/page/PageConfig/PageConfig.provider';
import { useModalId } from '@modules/modals/store/modal.selectors';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { closeModal } from '@modules/modals/store/modal.actions';
import { isFunction } from '@common/methods/isFunction';
import { getPathnameWithoutLang } from '@common/methods/getPathnameWithoutLang/getPathnameWithoutLang';
import { getPathLocalized } from '@common/methods/getPathLocalized/getPathLocalized';
import routingService from '../../../../LegacyApp/client/modules/app/routingService';
import { LinkToContext } from './LinkTo.context';

interface LinkToProps extends LinkProps {
	as?: any;
	href: string;
	className?: string;
	forceATag?: boolean;
	style?: object;
	rel?: string;
	target?: string;
	onClick?: (e: any) => void;
}

type LinkType = FC<PropsWithChildren<LinkToProps>>;

export const LinkTo: LinkType = (props) => {
	const { linksWithHardRedirect } = useContext(PageConfigContext);
	const dispatch = useAppDispatch();
	const id = useModalId();

	const closeModal_ = id ? () => dispatch(closeModal(true)) : null;

	const { href, locale, ...data } = props;
	const currentLocale = useAppSelector(
		(state) => state.app.language || languageConfig.default,
		shallowEqual,
	);
	let hrefString = href;
	if (!hrefString) {
		hrefString = '/';
	}

	const lang = locale || currentLocale;

	if (hrefString.indexOf('http') === -1) {
		hrefString =
			locale && !linksWithHardRedirect
				? getPathnameWithoutLang(hrefString, lang)
				: getPathLocalized({ pathname: hrefString, locale: lang });
	}

	if (data.onClick) {
		const onClick = (e) => {
			e.preventDefault();
			if (isFunction(closeModal_)) {
				closeModal_();
			}
			if (linksWithHardRedirect) {
				data.onClick(e);
				routingService.redirect(
					getPathLocalized({ pathname: hrefString, locale: lang }),
					true,
					true,
				);
			} else {
				routingService
					.dynamicRedirect(
						getPathLocalized({ pathname: hrefString, locale: lang }),
						true,
					)
					.then(() => {
						data.onClick(e);
					});
			}
		};
		return (
			<a
				className={data.className}
				style={data.style}
				onClick={onClick}
				href={getPathLocalized({ pathname: hrefString, locale: lang })}
			>
				{data.children}
			</a>
		);
	}

	if (linksWithHardRedirect) {
		return (
			<a
				style={data.style}
				onClick={closeModal_}
				className={data.className}
				href={getPathLocalized({ pathname: hrefString, locale: lang })}
			>
				{data.children}
			</a>
		);
	}

	const passATag = data.className || data.style || data.forceATag;

	const props_ = {
		...(!passATag && closeModal_ && { onClick: closeModal_ }),
		...data,
	};

	// console.log('LinkTo', {
	// 	props_,
	// 	passATag,
	// 	data,
	// 	hrefString,
	// 	locale,
	// 	linksWithHardRedirect,
	// 	href,
	// 	currentLocale,
	// 	lang,
	// });

	return (
		<LinkToContext.Provider
			value={{
				onClick: closeModal_,
			}}
		>
			<NextLink
				legacyBehavior={true}
				prefetch={false}
				href={hrefString}
				locale={locale}
				{...props_}
			>
				{passATag ? (
					<a
						onClick={closeModal_}
						style={data.style}
						className={data.className}
						href={hrefString}
					>
						{data.children}
					</a>
				) : (
					data.children
				)}
			</NextLink>
		</LinkToContext.Provider>
	);
};
