import { getGamesList } from '@modules/games/Game/methods/getGamesList';
import { isFunction } from '@common/methods/isFunction';
import { GameType } from '@modules/games/Game/constants/availableGames';

import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeysType } from '@modules/localStorage/types/localStorageKeys.type';

export type ParseValue<T = any> = (game: GameType, value: T, initValue: T) => T;
export type ValueFunc<T = any> = (game: GameType) => T;

export const getInitValue = <T = any>(
	value: T | Partial<Record<GameType | 'default', T | ValueFunc<T>>>,
	game: GameType,
) => {
	if (typeof value !== 'object') {
		return value;
	}
	if (value[game]) {
		return isFunction(value[game]) ? value[game](game) : value[game];
	}
	if ('default' in value && value.default) {
		return isFunction(value.default)
			? // @ts-expect-error invalid type
			  value.default(game)
			: value.default;
	}
	return value;
};

export const getPerGameInitObject = <T = any>(
	value: T | Partial<Record<GameType | 'default', T | ValueFunc<T>>>,
	type: string,
	cookieName: LocalStorageKeysType,
	noTotal: boolean,
	parseValue: ParseValue<T>,
) => {
	const result: Partial<Record<GameType | 'total', T>> = {};
	const lsValues = cookieName && type ? lsGet(cookieName) : false;
	getGamesList().forEach((game) => {
		const initValue = getInitValue<T>(value, game);
		result[game] =
			type && lsValues && lsValues[type]
				? lsValues[type][game] || initValue
				: initValue;
		if (parseValue) {
			result[game] = parseValue(game, result[game], initValue);
		}
		if (!noTotal) {
			result.total =
				type && lsValues && lsValues[type]
					? lsValues[type].total || initValue
					: initValue;
		}
	});
	// console.log('getPerGameInitObject', {
	//   value, type, cookieName, noTotal,
	//   result,
	//   lsValues,
	// });
	return result;
};
