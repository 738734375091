import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { useAppIsMenuVisible } from '@common/selectors/app.selectors';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { toggleMenu } from '@legacyApp/client/store/app/app.actions';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';

import { RectangleLoader } from '@common/components/contentLoaders/RectangleLoader';
import { Redirect } from '@common/components/default/Redirect/Redirect';
import { UserLoadingWrapper } from '@legacyApp/client/components/loading/UserLoadingWrapper';
import { NotificationIcon } from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import { BalanceContainer } from '@modules/balance';
import { MyVipIcon } from '@modules/header/MyVipIcon';
import { Button } from '@ui/button';

export const HeaderUser: FC = () => {
	const isLogged_ = useUserIsLogged();

	const dispatch = useDispatch();

	const isMenuVisible_ = useAppIsMenuVisible();

	const toggleMenu_ = useCallback(() => {
		if (!isMenuVisible_) {
			dispatch(toggleMenu());
		}
	}, [dispatch, isMenuVisible_]);

	return (
		<>
			<UserLoadingWrapper
				loader={
					<RectangleLoader
						passToWrapper
						height={20}
						width={150}
						id={'header-balance'}
					/>
				}
			>
				{isLogged_ ? (
					<BalanceContainer source="header" showHideZeroBalancesSwitch />
				) : (
					<>
						<Redirect href={'/login'} rel="nofollow">
							<Button
								name="Login"
								styleType={ButtonStyleType.SECONDARY}
								label={trans({ label: 'Login' })}
							/>
						</Redirect>

						<Redirect href={'/register'} rel="nofollow">
							<Button name="Register" label={trans({ label: 'Register' })} />
						</Redirect>
					</>
				)}
			</UserLoadingWrapper>

			{isLogged_ && (
				<UserLoadingWrapper
					loader={
						<RectangleLoader
							className="show-desktop"
							passToWrapper
							height={20}
							width={100}
							id={'header-menu'}
						/>
					}
				>
					{
						<button
							onClick={toggleMenu_}
							style={{
								background: 'var(--color-dark-500)',
								borderRadius: '100%',
								height: '32px',
								width: '32px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<NotificationIcon type={ROUTING_ID.USER_SETTINGS} />

							<MyVipIcon size={24} />
						</button>
					}
				</UserLoadingWrapper>
			)}
		</>
	);
};
