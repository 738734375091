import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { servicesConfig } from '@server/config/services.config';
import { websocketSetConnectionState } from '../../store/websocket/websocket.actions';
import {
	WebsocketChannelType,
	WebsocketEventType,
} from '../../store/websocket/websocket.constants';

class websocketClass {
	instance = null;

	getInstance(dispatch) {
		if (this.instance) {
			return this.instance;
		}
		this.instance = null;
		if (process.browser) {
			const options = {
				cluster: 'eu',
				broadcaster: 'pusher',
				key: servicesConfig.pusherId,
				wsHost: servicesConfig.socketHost,
				...(parseInt(servicesConfig.socketPort) && {
					wssPort: servicesConfig.socketPort,
				}),
				disableStats: true,
				enabledTransports: ['ws', 'flash'],
				forceTLS: true,
			};
			window['Pusher'] = Pusher;
			const instance = new Echo(options);
			this.instance = instance;
			this.handleInstance(instance, dispatch);
			return instance;
		}
		return null;
	}

	handleInstance(instance = this.instance, dispatch) {
		if (!instance?.connector?.pusher) {
			return setTimeout(() => {
				return this.handleInstance(this.instance, dispatch);
			}, 1000);
		}
		const pusher = instance.connector.pusher;
		// pusher.connection.bind('error', (error) => {
		// 	sentryError(error, pusher);
		// });
		dispatch(websocketSetConnectionState(pusher.connection.state));
		pusher.connection.bind('state_change', (states) => {
			dispatch(websocketSetConnectionState(states.current));
		});
	}

	isEventExist(
		channel: WebsocketChannelType,
		event: WebsocketEventType,
		channels,
	) {
		// console.log('isEventExist', {channel, event, data: channels, result: channels[channel]?.pusher?.channels?.channels[channel]?.callbacks?._callbacks[`_${event}`]});
		return channels[channel]?.pusher?.channels?.channels[channel]?.callbacks
			?._callbacks[`_${event}`];
	}

	getMessage(data) {
		const { message } = data;
		return message;
	}
}

const websocketService = new websocketClass();

export default websocketService;
