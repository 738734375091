import { IconProps } from '@icons/all-bets';
import { FC } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../hooks/useId';

export const CasinoV2: FC<IconProps> = ({ fill, fillStop }) => {
	const { get } = useId({ id: 'casino-v2' });
	const color = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M9 0.875C9 0.875 16.8125 5.875 16.8125 9.9375C16.8132 10.7109 16.5931 11.4684 16.178 12.121C15.763 12.7736 15.1703 13.2942 14.4696 13.6216C13.7689 13.949 12.9893 14.0695 12.2225 13.9691C11.4556 13.8687 10.7334 13.5514 10.1406 13.0547L11.5 17.125H6.5L7.85938 13.0547C7.26661 13.5514 6.54436 13.8687 5.7775 13.9691C5.01065 14.0695 4.23107 13.949 3.53038 13.6216C2.82968 13.2942 2.237 12.7736 1.82197 12.121C1.40695 11.4684 1.18684 10.7109 1.1875 9.9375C1.1875 5.875 9 0.875 9 0.875Z"
						stroke={get().url}
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<defs>
						<linearGradient
							id={get().id}
							x1="16.8125"
							y1="0.875"
							x2="0.574993"
							y2="16.488"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor={fillStop || color} />
							<stop offset="1" stopColor={color} />
						</linearGradient>
					</defs>
				</>
			}
			width={18}
			height={18}
		/>
	);
};
