import { FC } from 'react';
import { Loadable } from '@legacyApp/client/components/loader/loadable/loadable';
import { useAppMobile } from '@common/selectors/app.selectors';
import { BottomMenuProps } from './BottomMenuProps';

const BottomMenuLoadable = Loadable(
	{
		loader: () => import(/* webpackChunkName: "BottomMenu" */ './BottomMenu'),
		ssr: true,
	},
	'./BottomMenu',
	undefined,
	true,
);

export const BottomMenu: FC<BottomMenuProps> = (props) => {
	const isMobile = useAppMobile();
	if (!isMobile) {
		return null;
	}
	return <BottomMenuLoadable {...props} />;
};
