import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Close: FC<IconProps> = ({ fill }) => {
	return (
		<Svg
			content={
				<>
					<path
						d="M9.375 2.625L2.625 9.375"
						stroke={fill || 'white'}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M9.375 9.375L2.625 2.625"
						stroke={fill || 'white'}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			}
			width={12}
			height={12}
		/>
	);
};
