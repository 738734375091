import { LoginNameType } from '@legacyApp/types/user/LoginNameType';
import { filterArrayDuplicates } from '@legacyApp/client/modules/app/appService';
import { Message } from '../types/Message.type';
import { checkRealUserName } from './checkRealUserName';
import { sortUsers } from './sortUsers';

export const getUsersList = (
	messages: Array<Message>,
): Array<LoginNameType> => {
	const users = filterArrayDuplicates(
		messages.map((el) => el.user.login),
	).filter((el) => checkRealUserName(el));
	return sortUsers(users);
};
