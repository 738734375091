import Redux from 'redux';

export const objectBoolReducer = (
	init,
	TRUE_ACTION,
	FALSE_ACTION,
	callback,
) => {
	return (
		state = init,
		action: Redux.AnyAction = {
			type: '',
		},
	) => {
		switch (action.type) {
			case TRUE_ACTION: {
				if (callback) {
					return callback(true, action.payload, state);
				}
				return {
					...state,
					[action.payload]: true,
				};
			}
			case FALSE_ACTION: {
				if (callback) {
					return callback(false, action.payload, state);
				}
				return {
					...state,
					[action.payload]: false,
				};
			}
			default: {
				return state;
			}
		}
	};
};
