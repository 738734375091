export const TABLE_ID = {
	// by transaction store
	vaultHistory: 'vaultHistory',
	allBets: 'allBets',
	highRolls: 'highRolls',
	myBets: 'myBets',
	gameResults: 'results',
	depositHistory: 'depositHistory',
	withdrawHistory: 'withdrawHistory',
	affiliateCampaigns: 'affiliateCampaigns',
	affiliateAnalytics: 'affiliateAnalytics',
	affiliateFunds: 'affiliateFunds',
	affiliateCashoutHistory: 'affiliateCashoutHistory',
	rakebackFunds: 'rakebackFunds',
	otherTransactions: 'otherTransactions',
	friendsIgnored: 'friendsIgnored',
	sessions: 'sessions',
	sessionHistory: 'sessionHistory',
	// by hooks
	bonusHistoryClaimed: 'bonusHistoryClaimed',
	bonusHistoryExpired: 'bonusHistoryExpired',
	leaderboard: 'leaderboard',
	raceResults: 'raceResults',
	affiliateCampaignUsers: 'affiliateCampaignUsers',
	affiliateCampaignHistory: 'affiliateCampaignHistory',
	lastRacesResults: 'lastRacesResults',
	benefitsBreakdown: 'benefitsBreakdownTable',
	availableBonusCodes: 'availableBonusCodes',
} as const;
type TableId = keyof typeof TABLE_ID;
export type TableIdType = (typeof TABLE_ID)[TableId];
