import { apiConfig } from '@server/config/api.config';
import { GameMode } from '@modules/slots/store/slots.types';
import { OperatorSports } from '@modules/sportsbook/@types/operator.sports';

export const SPORTSBOOK_ID = 'sportsbook';

export const SPORTSBOOK_PAGE_ID = {
	INDEX: SPORTSBOOK_ID,
} as const;

export const SPORTSBOOK_META = {
	[SPORTSBOOK_PAGE_ID.INDEX]: {
		title: 'Bitcoin Sports Betting - Crypto Sports Betting',
	},
} as const;

export const PAGE_ID = SPORTSBOOK_PAGE_ID.INDEX;
export const SLUG = 'betradar-sportsbook';
export const MODE = GameMode.REAL;
export const SPORTSBOOK_SESSION_COOKIE = 'wolfbetsbsession';
export const SPORTS_IFRAME_URL = apiConfig.sportsIframeUrl;
export const SPORTS_OPERATOR: OperatorSports = OperatorSports.SOFTSWISS;
