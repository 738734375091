import md5 from 'md5';
import { jsonToString } from '@common/methods/jsonToString';
import { getTimestamp } from '../../client/modules/app/timeService';
import { sentryError } from '../../client/modules/app/sentryService';

export const createApiHash = (body = '') => {
	try {
		const bodyString = jsonToString(body);
		const bodyhash = md5(bodyString).split('');
		const timestamphash = md5(`${getTimestamp()}`).split('');
		return bodyhash
			.map((character, index) => {
				return `${character}${timestamphash[index]}`;
			})
			.join('');
	} catch (error) {
		sentryError(new Error('Error in createApiHash'), {
			body,
			error,
			jsonToString,
			md5,
		});
		return '';
	}
};
