import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import websocketService from '../../../modules/app/websocketService';
import { setSocketChannel } from '../websocket.actions';
import { WebsocketChannelType } from '../websocket.constants';

export const getInstanceThunk = (channel: WebsocketChannelType): AppThunk => {
	return (dispatch, getState) => {
		const state = getState();
		if (!state?.websocket?.channels?.[channel]) {
			const instance = websocketService.getInstance(dispatch);
			const channelInstance = instance.channel(channel);
			dispatch(setSocketChannel(channel, channelInstance));
			return channelInstance;
		}
		return state.websocket.channels[channel];
	};
};
