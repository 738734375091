import { combineReducers } from 'redux';
import {
	SET_SOCKET_CHANNEL,
	WEBSOCKET_SET_CONNECTION_STATE,
} from './websocket.constants';

const channelsReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_SOCKET_CHANNEL:
			if (!action.data) {
				if (state[action.name]) {
					const data = {
						...state,
					};
					delete data[action.name];
					return data;
				}
				return state;
			}
			return {
				...state,
				[action.name]: action.data,
			};
		default:
			return state;
	}
};

const connectionStateReducer = (
	state = null,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case WEBSOCKET_SET_CONNECTION_STATE:
			return action.payload;
		default:
			return state;
	}
};

const websocketReducer = combineReducers({
	channels: channelsReducer,
	connectionState: connectionStateReducer,
});

export { websocketReducer };
