import { actionSimple } from '../../modules/app/actionService';
import {
	REGISTRY_EPIC_INJECT,
	REGISTRY_EPIC_INJECTED,
	REGISTRY_EPIC_REJECT,
	REGISTRY_EPIC_REJECTED,
	REGISTRY_PACKAGE_REGISTER,
	REGISTRY_REDUCER_INJECT,
	REGISTRY_REDUCER_INJECTED,
	REGISTRY_REDUCER_REJECT,
	REGISTRY_REDUCER_REJECTED,
} from './registry.constants';

export const registryReducerInject = actionSimple(REGISTRY_REDUCER_INJECT);
export const registryEpicInject = actionSimple(REGISTRY_EPIC_INJECT);
export const registryReducerInjected = actionSimple(REGISTRY_REDUCER_INJECTED);
export const registryEpicInjected = actionSimple(REGISTRY_EPIC_INJECTED);
export const registryReducerReject = actionSimple(REGISTRY_REDUCER_REJECT);
export const registryEpicReject = actionSimple(REGISTRY_EPIC_REJECT);
export const registryReducerRejected = actionSimple(REGISTRY_REDUCER_REJECTED);
export const registryEpicRejected = actionSimple(REGISTRY_EPIC_REJECTED);

export const registryRegisterPackage = (id, modules) => ({
	type: REGISTRY_PACKAGE_REGISTER,
	id,
	modules,
});
