import { connect } from 'react-redux';
import { Install } from './install';

const mapStateToProps = (state) => {
	return {
		allow: state.pwa.installActive && !state.pwa.mode,
		callback: state.pwa.callback,
	};
};

const InstallContainer = connect(mapStateToProps, null)(Install);

export default InstallContainer;
