import Fingerprint from 'fingerprintjs2';
import md5 from 'md5';
import { cookieService } from '@common/controllers/cookieService';
import { stringToJson } from '@common/methods/stringToJson';

import { jsonToString } from '@common/methods/jsonToString';
import { CookiesKeys } from '@modules/cookies/types/cookiesKeys.type';

let data = cookieService.get(CookiesKeys.INSP_FRGCH);
if (data) {
	data = stringToJson(data);
}

export const parseData = (el) => {
	if (el && el.value && typeof el.value === 'object') {
		if (el.value.length) {
			return el.value.join(',');
		}
		return jsonToString(el.value);
	}
	return el.value + '';
};

export const browserFingerprint = () => {
	return new Promise((resolve) => {
		const simpleFingerprint = [
			'platform',
			'timezone',
			'language',
			'fonts',
			'webglVendorAndRenderer',
			'screenResolution',
		];
		const advancedFingerprint = ['webgl', 'canvas'];
		Fingerprint.get(
			{
				fonts: {
					extendedJsFonts: false,
				},
				excludes: {
					// canvas: true,
					audio: true,
					// webgl: true,
				},
			},
			(components) => {
				// console.log(components);
				return resolve({
					simple: components
						.filter((el) => simpleFingerprint.indexOf(el.key) > -1)
						.map(parseData)
						.join(';'),
					advanced: components
						.filter((el) => advancedFingerprint.indexOf(el.key) > -1)
						.map(parseData)
						.join(';'),
					details: {
						userAgent: components.find((el) => el.key === 'userAgent').value,
						timezone: components.find((el) => el.key === 'timezone').value,
						language: components.find((el) => el.key === 'language').value,
					},
				});
			},
		);
	});
};

export const getBcheck = () => {
	return new Promise((resolve) => {
		if (data) {
			return resolve(data);
		}
		return browserFingerprint().then((fingerprint) => {
			data = {
				md1: md5(fingerprint.simple),
				md2: md5(fingerprint.advanced),
				details: fingerprint.details,
			};
			cookieService.set(CookiesKeys.INSP_FRGCH, jsonToString(data), {
				expires: 1,
			});
			return resolve(data);
		});
	});
};

if (process.browser) {
	browserFingerprint().then((fingerprint) => {
		data = {
			md1: md5(fingerprint.simple),
			md2: md5(fingerprint.advanced),
			details: fingerprint.details,
		};
		// console.log('fingerprint', {
		//   fingerprint,
		//   data,
		// });
	});
}
