import styled, { css } from 'styled-components';
import { TabGroupStyled } from '@ui/tab-group/TabGroup.styled';
import { noWrap } from '@legacyApp/client/modules/style/defaults';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';

export const StyledHeaderBox = styled.div.attrs(() => ({
	className: 'header__box',
}))<{
	$noWrap?: boolean;
}>`
	margin: 10px 0;
	height: calc(100% - 20px);
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	gap: 8px;

	${(props) => (props.$noWrap ? noWrap : '')}

	.user-menu {
		margin: 0;
	}

	${TabGroupStyled} {
		margin-left: 24px;
		height: 32px;

		${chatViewport(
			(viewportPadding) => css`
				@media (max-width: ${(props) =>
						calcStyle({
							a: props.theme.media_v2.max_tablet_large,
							b: viewportPadding,
						})}) {
					display: none;
				}
			`,
		)}
	}
`;
