import Router from 'next/router';
import { checkAuth } from '../auth/checkAuth';
import { checkToken } from '../../client/modules/app/tokenService';
import routingService from '../../client/modules/app/routingService';

export const handlePageAuth = (id, token) => {
	const checkedToken = checkToken(token);
	const route = routingService.getRouteById(id);
	if (route) {
		const check = checkAuth(route && route.uri, checkedToken);
		// console.log('handlePageAuth', {
		// 	token,
		// 	id,
		// 	checkedToken,
		// 	route,
		// 	check,
		// });
		// if (token.value && route.isPrivate) ctx?.res?.cookie('token', JSON.stringify(token));
		if (!check) {
			if (process.browser) {
				console.log('client - redirect', { id });
				Router.push('/');
			}
			return false;
		}
	}
	return checkedToken;
};
