import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { APP_INITIALIZED } from '../app/app.constants';
import { epic } from '../../modules/app/epicService';
import { SET_CURRENCY } from '../user/user.constants';
import { trans } from '../../modules/translation/translate';
import { setConfirm } from '../confirm/confirm.actions';
import { showAlert } from '../alerts/alerts.actions';
import { converterSetConfirm, converterSetCurrency } from './converter.actions';
import { CONVERTER_SWITCH_CURRENCY } from './converter.constants';

const changeEpic = epic('changeEpic', {
	actions: (ofType) => ofType(SET_CURRENCY),
	callback: ({ store$ }) => {
		if (store$.value.converter.currency === 'usd') {
			return of();
		}
		return of(converterSetCurrency(store$.value.user.activeCurrency));
	},
});

const confirmEpic = epic('changeEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: () => {
		const confirm = lsGet(LocalStorageKeys.ENABLE_CONVERTER);
		if (confirm) {
			return of(converterSetConfirm(true));
		}
		return of();
	},
});

const currencyEpic = epic('currencyEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: ({ store$ }) => {
		let currency =
			lsGet(LocalStorageKeys.CURRENCY_CONVERTER) ||
			store$.value.user.activeCurrency;
		return of(converterSetCurrency(currency));
	},
});

const switchEpic = epic('switchEpic', {
	actions: (ofType) => ofType(CONVERTER_SWITCH_CURRENCY),
	callback: ({ store$, action }) => {
		const currency = action.payload || store$.value.user.activeCurrency;
		if (!store$.value.converter.confirm) {
			return of(
				setConfirm({
					callbackAction: (dispatch) => {
						return dispatch([
							converterSetConfirm(true),
							converterSetCurrency(
								store$.value.converter.currency === 'usd' ? currency : 'usd',
							),
						]);
					},
					question: trans({
						label:
							'It is just a visual change. All bets are always settled in cryptocurrencies.',
					}),
				}),
			);
		}
		const actions = [
			converterSetCurrency(
				store$.value.converter.currency === 'usd' ? currency : 'usd',
			),
		];
		if (store$.value.app.mobile) {
			actions.push(
				showAlert(
					'info',
					`USD Converter - ${
						store$.value.converter.currency !== 'usd' ? 'enabled' : 'disabled'
					}`,
				),
			);
		}
		return of(actions);
	},
});

const converterEpic = combineEpics(
	changeEpic,
	confirmEpic,
	currencyEpic,
	switchEpic,
);

export { converterEpic };
