// import {
// 	continents,
// 	countries,
// 	languages
// } from 'countries-list';
import { nanoid } from 'nanoid';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { getDeviceType } from '@common/methods/getDeviceType';
import { cookieService } from '@common/controllers/cookieService';
import { getClientSize } from '@common/methods/getClientSize';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { stringToJson } from '@common/methods/stringToJson';
import { jsonToString } from '@common/methods/jsonToString';
import { config } from '@legacyApp/client/config';
import transactionService from '@modules/transactions/transactionsService';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { regexStrings } from '@legacyApp/client/modules/app/regexService';
import { CookiesKeys } from '@modules/cookies/types/cookiesKeys.type';

export const cloneObj = (obj) => {
	if (null === obj || 'object' !== typeof obj) {
		return obj;
	}
	let copy = {};
	for (let attr in obj) {
		if (obj.hasOwnProperty(attr)) {
			copy[attr] = obj[attr];
		}
	}
	return copy;
};

export const copyToClipboard = (data) => {
	if (typeof data !== 'string') {
		return;
	}
	if (!process.browser) {
		return;
	}
	const input = document.createElement('input');
	input.value = data;
	document.body.insertBefore(input, document.querySelector('#__next'));
	input.select();
	document.execCommand('copy');
	input.remove();
};

export const cloneArr = (arr) => {
	return arr.map((item) =>
		isArray(item)
			? cloneArr(item)
			: typeof item === 'object'
			? cloneObj(item)
			: item,
	);
};

export const checkMobileValue = (mobileValue, desktopValue, isMobile) => {
	return isMobile ? mobileValue : desktopValue;
};

export const loop = (arr, fn, busy, err, i = 0) => {
	const body = (ok, er) => {
		try {
			const r = fn(arr[i], i, arr);
			r && r.then ? r.then(ok).catch(er) : ok(r);
		} catch (e) {
			er(e);
		}
	};
	const next = (ok, er) => () => loop(arr, fn, ok, er, ++i);
	const run = (ok, er) =>
		i < arr.length ? new Promise(body).then(next(ok, er)).catch(er) : ok();
	return busy ? run(busy, err) : new Promise(run);
};

export const extractJSON = (str) => {
	let firstOpen = 0,
		firstClose,
		candidate;
	firstOpen = str.indexOf('{', firstOpen + 1);
	do {
		firstClose = str.lastIndexOf('}');
		// console.log('firstOpen: ' + firstOpen, 'firstClose: ' + firstClose);
		if (firstClose <= firstOpen) {
			return null;
		}
		do {
			candidate = str.substring(firstOpen, firstClose + 1);
			// console.log('candidate: ' + candidate);
			try {
				let res = stringToJson(candidate, {});
				// console.log('...found');
				return [res, firstOpen, firstClose + 1];
			} catch (e) {
				// console.log('...failed');
			}
			firstClose = str.substr(0, firstClose).lastIndexOf('}');
		} while (firstClose > firstOpen);
		firstOpen = str.indexOf('{', firstOpen + 1);
	} while (firstOpen !== -1);
};

export const updateLockedView = (bool, action) => {
	const action_ = bool ? 'add' : 'remove';
	console.log('lockedView', {
		bool,
		action,
		action_,
	});
	document.body.classList[action_]('locked-view');
};

export const getId = (size = 12) => {
	if (size < 8) {
		throw new Error('getId size prop is passed lower than 8');
	} // https://zelark.github.io/nano-id-cc/
	return nanoid(size);
};

export const capitalize = (string, type = 'upper') => {
	if (!string || typeof string !== 'string') {
		return string;
	}
	try {
		const first =
			type === 'upper'
				? string[0].toUpperCase()
				: type === 'lower'
				? string[0].toLowerCase()
				: string[0];
		return first + string.substring(1).toLowerCase();
	} catch (error) {
		sentryError(error, {
			string,
		});
		return string;
	}
};

export const checkIsMobile = () => {
	const { width } = getClientSize();
	return process.browser ? width <= 820 : false;
};

export const checkIsMobileChat = () => {
	const chatWidth =
		getDeviceType() === 'desktopBig'
			? config.chatWidthDesktop
			: config.chatWidth;
	if (!process.browser) {
		return false;
	}
	const { width } = getClientSize();
	return width <= 820 + chatWidth;
};

export const checkMobileType = (type) => {
	return type.indexOf('mobile') > -1;
};

export const checkSmallMobile = (type) => {
	return type === 'mobileSmall';
};

export const checkTabletType = (type) => {
	return checkMobileType(type) || type.indexOf('tablet') > -1;
};

export const checkDesktop = (type) => {
	return type.indexOf('desktop') > -1;
};

export const checkTablet = (type) => {
	return ['tablet', 'desktop', 'desktopBig'].indexOf(type) > -1;
};

export const onLoad = async (callback) => {
	if (!process.browser) {
		return;
	}
	if (document.readyState === 'complete') {
		return callback();
	}
	return new Promise((resolve) => {
		window.addEventListener('load', () => resolve(callback()));
	});
};

export const logStateChange = (prev, curr, type, label) => {
	// eslint-disable-next-line no-undef
	if (NEXT_CONFIG.PRODUCTION) {
		return;
	}

	Object.entries(curr).forEach(([key, val]) => {
		// console.log(key, {
		//   type, label, val1: prev[key], val,
		// });
		if (typeof val === 'function' || ['t', 'i18n'].indexOf(key) > -1) {
			return;
		}
		const val1 =
			typeof prev[key] === 'object' ? jsonToString(prev[key]) : prev[key];
		const val2 = typeof val === 'object' ? jsonToString(val) : val;
		// console.log(key, {
		//   bool: val1 !== val2
		// });
		return (
			val1 !== val2 &&
			console.log(
				`${type} ${label} -> '${key}' changed:`,
				prev[key],
				'vs',
				curr[key],
			)
		);
	});
};

export const avgArray = (array) => {
	let sum = 0;
	array.forEach((el) => (sum += el));
	return sum / array.length;
};

export const getCurrencyGtmId = (currency) => {
	switch (currency.toLowerCase()) {
		case 'btc': {
			return 1;
		}
		case 'doge': {
			return 2;
		}
		case 'eth': {
			return 3;
		}
		case 'ltc': {
			return 4;
		}
		case 'trx': {
			return 5;
		}
		case 'bch': {
			return 6;
		}
		default: {
			return 1;
		}
	}
};

export const checkMobileDevice = () => {
	let isMobileDevice = lsGet(LocalStorageKeys.MOBILE_DEVICE);
	if (!isMobileDevice && isMobileDevice !== false && process.browser) {
		isMobileDevice = !!(
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i) ||
			(navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
		);
		lsSet(LocalStorageKeys.MOBILE_DEVICE, isMobileDevice);
	}
	return isMobileDevice;
};

export const hasNumber = (string) => {
	return regexStrings.hasNumber.test(string);
};

export const getLocationData = () => {
	const lsData = lsGet(LocalStorageKeys.LOCATION_DATA);
	if (lsData) {
		return lsData;
	}
	// const language = process.browser ? navigator.language.split('-')[0].toLowerCase() : 'en';
	// const countryKey = Object.keys(countries).find(countryKey => {
	// 	const country = countries[countryKey];
	// 	return country.languages.indexOf(language) > -1;
	// });
	// const country = countries[countryKey];
	return {
		language: process.browser ? navigator.language : 'en-US', // || languages[language],
		// country,
		// continent: country ? continents[country.continent] : false,
		details: false,
	};
};

export const setLocationData = () => {
	// const lsData = ls.get(LocalStorageKeys.LOCATION_DATA);
	// if (lsData) return;
	// FetchService.fetch('https://ipapi.co/json').then(response => {
	//   if (response.status !== 200) return;
	//   return FetchService.getResponseData(response).then(data => {
	//     if (!data || !data.languages) return;
	//     lsSet(LocalStorageKeys.LOCATION_DATA, {
	//       language: languages[data.languages],
	//       country: countries[data.country_code],
	//       continent: continents[data.continent_code],
	//       details: data,
	//     });
	//   });
	// });
};

export const currentVersion = () => {
	if (!process.browser) {
		return false;
	}
	const scripts = document.querySelectorAll('script') || [];
	const script = nodeListToArray(scripts).find((el) => {
		// console.log('currentVersion', el.src, el);
		return el.src && el.src.indexOf('chunks/commons.') > -1;
	});
	if (!script) {
		return false;
	}
	return script.src.split('chunks/commons.')[1].split('.js')[0];
};

export const nodeListToArray = (list) => Array.apply(null, list);

export const insertAtArray = (array, index, ...elementsArray) => {
	array.splice(index, 0, ...elementsArray);
	return array;
};

export const removeIndexArray = (array, index) => {
	array.splice(index, 1);
	return [...array];
};

export const saveLanguage = (lang) => {
	if (!process.browser) {
		return false;
	}
	document.head.parentElement.setAttribute(LocalStorageKeys.LANGUAGE, lang);
	lsSet(LocalStorageKeys.LANGUAGE, lang);
};

export const getLanguage = () => {
	return lsGet(LocalStorageKeys.LANGUAGE);
};

export const parseTimestampId = (id) => {
	if (!id) {
		return id;
	}
	return id.split('_')[0];
};

export const handleDeprecatedBrowserData = () => {
	if (!cookieService.get(CookiesKeys.DEPRECATED_LANG_CLEARED)) {
		lsClear(LocalStorageKeys.I18NEXT_LNG);
		cookieService.set(CookiesKeys.DEPRECATED_LANG_CLEARED, true);
	}

	if (!cookieService.get(CookiesKeys.DEPRECATED_CHAT_ROOM_CLEARED)) {
		lsClear(LocalStorageKeys.CHAT_ROOM);
		cookieService.set(CookiesKeys.DEPRECATED_CHAT_ROOM_CLEARED, true);
	}

	return {};
};

export const camelCaseToSentence = (string) => {
	const result = string.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
};

export const filterArrayDuplicates = (array, keys) => {
	if (!array || !array.length) {
		return array;
	}
	const isSimpleElementsArray = !array.some((el) => typeof el === 'object');
	if (isSimpleElementsArray) {
		return array.filter(function (item, pos) {
			return array.indexOf(item) === pos;
		});
	}
	return array.filter(
		(el, index, self) =>
			self.findIndex((t) =>
				transactionService.isDuplicate(el, t, keys, true),
			) === index,
	);
};

export const sequenceArray = (length) => {
	return [...Array(length).keys()];
};

export const isArray = (data) => Array.isArray(data);

export const shuffleArray = (array) =>
	[...array].sort(() => Math.random() - 0.5);
