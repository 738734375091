import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { epic, epicOldState } from '../../modules/app/epicService';
import audioService from '../../modules/app/audioService';
import { parseOptions, setOption } from '../../modules/app/optionsService';
import { showAlert } from '../alerts/alerts.actions';
import { camelCaseToSentence, capitalize } from '../../modules/app/appService';
import { APP_INITIALIZED } from '../app/app.constants';
import { OPTIONS_DISABLE, OPTIONS_ENABLE } from './options.constants';

import { optionChangeThunk } from './thunks/optionChange.thunk';

const localStorageEpic = epicOldState('localStorageEpic', {
	actions: (ofType) => ofType(OPTIONS_ENABLE, OPTIONS_DISABLE),
	callback: ({ action, newState, oldState }) => {
		const bool = action.type === OPTIONS_ENABLE;
		if (action.payload === 'sound') {
			bool ? audioService.startAll() : audioService.stopAll();
		}
		const noAlert = !!action.props;
		setOption(action.payload, bool);
		if (
			!noAlert &&
			newState.app.mobile &&
			oldState.options[action.payload] !== newState.options[action.payload] &&
			!action.props
		) {
			return [
				showAlert(
					'info',
					`${capitalize(camelCaseToSentence(action.payload))} - ${
						bool ? 'enabled' : 'disabled'
					}`,
				),
			];
		}
		return [];
	},
});

const optionsInitEpic = epic('optionsInitEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: ({ store$ }) => {
		const options = {
			...store$.value.options,
			...parseOptions(),
			...lsGet(LocalStorageKeys.OPTIONS, {}),
		};
		if (options.sound) {
			audioService.startAll();
		}
		return of(
			Object.keys(options).map((key) => {
				return optionChangeThunk(key, options[key], {
					noAlert: true,
				});
			}),
		);
	},
});

const optionsEpic = combineEpics(localStorageEpic, optionsInitEpic);

export { optionsEpic };
