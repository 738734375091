import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
const defaultBetValue = 0;

const NAME = 'unread';

export type ValueType = number;

const initialState: ValueType = defaultBetValue;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const unreadSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setUnread: setAction,
	},
});
