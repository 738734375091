import React, { CSSProperties } from 'react';

const nFormatter = (num_, minValue_, props) => {
	if (!num_ || !+num_ || typeof +num_ !== 'number') {
		return {
			number: num_,
		};
	}

	const num = +num_;

	const minValue = minValue_ || 0;
	const si = props.params.shortConfig || [
		{ value: 1e18, symbol: 'E' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e3, symbol: 'k' },
	];
	// const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	let i;

	if (typeof num === 'number' && num >= minValue) {
		for (i = 0; i < si.length; i++) {
			if (num >= si[i].value) {
				// return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
				return {
					number: num / si[i].value,
					letter: si[i].symbol,
				};
			}
		}
	}
	//return num.replace(rx, "$1");
	return {
		number: num,
	};
};

const NumericLabel = (props) => {
	let option = {
		minimumFractionDigits: 0,
		maximumFractionDigits: null,
	};

	let number = +props.children;
	let locales = 'en-US';
	let mystyle: CSSProperties = {
		textAlign: 'left',
	};
	let css = '';

	if (props.params) {
		locales = props.params.locales;
		if (props.params.wholenumber == 'floor') {
			number = Math.floor(props.children);
		} else if (props.params.wholenumber == 'ceil') {
			number = Math.ceil(props.children);
		} else {
			number = +props.children;
		}

		let styles: CSSProperties['textAlign'] = 'right';
		if (props.params.justification == 'L') {
			styles = 'left';
		} else if (props.params.justification == 'C') {
			styles = 'center';
		}

		mystyle = {
			textAlign: styles,
		};

		let currencyIndicator = 'USD';

		if (props.params.currencyIndicator) {
			currencyIndicator = props.params.currencyIndicator;
		}

		if (props.params.percentage) {
			option = Object.assign(option, {
				style: 'percent',
				maximumFractionDigits: props.params.precision || 2,
				minimumFractionDigits: props.params.precision || 0,
				useGrouping: props.params.commafy,
			});
		} else if (props.params.currency) {
			option = Object.assign(option, {
				style: 'currency',
				currency: currencyIndicator,
				maximumFractionDigits: props.params.precision || 2,
				minimumFractionDigits: props.params.precision || 0,
				useGrouping: props.params.commafy,
			});
		} else {
			option = Object.assign(option, {
				style: 'decimal',
				useGrouping: props.params.commafy,
			});

			if (props.params.precision) {
				option.maximumFractionDigits = props.params.precision;
				option.minimumFractionDigits = props.params.precision || 0;
			}
		}

		if (props.params.cssClass) {
			props.params.cssClass.map((clas) => {
				css += clas + ' ';
			});
		}
	}

	let shortenNumber = number;
	let numberLetter = '';

	if (props.params && props.params.shortFormat) {
		const sn = nFormatter(number, props.params.shortFormatMinValue || 0, props);
		shortenNumber = sn.number;
		numberLetter = sn.letter || '';

		if (
			props.params.shortFormatMinValue &&
			+number >= props.params.shortFormatMinValue
		) {
			option.maximumFractionDigits =
				props.params.shortFormatPrecision || props.params.precision || 0;
		}
	}

	option.minimumFractionDigits = Math.min(
		~~option.minimumFractionDigits,
		~~option.maximumFractionDigits,
	);

	let theFormattedNumber = String(shortenNumber);

	if (typeof shortenNumber === 'number') {
		theFormattedNumber = Intl.NumberFormat(locales, option).format(
			+shortenNumber,
		);
	}

	if (numberLetter) {
		if (props.params && props.params.percentage) {
			theFormattedNumber = theFormattedNumber.replace('%', numberLetter + '%');
		} else {
			theFormattedNumber += numberLetter;
		}
	}

	let title = null;
	if (props.params && props.params.title) {
		props.params.title === true
			? (title = number)
			: (title = props.params.title);
	}

	if (
		mystyle.textAlign &&
		(mystyle.textAlign == 'right' || mystyle.textAlign == 'center')
	) {
		// div
		if (title) {
			// with title
			return (
				<div className={css} style={mystyle} title={title}>
					{theFormattedNumber}
				</div>
			);
		} else {
			// without title
			return (
				<div className={css} style={mystyle}>
					{theFormattedNumber}
				</div>
			);
		}
	} else {
		// span
		if (title) {
			// with title
			return (
				<span className={css} style={mystyle} title={title}>
					{theFormattedNumber}
				</span>
			);
		} else {
			// without title
			return (
				<span className={css} style={mystyle}>
					{theFormattedNumber}
				</span>
			);
		}
	}
};

export default NumericLabel;
