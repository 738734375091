import styled, { css } from 'styled-components';
import classname from 'classnames';
import {
	borderRadius,
	boxNoHeight,
	inputHeight,
} from '../../../modules/style/defaults';
import { fontSize } from '../../../modules/style/mixins/fontSize';
import { calcStyle } from '../../../modules/style/methods/calcStyle';

const gameBoxAdvancedSettingsRowsStyle = css`
	margin-top: 15px;
	padding-top: 20px;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		padding: 5px;
		padding-top: 10px;
	}
`;

const advancedSettingsMainStyle = css`
	align-items: center;
	display: flex;
	margin-bottom: 10px;
	position: relative;

	.dropdown {
		margin: 0 10px 0 0 !important;

		> button {
			${inputHeight};
			margin: 0 !important;
			padding-left: 5px !important;
			padding-right: 20px !important;
			white-space: nowrap;
			width: 100%;

			i {
				right: 10px;
			}
		}
	}

	> span {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);

		&:last-of-type {
			${(props) =>
				fontSize({
					size: calcStyle({
						mode: '-',
						a: props.theme.text.size.default,
						b: '2px',
					}),
					important: true,
				})};
		}
	}
`;

export const StyledConditionBlockActionSection = styled.div<{
	$borderSide: 'left' | 'right';
	$isEditMode?: boolean;
}>`
	border-${(props) => props.$borderSide}: 1px solid var(--color-dark-${(props) =>
	props.$isEditMode ? '400' : '500'});
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;

	div {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;

		&:not(:first-of-type) {
			border-top: 1px solid var(--color-dark-${(props) =>
				props.$isEditMode ? '400' : '500'});
		}
	}
`;

interface StyledBorderBoxProps {
	$conditionType?: 'bets' | 'wallet';
	$isEditMode?: boolean;
	$isError?: boolean;
	$isNoEdit?: boolean;
}

export const StyledBorderBox = styled.div.attrs<StyledBorderBoxProps>(
	(props) => ({
		className: classname('game-box', props.className),
	}),
)<StyledBorderBoxProps>`
	border-radius: 8px;
	${boxNoHeight};
	background-color: transparent;
	border: 1px solid var(--color-dark-500);
	transition: ${(props) => props.theme.layout.transition};
	position: relative;
	margin-bottom: 8px;
	cursor: pointer;

	&:last-of-type {
		margin-bottom: 8px;
	}

	.input {
		@media (max-width: 450px) {
			padding-right: 5px;
			padding-left: 5px;
		}
	}

	.checkbox {
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	${(props) =>
		props.$conditionType === 'bets'
			? css`
					@media (max-width: ${props.theme.media.maxWidthSmallTablet}) {
						[data-id*='when-type'] {
							width: calc(100% - 45px) !important;
						}

						[data-id*='when-name'] {
							width: 40%;
						}
					}
			  `
			: ''};

	${(props) =>
		props.$conditionType === 'wallet'
			? css`
					@media (max-width: ${props.theme.media.maxWidthSmallTablet}) {
						[data-id*='when-name'] {
							width: calc(100% - 45px) !important;
						}
					}
			  `
			: ''};

	${(props) =>
		props.$conditionType === 'bets' || props.$conditionType === 'wallet'
			? gameBoxAdvancedSettingsRowsStyle
			: ''};

	${(props) =>
		props.$isEditMode
			? css`
					border-color: ${props.theme.colors.active};
			  `
			: ''};

	${(props) =>
		props.$isError
			? css`
					${props.$isEditMode
						? ''
						: css`
								border-color: ${props.theme.colors.negative};
						  `};
			  `
			: ''};

	${(props) =>
		props.$isNoEdit
			? css`
					cursor: default;
					margin-bottom: 0 !important;
			  `
			: ''};
`;

export const StyledBorderBoxSwitch = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
	justify-content: flex-start;
	padding: 5px 10px;
	${borderRadius};

	> div {
		background-color: var(--color-dark-600);
		border-radius: 3px;
		display: flex;
		flex-direction: row;
		padding: 2px;
	}
`;

export const StyledBorderBoxCommand = styled.div`
	${boxNoHeight};
	${advancedSettingsMainStyle};

	margin-bottom: 0;
	padding: 15px 0;

	&:first-of-type {
		padding-top: 20px !important;
	}

	> label {
		margin-bottom: 0 !important;
		min-width: 30px;
		text-align: right;
		color: var(--color-dark-100);
	}

	.dropdown {
		align-items: center;
		display: flex;
		padding-bottom: 0 !important;

		.button-help {
			align-items: center;
			display: flex;
			height: 30px !important;
			justify-content: center;
			margin: 0 0 0 10px !important;
			padding: 0 !important;
			width: 25px;
		}

		button:not(.button-help) {
			padding-left: 10px !important;
			padding-right: 30px !important;
			text-align: left;
			width: unset;
		}
	}

	.input-wrapper {
		max-width: 200px;
		padding: 0 !important;
		position: relative;

		label {
			position: absolute;
			right: 2px;
			top: -20px;
		}

		.input-message {
			bottom: -14px;
			left: 0;
			position: absolute;
			white-space: nowrap;
		}
	}

	.dropdown,
	.input-wrapper {
		margin: 0 0 0 10px !important;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		> label {
			min-width: 25px;
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthDesktop}) {
		.input-wrapper {
			label {
				top: -20px;
			}
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		display: block;
		padding-top: 0;

		.dropdown,
		.input-wrapper {
			width: 100%;
			max-width: unset;
			margin: 10px 0 0 !important;
		}

		.input-wrapper {
			label {
				top: -15px;
			}
		}

		> label {
			width: 45px;
			float: left;
			height: 35px;
			margin-top: 10px;
			line-height: 35px;
			padding-right: 10px;
			margin-bottom: 0 !important;
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthMobile}) {
		> label {
			height: 30px;
			line-height: 30px;
			margin-bottom: 0 !important;
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		[data-id*='command-name'] {
			width: calc(100% - 45px);
		}
	}
`;

export const StyledConditionBlockEdit = styled.div`
	margin-top: 0;
	width: 100%;

	${StyledBorderBox} {
		border: none;
		margin: 0;
	}
`;

export const StyledBorderBoxMainSimple = styled.div.attrs({
	className: 'game-box--advanced-settings__main',
})`
	${advancedSettingsMainStyle}
	> span {
		position: relative;
		top: unset;
		right: unset;
		transform: unset;
	}
`;
