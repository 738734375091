import { combineReducers } from 'redux';
import { RESET_USER_DATA } from '../user/user.constants';
import {
	SET_FAUCET_DELAY_UNTIL,
	SET_FAUCET_REMAINING,
} from './faucet.constants';

const remainingReducer = (
	state = 0,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_FAUCET_REMAINING:
			return action.payload;
		case RESET_USER_DATA:
			return 0;
		default:
			return state;
	}
};

const delayUntilReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_FAUCET_DELAY_UNTIL:
			// console.log('SET_FAUCET_DELAY_UNTIL', {
			//   until: action.payload,
			//   now: new Date().getTime(),
			//   diff: action.payload - new Date().getTime(),
			// });
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const faucetReducer = combineReducers({
	remaining: remainingReducer,
	delayUntil: delayUntilReducer,
});

export { faucetReducer };
