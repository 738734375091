import styled, { css } from 'styled-components';

export const StyledDropdownMenu = styled.div.attrs(() => ({
	className: 'dropdown-menu overflow-scrollbar--vertical',
}))`
	position: fixed;
	border-radius: 8px;
	opacity: ${(props) => (props.visible ? '1' : '0')};
	overflow: hidden;
	visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
	transition: visibility 0.2s linear, opacity 0.2s linear;
	pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
	overflow-y: auto;
	background-color: var(--color-dark-700);
	color: ${(props) => props.theme.colors.text};
	z-index: 9999999; // 7
	min-width: 150px;
	${(props) =>
		props.compact
			? css`
					max-height: 250px !important;

					@media (max-width: ${props.theme.media.maxWidthTablet}) {
						max-height: 230px !important;
					}
			  `
			: ''};

	nav {
		display: flex;
		flex-direction: column;
	}

	&[data-id*='when-name'] {
		* {
			text-transform: capitalize;
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthDesktop}) {
		top: 55px; /*with stats bar was 75px*/
	}
`;
