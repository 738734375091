import { validateBetAmount } from '@legacyApp/client/modules/app/gameService';
import { actionSimple } from '@legacyApp/client/modules/app/actionService';
import {
	validatePayout,
	validateWinChance,
} from '@legacyApp/client/modules/app/validateService';
import {
	CHECK_GAME_FORM,
	DO_VALIDATE_BET_AMOUNT,
	DO_VALIDATE_BET_AMOUNT_AFTER_CHECK_BALANCE,
	DO_VALIDATE_PAYOUT,
	DO_VALIDATE_WIN_CHANCE,
	GAME_UPDATE_AMOUNT_ERROR,
	GAME_UPDATE_FORM_ERROR,
	GAME_UPDATE_PAYOUT_ERROR,
	GAME_UPDATE_PROFIT_ERROR,
	GAME_UPDATE_WIN_CHANCE_ERROR,
} from './gameValidation.constants';

export const gameUpdateAmountError = actionSimple(GAME_UPDATE_AMOUNT_ERROR);
export const gameUpdatePayoutError = actionSimple(GAME_UPDATE_PAYOUT_ERROR);
export const gameUpdateWinChanceError = actionSimple(
	GAME_UPDATE_WIN_CHANCE_ERROR,
);
export const gameUpdateProfitError = actionSimple(GAME_UPDATE_PROFIT_ERROR);
export const gameUpdateFormError = actionSimple(GAME_UPDATE_FORM_ERROR);
export const doValidateBetAmount = (
	game,
	result,
	value,
	balances,
	currency,
	isLogged,
	changeError = true,
	checkBalance,
	clearError,
) => {
	return {
		type: DO_VALIDATE_BET_AMOUNT,
		game,
		result:
			result ||
			validateBetAmount({
				value,
				balances,
				isLogged,
				currency,
				checkBalance,
				game,
			}),
		value,
		balances,
		isLogged,
		currency,
		changeError,
		checkBalance,
		clearError,
	};
};
export const doValidateBetAmountAfterCheckBalance = (
	game,
	result,
	value,
	balances,
	currency,
	isLogged,
	changeError = true,
	checkBalance,
	clearError,
) => {
	return {
		type: DO_VALIDATE_BET_AMOUNT_AFTER_CHECK_BALANCE,
		game,
		result:
			result ||
			validateBetAmount({
				value,
				balances,
				isLogged,
				currency,
				checkBalance,
				game,
			}),
		value,
		balances,
		isLogged,
		currency,
		changeError,
		checkBalance,
		clearError,
	};
};

export const checkGameForm = (game, checkBalance = true) => {
	return {
		type: CHECK_GAME_FORM,
		game,
		checkBalance,
	};
};

export const doValidateWinChance = (
	game,
	result,
	value,
	changeError = true,
	clearError,
) => {
	return {
		type: DO_VALIDATE_WIN_CHANCE,
		result: result || validateWinChance(value, game),
		game,
		value,
		changeError,
		clearError,
	};
};

export const doValidatePayout = (
	game,
	result,
	value,
	changeError = true,
	clearError,
) => {
	return {
		type: DO_VALIDATE_PAYOUT,
		result: result || validatePayout(value, game),
		game,
		value,
		changeError,
		clearError,
	};
};
