import { css } from 'styled-components';
import { theme } from '../theme';
import { calcStyle } from '../methods/calcStyle';
import { importantFunc } from './importantFunc';

interface FontSize {
	size?: string;
	sizeDiff?: number;
	sizeDesktop?: string;
	sizeDesktopDiff?: number;
	minWidth?: string;
	sizeBigScreen?: string;
	sizeBigScreenDiff?: number;
	minBigWidth?: string;
	important?: boolean;
	label?: string;
}

export const fontSize = ({
	size = theme.text.size.default,
	sizeDiff = 0,
	sizeDesktop = theme.text.size.desktop,
	sizeDesktopDiff = 0,
	minWidth = theme.media.minWidthDesktop,
	sizeBigScreen = null,
	sizeBigScreenDiff = null,
	minBigWidth = theme.media.minWidthBigDesktop,
	important = null,
	label = 'font-size',
}: FontSize = {}) => {
	const importantLabel = importantFunc(important);
	return css`
		${label}: ${sizeDiff
			? calcStyle({
					a: size,
					b: sizeDiff,
			  })
			: size}${importantLabel};

		@media (min-width: ${minWidth}) {
			${label}: ${sizeDesktopDiff
				? calcStyle({
						a: sizeDesktop,
						b: sizeDesktopDiff,
				  })
				: sizeDesktop}${importantLabel};
		}

		${sizeBigScreen || sizeBigScreenDiff
			? css`
					@media (min-width: ${minBigWidth}) {
						${label}: ${sizeBigScreenDiff
							? calcStyle({
									a: sizeBigScreen || theme.text.size.bigScreen,
									b: sizeBigScreenDiff || 0,
							  })
							: sizeBigScreen}${importantLabel};
					}
			  `
			: ''}
	`;
};
