import { isFunction } from '@common/methods/isFunction';
import { actionSimple } from '../../modules/app/actionService';
import { handlePwaPrompt } from '../../modules/app/pwaService';
import {
	PWA_INSTALL_ACCEPTED,
	PWA_INSTALL_DECLINED,
	PWA_SET_ACTIVE,
	PWA_SET_CALLBACK,
	PWA_SET_MODE,
} from './pwa.constants';

export const pwaSetActive = actionSimple(PWA_SET_ACTIVE);

export const pwaSetCallback = actionSimple(PWA_SET_CALLBACK);

export const pwaSetMode = actionSimple(PWA_SET_MODE);

export const pwaInstallAccepted = actionSimple(PWA_INSTALL_ACCEPTED);

export const pwaInstallDeclined = actionSimple(PWA_INSTALL_DECLINED);

const onActive = (dispatch) => {
	return (onClick) => {
		if (!isFunction(onClick)) {
			return;
		}
		const onAccept = () => dispatch(pwaInstallAccepted());
		const onDecline = () => dispatch(pwaInstallDeclined());
		dispatch([pwaSetCallback(() => onClick(onAccept, onDecline))]);
	};
};

const onLoad = (dispatch) => {
	return (pwaMode) => {
		if (!pwaMode) {
			return;
		}
		dispatch(pwaSetMode(pwaMode));
	};
};

const onInstall = (dispatch) => {
	return () => dispatch(pwaInstallAccepted());
};

export const pwaInit = () => {
	return (dispatch) => {
		handlePwaPrompt(onActive(dispatch), onLoad(dispatch), onInstall(dispatch));
	};
};
