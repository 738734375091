// eslint-disable-next-line import/named
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import Redux from 'redux';
import { RootState } from '../../client/store/configure.store';

export type AppDispatch = ThunkDispatch<RootState, any, Redux.AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useDispatchCallback = <
	T = (...args: any) => Parameters<AppDispatch>[0],
>(
	callback: T,
) => {
	const dispatch = useAppDispatch();
	// @ts-expect-error invalid type
	return (...payload: Parameters<T>) =>
		// @ts-expect-error invalid type
		dispatch(callback(...payload));
};
