import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { languageConfig } from '@server/config/language.config';
import { useAppLanguage } from '@common/selectors/app.selectors';
import { preventDefault } from '@common/methods/preventDefault';
import { LinkTo } from '../../default/LinkTo/LinkTo';
import { LanguageType } from '../LangPicker';
import { FlagIcon } from '../../icons/FlagIcon';

export const FlagLang: FunctionComponent<{
	lang: LanguageType;
}> = ({ lang }) => {
	const router = useRouter();
	const current = useAppLanguage();
	const locale = lang.id;

	const element = (
		<StyledFlagLanguage className={`lang-${locale} text-style-sm-medium`}>
			<div>
				<FlagIcon
					symbol={locale === languageConfig.default ? 'gb' : locale}
					withWrapper
				/>
			</div>
			<span>{lang.name}</span>
		</StyledFlagLanguage>
	);

	if (lang.id === current) {
		return <a onClick={preventDefault}>{element}</a>;
	}

	return (
		<LinkTo passHref locale={locale} href={router.asPath} forceATag={true}>
			{element}
		</LinkTo>
	);
};

export const StyledFlagLanguage = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-start;
	position: relative;

	> div {
		height: 16px;
		overflow: hidden;
		position: relative;
		width: 22px;

		> div {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	> span {
		margin-left: 10px;
		text-transform: capitalize;
	}
`;
