import { FC, PropsWithChildren } from 'react';
import NumericLabel from './NumericLabel';

export const PrettyNumber: FC<
	PropsWithChildren<{
		params: Record<string, any>;
	}>
> = ({ children, params }) => {
	return (
		<NumericLabel
			params={{
				locales: 'en-US',
				shortFormatMinValue: 10000,
				shortConfig: [
					{ value: 1e12, symbol: 'T' },
					{ value: 1e9, symbol: 'B' },
					{ value: 1e6, symbol: 'M' },
					{ value: 1e3, symbol: 'k' },
				],
				...(params || {}),
			}}
		>
			{children}
		</NumericLabel>
	);
};
