import { combineReducers } from 'redux';
import { setReducer } from '@legacyApp/methods/store/setReducer';
import { VAULT_SET_BALANCE } from './vault.constants';

const balancesReducer = setReducer([], VAULT_SET_BALANCE);

const vaultReducer = combineReducers({
	balances: balancesReducer,
});

export { vaultReducer };
