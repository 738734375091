import { combineEpics } from 'redux-observable';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { bindSocketPrivateChannelEvent } from '../websocket/websocket.actions';
import websocketService from '../../modules/app/websocketService';
import { config, FEATURES_TYPE } from '../../config';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import sortService, { DIRECTION } from '../../modules/app/sortService';
import {
	privateMessagesCloseActive,
	privateMessagesGetHistory,
	privateMessagesSetHistory,
} from '../privateMessages/privateMessages.actions';
import { epic } from '../../modules/app/epicService';
import { USER_LOGGED } from '../user/user.constants';
import { isFeatureAvailable } from '../../modules/app/featureService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { tokenService } from '../../modules/app/tokenService';
import { WEBSOCKET_EVENTS } from '../websocket/websocket.constants';
import {
	friendsAcceptedRequest,
	friendsCancelledRequest,
	friendsDeleted,
	friendsGetList,
	friendsGetOnline,
	friendsGetRequests,
	friendsNewRequest,
	friendsRepeatOnline,
	friendsSetDisableOnline,
	friendsSetList,
	friendsSetOnline,
	friendsSetRequests,
} from './friends.actions';
import {
	FRIENDS_ACCEPTED_REQUEST,
	FRIENDS_CANCELLED_REQUEST,
	FRIENDS_DELETED,
	FRIENDS_GET_LIST,
	FRIENDS_GET_ONLINE,
	FRIENDS_GET_REQUESTS,
	FRIENDS_NEW_REQUEST,
	FRIENDS_REPEAT_ONLINE,
} from './friends.constants';

const initial = epic('initial', {
	actions: (ofType) => ofType(USER_LOGGED),
	callback: ({ store$ }) => {
		if (!tokenService.token.value) {
			return of();
		}
		const result = [friendsGetList(), friendsGetRequests()];
		if (!store$.value.friends.repeatOnlineActive) {
			result.push(friendsRepeatOnline());
		}
		return of(result);
	},
});

const friendsListEpic = epic('friendsListEpic', {
	actions: (ofType) => ofType(FRIENDS_GET_LIST),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/user/friends',
					parameters: {
						Authorization: true,
					},
					loaderId: 'getFriends',
				},
				(data) => {
					if (!data.data) {
						return [];
					}
					return [friendsSetList(data.data)];
				},
			),
		);
	},
});

const friendsRequestsEpic = epic('friendsRequestsEpic', {
	actions: (ofType) => ofType(FRIENDS_GET_REQUESTS),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/user/friends/requests',
					parameters: {
						Authorization: true,
					},
					loaderId: 'getFriendsRequests',
				},
				(data) => {
					if (!data.data) {
						return [];
					}
					return [
						friendsSetRequests(data.data || []),
						bindSocketPrivateChannelEvent(
							WEBSOCKET_EVENTS.FRIENDS_REQUEST_SENT,
							(data, dispatch) => {
								dispatch(friendsNewRequest(websocketService.getMessage(data)));
							},
						),
						bindSocketPrivateChannelEvent(
							WEBSOCKET_EVENTS.FRIENDS_REQUEST_CANCELED,
							(data, dispatch) => {
								dispatch(
									friendsCancelledRequest(websocketService.getMessage(data)),
								);
							},
						),
						bindSocketPrivateChannelEvent(
							WEBSOCKET_EVENTS.FRIENDS_REQUEST_ACCEPTED,
							(data, dispatch) => {
								dispatch(
									friendsAcceptedRequest(websocketService.getMessage(data)),
								);
							},
						),
						bindSocketPrivateChannelEvent(
							WEBSOCKET_EVENTS.FRIENDS_FRIEND_DELETED,
							(data, dispatch) => {
								dispatch(friendsDeleted(websocketService.getMessage(data)));
							},
						),
					];
				},
			),
		);
	},
});

const newRequestEpic = epic('newRequestEpic', {
	actions: (ofType) => ofType(FRIENDS_NEW_REQUEST),
	callback: ({ action, store$ }) => {
		const list = store$.value.friends.requests;
		const data = action.payload;
		if (list.some((el) => el.user.uuid === data.user.uuid)) {
			return of();
		}
		const result = [friendsSetRequests([data, ...list])];
		if (
			!store$.value.preferences.community.block_friend_requests_notifications
				.bool
		) {
			result.push(
				showAlert(
					'info',
					trans({
						label: 'You have received an invitation to friends from {{login}}',
						options: {
							login: data.user.login,
						},
					}),
				),
			);
		}
		return of(result);
	},
});

const cancelRequestEpic = epic('cancelRequestEpic', {
	actions: (ofType) => ofType(FRIENDS_CANCELLED_REQUEST),
	callback: ({ action, store$ }) => {
		const list = store$.value.friends.requests.filter(
			(el) => el.user.uuid !== action.payload.user.uuid,
		);
		return of(friendsSetRequests([...list]));
	},
});

const acceptedRequestEpic = epic('acceptedRequestEpic', {
	actions: (ofType) => ofType(FRIENDS_ACCEPTED_REQUEST),
	callback: ({ action, store$ }) => {
		const list = store$.value.friends.requests.filter(
			(el) => el.user.uuid !== action.payload.user.uuid,
		);
		const result = [
			friendsSetRequests([...list]),
			friendsSetList([action.payload, ...store$.value.friends.list]),
			privateMessagesGetHistory(),
		];
		if (
			!store$.value.preferences.community.block_friend_requests_notifications
				.bool
		) {
			result.push(
				showAlert(
					'info',
					trans({
						label: 'Your friend request has been accepted by {{login}}',
						options: {
							login: action.payload.user.login,
						},
					}),
				),
			);
		}
		return of(result);
	},
});

const deletedFriendEpic = epic('deletedFriendEpic', {
	actions: (ofType) => ofType(FRIENDS_DELETED),
	callback: ({ action, store$ }) => {
		const list = store$.value.friends.list.filter(
			(el) => el.user.uuid !== action.payload.user.uuid,
		);
		const result = [friendsSetList([...list])];
		if (!store$.value.preferences.community.allow_messages_from_all.bool) {
			result.push(
				privateMessagesSetHistory(
					store$.value.privateMessages.history.filter(
						(el) => el.user.uuid !== action.payload.user.uuid,
					),
				),
			);
			result.push(
				privateMessagesCloseActive(
					store$.value.privateMessages.activeList.find(
						(el) => el.user.uuid === action.payload.user.uuid,
					),
				),
			);
		}
		return of(result);
	},
});

const getOnlineEpic = epic('onlineEpic', {
	actions: (ofType) => ofType(FRIENDS_GET_ONLINE),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/user/online',
					parameters: {
						Authorization: true,
					},
					loaderId: 'getFriendsOnline',
					disableErrorHandler: true,
				},
				(data) => {
					// console.log('getFriendsOnline', data);
					const actions = [];
					if (data && data.status === 403) {
						actions.push(friendsSetDisableOnline(true));
					}
					if (data && data.data) {
						actions.push(
							friendsSetOnline(
								data.data.sort((a, b) =>
									sortService.sort(DIRECTION.ASC, a.uuid, b.uuid),
								),
							),
						);
					}
					return actions;
				},
			),
		);
	},
});

const onlineRepeatEpic = epic('onlineRepeatEpic', {
	actions: (ofType) => ofType(FRIENDS_REPEAT_ONLINE),
	callback: ({ store$ }) => {
		if (
			!store$.value.user.isLogged ||
			!isFeatureAvailable(FEATURES_TYPE.ONLINE_UPDATE) ||
			store$.value.friends.disableOnline
		) {
			return of();
		}
		return of(friendsRepeatOnline()).pipe(delay(config.refreshOnlineTimeout));
	},
});

const onlineRepeatGetEpic = epic('onlineRepeatGetEpic', {
	actions: (ofType) => ofType(FRIENDS_REPEAT_ONLINE),
	callback: () => {
		return of(friendsGetOnline());
	},
});

const friendsEpic = combineEpics(
	initial,
	friendsListEpic,
	friendsRequestsEpic,
	newRequestEpic,
	cancelRequestEpic,
	acceptedRequestEpic,
	deletedFriendEpic,
	getOnlineEpic,
	onlineRepeatEpic,
	onlineRepeatGetEpic,
);

export { friendsEpic };
