import styled from 'styled-components';

export const StyledLoadingComponent = styled.div`
	height: 100%;
	width: 100%;

	.loading-logo {
		height: 39px;
		margin-top: 0;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		.loading-logo {
			height: 24px !important;
		}

		p {
			margin: 0 0 5px;
			font-size: 10px !important;
		}

		button {
			padding: 5px 10px;
			font-size: 10px !important;
		}
	}

	&[data-component*='.page'] {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);

		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			position: fixed;
		}
	}

	&[data-component*='Modal'] {
		margin-bottom: 50px;
		min-height: 110px;
	}

	.chat-wrapper & {
		.loading-logo {
			height: 20px !important;
		}

		p {
			font-size: 10px !important;
			margin: 0 0 5px;
		}

		button {
			font-size: 10px !important;
			padding: 5px 10px;
		}
	}
`;
