import { css } from 'styled-components';
import { parseImageSrc } from '@common/components/default/Image/Image';
import { FooterStyle } from '@common/components/footer/Footer';

export const homeBackground = (url?: string, background = null) => {
	return css`
		position: relative;

		${url || background
			? css`
					&:after,
					&:before {
						bottom: 0;
						content: '';
						height: 100%;
						left: 0;
						position: absolute;
						width: 100%;
					}
			  `
			: ''}
		${url
			? css`
					&:after {
						//background-attachment: fixed;
						background: url(${parseImageSrc(url)});
						background-position: top;
						background-repeat: no-repeat;
						background-size: 200%;
						z-index: 0;
					}
			  `
			: ''}
		${background
			? css`
					&:before {
						background: ${background};
						background-position-y: 40vw;
						z-index: 0;
					}
			  `
			: ''}
		.main {
			background-color: transparent;
			z-index: 1;
		}

		.header {
			background-color: transparent;
		}

		${FooterStyle} {
			z-index: 1;
		}
	`;
};
