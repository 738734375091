import { statusSlice } from './slices/status.slice';
import { levelSlice } from './slices/level.slice';
import { loadedSlice } from './slices/loaded.slice';
import { levelsSlice } from './slices/levels.slice';

export const vipActions = {
	...statusSlice.actions,
	...levelSlice.actions,
	...loadedSlice.actions,
	...levelsSlice.actions,
};
