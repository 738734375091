import { onLoad } from './appService';

export interface FetchScriptOptions {
	noCache?: boolean;
	callback?: any;
	onerror?: any;
	type?: string;
}

export const fetchScript_ = async (
	url: string,
	options?: FetchScriptOptions,
) => {
	if (!process.browser) {
		return null;
	}
	const element = document.querySelector(`script[src="${url}"]`);
	if (element) {
		if (options?.noCache && element.remove) {
			element.remove();
		} else {
			if (options?.callback) {
				options.callback(null);
			}
			return null;
		}
	}
	return fetch(url)
		.then((response) => {
			if (!response?.ok) {
				throw response;
			}
			console.log('fetchScript response', url, { response });
			return response.blob();
		})
		.then(function (myBlob) {
			console.log('fetchScript blob', url, { myBlob });
			const objectURL = URL.createObjectURL(myBlob);
			const sc = document.createElement('script');
			sc.setAttribute('src', objectURL);
			sc.setAttribute('type', options?.type || 'text/javascript');
			document.head.appendChild(sc);
			if (options?.callback) {
				options.callback();
			}
			return null;
		})
		.catch((error) => {
			if (options?.onerror) {
				options.onerror(error);
			}
			return null;
		});
};

export const fetchScript = async (
	url: string,
	options?: FetchScriptOptions,
): Promise<unknown> => {
	return await onLoad(() => {
		return new Promise((resolve) => {
			if (!process.browser) {
				return resolve(null);
			}
			const element = document.querySelector(`script[src="${url}"]`);
			if (element) {
				if (options?.noCache && element.remove) {
					element.remove();
				} else {
					if (options?.callback) {
						options.callback(null);
					}
					return resolve(null);
				}
			}
			const script = document.createElement('script');
			const scripts = document.getElementsByTagName('script');
			const scriptsLast = scripts[scripts.length - 1];
			script.async = true;
			script.onload = (ev) => {
				if (options?.callback) {
					return resolve(options.callback(ev));
				}
				return resolve(null);
			};
			script.onerror = function (event, source, lineno, colno, error) {
				if (options?.onerror) {
					return resolve(options.onerror(event, source, lineno, colno, error));
				}
				return resolve(null);
			};
			script.src = url;
			if (options?.type) {
				script.type = options.type;
			}
			// script.setAttribute('crossorigin','*');
			scriptsLast.parentNode.insertBefore(script, scriptsLast);
		});
	});
};
