import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { RESET_USER_DATA } from '@legacyApp/client/store/user/user.constants';
import { epic } from '@legacyApp/client/modules/app/epicService';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { APP_INITIALIZED } from '@legacyApp/client/store/app/app.constants';
import { supportUpdateUserThunk } from '@modules/support/store/thunks/supportUpdateUser.thunk';
import { vipActions } from './vip.actions';
import { vipGetLevelsThunk } from './thunks/vipGetLevels.thunk';
import { vipResetStateThunk } from './thunks/vipResetState.thunk';
import { vipInitialThunk } from './thunks/vipInitial.thunk';

const levelEpic = epic('levelEpic', {
	actions: (ofType) =>
		ofType(vipActions.statusUpdate().type, vipActions.levelsUpdate().type),
	callback: ({ store$ }) => {
		if (
			!store$.value.vip.levels.length ||
			!isTrueOrZero(store$.value.vip.status)
		) {
			return of(vipGetLevelsThunk());
		}
		const levelsBelowThreshold = store$.value.vip.levels.filter(
			(el) => store$.value.vip.status > el.threshold,
		);
		const currentLevel = levelsBelowThreshold.length
			? levelsBelowThreshold[levelsBelowThreshold.length - 1]
			: null;
		if (
			currentLevel === null ||
			(typeof currentLevel === 'object' &&
				currentLevel?.level === store$.value.vip.level.level)
		) {
			if (!store$.value.vip.loaded) {
				return of(vipActions.loadedUpdate(true));
			}
			return of();
		}
		return of(vipActions.levelUpdate(currentLevel));
	},
});

const loadedEpic = epic('loadedEpic', {
	actions: (ofType) => ofType(vipActions.levelUpdate().type),
	callback: ({ store$ }) => {
		if (
			!store$.value.vip.levels.length ||
			!isTrueOrZero(store$.value.vip.status)
		) {
			return of(vipGetLevelsThunk());
		}
		return of([
			supportUpdateUserThunk({
				vip: store$.value.vip.level.level,
			}),
			vipActions.loadedUpdate(true),
		]);
	},
});

const loginEpic = epic('loginEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: () => {
		return of(vipInitialThunk());
	},
});

const resetUserDataEpic = epic('resetUserDataEpic', {
	actions: (ofType) => ofType(RESET_USER_DATA),
	callback: () => {
		return of(vipResetStateThunk());
	},
});

const vipEpic = combineEpics(
	resetUserDataEpic,
	levelEpic,
	loadedEpic,
	loginEpic,
);

export { vipEpic };
