import { FC } from 'react';
import { enhanceComponent } from '@legacyApp/client/wrappers/enhanceComponent';
import { PackageType } from '@legacyApp/client/store/registry/registry.constants';

const ModalStoreController: FC = () => {
	return null;
};

export const modalStoreProps = {
	asyncReducers: [
		{
			id: 'modal',
			callback: () => import('../../../modules/modals/store/modal.reducer'),
		},
	],
	asyncEpics: [
		{
			id: 'modal',
			callback: () => import('../../../modules/modals/store/modal.epic'),
		},
	],
	asyncPackage: PackageType.MODAL,
};

export default enhanceComponent({
	ModalStoreController,
	props: modalStoreProps,
});
