import styled from 'styled-components';

export const CurrencyIcon = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	img {
		height: 20px;
		margin: 10px auto;
		max-width: 100%;

		&[alt='doge'],
		&[alt='xrp'] {
			margin-top: 13px;
		}
	}
`;
