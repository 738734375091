import { config } from '@legacyApp/client/config';
import { filterArrayDuplicates } from '@legacyApp/client/modules/app/appService';
import { Message } from '../types/Message.type';

export const parseStream = (data: Array<Message>) => {
	if (!data || !data.length) {
		return data;
	}
	if (data.length <= config.chatStreamLength) {
		return filterArrayDuplicates(data);
	}
	return filterArrayDuplicates(
		data.slice(data.length - config.chatStreamLength),
	);
};
