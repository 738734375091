import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { sportsBetSlipClearPlacedBets } from '../sportsBetSlip/sportsBetSlip.thunk';
import { sportsSetSideContentAction } from './sports.actions';

export const sportsSetSideContent = (
	id?: 'bet_slip' | 'my_bets' | false,
): AppThunk => {
	return (dispatch, getState) => {
		if (!getState().sports) {
			return;
		}
		dispatch(sportsBetSlipClearPlacedBets());
		dispatch(sportsSetSideContentAction(id));
	};
};
