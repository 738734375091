import { BetState } from '@legacyApp/types/bets/BetDetailsType';
import { UserType } from '@legacyApp/types/user/UserType';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { BetSlipTypeType } from '../BetSlip/BetSlipType';
import { GameType } from '../../../games/Game/constants/availableGames';
import { BetSlipDetailsEventType } from './BetSlipDetailsEventType';

export enum BetSlipDetailsProgressState {
	PENDING = 'pending',
	CASHOUT = 'cashout',
	CANCELED = 'canceled',
	REJECTED = 'rejected',
	CLOSED = 'closed',
}

export type BetSlipDetailsState = BetState | BetSlipDetailsProgressState;

export interface BetSlipDetailsType {
	dataType: GameType.SPORTS;
	amount: string;
	currency: CurrencyType;
	hash: string;
	profit: string;
	published_at: number;
	state: BetSlipDetailsState;
	total_odds: number;
	type: BetSlipTypeType;
	betslip_events: BetSlipDetailsEventType[];
	total_available_cashout_odds?: number;
	user: UserType;
}
