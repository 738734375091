import { VipLevel } from '../store/types/vip.types';
import { VipBenefits } from '../constants/vip.config';

export interface UseVipBenefitStateProps {
	vipLevel: VipLevel;
	benefitType: VipBenefits;
}

export const getVipBenefitValue = (
	vipLevel: VipLevel,
	benefitType: VipBenefits,
) => {
	if (!vipLevel?.dailyBonus) {
		return false;
	}
	switch (benefitType) {
		case VipBenefits.DAILY_CHARGE: {
			return vipLevel?.dailyBonus?.spins ? vipLevel.dailyBonus : null;
		}
		case VipBenefits.LEVEL_UP_BONUS: {
			return vipLevel.rankBonus;
		}
		case VipBenefits.WEEKLY_BONUS_CODE: {
			return vipLevel.weeklyBonus;
		}
		case VipBenefits.INSANE_BONUS_CODE: {
			return vipLevel.insaneBonus;
		}
		case VipBenefits.BONUS_CODE: {
			return true;
		}
		case VipBenefits.RAKEBACK: {
			return vipLevel.commission;
		}
		default: {
			return false;
		}
	}
};

export const useVipBenefitState = ({
	vipLevel,
	benefitType,
}: UseVipBenefitStateProps) => {
	return getVipBenefitValue(vipLevel, benefitType);
};
