export enum DIRECTION {
	ASC = 'asc',
	DESC = 'desc',
}

export type Direction = true | false | DIRECTION.ASC | DIRECTION.DESC | 1 | 0;

type Value = number | string | boolean;

class SortClass {
	public invalidValue: string;

	constructor() {
		this.invalidValue = '-';
	}

	// eslint-disable-next-line max-params
	sort(
		direction: Direction,
		mainValueA: Value = 0,
		mainValueB: Value = 0,
		secondIterationValueA: Value = 0,
		secondIterationValueB: Value = 0,
		thirdIterationValueA: Value = 0,
		thirdIterationValueB: Value = 0,
	) {
		const direction_ = this.getDirection(direction);

		if (this.isValid(mainValueB) && !this.isValid(mainValueA)) {
			return 1;
		}
		if (this.isValid(mainValueA) && !this.isValid(mainValueB)) {
			return -1;
		}

		let spaceshipResult = this.spaceship(mainValueA, mainValueB);

		if (!spaceshipResult) {
			spaceshipResult = this.spaceship(
				secondIterationValueA,
				secondIterationValueB,
			);
		}

		if (!spaceshipResult) {
			spaceshipResult = this.spaceship(
				thirdIterationValueA,
				thirdIterationValueB,
			);
		}

		return spaceshipResult * direction_;
	}

	isValid(value) {
		return value && value !== this.invalidValue;
	}

	// direction:
	// true,1 - DIRECTION.DESC
	// false,-1 - DIRECTION.ASC
	getDirection(direction: Direction): number {
		if (typeof direction === 'boolean') {
			return direction ? 1 : -1;
		}
		if (typeof direction === 'string') {
			return direction === DIRECTION.DESC ? 1 : -1;
		}
		return direction;
	}

	spaceship(a, b) {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	}
}

export default new SortClass();
