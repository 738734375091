import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { vipStatusUpdateThunk } from './vipStatusUpdate.thunk';
import { vipGetLevelsThunk } from './vipGetLevels.thunk';

export const vipInitialThunk = (): AppThunk => {
	return (dispatch) => {
		dispatch(vipStatusUpdateThunk(true));
		dispatch(vipGetLevelsThunk());
	};
};
