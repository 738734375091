import { useEffect, useRef } from 'react';
import { isFunction } from '@common/methods/isFunction';

export const usePrevious = <T = any>(value, checkDiff?: (a, b) => boolean) => {
	const ref = useRef<T>(value);
	useEffect(() => {
		if (isFunction(checkDiff)) {
			if (checkDiff(ref.current, value)) {
				ref.current = value;
			}
			return;
		}
		ref.current = value;
	}, [checkDiff, value]);
	return ref.current;
};
