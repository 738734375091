import { FC, useState } from 'react';
import styled from 'styled-components';

import { PageContentType } from '@common/components/text/SeoPageContent/PageContentType';
import {
	StyledContent,
	StyledContentWrapper,
} from '@common/components/text/Content/Content.styled';
import { Button } from '@ui/button';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { trans } from '@legacyApp/client/modules/translation/translate';

const SeoPageContent: FC<PageContentType> = (props) => {
	const toggle = true;
	const { shadowColor } = props?.options || {};

	const [open, setOpen] = useState(!toggle);

	return (
		<StyledSeoContentWrapper $shadowColor={shadowColor} $open={open}>
			<div
				className="text-style-xl-medium"
				dangerouslySetInnerHTML={{
					__html: props.header,
				}}
			/>

			<StyledContent $open={open}>
				<div
					className="text-style-sm-regular"
					dangerouslySetInnerHTML={{
						__html: props.description,
					}}
				/>
				{toggle && (
					<Button
						styleType={ButtonStyleType.SECONDARY}
						label={trans({ label: open ? 'Hide' : 'Open' })}
						onClick={() => setOpen(!open)}
					/>
				)}
			</StyledContent>
		</StyledSeoContentWrapper>
	);
};

export const StyledSeoContentWrapper = styled(StyledContentWrapper)<{
	$open: boolean;
	$shadowColor?: string;
}>`
	h1 {
		margin-bottom: 0 !important;
		height: unset !important;
	}

	h2 {
		margin: 20px 0 10px !important;
	}

	h3 {
		margin: 15px 0 10px !important;
	}

	ul,
	ol,
	menu {
		list-style: unset;
		margin: unset;
		padding: 0 0 0 25px;
		width: 100%;
	}

	li {
		float: left;
		margin-bottom: 5px;
		width: 100%;

		> * {
			margin: 0 !important;
		}
	}
`;

export default SeoPageContent;
