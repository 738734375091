import React, { FC } from 'react';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { Loadable } from '../loader/loadable/loadable';
import { testMenuConfig } from './TestMenuConfig';

const TestMenu_ = Loadable(
	{
		loader: () => import(/* webpackChunkName: "TestMenu" */ './TestMenu'),
		ssr: false,
	},
	'./TestMenu',
);

export const TestMenu: FC = () => {
	if (!Object.keys(testMenuConfig).find((key) => testMenuConfig[key])) {
		return null;
	}
	return NEXT_CONFIG.DEV ? <TestMenu_ game={'dice'} /> : null;
};
