import { css } from 'styled-components';

export const dashboard = (callback = null, noBigDesktop = false) => {
	const padding = {
		default: '20px',
		mobile: '15px',
		desktop: '15px',
	};
	return css`
		${callback
			? callback(padding.default, '100%')
			: css`
					float: left;
					padding-left: ${padding.default};
					padding-right: ${padding.default};
					width: 100%;
			  `};

		@media (max-width: ${(props) => props.theme.media.maxWidthMobile}) {
			${callback
				? callback(padding.mobile, '100%')
				: css`
						padding-left: ${padding.mobile};
						padding-right: ${padding.mobile};
				  `}
		}

		@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
			${(props) =>
				callback
					? callback(padding.desktop, props.theme.layout.container.small)
					: css`
							margin: 0 auto;
							max-width: ${props.theme.layout.container.small};
							padding-left: ${padding.desktop};
							padding-right: ${padding.desktop};
					  `}
		}

		${!noBigDesktop &&
		((props) => css`
			@media (min-width: ${props.theme.media.minWidthBigDesktop}) {
				${callback
					? callback(padding.default, props.theme.layout.container.big)
					: css`
							max-width: ${props.theme.layout.container.big};
					  `}
			}
		`)}
	`;
};

export const padding_v2 = {
	default: '80px',
	desktop: '40px',
	tablet: '20px',
	mobile: '15px',
};

export const dashboard_v2 = (callback = null, noBigDesktop = false) => {
	const padding = padding_v2;
	const width = {
		big: '1600px',
		medium: '1280px',
	};
	return css`
		${callback
			? callback(padding.tablet, '100%')
			: css`
					float: left;
					padding-left: ${padding.tablet};
					padding-right: ${padding.tablet};
					width: 100%;
			  `};

		@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
			${callback
				? callback(padding.mobile, '100%')
				: css`
						padding-left: ${padding.mobile};
						padding-right: ${padding.mobile};
				  `}
		}

		@media (min-width: ${(props) => props.theme.media_v2.min_tablet_large}) {
			${callback
				? callback(padding.desktop, width.medium)
				: css`
						margin: 0 auto;
						max-width: ${width.medium};
						padding-left: ${padding.desktop};
						padding-right: ${padding.desktop};
				  `}
		}

		${!noBigDesktop &&
		((props) => css`
			@media (min-width: ${props.theme.media_v2.min_desktop_medium}) {
				${callback
					? callback(padding.default, width.big)
					: css`
							max-width: ${width.big};
							padding-left: ${padding.default};
							padding-right: ${padding.default};
					  `}
			}
		`)}
	`;
};
