import { useEffect, useState } from 'react';
import { isFunction } from '../methods/isFunction';

export const useStateEffect = <T>(
	get: () => T,
	checkIsDiff?: (a: T, b: T) => boolean,
): T => {
	const [value, setValue] = useState(get());

	useEffect(() => {
		const value_ = get();
		const isChanged = isFunction(checkIsDiff)
			? checkIsDiff(value_, value)
			: value_ !== value;
		if (!isChanged) {
			return;
		}
		setValue(value_);
	}, [value, get, checkIsDiff]);

	return value;
};
