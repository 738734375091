import CryptoJS from 'crypto-js';
// import forge from 'node-forge';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { LinesType, Risk } from '@modules/games/PlinkoGame/types';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { mathService } from '../math/mathService';
import { sentryError } from './sentryService';
import { getMarginMultiplier } from './validateService';

const separator = '_';

export const getHash = (client_seed, server_seed, nonce) => {
	// if (!(!!server_seed && !!client_seed && (!!nonce || typeof nonce === 'number'))) {
	//   return false;
	// }
	// console.log('hash', {first: server_seed, second: `${client_seed}${separator}${nonce}`});
	return hmac_sha256(server_seed, `${client_seed}${separator}${nonce}`);
};

export const sha256 = (m) => {
	return CryptoJS.SHA256(m).toString();
};

export const hmac_sha256 = (K, m) => {
	return CryptoJS.HmacSHA256(K, m).toString();
	// const hmac = forge.hmac.create();
	// hmac.start('sha256', K);
	// hmac.update(m);
	// console.log('hmac', {
	//   stake: hmac.digest().toHex(),
	//   crypto: CryptoJS.HmacSHA256(m,K).toString(),
	// });
	// return hmac.digest().toHex();
};

export const betSubnonceVerification = (
	client_seed,
	server_seed,
	nonce,
	callback,
	betSubnonceLength,
) => {
	const hash = getHash(client_seed, server_seed, `${nonce}${separator}0`);
	if (!hash) {
		return null;
	}
	if (betSubnonceLength && betSubnonceLength > 1) {
		return {
			hash: hash,
			data: new Array(100).fill(0).map((el, index) => {
				return callback(
					getHash(client_seed, server_seed, `${nonce}${separator}${index}`),
				).toString();
			}),
		};
	}
	return {
		hash,
		data: callback(hash).toString(),
	};
};

export const betVerification = (betHash, maxResults) => {
	if (!betHash || !maxResults) {
		return null;
	}
	const floats = new Array(4).fill(0).map((el, index) => {
		const hex = betHash.substr(index * 2, 2);
		// console.log({hex, index, betHash, float: parseInt(hex, 16)});
		return parseInt(hex, 16);
	});
	let sum = 0;
	floats.forEach((float, index) => {
		// console.log({float, sum, index, res: (float / Math.pow(256, index))});
		sum += roundNumber(float / Math.pow(256, index + 1), 10);
	});
	// console.log(round(sum * maxResults, 0, 'floor'));
	return sum * maxResults;
};

export const diceVerification = (
	client_seed,
	server_seed,
	nonce,
): {
	hash: string;
	data: string;
} => {
	const hash = getHash(client_seed, server_seed, nonce);
	// console.log('Dice hash', hash);
	if (!hash) {
		return null;
	}

	let index = 0;
	let lucky = parseInt(hash.substr(index, 5), 16);
	while (lucky >= 1000000) {
		lucky = parseInt(hash.substr(index, 5), 16);
		index += 5;
	}

	return {
		hash,
		data: mathService.divide(lucky % 10000, 100, 2).toString(),
	};
};

export const gameVerification = (
	client_seed,
	server_seed,
	nonce,
	maxResults,
	maxSubnonce = null,
) => {
	return betSubnonceVerification(
		client_seed,
		server_seed,
		nonce,
		(hash) => betVerification(hash, maxResults),
		maxSubnonce,
	);
};

export const hiloFullTailVerification = (client_seed, server_seed, nonce) => {
	if (!client_seed || !server_seed || !nonce) {
		return;
	}
	const array = [];
	const maxValue = 52;
	let subnonce = 0;
	while (array.length < maxValue || subnonce > 1000000) {
		const hash = getHash(client_seed, server_seed, `${nonce}_${subnonce}`);
		const result = betVerification(hash, maxValue);
		if (!isTrueOrZero(result)) {
			break;
		}
		if (result >= maxValue) {
			sentryError(
				new Error(
					`Wrong bet result - result: ${result}; subnonce: ${subnonce}`,
				),
				{
					client_seed,
					server_seed,
					nonce,
					subnonce,
					result,
				},
			);
			break;
		}
		if (array.indexOf(result) === -1) {
			array.push(result);
		}
		subnonce++;
	}
	// console.log({array, subnonce});
	if (array.length < maxValue) {
		sentryError(new Error('No full hilo tail'), {
			client_seed,
			server_seed,
			nonce,
			subnonce,
			array,
		});
	}
	return {
		array,
		subnonce,
	};
};

export const limboResult = (float) => {
	const normalizedFloat = Math.max(float, 0.01);
	const floatPoint = (1e8 / normalizedFloat) * getMarginMultiplier('limbo');
	const clampedFloatPoint = Math.min(floatPoint, 9900000);
	const crashPoint = Math.floor(clampedFloatPoint * 100) / 100;
	// console.log('limboResult', {
	//   float,
	//   floatPoint,
	//   normalizedFloat,
	//   clampedFloatPoint,
	//   crashPoint,
	//   result: Math.max(crashPoint, 1),
	//   margin: getMarginMultiplier('limbo'),
	// });
	return Math.max(crashPoint, 1);
};

export const hiloResult = (float) => {
	return roundNumber(float, 0, 'floor');
};

export const plinkoResult = (
	floats: Array<number | string>,
	risk: Risk,
	lines: LinesType,
) => {
	const path = [];
	for (let i = 0; i < lines; i++) {
		path.push(floats[i] >= 1 ? 1 : 0);
	}
	return {
		result: path.reduce((acc, el) => acc + el, 0),
		path,
	};
};
