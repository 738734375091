import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const NAME = 'disabled';

export type ValueType = boolean;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	if (!action.payload) {
		return null;
	}
	return action.payload;
};

export const disabledSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setDisabled: setAction,
	},
});
