import {
	FORM_VERIFICATION_SET_GAME,
	RESET_CAMPAIGN_FORM,
	RESET_FORMS,
	RESET_PASSWORD_FORM,
	RESET_USERNAME_FORM,
	RESET_VERIFICATION_FORM,
	SET_CLIENT_SEED,
	SET_NONCE,
	SET_SERVER_SEED,
	UPDATE_CAMPAIGN_CODE,
	UPDATE_CAMPAIGN_NAME,
	UPDATE_FORM,
	UPDATE_PASSWORD_CONFIRM_VALUE,
	UPDATE_PASSWORD_VALIDATE,
	UPDATE_PASSWORD_VALUE,
	UPDATE_USERNAME_VALIDATE,
	UPDATE_USERNAME_VALUE,
} from './forms.constants';

const resetForms = () => {
	return {
		type: RESET_FORMS,
	};
};

const resetPasswordForm = () => {
	return {
		type: RESET_PASSWORD_FORM,
	};
};

const resetUsernameForm = () => {
	return {
		type: RESET_USERNAME_FORM,
	};
};

const resetVerificationForm = () => {
	return {
		type: RESET_VERIFICATION_FORM,
	};
};

const updatePasswordConfirmValue = (payload) => {
	return {
		type: UPDATE_PASSWORD_CONFIRM_VALUE,
		payload,
	};
};

const updatePasswordValue = (payload) => {
	return {
		type: UPDATE_PASSWORD_VALUE,
		payload,
	};
};

const updatePasswordValidate = (payload) => {
	return {
		type: UPDATE_PASSWORD_VALIDATE,
		payload,
	};
};

const updateUsernameValue = (payload) => {
	return {
		type: UPDATE_USERNAME_VALUE,
		payload,
	};
};

const updateUsernameValidate = (payload) => {
	return {
		type: UPDATE_USERNAME_VALIDATE,
		payload,
	};
};

const setClientSeed = (payload) => {
	return {
		type: SET_CLIENT_SEED,
		payload,
	};
};

const setServerSeed = (payload) => {
	return {
		type: SET_SERVER_SEED,
		payload,
	};
};

const setNonce = (payload) => {
	return {
		type: SET_NONCE,
		payload,
	};
};

const formVerificationSetGame = (payload) => ({
	type: FORM_VERIFICATION_SET_GAME,
	payload,
});

const updateCampaignName = (payload) => {
	return {
		type: UPDATE_CAMPAIGN_NAME,
		payload,
	};
};

const updateCampaignCode = (payload) => {
	return {
		type: UPDATE_CAMPAIGN_CODE,
		payload,
	};
};

const resetCampaignForm = () => {
	return {
		type: RESET_CAMPAIGN_FORM,
	};
};

export const updateForm = ({ section, field, value }) => {
	return {
		type: UPDATE_FORM,
		payload: {
			section,
			field,
			value,
		},
	};
};

export {
	resetForms,
	resetPasswordForm,
	resetUsernameForm,
	updatePasswordConfirmValue,
	updatePasswordValue,
	updatePasswordValidate,
	updateUsernameValue,
	updateUsernameValidate,
	setClientSeed,
	setNonce,
	setServerSeed,
	resetVerificationForm,
	updateCampaignCode,
	updateCampaignName,
	resetCampaignForm,
	formVerificationSetGame,
};
