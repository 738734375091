import { fetchResponse } from '../fetch/fetch.actions';
import { fetchApi, fetchApiAction } from '../fetch/fetch.thunk';
import {
	CHANGE_CURRENCY,
	CHANGE_EMAIL,
	CHANGE_NAME,
	CHANGE_PASSWORD,
	CHECK_AM_I_UNIQUE,
	CLEAR_FIRST_LOGIN,
	REFRESH_USER_DATA,
	RESEND_VERIFICATION_EMAIL,
	RESET_TOKEN,
	RESET_USER_DATA,
	SEND_NEW_PASSWORD,
	SET_CURRENCY,
	SET_EMAIL,
	SET_FIRST_LOGIN,
	SET_HAS_AVAILABLE_BONUS_CODE,
	SET_JOINED_DATE,
	SET_LAST_REGISTER_TIMESTAMP,
	SET_NEW_PASSWORD,
	SET_SOCKET_PRIVATE_CHANNEL,
	SET_TOKEN,
	SET_USER_ADMIN,
	SET_USER_HIGH_ROLLER,
	SET_USER_ID,
	SET_USER_MODERATOR,
	SET_USER_RACE_BADGE,
	SET_USERNAME,
	USER_GET_BETS,
	USER_GET_DATA,
	USER_HANDLE_LOGIN_RESPONSE,
	USER_HANDLE_REGISTER_RESPONSE,
	USER_HAS_EMAIL_TO_VERIFY,
	USER_HAS_KYC_TO_VERIFY,
	USER_HAS_PASSWORD_TO_SET,
	USER_LOGGED,
	USER_LOGGING,
	USER_LOGOUT,
	USER_NOT_LOGGED,
	USER_REGISTER_SEND,
	USER_SESSION_ENDED,
	USER_SET_WITHDRAW_BLOCKED,
	USER_SET_WITHDRAW_BLOCKED_TIMESTAMP,
} from './user.constants';

export const refreshUserData = () => {
	return {
		type: REFRESH_USER_DATA,
	};
};

export const getUserData = (
	{ enableLoader, noError } = { enableLoader: false, noError: false },
) => {
	return {
		type: USER_GET_DATA,
		enableLoader,
		noError,
	};
};

export const userSessionEnded = () => {
	return {
		type: USER_SESSION_ENDED,
	};
};

export const getMyBets = (page, replaceData, perPage) => {
	return {
		type: USER_GET_BETS,
		page,
		replaceData,
		perPage,
	};
};

export const loginUser = (name, password) => {
	return {
		type: USER_LOGGING,
		name,
		password,
	};
};

export const notLoggedUser = (message) => {
	return {
		type: USER_NOT_LOGGED,
		message,
	};
};

export const loggedUser = (isLogged) => {
	return {
		type: USER_LOGGED,
		isLogged,
	};
};

export const logoutUser = (sendRequest = true) => {
	return {
		type: USER_LOGOUT,
		sendRequest,
	};
};

export const setToken = (data) => {
	return {
		type: SET_TOKEN,
		data,
	};
};

export const resetToken = () => {
	return {
		type: RESET_TOKEN,
	};
};

export const setUserName = (name) => {
	return {
		type: SET_USERNAME,
		name,
	};
};

export const setEmail = (payload) => {
	return {
		type: SET_EMAIL,
		payload,
	};
};

export const registerUser = (data) => {
	return {
		type: USER_REGISTER_SEND,
		data,
	};
};

export const resetData = () => {
	return {
		type: RESET_USER_DATA,
	};
};

export const setCurrency = (payload) => {
	return {
		type: SET_CURRENCY,
		payload,
	};
};

export const changeCurrency = (payload) => {
	return {
		type: CHANGE_CURRENCY,
		payload,
	};
};

export const changePassword = (payload) => {
	return {
		type: CHANGE_PASSWORD,
		payload,
	};
};

export const changeName = (payload) => {
	return {
		type: CHANGE_NAME,
		payload,
	};
};

export const changeEmail = (payload) => {
	return {
		type: CHANGE_EMAIL,
		payload,
	};
};

export const resendVerificationEmail = (payload) => {
	return {
		type: RESEND_VERIFICATION_EMAIL,
		payload,
	};
};

export const hasEmailToVerify = (payload) => {
	return {
		type: USER_HAS_EMAIL_TO_VERIFY,
		payload,
	};
};

export const hasKycToVerify = (payload) => {
	return {
		type: USER_HAS_KYC_TO_VERIFY,
		payload,
	};
};

export const sendNewPassword = (payload) => {
	return {
		type: SEND_NEW_PASSWORD,
		payload,
	};
};

export const setNewPassword = (payload, callback) => {
	return {
		type: SET_NEW_PASSWORD,
		payload,
		callback,
	};
};

export const setFirstLogin = (payload) => {
	return {
		type: SET_FIRST_LOGIN,
		payload,
	};
};

export const clearFirstLogin = (payload) => {
	return {
		type: CLEAR_FIRST_LOGIN,
		payload,
	};
};

export const setSocketPrivateChannel = (payload) => {
	return {
		type: SET_SOCKET_PRIVATE_CHANNEL,
		payload,
	};
};

export const hasPasswordToSet = (payload) => {
	return {
		type: USER_HAS_PASSWORD_TO_SET,
		payload,
	};
};

export const setJoinedDate = (payload) => {
	return {
		type: SET_JOINED_DATE,
		payload,
	};
};

export const setUserId = (payload) => {
	return {
		type: SET_USER_ID,
		payload,
	};
};

export const setUserAdmin = (payload) => {
	return {
		type: SET_USER_ADMIN,
		payload,
	};
};

export const setUserModerator = (payload) => {
	return {
		type: SET_USER_MODERATOR,
		payload,
	};
};

export const setUserHighRoller = (payload) => {
	return {
		type: SET_USER_HIGH_ROLLER,
		payload,
	};
};

export const setUserRaceBadge = (payload) => {
	return {
		type: SET_USER_RACE_BADGE,
		payload,
	};
};

export const getUserInfo = (uuid) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			const userState = getState().user;
			// console.log('getUserInfo', userState, uuid);
			if (userState.id === uuid || !uuid) {
				return resolve({
					status: 200,
					user: {
						is_admin: userState.isAdmin,
						is_high_roller: userState.isHighRoller,
						is_moderator: userState.isModerator,
						login: userState.name,
						uuid: uuid || userState.id,
						race_badge: userState.raceBadge,
						vip_level: userState.vipLevel,
						joined: userState.joinedDate,
					},
				});
			}
			return resolve(
				dispatch(
					fetchApi(
						`/info/${uuid}`,
						undefined,
						'GET',
						'getUserInfoData',
						undefined,
						true,
						false,
						true,
					),
				),
			);
		});
	};
};

export const checkAmIUnique = () => {
	return {
		type: CHECK_AM_I_UNIQUE,
	};
};

export const setLastRegisterTimestamp = (payload) => {
	return {
		type: SET_LAST_REGISTER_TIMESTAMP,
		payload,
	};
};

export const userSetWithdrawBlockedTimestamp = (payload) => ({
	type: USER_SET_WITHDRAW_BLOCKED_TIMESTAMP,
	payload,
});

export const userSetWithdrawBlocked = (payload) => ({
	type: USER_SET_WITHDRAW_BLOCKED,
	payload,
});

export const userHandleLoginResponse = (payload) => ({
	type: USER_HANDLE_LOGIN_RESPONSE,
	payload,
});

export const userHandleRegisterResponse = (payload) => ({
	type: USER_HANDLE_REGISTER_RESPONSE,
	payload,
});

export const getUserDetailsByLogin = (login, callback, catchCallback) => {
	return (dispatch) => {
		console.log('getUserDetailsByLogin', {
			login,
		});
		if (!login) {
			return dispatch(
				fetchResponse({
					response: false,
					callback,
					catchCallback,
				}),
			);
		}
		return dispatch(
			fetchApiAction(
				{
					url: `/user/${login}`,
					parameters: {
						Authorization: true,
					},
					method: 'GET',
					loadingId: 'getUserByLogin',
				},
				(result) => {
					if (!result || !result.user) {
						result = false;
					}
					result = result.user;
					return callback(result);
				},
				catchCallback,
			),
		);
	};
};

export const setHasAvailableBonusCode = (payload) => ({
	type: SET_HAS_AVAILABLE_BONUS_CODE,
	payload,
});
