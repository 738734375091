import { SPORTS_PAGE_ID } from '@modules/sports/constants/sports.page.config';
import { SPORTSBOOK_PAGE_ID } from '@modules/sportsbook/constants/sportsbook.page.config';

export const ROUTING_ID = {
	GAME: 'game',
	LOGIN: 'login',
	REGISTER: 'register',
	USER_DEPOSIT: 'user_deposit',
	USER_WITHDRAW: 'user_withdraw',
	USER_SETTINGS: 'user_settings',
	USER_ALL_TRANSACTIONS: 'user_allTransactions',
	USER_SPORTS_HISTORY: 'user_sports_history',
	USER_SPORTS_HISTORY_V1: 'user_sports_history_v1',
	USER_EMAIL_VERIFIED: 'user_email_verified',
	USER_EMAIL_VERIFICATION_FAILED: 'user_email_verificationFailed',
	USER_ACCOUNT_BLOCKED: 'user_account_blocked',
	USER_ACCOUNT_BLOCKED_ERROR: 'user_account_blockedError',
	USER_NEW_PASSWORD: 'user_newPassword',
	HOME: 'home',
	USER_AFFILIATE: 'user_affiliate',
	USER_VIP: 'user_vip',
	USER_VIP_BONUS_CODE: 'user_vip_bonus_code',
	FAQ: 'faq',
	TERMS: 'terms',
	PROVABLY_FAIR: 'provablyFair',
	REMIND_PASSWORD: 'remindPassword',
	PROMOTIONS: 'promotions',
	RANKING: 'ranking',
	SPORTS_BOOK: SPORTSBOOK_PAGE_ID.INDEX,
	SPORTS: SPORTS_PAGE_ID.INDEX,
	SPORTS_LIVE: SPORTS_PAGE_ID.LIVE,
	SPORTS_MENU: SPORTS_PAGE_ID.MENU,
	USER_VERIFICATION: 'user_verification',
	REDIRECT: 'redirect',
	ACCESS_DENIED: 'access_denied',
	BETS: 'bets',
	CASINO: 'casino',
} as const;
