import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { preferencesSet } from './preferences.actions';
import { PREFERENCES_INIT, PREFERENCES_UPDATE } from './preferences.constants';

const initPreferencesEpic = epic('initPreferencesEpic', {
	actions: (ofType) => ofType(PREFERENCES_INIT),
	callback: ({ action, store$ }) => {
		if (
			Object.keys(action.payload)
				.map((key) => `${key}${action.payload[key]}`)
				.join(',') !==
			Object.keys(store$.value.preferences[action.data_type])
				.map(
					(key) =>
						`${key}${store$.value.preferences[action.data_type][key].bool}`,
				)
				.join(',')
		) {
			return of(preferencesSet(action.data_type, action.payload));
		}
		return of();
	},
});

const updatePrivacyEpic = epic('updatePrivacyEpic', {
	actions: (ofType) => ofType(PREFERENCES_UPDATE),
	callback: ({ action }) => {
		const data = {};
		Object.keys(action.payload).forEach((key) => {
			data[key] = action.payload[key].bool;
		});
		return of(
			fetchApiAction(
				{
					url: `/user/change/preferences/${action.data_type}`,
					parameters: {
						Authorization: true,
						body: {
							...data,
						},
					},
					method: 'POST',
					loaderId: `updatePreferences-${action.data_type}`,
					lockByModal: true,
				},
				() => {
					const result = [];
					result.push(preferencesSet(action.data_type, action.payload));
					return result;
				},
			),
		);
	},
});

const preferencesEpic = combineEpics(updatePrivacyEpic, initPreferencesEpic);

export { preferencesEpic };
