import { VipBenefits } from './vip.config';

export const VIP_BENEFITS = {
	[VipBenefits.LEVEL_UP_BONUS]: {
		name: 'Level-Up Bonus',
		description:
			'As you progress through our VIP tiers, revel in generous level-up bonuses that keep the excitement flowing.',
	},
	[VipBenefits.WEEKLY_BONUS_CODE]: {
		name: 'Weekly Bonus Code',
		description:
			'Unwrap thrilling surprises every week with our exclusive VIP weekly bonus codes, delivering rewards that keep the action going strong.',
	},
	[VipBenefits.OTHER]: {
		name: 'Other',
		description: null,
	},
	[VipBenefits.DAILY_CHARGE]: {
		name: 'Daily Charge',
		description:
			'Experience the thrill daily with our special VIP daily charges, adding an extra layer of excitement to your gaming routine.',
	},
	[VipBenefits.RAKEBACK]: {
		name: 'Rakeback',
		description:
			'Get back a portion of your wagers with our Rakeback feature, adding extra value to every bet you make.',
	},
	[VipBenefits.WOLF_RACE]: {
		name: 'Wolf Race',
		description: null,
	},
	[VipBenefits.PRIORITY_SUPPORT]: {
		name: 'Priority Support',
		description:
			'Enjoy top-notch assistance with our dedicated priority support, ensuring your gaming experience is seamless and hassle-free.',
	},
	[VipBenefits.INSANE_BONUS_CODE]: {
		name: 'Insane Bonus Code',
		description:
			'Get your hands on jaw-dropping bonuses with our Insane Bonus Codes, setting the stage for unforgettable gaming sessions.',
	},
	[VipBenefits.RAIN_GIVEAWAYS]: {
		name: 'Chat Rain & Giveaways',
		description: null,
	},
	[VipBenefits.CHAT_RAIN]: {
		name: 'Chat Rain',
		description: null,
	},
	[VipBenefits.BONUS_CODE]: {
		name: 'Bonus Code',
		description: null,
	},
} as const;
