import logSlowReducers from 'redux-log-slow-reducers';
import { routerReducer } from 'connected-next-router';
import { vipReducer } from '@modules/vip/store/vip.reducer';
import { balanceReducer } from '@modules/balance/store/balance.reducer';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { supportReducer } from '@modules/support/store/support.reducer';
import { appReducer } from '../app/app.reducer';
import { userReducer } from '../user/user.reducer';
import { confirmReducer } from '../confirm/confirm.reducer';
import { transactionsReducer } from '../transactions/transactions.reducer';
import { twoFactorAuthenticationReducer } from '../twoFactorAuthentication/twoFactorAuthentication.reducer';
import { loaderReducer } from '../loader/loader.reducer';
import { globalStatisticsReducer } from '../globalStatistics/globalStatistics.reducer';
import { formsReducer } from '../forms/forms.reducer';
import { faucetReducer } from '../faucet/faucet.reducer';
import { notificationReducer } from '../notification/notification.reducer';
import { affiliateReducer } from '../affiliate/affiliate.reducer';
import { websocketReducer } from '../websocket/websocket.reducer';
import { alertsReducer } from '../alerts/alerts.reducer';
import { tipReducer } from '../tip/tip.reducer';
import { serverDataReducer } from '../serverData/serverData.reducer';
import { vaultReducer } from '../vault/vault.reducer';
import { privateMessagesReducer } from '../privateMessages/privateMessages.reducer';
import { friendsReducer } from '../friends/friends.reducer';
import { preferencesReducer } from '../preferences/preferences.reducer';
import { dropdownReducer } from '../dropdown/dropdown.reducer';
import { converterReducer } from '../converter/converter.reducer';
import { optionsReducer } from '../options/options.reducer';
import { pwaReducer } from '../pwa/pwa.reducer';
import registryReducer from '../registry/registry.reducer';

export const createRootReducer = (data = {}) => {
	// console.log('createRootReducer', data);
	let reducers = {
		router: routerReducer,
		app: appReducer,
		user: userReducer,
		confirm: confirmReducer,
		transactions: transactionsReducer,
		twoFA: twoFactorAuthenticationReducer,
		loader: loaderReducer,
		stats: globalStatisticsReducer,
		forms: formsReducer,
		notification: notificationReducer,
		faucet: faucetReducer,
		affiliate: affiliateReducer,
		websocket: websocketReducer,
		alerts: alertsReducer,
		vip: vipReducer,
		tip: tipReducer,
		serverData: serverDataReducer(data.serverData),
		vault: vaultReducer,
		privateMessages: privateMessagesReducer,
		friends: friendsReducer,
		preferences: preferencesReducer,
		dropdown: dropdownReducer,
		converter: converterReducer,
		balance: balanceReducer,
		support: supportReducer,
		pwa: pwaReducer,
		options: optionsReducer(data.options),
		registry: registryReducer,

		// dailyStreak: dailyStreakReducer,

		// dice: diceReducer,
		// limbo: limboReducer,
		// hilo: hiloReducer,
		// game: gameReducer,
		// gameSession: gameSessionReducer,
		// gameAutobet: gameAutobetReducer,
		// gameValidation: gameValidationReducer,
	};
	if (NEXT_CONFIG.DEV) {
		reducers = logSlowReducers(reducers);
	}
	return reducers;
};
