import enhanceWithClickOutside from 'react-click-outside';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { whyDidYouRenderOptions } from '../modules/app/devService';
import enhanceWithErrorHandling from './enhanceWithErrorHandling';
import enhanceWithAsyncStore from './enhanceWithAsyncStore';
import enhanceWithCurrencyConveter from './enhanceWithCurrencyConveter';

export const enhanceComponent = (data) => {
	const keys = Object.keys(data);
	const isInvalid = ['displayName', 'propTypes'].some(
		(el) => keys.indexOf(el) > -1,
	);
	if (isInvalid) {
		console.error('Invalid component passed', {
			data,
			keys,
			isInvalid,
		});
		return false;
	}
	const componentKeyIndex = keys.findIndex(
		(el) => ['props'].indexOf(el) === -1,
	);
	const componentName = keys[componentKeyIndex];
	let WrappedComponent = data[componentName];
	if (data?.props?.currencyConverter) {
		WrappedComponent = enhanceWithCurrencyConveter(WrappedComponent);
	}
	if (data?.props?.clickOutside) {
		WrappedComponent = enhanceWithClickOutside(WrappedComponent);
	}

	if (data?.props?.asyncEpics?.length || data?.props?.asyncReducers?.length) {
		WrappedComponent = enhanceWithAsyncStore(
			{
				[componentName]: WrappedComponent,
			},
			data.props,
		);
	}
	// console.log('enhanceGame', {
	//   data,
	//   keys,
	//   componentKeyIndex,
	//   componentName,
	//   WrappedComponent,
	// });
	const result = enhanceWithErrorHandling({
		[componentName]: WrappedComponent,
	});
	if (NEXT_CONFIG.DEV) {
		return whyDidYouRenderOptions(result, componentName);
	}
	return result;
};
