import queryString from 'query-string';
import Router from 'next/router';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { sentryError } from './sentryService';

const getUrl = (data) => {
	let path = Object.keys(Router.router.query).length
		? Router.router.pathname
		: Router.router.asPath;
	if (path.indexOf('?') > -1) {
		path = path.split('?')[0];
	}
	const query = {
		...(Object.keys(data).length ? Router.router.query : {}),
		...data,
	};
	Object.keys(Router.router.query).forEach((key) => {
		if (path.indexOf(`[${key}]`) > -1) {
			path = path.replace(`[${key}]`, Router.router.query[key]);
			if (query[key]) {
				delete query[key];
			}
		}
	});
	Object.keys(query).forEach((key) => {
		if (!isTrueOrZero(query[key])) {
			delete query[key];
		}
	});
	return `${path}${
		Object.keys(query).length ? `?${queryString.stringify(query)}` : ''
	}`;
};

export const setQueryParams = (data = {}) => {
	if (process.browser) {
		const url = getUrl(data);
		console.log('setQueryParams - redirect', {
			url,
			data,
			router: Router.router,
			asPath: Router.router.asPath,
		});
		if (url === Router.router.asPath) {
			return;
		}
		try {
			Router.push(url, undefined, { shallow: true, scroll: false });
		} catch (error) {
			sentryError('Cannot pushState in setQueryParams', {
				data,
				query: Router.router.query,
				url,
				locationObj: Router.router,
				error,
			});
		}
	}
};
