import { Explorers } from '@common/constants/currency/settings/explorers';
import { PartialRecord } from '@common/types/partialRecord.types';

export enum Currencies {
	usdt = 'usdt',
	btc = 'btc',
	eth = 'eth',
	xrp = 'xrp',
	doge = 'doge',
	bnb = 'bnb',
	trx = 'trx',
	shib = 'shib',
	ltc = 'ltc',
	xlm = 'xlm',
	sushi = 'sushi',
	uni = 'uni',
	etc = 'etc',
	bch = 'bch',
	ada = 'ada',
	dot = 'dot',
	optim = 'optim',
	matic = 'matic',
	pepe = 'pepe',
	bonk = 'bonk',
	floki = 'floki',
	sol = 'sol',
}

export enum Token {
	usdt = 'usdt',
	usdc = 'usdc',
	busd = 'busd',
	btc = 'btc',
	eth = 'eth',
	xrp = 'xrp',
	doge = 'doge',
	bnb = 'bnb',
	trx = 'trx',
	shib = 'shib',
	ltc = 'ltc',
	xlm = 'xlm',
	sushi = 'sushi',
	uni = 'uni',
	etc = 'etc',
	bch = 'bch',
	ada = 'ada',
	dot = 'dot',
	op = 'op',
	pol = 'pol',
	pepe = 'pepe',
	bonk = 'bonk',
	floki = 'floki',
	sol = 'sol',
}

export const CurrenciesLayout = {
	usd: 'usd',
	pol: Token.pol,
	eur: 'eur',
	op: Token.op,
} as const;

export type CurrencyType = Currencies | keyof typeof CurrenciesLayout;
export type CurrencyNetworkType = keyof typeof Explorers;
export type CurrencyTokenType = Token;

export type CurrencyTypeMap<T> = PartialRecord<CurrencyType, T>;
export type CurrencyNetworkTypeMap<T> = PartialRecord<CurrencyNetworkType, T>;
export type CurrencyTokenTypeMap<T> = PartialRecord<CurrencyTokenType, T>;
