import { isTrueOrZero } from './isTrueOrZero';

export const removeEmptyProperties = (obj: Record<string, any>) => {
	if (!obj) {
		return undefined;
	}
	if (Array.isArray(obj)) {
		return obj;
	}
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([_, v]) => {
				return isTrueOrZero(v);
			})
			.map(([k, v]) => [k, v === Object(v) ? removeEmptyProperties(v) : v]),
	);
};
