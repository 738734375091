import { useCallback, useEffect } from 'react';

import { useForceUpdate } from '@legacyApp/hooks/app/useForceRerender';
import {
	useAppIsSideMenuVisible,
	useAppMobile,
} from '@common/selectors/app.selectors';
import {
	setLockedView,
	toggleSideMenu,
} from '@legacyApp/client/store/app/app.actions';
import { useDispatchCallback } from '@legacyApp/hooks/store/useAppDispatch';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';

export const useSideMenu = () => {
	const isOpen = useAppIsSideMenuVisible();
	const isMobile = useAppMobile();

	const dispatchSetLockedView = useDispatchCallback(setLockedView);
	const dispatchToggleSideMenu = useDispatchCallback(toggleSideMenu);

	const update = useForceUpdate();

	const toggleSideMenu_ = (bool) => {
		if (bool) {
			document.body.classList.add('left-menu-visible');
			document.body.classList.remove('left-menu-hidden');
		} else {
			document.body.classList.remove('left-menu-visible');
			document.body.classList.add('left-menu-hidden');
		}

		if (isMobile) {
			dispatchSetLockedView('sideMenuProvider', bool);
		}

		lsSet(LocalStorageKeys.SIDE_MENU_IS_OPEN, bool);

		dispatchToggleSideMenu(bool);
	};

	useEffect(() => {
		update();
	}, [isOpen, update]);

	const closeOnMobile = useCallback(() => {
		if (isMobile) {
			toggleSideMenu_(false);
		}
	}, [isMobile]);

	return {
		isOpen,
		setIsOpen: toggleSideMenu_,
		closeOnMobile,
	};
};
