import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { toggleChatVisibilityBodyClass } from '../../methods/toggleChatVisibilityBodyClass';
import { checkChatDisabled } from '../../methods/checkChatDisabled';

let initialState = !!lsGet(LocalStorageKeys.CHAT_VISIBLE);
if (process.browser) {
	if (checkChatDisabled(document.location.pathname)) {
		initialState = false;
	} else {
		toggleChatVisibilityBodyClass(initialState);
	}
}

const NAME = 'visible';

export type ValueType = boolean;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<{
		isVisible: ValueType;
		noLs?: boolean;
	}>
> = (state, action) => {
	return action.payload.isVisible;
};

export const visibleSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setVisible: setAction,
	},
});
