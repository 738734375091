import React, { FC, PropsWithChildren } from 'react';
import { Header } from '@modules/header/Header';
import { ChristmasTheme } from '@legacyApp/client/components/christmasTheme';
import SideMenu from '@modules/SideMenu';
import { Footer } from '../footer/Footer';
import { EnhancedNextComponentPageProps } from '../../hoc/pageEnhancer';
import { useAppIsViewLocked } from '../../selectors/app.selectors';
import { ROUTING_ID } from '../../constants/routing/ids.routing.constants';
import { StyledMain } from './Main.styled';
import { StyledDefaultGlobal } from './Default.global.styled';
import { StyledPagesGlobal } from './Pages.global.styled';
import { StyledChatGlobal } from './Chat.global.styled';
import { AsyncModules } from './AsyncModules';
import { useApp } from './useApp';
import { AppContext } from './AppContext';

const Footer_ = () => {
	const isViewLocked = useAppIsViewLocked();
	return isViewLocked ? null : <Footer />;
};

export const App: FC<PropsWithChildren<EnhancedNextComponentPageProps>> = ({
	children,
	props,
	id,
}) => {
	const className = useApp({
		props,
		id,
	});
	return (
		<AppContext.Provider value={{ props, id }}>
			<div className={className}>
				<StyledDefaultGlobal />
				<StyledPagesGlobal />
				<StyledChatGlobal />
				{props?.noHeader ? null : <Header />}
				<StyledMain>
					<ChristmasTheme />
					{children}
				</StyledMain>
				{props?.noFooter ? null : <Footer_ />}
				<AsyncModules id={id || ROUTING_ID.HOME} props={props} />
				{props?.noHeader ? null : <SideMenu />}
			</div>
		</AppContext.Provider>
	);
};
