import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '@common/methods/isFunction';
import { Button } from '../../button';
import { enhanceComponent } from '../../../wrappers/enhanceComponent';
import { Loader } from '../loader';
import { LoadingLogo } from '../../loadingLogo/loading';
import { trans } from '../../../modules/translation/translate';
import { StyledLoadingComponent } from './LoadingComponent.styled';

class LoadingComponent extends React.PureComponent {
	static propTypes = {
		data: PropTypes.any,
		id: PropTypes.any,
		group: PropTypes.any, // string, boolean
		loadingGroup: PropTypes.func,
		loadedGroup: PropTypes.func,
		setGroupData: PropTypes.func,
	};

	constructor(props) {
		super(props);
		// console.log('loadingComponent', props.id, props.group);
		this.toggleGroup(true, props);
		this.interval = null;
	}

	componentDidMount() {
		this.setRetryInterval();
	}

	componentDidUpdate(prevProps) {
		const isErrorChanged = this.props.data.error !== prevProps.data.error;
		const isTimeoutChanged =
			this.props.data.timedOut !== prevProps.data.timedOut;
		const isRetryChanged = this.props.data.retry !== prevProps.data.retry;

		if (isErrorChanged && this.props.data.error) {
			console.error(this.props.data.error);
		}

		if (isRetryChanged) {
			this.setRetryInterval();
		}

		if (
			(isErrorChanged || isTimeoutChanged || isRetryChanged) &&
			this.props.group
		) {
			this.props.setGroupData({
				group: this.props.group,
				element: this.props.id,
				data: this.props.data,
			});
		}
	}

	setRetryInterval() {
		if (isFunction(this.props?.data?.retry)) {
			if (this.interval) {
				clearInterval(this.interval);
			}
			this.props.data.retry();
			this.interval = setTimeout(() => {
				console.log('retry - update');
				if (isFunction(this.props?.data?.retry)) {
					this.props.data.retry();
				}
			}, 5000);
		}
	}

	toggleGroup = (bool, props = this.props) => {
		if (!props.group || !props.id) {
			return;
		}
		const func = bool ? props.loadingGroup : props.loadedGroup;
		const data = {
			group: props.group,
			element: props.id,
		};
		func(data);
	};

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
		this.toggleGroup(false, this.props);
	}

	retry = () => {
		// sentryError(new Error(`Retry loading component - ${this.props.id}`), this.props);
		this.props.data.retry();
	};

	render() {
		if (
			!this.props.data.error &&
			!this.props.data.timedOut &&
			!this.props.data.pastDelay
		) {
			return null;
		}
		const additional =
			this.props.data.error || this.props.data.timedOut ? (
				<div>
					<p>
						{this.props.data.error
							? trans({ label: 'Error during loading page element' })
							: this.props.data.timedOut
							? trans({ label: 'Taking a long time to load page element...' })
							: ''}{' '}
						({this.props.id})
					</p>
					<Button
						name={`retry-component-${this.props.id}`}
						onClick={this.retry}
					>
						{trans({ label: 'Retry' })}
					</Button>
				</div>
			) : null;
		return (
			<StyledLoadingComponent data-component={this.props.id}>
				<Loader
					loader={<LoadingLogo />}
					activeOnClick={!!additional}
					active={true}
					additional={additional}
				/>
			</StyledLoadingComponent>
		);
	}
}

LoadingComponent = enhanceComponent({
	LoadingComponent,
});

export { LoadingComponent };
