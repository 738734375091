import { useCallback, useEffect, useRef } from 'react';
import { isFunction } from '@common/methods/isFunction';
import { usePrevious } from '../render/usePrevious';

type CallbackResult = void | any | Promise<any>;

export const useInterval = <T = CallbackResult>({
	interval,
	callback,
	id,
}: {
	interval: number;
	callback: () => T;
	id: string;
}): void => {
	const intervalHandler = useRef(null);
	const isLoading = useRef(false);
	const prevId = usePrevious(id);
	const prevInterval = usePrevious(interval);

	const get = useCallback(() => {
		if (!callback) {
			return;
		}
		isLoading.current = true;
		const result = callback();
		if (!result) {
			return;
		}
		// @ts-expect-error invalid type
		if (isFunction(result?.then)) {
			// @ts-expect-error invalid type
			result.then((result) => {
				isLoading.current = false;
				return result;
			});
		} else {
			isLoading.current = false;
		}
	}, [callback]);

	const setInterval_ = useCallback(() => {
		if (prevId === id && prevInterval === interval && intervalHandler.current) {
			return;
		}
		if (intervalHandler.current) {
			clearInterval(intervalHandler.current);
			intervalHandler.current = null;
		}
		if (!interval && !!prevInterval) {
			return;
		}
		get();
		if (interval) {
			intervalHandler.current = setInterval(() => {
				get();
			}, interval);
		}
	}, [get, id, interval, prevId, prevInterval]);

	const clearInterval_ = useCallback(() => {
		if (intervalHandler.current) {
			clearInterval(intervalHandler.current);
			intervalHandler.current = null;
		}
	}, []);

	useEffect(() => {
		setInterval_();
		return clearInterval_;
	}, [clearInterval_, setInterval_]);
};
