import React, { FC, useCallback } from 'react';
import { Button, CSSClassNameButton, CSSClassNameIcon } from '@ui/button';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { useAppMobile } from '@common/selectors/app.selectors';
import { config } from '@legacyApp/client/config';
import routingService from '@legacyApp/client/modules/app/routingService';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { NOTIFICATION_ID } from '@legacyApp/client/store/notification/notification.constants';
import { openModal } from '@modules/modals/store/modal.actions';
import { useSideMenu } from '@modules/SideMenu/hooks/useSideMenu';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { Size } from '@uiTypes/Size';
import { LangPicker } from '@common/components/langPicker/LangPicker';
import { ClickOutsideWrapper } from '@legacyApp/client/components/clickOutSideWrapper';
import { socialConfig } from '@server/config/social.config';
import { InstallContainer } from '@legacyApp/client/components/install';
import { NotificationIcon } from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import { SupportButton } from '@modules/support/components/SupportButton';
import { VipIcon } from '@ui/vip-icon';
import { SideMenuList, SideMenuListElement } from './components/SideMenuList';
import { SideMenuQuickAccess } from './components/SideMenuQuickAccess';
import { SideMenuNavigation } from './components/SideMenuNavigation';

const StyledVipClub = styled.span`
	.${CSSClassNameButton} {
		padding-left: 16px;
		width: 100%;

		.${CSSClassNameIcon} {
			margin-right: 8px !important;
		}
	}
`;

const FIRST_LIST = [
	{
		id: 'vip',
		href: routingService.to(ROUTING_ID.USER_VIP),
		label: (
			<>
				<StyledVipClub>
					<Button
						styleType={ButtonStyleType.SECONDARY}
						size={Size.LARGE}
						label={trans({ label: 'VIP Club' })}
						iconBefore={<VipIcon level={16} size={15} />}
					>
						<NotificationIcon type={ROUTING_ID.USER_VIP} />
					</Button>
				</StyledVipClub>
			</>
		),
	},
	{
		id: 'ranking',
		href: routingService.to(ROUTING_ID.RANKING),
		label: trans({ label: 'Ranking' }),
	},
	{
		id: 'promotions',
		href: routingService.to(ROUTING_ID.PROMOTIONS),
		label: trans({ label: 'Promotions' }),
	},
	{
		id: 'affiliate',
		href: routingService.to(ROUTING_ID.USER_AFFILIATE),
		label: trans({ label: 'Affiliate' }),
	},
];

const SECOND_LIST = (dispatch): Array<SideMenuListElement> => {
	return [
		{
			id: 'provablyfair',
			href: routingService.to(ROUTING_ID.PROVABLY_FAIR),
			label: trans({ label: 'Provably Fair' }),
		},
		{
			id: 'newfeatures',
			onClick: () => dispatch(openModal(MODAL_ID.NEW_FEATURES, 'SideMenu')),
			label: (
				<>
					<NotificationIcon notification={NOTIFICATION_ID.NEW_FEATURES} />
					{trans({ label: 'New Features' })}
				</>
			),
		},
		{
			id: 'api',
			href: config.documentationUrl,
			label: trans({ label: 'API' }),
		},
	];
};

const THIRD_LIST: Array<SideMenuListElement> = [
	{
		id: 'twitter',
		href: socialConfig.twitter,
		label: 'X/Twitter',
	},
	{
		id: 'facebook',
		href: socialConfig.facebook,
		label: 'Facebook',
	},
	{
		id: 'instagram',
		href: socialConfig.instagram,
		label: 'Instagram',
	},
	{
		id: 'telegram',
		href: socialConfig.telegram,
		label: 'Telegram',
	},
	{
		id: 'discord',
		href: socialConfig.discord,
		label: 'Discord',
	},
	{
		id: 'bitcointalk',
		href: socialConfig.bitcointalk,
		label: 'Bitcointalk',
	},
];

const SideMenu: FC = () => {
	const { isOpen, setIsOpen } = useSideMenu();

	const isMobile = useAppMobile();

	const dispatch = useDispatch();

	const onClickOutside = useCallback(() => {
		if (isMobile) {
			setIsOpen(false);
		}
	}, [isMobile, setIsOpen]);

	return (
		<ClickOutsideWrapper onClickOutside={onClickOutside}>
			<SideMenuStyled $isOpen={isOpen} className="overflow-scrollbar--vertical">
				<SideMenuNavigation />

				<SideMenuQuickAccess />

				<SideMenuList list={FIRST_LIST} />

				<SideMenuSpacer />

				<SideMenuList list={SECOND_LIST(dispatch)} />

				<SideMenuSupportButton>
					<SupportButton size={Size.LARGE} />
				</SideMenuSupportButton>

				<SideMenuList list={THIRD_LIST} className="side-menu-list-dark" />

				<LangPicker />

				<InstallContainer />
			</SideMenuStyled>
		</ClickOutsideWrapper>
	);
};

const SideMenuSupportButton = styled.div``;

const SideMenuStyled = styled.div<{
	$isOpen: Boolean;
}>`
	background-color: var(--color-dark-900);
	height: 100%;
	left: 0;
	overflow: auto;
	padding: 18px;
	position: fixed;
	top: 0;
	transform: translateX(
		${(props) =>
			props.$isOpen ? '0' : css`-${props.theme.layout.chat.leftMenuWidth}`}
	);
	transition: 100ms;
	width: ${(props) => props.theme.layout.chat.leftMenuWidth};
	z-index: 9999;

	> * {
		margin-bottom: 24px;
	}

	.side-menu-list-dark {
		* {
			color: var(--color-dark-200);
		}
	}

	${SideMenuSupportButton} {
		> .${CSSClassNameButton} {
			padding-left: 16px;
			width: 100%;

			svg {
				margin-right: 8px !important;
			}
		}
	}

	@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
		width: ${(props) => props.theme.layout.chat.leftMenuWidth};
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		z-index: 9999;
		transform: translateX(${(props) => (props.$isOpen ? '0' : '-100%')});
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthMobile}) {
		width: 100%;
	}
`;

const SideMenuSpacer = styled.div`
	background-color: var(--color-dark-500);
	height: 1px;
	width: 100%;
`;

export default SideMenu;
