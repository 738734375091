import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Support: FC<IconProps> = ({ fill }) => {
	return (
		<Svg
			content={
				<path
					d="M14.0938 8.50021H12.0938C11.8285 8.50021 11.5742 8.60557 11.3866 8.7931C11.1991 8.98064 11.0938 9.23499 11.0938 9.50021V12.0002C11.0938 12.2654 11.1991 12.5198 11.3866 12.7073C11.5742 12.8949 11.8285 13.0002 12.0938 13.0002H13.0938C13.359 13.0002 13.6133 12.8949 13.8009 12.7073C13.9884 12.5198 14.0938 12.2654 14.0938 12.0002V8.50021ZM14.0938 8.50021C14.0938 7.70805 13.9369 6.92373 13.6323 6.1925C13.3276 5.46127 12.8812 4.7976 12.3188 4.23979C11.7563 3.68198 11.0889 3.24107 10.3552 2.9425C9.62147 2.64393 8.83588 2.49361 8.04375 2.50021C7.25214 2.49444 6.46722 2.64537 5.73421 2.94431C5.0012 3.24326 4.33459 3.6843 3.7728 4.24202C3.21101 4.79975 2.76513 5.46314 2.46087 6.19396C2.15661 6.92478 1.99998 7.70858 2 8.50021V12.0002C2 12.2654 2.10536 12.5198 2.29289 12.7073C2.48043 12.8949 2.73478 13.0002 3 13.0002H4C4.26522 13.0002 4.51957 12.8949 4.70711 12.7073C4.89464 12.5198 5 12.2654 5 12.0002V9.50021C5 9.23499 4.89464 8.98064 4.70711 8.7931C4.51957 8.60557 4.26522 8.50021 4 8.50021H2"
					stroke={fill}
					fill="transparent"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			}
			width={16}
			height={16}
		/>
	);
};
