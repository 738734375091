import { Loadable } from '../loader/loadable/loadable';
import { isFeatureAvailable } from '../../modules/app/featureService';
import { FEATURES_TYPE } from '../../config';

const ChristmasThemeLoadable = Loadable(
	{
		loader: () => import(/* webpackChunkName: "app" */ './christmasTheme'),
		ssr: false,
	},
	'./christmasTheme',
	undefined,
	true,
);

export const ChristmasTheme = () => {
	if (!isFeatureAvailable(FEATURES_TYPE.CHRISTMAS)) {
		return null;
	}
	return <ChristmasThemeLoadable />;
};
