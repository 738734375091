import styled from 'styled-components';
import { boxNoHeight } from '../../modules/style/defaults';

export const InstallStyle = styled.div.attrs(() => ({
	className: 'install-box',
}))`
	${boxNoHeight};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 10px;
	background-color: ${(props) => props.theme.colors.background};

	> div {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			padding-right: 10px;
		}
	}

	button {
		margin: 0;
		width: 100%;
	}
`;
