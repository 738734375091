import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { closeModal } from '@modules/modals/store/modal.actions';
import { faucetEvent } from '../gtm/gtm.actions';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { setFaucetDelayUntil, setFaucetRemaining } from './faucet.actions';
import { CLAIM_FAUCET } from './faucet.constants';

const claimEpic = epic('claimEpic', {
	actions: (ofType) => ofType(CLAIM_FAUCET),
	callback: ({ action, store$ }) => {
		const state = store$.value;
		const faucetError = action.error;

		if (faucetError) {
			return of([showAlert('error', faucetError)]);
		}
		return of(
			fetchApiAction(
				{
					url: '/user/faucet/claim',
					parameters: {
						Authorization: true,
						body: {
							currency: state.user.activeCurrency,
						},
					},
					method: 'POST',
					loaderId: 'claimFaucet',
					lockByModal: true,
				},
				(data) => {
					const result = [];
					if (data.amount) {
						result.push(
							faucetEvent({
								currency: data.currency,
								amount: data.amount,
							}),
							showAlert(
								'success',
								trans({
									label: 'Faucet claimed {{amount}} {{currency}}!',
									options: {
										amount: data.amount,
										currency: data.currency.toUpperCase(),
									},
								}),
							),
							closeModal(),
							setFaucetDelayUntil(data.delayUntil * 1000),
							setFaucetRemaining(data.faucetRemaining),
						);
					}
					return result;
				},
			),
		);
	},
});

const faucetEpic = combineEpics(claimEpic);

export { faucetEpic };
