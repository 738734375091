import { FC } from 'react';
import styled from 'styled-components';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { AwesomeIcon } from '../../icons/AwesomeIcon';

export const OnlineIcon: FC<{
	className?: string;
}> = ({ className }) => {
	return (
		<span>
			<StyledOnlineIcon className={className} icon={faCircle} />
		</span>
	);
};

export const StyledOnlineIcon = styled(AwesomeIcon)`
	color: ${(props) => props.theme.colors.positive};
	height: 8px;
	margin: 0 5px 2px 0 !important;
`;
