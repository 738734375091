import { FunctionComponent } from 'react';
import { StyledVipIcon, StyledVipIconLabel } from '@ui/vip-icon/VipIcon.styled';
import {
	VIP_LEVELS_COLORS,
	VipLevelType,
} from '@modules/vip/constants/vip.config';
import Icon from '../../../common/components/icons/Icon';

export interface VipIconProps {
	level: number;
	nonLevel?: boolean;
	size: number;
	noValue?: boolean;
}

export type VipIconType = FunctionComponent<VipIconProps>;

export const VipIcon: VipIconType = ({ level, nonLevel, size, noValue }) => {
	if (!level) {
		return nonLevel ? (
			<StyledVipIcon data-size={size} $size={size}>
				<Icon id="vip-levels/unranked" noColor />
			</StyledVipIcon>
		) : null;
	}

	const type: VipLevelType = VIP_LEVELS_COLORS[level];

	if (!type) {
		return null;
	}

	return (
		<StyledVipIcon data-size={size} $type={type} $size={size} $level={level}>
			{type !== VipLevelType.DIAMOND && !noValue && (
				<StyledVipIconLabel className="text-style-h-4">
					{level}
				</StyledVipIconLabel>
			)}
			<Icon id={`vip-levels/${type}-star`} noColor />
		</StyledVipIcon>
	);
};
