import React, { FC } from 'react';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { Trans } from '@legacyApp/client/modules/translation/translate';

import { VIP_CURRENCY } from '../constants/vip.config';
import { VipSectionProgress } from '../../header/HeaderMenu/VipSection';
import { useVipStatus } from '../store/selectors/vip.selectors';
import { useNextLevel } from '../hooks/useNextLevel';

export const VipLeftToNextLevel: FC = () => {
	const { nextLevel } = useNextLevel();
	const status = useVipStatus();

	if (!nextLevel) {
		return (
			<VipSectionProgress>
				<span className="text-style-xs-regular text-capitalize">
					<Trans label={'wagered'} />
				</span>{' '}
				<span className="text-style-xs-medium">
					{mathService.currencyValue(status, VIP_CURRENCY)}
				</span>
			</VipSectionProgress>
		);
	}

	return (
		<VipSectionProgress>
			<span className="text-style-xs-medium">
				{mathService.currencyValue(
					nextLevel.threshold - (status || 0),
					VIP_CURRENCY,
				)}{' '}
			</span>
			<span className="text-style-xs-regular">
				<Trans label={'left till next level'} />
			</span>
		</VipSectionProgress>
	);
};
