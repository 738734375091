import { TransactionsMetaType } from '@modules/transactions/constants/TransactionsResponse.type';
import { TableIdType } from '@modules/transactions/constants/TableId';
import { actionSimple } from '../../modules/app/actionService';
import {
	CLEAR_TRANSACTIONS,
	GET_ALL_BETS,
	GET_HIGH_ROLLS,
	GET_TRANSACTIONS,
	HANDLE_UPDATE_INTERVAL,
	HANDLE_WITHDRAWS,
	NEW_BET,
	NEW_DEPOSIT,
	NEW_DEPOSIT_DETECTED,
	NEW_HIGH_ROLL,
	NEW_MY_BET,
	NEW_RESULT,
	PENDING_TRANSACTIONS,
	RESET_PENDING_TRANSACTIONS,
	RESET_TRANSACTIONS,
	SET_ALL_BETS,
	SET_HIGH_ROLLS,
	SET_NEW_MY_BETS,
	TRANSACTIONS_ACTIVE,
	TRANSACTIONS_FULFILLED,
	TRANSACTIONS_GET_VAULT_HISTORY,
	TRANSACTIONS_SET,
	TRANSACTIONS_SET_META,
	TRANSACTIONS_UPDATE,
	TRANSACTIONS_UPDATING,
	WITHDRAW_CONFIRMED,
} from './transactions.constants';

export const newBet = (payload, notSaveToMyBets, reset) => {
	return {
		type: NEW_BET,
		payload,
		notSaveToMyBets,
		reset,
	};
};

export const newHighRoll = (payload, reset) => {
	return {
		type: NEW_HIGH_ROLL,
		payload,
		reset,
	};
};

export const newMyBet = (payload, limit, reset) => {
	return {
		type: NEW_MY_BET,
		payload,
		limit,
		reset,
	};
};

export const newDeposit = (payload) => {
	return {
		type: NEW_DEPOSIT,
		payload,
	};
};

export const newDepositDetected = (payload) => {
	return {
		type: NEW_DEPOSIT_DETECTED,
		payload,
	};
};

export const newResult = actionSimple(NEW_RESULT);

export const setAllBets = (payload, reset) => {
	return {
		type: SET_ALL_BETS,
		payload,
		reset,
	};
};

export const setHighRolls = (payload, reset) => {
	return {
		type: SET_HIGH_ROLLS,
		payload,
		reset,
	};
};

export const getAllBets = (page) => {
	return {
		type: GET_ALL_BETS,
		page,
	};
};

export const getHighRolls = (page) => {
	return {
		type: GET_HIGH_ROLLS,
		page,
	};
};

export const transactionsFulfilled = (id, bool) => {
	return {
		type: TRANSACTIONS_FULFILLED,
		id,
		bool,
	};
};

export const setNewMyBets = (payload, reset = undefined) => {
	return {
		type: SET_NEW_MY_BETS,
		payload,
		reset,
	};
};

export const resetTransactions = (id) => {
	return {
		type: RESET_TRANSACTIONS,
		id,
	};
};

export const transactionsUpdating = (id, bool) => {
	return {
		type: TRANSACTIONS_UPDATING,
		id,
		bool,
	};
};

export const getTransactions = (
	id,
	page,
	disable,
	refresh,
	props = undefined,
) => {
	return {
		type: GET_TRANSACTIONS,
		id,
		page,
		disable,
		refresh,
		props,
	};
};

export const clearTransactions = (id) => {
	return {
		type: CLEAR_TRANSACTIONS,
		id,
	};
};

export const transactionsActive = (id, bool) => {
	return {
		type: TRANSACTIONS_ACTIVE,
		id,
		bool,
	};
};

export const pendingTransactions = (id, data) => {
	return {
		type: PENDING_TRANSACTIONS,
		data,
		id,
	};
};

export const handleUpdateInterval = (id) => {
	return {
		type: HANDLE_UPDATE_INTERVAL,
		id,
	};
};

export const resetPendingTransactions = (id) => ({
	type: RESET_PENDING_TRANSACTIONS,
	id,
});

export const handleWithdraws = (page = undefined) => ({
	type: HANDLE_WITHDRAWS,
	page,
});

export const withdrawConfirmed = (payload) => ({
	type: WITHDRAW_CONFIRMED,
	payload,
});

export const transactionsSet = (id, payload, reset) => ({
	type: TRANSACTIONS_SET,
	payload,
	reset,
	id,
});

export const transactionsGetVaultHistory = (page, refresh) => ({
	type: TRANSACTIONS_GET_VAULT_HISTORY,
	page,
	refresh,
});

export const transactionsUpdate = (id, payload) => ({
	type: TRANSACTIONS_UPDATE,
	id,
	payload,
});

export const transactionsSetMeta = (
	id: TableIdType,
	payload: TransactionsMetaType,
) => ({
	type: TRANSACTIONS_SET_META,
	id,
	payload,
});
