import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';

import { trans } from '@legacyApp/client/modules/translation/translate';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';

import { StyledContent, StyledContentWrapper } from '@common/components/text/Content/Content.styled';
import { Button } from '@ui/button';

const Content = ({ title, content, namespace, options = {} }) => {
	const {
		noTitleTranslate,
		noParagraphTranslate,
		symbol,
		noTitleIndex,
		noParagraphIndex,
		shadowColor,
		toggle,
	} = options;

	const [open, setOpen] = useState(!toggle);

	return (
		<StyledContentWrapper $shadowColor={shadowColor} $open={open}>
			<h2 className="text-uppercase text-style-h-5">
				{noTitleTranslate ? title : trans({ label: title })}
			</h2>

			<StyledContent $shadowColor={shadowColor} $open={open}>
				{content.map((el, index) => {
					return (
						<section key={index}>
							{el.title && (
								<h2 className="icon-wrapper user-select-text text-style-md-semibold">
									{noTitleIndex ? '' : `${index + 1}. `}

									{trans({
										label: el.title,
										noTranslationCheck: noParagraphTranslate,
										namespace: namespace,
									})}
								</h2>
							)}

							{el.paragraphs &&
								el.paragraphs.map((paragraph, index) => {
									return (
										<div className="user-select-text" key={index}>
											{!noParagraphIndex && (
												<div className="paragraph-title text-bold user-select-text">
													{symbol}

													{index + 1}
												</div>
											)}

											{paragraph.length ? (
												paragraph.map((text) => {
													return (
														<p
															className={classnames(
																'paragraph-text user-select-text text-style-xs-regular',
																{
																	'no-side-padding': noParagraphIndex,
																},
															)}
															key={text.message || text}
														>
															{trans({
																label: text,
																noTranslationCheck: noParagraphTranslate,
																namespace: namespace,
															})}
														</p>
													);
												})
											) : (
												<Fragment key={paragraph.title.message || paragraph.title}>
													<p className="paragraph-text user-select-text text-style-xs-regular">
														{trans({
															label: paragraph.title,
															noTranslationCheck: noParagraphTranslate,
															namespace: namespace,
														})}
													</p>

													<ul className="paragraph-text user-select-text text-style-xs-regular">
														{paragraph.list.map((el) => {
															return (
																<li
																	key={el.message || el}
																	className="text-style-xs-regular"
																>
																	{trans({
																		label: el,
																		noTranslationCheck: noParagraphTranslate,
																		namespace: namespace,
																	})}
																</li>
															);
														})}
													</ul>

													{paragraph.subtitle ? (
														<p className="paragraph-text user-select-text text-style-xs-regular">
															{trans({
																label: paragraph.subtitle,
																noTranslationCheck: noParagraphTranslate,
																namespace: namespace,
															})}
														</p>
													) : null}
												</Fragment>
											)}
										</div>
									);
								})}
						</section>
					);
				})}

				{toggle && (
					<Button
						styleType={ButtonStyleType.SECONDARY}
						label={trans({ label: open ? 'Hide' : 'Open' })}
						onClick={() => setOpen(!open)}
					/>
				)}
			</StyledContent>
		</StyledContentWrapper>
	);
};

Content.propTypes = {
	title: PropTypes.any,
	options: PropTypes.object,
	content: PropTypes.array.isRequired,
	namespace: PropTypes.string,
};

export { Content };
