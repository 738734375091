import { of } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { isFunction } from '@common/methods/isFunction';
import { epic } from '../../modules/app/epicService';
import {
	clearConfirm,
	setConfirmCallbackCancelDispatch,
	setConfirmCallbackDispatch,
} from './confirm.actions';
import { CANCEL_CONFIRM, DO_CONFIRM, SET_CONFIRM } from './confirm.constants';

const doEpic = epic('doEpic', {
	actions: (ofType) => ofType(DO_CONFIRM),
	callback: ({ store$ }) => {
		const data = store$.value.confirm.data;

		if (isFunction(store$.value.confirm.callback)) {
			store$.value.confirm.callback(data);
		}

		return !store$.value.confirm.data?.doNotClearConfirm && of(clearConfirm());
	},
});

const cancelEpic = epic('cancelEpic', {
	actions: (ofType) => ofType(CANCEL_CONFIRM),
	callback: ({ store$ }) => {
		const data = store$.value.confirm.dataCancel;
		if (isFunction(store$.value.confirm.callbackCancel)) {
			store$.value.confirm.callbackCancel(data);
		}
		return of(clearConfirm());
	},
});

const callbackActionEpic = epic('callbackActionEpic', {
	actions: (ofType) => ofType(SET_CONFIRM),
	callback: ({ action }) => {
		const result = [];
		if (action.payload.callbackAction) {
			result.push(setConfirmCallbackDispatch(action.payload.callbackAction));
		}
		if (action.payload.callbackCancelAction) {
			result.push(
				setConfirmCallbackCancelDispatch(action.payload.callbackCancelAction),
			);
		}
		return of(result);
	},
});

const confirmEpic = combineEpics(doEpic, cancelEpic, callbackActionEpic);

export { confirmEpic };
