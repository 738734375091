import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { closeModal } from '@modules/modals/store/modal.actions';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { SUBMIT_TIP } from './tip.constants';

const submitTipEpic = epic('submitTipEpic', {
	actions: (ofType) => ofType(SUBMIT_TIP),
	callback: ({ action, store$ }) => {
		return of(
			fetchApiAction(
				{
					url: '/v2/tip/send',
					parameters: {
						Authorization: true,
						body: {
							amount: action.payload.amount,
							currency: action.payload.currency,
							user: action.payload.user,
							// TODO: chat store usage
							room: store$.value?.chat?.room,
						},
					},
					method: 'POST',
					loaderId: 'submitTip',
					lockByModal: true,
				},
				(data) => {
					if (data.message) {
						return [closeModal()];
					}
					return [];
				},
			),
		);
	},
});

const tipEpic = combineEpics(submitTipEpic);

export { tipEpic };
