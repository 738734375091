import { FC } from 'react';
import styled from 'styled-components';
import { Image, parseImageSrc } from '../default/Image/Image';

const parseSymbol = (symbol: string): string => {
	if (symbol.toLowerCase() === 'en') {
		return 'gb-eng';
	}
	if (symbol.toLowerCase() === 'wa') {
		return 'gb-wls';
	}
	if (symbol.toLowerCase() === 'sx') {
		return 'gb-sct';
	}
	if (symbol.toLowerCase() === 'nx') {
		return 'gb-nir';
	}
	if (symbol.toLowerCase() === 'ar') {
		return 'sd';
	}
	return symbol;
};

export const FlagIcon: FC<{
	symbol: string;
	scale?: string;
	withWrapper?: boolean;
}> = ({ symbol, scale = '3x2', withWrapper }) => {
	if (!symbol) {
		return null;
	}
	if (symbol === 'admin') {
		return null;
	}
	const src = parseImageSrc(
		`/img/flags/${scale}/${parseSymbol(symbol).toUpperCase()}.svg`,
	);
	const img = <Image src={src} alt={symbol} />;
	return withWrapper ? <FlagIconWrapper $src={src} $scale={scale} /> : img;
};

const getScaleHeight = (scale, width) => {
	const [widthScale, heightScale] = scale.split('x');
	return (width / widthScale) * heightScale;
};

export const FlagIconWrapper = styled.div<{
	$src?: string;
	$scale: string;
}>`
	height: 100%;
	width: auto;

	&:before {
		background-image: url(${(props) => props.$src});
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		display: block;
		height: ${(props) => getScaleHeight(props.$scale, 25)}px;
		width: 25px;
	}
`;
