import React, { FC, ReactElement } from 'react';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { enhanceComponent } from '../../wrappers/enhanceComponent';
import { useAppSelector } from '../../../hooks/store/useAppSelector';
import { StyledLoader } from './Loader.styled';

const checkActive = ({
	modules,
	module,
}: {
	modules: Record<string, boolean>;
	module?: string | string[];
}): boolean => {
	if (!Object.keys(modules).length) {
		return false;
	}
	module = module ?? 'app';
	if (typeof module === 'string') {
		return modules[module];
	}
	if (Array.isArray(module) && module.length) {
		return !!module.find((el) => !!modules[el]);
	}
	return false;
};

export interface LoaderProps {
	module?: string | string[];
	message?: ReactElement;
	children?: ReactElement;
	active?: boolean;
	additional?: ReactElement;
	activeOnClick?: boolean;
	loader?: ReactElement;
	absolutePosition?: boolean;
}

let Loader: FC<LoaderProps> = ({
	module,
	message,
	children,
	active,
	additional,
	activeOnClick,
	loader,
	absolutePosition,
}) => {
	const { modules } = useAppSelector((state) => {
		return {
			modules: state.loader.modules,
		};
	});

	const isActive =
		active !== undefined ? active : checkActive({ modules, module });
	return (
		<>
			<StyledLoader
				$absolutePosition={absolutePosition}
				$active={isActive}
				$pointerEvents={activeOnClick}
			>
				{loader || <AwesomeIcon icon={faSyncAlt} />}
				{additional}
			</StyledLoader>
			{!isActive && message}
			{!isActive && children}
		</>
	);
};

Loader = enhanceComponent({
	Loader,
});

export { Loader };
