import { currenciesSettings } from '@common/controllers/currency/currenciesSettings';
import { CurrencyType } from '../../types/wallet/CurrencyType';

export const getLimits = ({
	currency,
	game,
}: {
	currency?: CurrencyType;
	game?: string;
}) => {
	if (!currency) {
		return {};
	}
	const settings = currenciesSettings.getSettings(currency);
	if (!settings) {
		return {};
	}
	return settings[game] || settings.casino || {};
};
