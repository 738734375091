export const DO_VALIDATE_BET_AMOUNT = 'DO_VALIDATE_BET_AMOUNT';
export const DO_VALIDATE_BET_AMOUNT_AFTER_CHECK_BALANCE =
	'DO_VALIDATE_BET_AMOUNT_AFTER_CHECK_BALANCE';
export const DO_VALIDATE_WIN_CHANCE = 'DO_VALIDATE_WIN_CHANCE';
export const DO_VALIDATE_PAYOUT = 'DO_VALIDATE_PAYOUT';
export const GAME_UPDATE_FORM_ERROR = 'GAME_UPDATE_FORM_ERROR';
export const GAME_UPDATE_AMOUNT_ERROR = 'GAME_UPDATE_AMOUNT_ERROR';
export const GAME_UPDATE_PAYOUT_ERROR = 'GAME_UPDATE_PAYOUT_ERROR';
export const GAME_UPDATE_WIN_CHANCE_ERROR = 'GAME_UPDATE_WIN_CHANCE_ERROR';
export const GAME_UPDATE_PROFIT_ERROR = 'GAME_UPDATE_PROFIT_ERROR';
export const CHECK_GAME_FORM = 'CHECK_GAME_FORM';
