import { FC } from 'react';
import NextNprogress from 'nextjs-progressbar';
import { theme } from '@legacyApp/client/modules/style/theme';

export const PageLoadingProgressBar: FC = () => {
	return (
		<NextNprogress
			color={theme.colors.active}
			startPosition={0.3}
			stopDelayMs={200}
			height={3}
			showOnShallow={true}
			options={{ showSpinner: false }}
		/>
	);
};
