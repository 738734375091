import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppMobile } from '@common/selectors/app.selectors';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { useSportsSideContent } from '../../../sports/store/selectors/sportsBetSlip.selectors';
import { chatActions } from '../../store/chat.actions';
import { sportsSetSideContent } from '../../../sports/store/sports/sports.thunk';
import { useSideMenu } from '../../../SideMenu/hooks/useSideMenu';
import { useChatVisible } from '../../store/selectors/useChatVisible';
import { useChatDisabled } from '../../store/selectors/useChatDisabled';
import ChatStoreController from '../../store/ChatStoreController';

export const ChatToggleWrapper: FC<
	PropsWithChildren<{
		disabled?: boolean;
		className?: string;
		isContentType?: string;
	}>
> = ({ children, disabled, className, isContentType }) => {
	const dispatch = useDispatch();

	const isMobile = useAppMobile();
	const disabled_ = useChatDisabled();
	const contentType = useSportsSideContent();
	const isChatOpen = useChatVisible();

	const { isOpen: isSideMenuOpen, setIsOpen: setIsSideMenuOpen } =
		useSideMenu();
	const prevIsSideMenuOpen = usePrevious(isSideMenuOpen);

	const toggleChat = useCallback(
		(bool: boolean) => {
			if (isSideMenuOpen && !!bool) {
				setIsSideMenuOpen(false);
			}
			dispatch(chatActions.setVisible({ isVisible: bool }));
		},
		[dispatch, isSideMenuOpen, setIsSideMenuOpen],
	);
	const setContentType = useCallback(
		(type) => dispatch(sportsSetSideContent(type || false)),
		[dispatch],
	);

	const onClick = useCallback(() => {
		if (!isMobile) {
			return toggleChat(!isChatOpen);
		}
		if (isContentType && isContentType === contentType) {
			if (isChatOpen) {
				setContentType(false);
				toggleChat(false);
			} else {
				toggleChat(true);
			}
			return;
		}
		if (isContentType) {
			setContentType(isContentType);
			if (!isChatOpen) {
				toggleChat(true);
			}
			return;
		}
		if (isChatOpen) {
			if (contentType) {
				setContentType(false);
			} else {
				toggleChat(false);
			}
			return;
		}
		if (contentType) {
			setContentType(false);
		}
		toggleChat(true);
	}, [
		contentType,
		isContentType,
		isMobile,
		setContentType,
		toggleChat,
		isChatOpen,
	]);

	useEffect(() => {
		if (isSideMenuOpen && !prevIsSideMenuOpen) {
			toggleChat(false);
		}
	}, [isSideMenuOpen, prevIsSideMenuOpen, toggleChat]);

	if (disabled || disabled_) {
		return null;
	}

	return (
		<>
			<ChatStoreController />
			<div onClick={onClick} className={className || 'box-no-height'}>
				{children}
			</div>
		</>
	);
};
