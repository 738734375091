import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';
import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { bindSocketChannelEventThunk } from '@legacyApp/client/store/websocket/thunks/bindSocketChannelEvent.thunk';
import {
	WEBSOCKET_CHANNELS,
	WEBSOCKET_EVENTS,
} from '@legacyApp/client/store/websocket/websocket.constants';
import { unbindStreams } from '../chat.constants';
import { chatActions } from '../chat.actions';
import websocketService from '../../../../LegacyApp/client/modules/app/websocketService';
import sortService, {
	DIRECTION,
} from '../../../../LegacyApp/client/modules/app/sortService';
import { getUsersList } from '../../methods/getUsersList';

const bindChatStream = (room) => {
	if (unbindStreams.is(room)) {
		unbindStreams.reset(room);
		return false;
	}
	return true;
};

export const getRoomStreamThunk = (
	noCache?: boolean,
): AppThunk<Promise<void>> => {
	return async (dispatch, getState) => {
		const { chat } = getState();
		const room = chat?.room;
		if (!room) {
			return;
		}
		const data = await dispatch(
			fetchApi({
				url: `/chat/messages?room=${room}${
					noCache ? `&t=${getTimestamp()}` : ''
				}`,
				loaderId: 'getRoomStream',
			}),
		);
		if (bindChatStream(room)) {
			dispatch(
				bindSocketChannelEventThunk(
					`${WEBSOCKET_CHANNELS.CHAT}.${room}`,
					WEBSOCKET_EVENTS.MESSAGE,
					(data, dispatch) => {
						// console.log('message', {data});
						dispatch(
							chatActions.updateStream({
								room: room,
								data: websocketService.getMessage(data),
							}),
						);
					},
				),
			);
		}
		if (data.messages) {
			dispatch(
				chatActions.setStream({
					room,
					list: data.messages.sort((a, b) => {
						const aTimestamp = a.published_at
							? a.published_at
							: getTimestamp(a.created_at);
						const bTimestamp = b.published_at
							? b.published_at
							: getTimestamp(b.created_at);
						return sortService.sort(
							DIRECTION.ASC,
							aTimestamp,
							bTimestamp,
							a.nonce,
							b.nonce,
						);
					}),
				}),
			);
			dispatch(chatActions.setUsers(getUsersList(data.messages)));
		}
	};
};
