import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { openModal } from 'src/modules/modals/store/modal.actions';
import { MODAL_ID } from 'src/common/constants/modal/ids.modal.constants';
import { supportActions } from '@modules/support/store/support.actions';
import { epic } from '../../../LegacyApp/client/modules/app/epicService';
import { APP_INITIALIZED } from '../../../LegacyApp/client/store/app/app.constants';
import { supportInit } from './thunks/supportInit.thunk';

const errorEpic = epic('errorEpic', {
	actions: (ofType) => ofType(supportActions.setOpen().type),
	callback: ({ action, store$ }) => {
		if (!action.payload || !store$.value.support.error) {
			return of();
		}
		return of(openModal(MODAL_ID.LIVE_SUPPORT_ERROR, 'supportErrorEpic'));
	},
});

const initEpic = epic('initEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: () => {
		return of(supportInit());
	},
});

const supportEpic = combineEpics(errorEpic, initEpic);

export { supportEpic };
