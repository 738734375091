import { VipLevel } from './types/vip.types';

export const DEFAULT_LEVEL: VipLevel = {
	level: 0,
	id: 0,
	threshold: 0,
	commission: null,
	rankBonus: null,
	dailyBonus: null,
	weeklyBonus: null,
	insaneBonus: null,
};
