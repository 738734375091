import { FC } from 'react';
import Icon from '@common/components/icons/Icon';
import {
	CurrencyNetworkType,
	CurrencyTokenType,
	CurrencyType,
} from '@legacyApp/types/wallet/CurrencyType';
import { mapApiTokenToLayoutToken } from '@common/controllers/currency/currenciesSettings';

import styles from '@legacyApp/client/components/currencySymbol/tokenSymbol.module.scss';

export const TokenSymbol: FC<{
	token: CurrencyType | CurrencyTokenType | CurrencyNetworkType;
	classes?: string;
	stylesForWrapper?: Record<string, string | number>;
}> = ({ token, classes, stylesForWrapper }) => {
	if (!token) {
		return null;
	}

	const token_ = mapApiTokenToLayoutToken(token);

	return (
		<div
			className={`currency-logo ${styles.currencyLogo}`}
			data-token={token_}
			style={stylesForWrapper}
		>
			<Icon
				data-name={token_}
				className={classes}
				id={`currencies/${token_}`}
			/>
		</div>
	);
};

export const CSSClassNameCurrencyLogo = 'currency-logo';
