import { useCallback } from 'react';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { isLevelEqual, useVipLevel } from '../store/selectors/vip.selectors';
import { useVip } from './useVip';

export const useNextLevel = () => {
	const { levels } = useVip();
	const level = useVipLevel();
	const getNextLevel = useCallback(() => {
		if (levels?.length && isTrueOrZero(level?.level)) {
			return levels[level?.level];
		}
		return null;
	}, [level?.level, levels]);
	const nextLevel = useStateEffect(getNextLevel, (a, b) => !isLevelEqual(a, b));
	return {
		nextLevel,
		levels,
	};
};
