import React, { FC } from 'react';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { useViewport } from '@legacyApp/hooks/render/useViewport';
import { useOnMount } from '@common/hooks/useOnMount';
import { fetchScript } from '../../modules/app/fetchScript';

const ID = '49f32fc2-fd6a-44b6-9b4d-f8e866675940';
const URL = `https://${ID}.snippet.anjouangaming.org/anj-seal.js`;

const getLicense = () => {
	fetchScript(URL, { noCache: true });
};

const EmLicense_: FC = () => {
	useOnMount(() => {
		getLicense();
	});

	return (
		<div
			id={`anj-${ID}`}
			data-anj-seal-id={ID}
			data-anj-image-size="128"
			data-anj-image-type="basic-small"
		/>
	);
};

const EmLicence__ = () => {
	const { active, ref } = useViewport({ rootMargin: '100px' });
	return <div ref={ref}>{active && <EmLicense_ />}</div>;
};

export const EmLicense = () => {
	if (!NEXT_CONFIG.PRODUCTION || !process.browser) {
		return null;
	}
	return <EmLicence__ />;
};
