export const OPTIONS_ENABLE = 'OPTIONS_ENABLE';
export const OPTIONS_DISABLE = 'OPTIONS_DISABLE';

export const OPTIONS = {
	SOUND: 'sound',
	ANIMATION: 'animation',
	AUTOBET_SUMMARY: 'autobetSummary',
	MAX_BET: 'maxBet',
	CHART: 'chart',
	HOTKEYS: 'hotkeys',
	AUTOBET_CHART: 'autobetChart',
	TIP: 'tip',
	HIGH_SPEED: 'highSpeed',
	HIDE_ZERO_BALANCES: 'hideZeroBalances',
} as const;

type Options = keyof typeof OPTIONS;
export type OptionsType = (typeof OPTIONS)[Options];
