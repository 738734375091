export class pendingActionsClass {
	constructor() {
		this.data = {};
	}

	setTimeout(id, callback, timeout) {
		// console.log('setTimeoutPending', id, this);
		this.data[id] = setTimeout(() => {
			// console.log('callbackPending', id, this);
			callback();
		}, timeout);
	}

	is(id) {
		// console.log('isPending', id, !!this.data[id], this);
		return !!this.data[id];
	}

	reset(id) {
		if (this.data[id]) {
			// console.log('resetPending', id, this);
			clearTimeout(this.data[id]);
			delete this.data[id];
		}
	}
}
