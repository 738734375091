export enum PackageType {
	SEED = 'SEED',
	GAME = 'GAME',
	SPORTS = 'SPORTS',
	SLOTS = 'SLOTS',
	RACE = 'RACE',
	PERFORMANCE = 'PERFORMANCE',
	MODAL = 'MODAL',
	CAPTCHA = 'CAPTCHA',
	CHAT = 'CHAT',
}

export const REGISTRY_REDUCER_INJECT = 'REGISTRY_REDUCER_INJECT';
export const REGISTRY_EPIC_INJECT = 'REGISTRY_EPIC_INJECT';
export const REGISTRY_REDUCER_REJECT = 'REGISTRY_REDUCER_REJECT';
export const REGISTRY_EPIC_REJECT = 'REGISTRY_EPIC_REJECT';
export const REGISTRY_REDUCER_INJECTED = 'REGISTRY_REDUCER_INJECTED';
export const REGISTRY_EPIC_INJECTED = 'REGISTRY_EPIC_INJECTED';
export const REGISTRY_REDUCER_REJECTED = 'REGISTRY_REDUCER_REJECTED';
export const REGISTRY_EPIC_REJECTED = 'REGISTRY_EPIC_REJECTED';
export const REGISTRY_PACKAGE_INJECTED = (type: PackageType) =>
	`REGISTRY_${type}_INJECTED`;
export const REGISTRY_PACKAGE_REJECTED = (type: PackageType) =>
	`REGISTRY_${type}_REJECTED`;
export const REGISTRY_GAME_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.GAME,
);
export const REGISTRY_SEED_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.SEED,
);
export const REGISTRY_RACE_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.RACE,
);
export const REGISTRY_PERFORMANCE_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.PERFORMANCE,
);
export const REGISTRY_MODAL_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.MODAL,
);
export const REGISTRY_CAPTCHA_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.CAPTCHA,
);
export const REGISTRY_CHAT_PACKAGE = REGISTRY_PACKAGE_INJECTED(
	PackageType.CHAT,
);
export const REGISTRY_PACKAGE_REGISTER = 'REGISTRY_PACKAGE_REGISTER';
