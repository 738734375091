import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../../hooks/useId';

export interface IconProps {
	fill?: string;
	fillStop?: string;
	width?: number;
	height?: number;
	active?: boolean;
}

export const RankTrophy = ({ color_1, color_2, color_3, color_4 }) => {
	const { get } = useId({ id: 'rank_trophy' });
	const color_1_ = color_1 || 'var(--color-dark-400)';
	const color_2_ = color_2 || 'var(--color-dark-400)';
	const color_3_ = color_3 || 'var(--color-dark-400)';
	const color_4_ = color_4 || 'var(--color-dark-400)';
	return Svg({
		content: (
			<>
				<path
					d="M9.79386 5.66809L9.17392 5.63216C9.13749 5.63216 9.10142 5.63216 9.10142 5.66809L8.65703 6.10353L8.73928 6.13693L9.35499 6.17285C9.35691 6.17285 9.35872 6.17285 9.36045 6.17285C9.37252 6.17281 9.38013 6.17251 9.38699 6.17009C9.39758 6.16635 9.4064 6.15757 9.42713 6.13693L9.82534 5.70437L9.79386 5.66809Z"
					fill={get(1).url}
				/>
				<path
					d="M6.66471 5.66083C6.69717 5.66083 6.72931 5.66083 6.72931 5.69297L7.18087 6.11239H7.05199L6.50337 6.14453C6.50167 6.14453 6.50005 6.14453 6.49851 6.14453C6.48777 6.14449 6.48099 6.14422 6.47488 6.14206C6.46661 6.13914 6.45957 6.13277 6.44556 6.11884C6.44556 6.11884 6.44556 6.11884 6.44556 6.11884C6.44356 6.11685 6.44141 6.11471 6.4391 6.1124L6.08427 5.72543L6.01967 5.66083H6.66471Z"
					fill={get(1).url}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 3.44444C16 3.075 15.7028 2.77778 15.3333 2.77778H12.4444V1.66667C12.4444 1.29722 12.1472 1 11.7778 1H4.22222C3.85278 1 3.55556 1.29722 3.55556 1.66667V2.77778H0.666667C0.297222 2.77778 0 3.075 0 3.44444V5C0 6.84722 2.16389 8.65833 4.775 8.95555C5.64722 10.4028 6.66667 11 6.66667 11V13H5.33333C4.35278 13 3.55556 13.575 3.55556 14.5556V14.8889C3.55556 15.0722 3.70556 15.2222 3.88889 15.2222H12.1111C12.2944 15.2222 12.4444 15.0722 12.4444 14.8889V14.5556C12.4444 13.575 11.6472 13 10.6667 13H9.33333V11C9.33333 11 10.3528 10.4028 11.225 8.95555C13.8444 8.65556 16 6.84167 16 5V3.44444ZM3.91667 6.95C2.59722 6.49444 1.77778 5.56389 1.77778 5V4.55556H3.56111C3.58889 5.46111 3.72222 6.25556 3.91667 6.95ZM14.2222 5C14.2222 5.56111 13.4056 6.49444 12.0833 6.95C12.2778 6.25556 12.4111 5.46111 12.4389 4.55556H14.2222V5ZM8.07296 3.24692L8.07397 3.24619L8.07487 3.2408L9.80092 2L10.0769 4.22878L10.9932 5.85005L10.9847 5.86122L10.9915 5.87334L11.0001 5.87493L10.9948 5.87927L11.0001 5.88878L10.9916 5.88187L10.9325 5.93017L8.60391 9.00122H7.14379L4.75449 5.85005L4.75558 5.84812L4.75449 5.84721L4.75624 5.84695L4.76211 5.83657L4.74756 5.84719L5.6695 4.22513L5.94678 2.01385L5.94895 2.0154L5.94914 2.01385L6.602 2.48037L7.67861 3.24692H8.07296Z"
					fill={get(1).url}
				/>
				<mask
					id={get().id}
					style={{ maskType: 'alpha' }}
					maskUnits="userSpaceOnUse"
					x="0"
					y="1"
					width="16"
					height="15"
				>
					<path
						d="M9.79386 5.66809L9.17392 5.63216C9.13749 5.63216 9.10142 5.63216 9.10142 5.66809L8.65703 6.10353L8.73928 6.13693L9.35499 6.17285C9.35691 6.17285 9.35872 6.17285 9.36045 6.17285C9.37252 6.17281 9.38013 6.17251 9.38699 6.17009C9.39758 6.16635 9.4064 6.15757 9.42713 6.13693L9.82534 5.70437L9.79386 5.66809Z"
						fill={get(2).url}
					/>
					<path
						d="M6.66471 5.66083C6.69717 5.66083 6.72931 5.66083 6.72931 5.69297L7.18087 6.11239H7.05199L6.50337 6.14453C6.50167 6.14453 6.50005 6.14453 6.49851 6.14453C6.48777 6.14449 6.48099 6.14422 6.47488 6.14206C6.46661 6.13914 6.45957 6.13277 6.44556 6.11884C6.44556 6.11884 6.44556 6.11884 6.44556 6.11884C6.44356 6.11685 6.44141 6.11471 6.4391 6.1124L6.08427 5.72543L6.01967 5.66083H6.66471Z"
						fill={get(2).url}
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M16 3.44444C16 3.075 15.7028 2.77778 15.3333 2.77778H12.4444V1.66667C12.4444 1.29722 12.1472 1 11.7778 1H4.22222C3.85278 1 3.55556 1.29722 3.55556 1.66667V2.77778H0.666667C0.297222 2.77778 0 3.075 0 3.44444V5C0 6.84722 2.16389 8.65833 4.775 8.95555C5.64722 10.4028 6.66667 11 6.66667 11V13H5.33333C4.35278 13 3.55556 13.575 3.55556 14.5556V14.8889C3.55556 15.0722 3.70556 15.2222 3.88889 15.2222H12.1111C12.2944 15.2222 12.4444 15.0722 12.4444 14.8889V14.5556C12.4444 13.575 11.6472 13 10.6667 13H9.33333V11C9.33333 11 10.3528 10.4028 11.225 8.95555C13.8444 8.65556 16 6.84167 16 5V3.44444ZM3.91667 6.95C2.59722 6.49444 1.77778 5.56389 1.77778 5V4.55556H3.56111C3.58889 5.46111 3.72222 6.25556 3.91667 6.95ZM14.2222 5C14.2222 5.56111 13.4056 6.49444 12.0833 6.95C12.2778 6.25556 12.4111 5.46111 12.4389 4.55556H14.2222V5ZM8.07296 3.24692L8.07397 3.24619L8.07487 3.2408L9.80092 2L10.0769 4.22878L10.9932 5.85005L10.9847 5.86122L10.9915 5.87334L11.0001 5.87493L10.9948 5.87927L11.0001 5.88878L10.9916 5.88187L10.9325 5.93017L8.60391 9.00122H7.14379L4.75449 5.85005L4.75558 5.84812L4.75449 5.84721L4.75624 5.84695L4.76211 5.83657L4.74756 5.84719L5.6695 4.22513L5.94678 2.01385L5.94895 2.0154L5.94914 2.01385L6.602 2.48037L7.67861 3.24692H8.07296Z"
						fill={get(2).url}
					/>
				</mask>
				<g mask={get().url}>
					<path d="M11 1H18L14 16H7L11 1Z" fill="white" fillOpacity="0.15" />
					<path d="M9 1H10L6 16H5L9 1Z" fill="white" fillOpacity="0.15" />
				</g>
				<defs>
					<linearGradient
						id={get(1).id}
						x1="18.4584"
						y1="0.310478"
						x2="9.21484"
						y2="12.0899"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor={color_1_} />
						<stop offset="1" stopColor={color_2_} />
					</linearGradient>
					<linearGradient
						id={get(2).id}
						x1="15.397"
						y1="2.32827"
						x2="10.2808"
						y2="16.0713"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor={color_3_} />
						<stop offset="1" stopColor={color_4_} />
					</linearGradient>
				</defs>
			</>
		),
		width: 16,
		height: 16,
	});
};

export const FirstRankTrophy = () => (
	<RankTrophy
		color_1={'#FFCD00'}
		color_2={'#A08523'}
		color_3={'#FAFAFA'}
		color_4={'#6B6B6B'}
	/>
);
export const SecondRankTrophy = () => (
	<RankTrophy
		color_1={'#FAFAFA'}
		color_2={'#6B6B6B'}
		color_3={'#FAFAFA'}
		color_4={'#6B6B6B'}
	/>
);
export const ThirdRankTrophy = () => (
	<RankTrophy
		color_1={'#BF623D'}
		color_2={'#7E412A'}
		color_3={'#BF623D'}
		color_4={'#7E412A'}
	/>
);
