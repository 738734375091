import { combineEpics } from 'redux-observable';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { chatActions } from '@modules/chat/store/chat.actions';
import { closeModal } from '@modules/modals/store/modal.actions';
import { APP_INITIALIZED } from '../app/app.constants';
import { bindPrivateMessagesSocketEvent } from '../websocket/websocket.actions';
import websocketService from '../../modules/app/websocketService';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import sortService, { DIRECTION } from '../../modules/app/sortService';
import { epic } from '../../modules/app/epicService';
import { USER_LOGGED } from '../user/user.constants';
import { PREFERENCES_SET } from '../preferences/preferences.constants';
import { filterArrayDuplicates } from '../../modules/app/appService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { tokenService } from '../../modules/app/tokenService';
import { WEBSOCKET_EVENTS } from '../websocket/websocket.constants';
import {
	privateMessagesChannelRead,
	privateMessagesChannelReadSend,
	privateMessagesClearStreamData,
	privateMessagesCloseActive,
	privateMessagesGetHistory,
	privateMessagesNewMessages,
	privateMessagesSetActiveList,
	privateMessagesSetHistory,
	privateMessagesSetStream,
	privateMessagesSetUnread,
} from './privateMessages.actions';
import {
	PRIVATE_MESSAGES_CHANNEL_ID,
	PRIVATE_MESSAGES_CHANNEL_READ_SEND,
	PRIVATE_MESSAGES_CLEAR_STREAM_DATA,
	PRIVATE_MESSAGES_CLOSE_ACTIVE,
	PRIVATE_MESSAGES_GET_HISTORY,
	PRIVATE_MESSAGES_GET_STREAM,
	PRIVATE_MESSAGES_NEW_MESSAGE,
	PRIVATE_MESSAGES_OPEN_ACTIVE,
	PRIVATE_MESSAGES_SET_HISTORY,
} from './privateMessages.constants';

const initial = epic('initial', {
	actions: (ofType) => ofType(APP_INITIALIZED, USER_LOGGED, PREFERENCES_SET),
	callback: () => {
		if (!tokenService.token.value) {
			return of();
		}
		return of(privateMessagesGetHistory());
	},
});

const historyEpic = epic('historyEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_GET_HISTORY),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/user/rooms/open',
					parameters: {
						Authorization: true,
					},
					loadingId: 'getPrivateMessagesHistory',
				},
				(data) => {
					// console.log('PRIVATE_MESSAGES_GET_HISTORY', {data, bool: !data || !data.data || !data.data.length});
					if (!data || !data.data) {
						return [];
					}
					const result = [
						privateMessagesSetHistory(
							data.data.map((el) => ({
								...el,
								channelId: el.user.uuid,
							})),
						),
					];
					data.data.forEach((el) => {
						if (el.unread) {
							result.push(privateMessagesSetUnread(el.user.uuid, true));
						}
					});
					return result;
				},
			),
		);
	},
});

const openActiveEpic = epic('openActiveEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_OPEN_ACTIVE),
	callback: ({ action, store$ }) => {
		let activeList = store$.value.privateMessages.activeList;
		if (
			!activeList.find((chat) => chat.channelId === action.payload.channelId)
		) {
			activeList = [
				{
					...action.payload,
				},
				...activeList,
			];
		}
		const result = [
			chatActions.setPrivateActive(action.payload.channelId),
			privateMessagesSetActiveList(activeList),
		];
		if (store$.value.modal.id) {
			result.push(closeModal());
		}
		return of(result);
	},
});

const closeActiveEpic = epic('closeActiveEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_CLOSE_ACTIVE),
	callback: ({ action, store$ }) => {
		if (!action.payload) {
			return of();
		}
		let activeList = store$.value.privateMessages.activeList;
		const index = activeList.findIndex(
			(chat) => chat.channelId === action.payload.channelId,
		);
		if (index === -1) {
			return of();
		}
		activeList = activeList.filter(
			(chat) => chat.channelId !== action.payload.channelId,
		);
		const result = [
			privateMessagesClearStreamData(action.payload.channelId),
			chatActions.setPrivateActive(
				activeList.length ? activeList[0].channelId : false,
			),
			privateMessagesSetActiveList(activeList),
		];
		return of(result);
	},
});

const clearPrivateEpic = epic('clearPrivateEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_CLOSE_ACTIVE),
	callback: ({ action, store$ }) => {
		if (!action.payload) {
			return of();
		}
		if (
			!store$.value.privateMessages.stream[action.payload.user.uuid] ||
			!store$.value.privateMessages.stream[action.payload.user.uuid].length
		) {
			return of();
		}
		return of(privateMessagesClearStreamData(action.payload.user.uuid)).pipe(
			delay(5000),
		);
	},
});

const clearStreamDataEpic = epic('clearStreamDataEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_CLEAR_STREAM_DATA),
	callback: ({ action, store$ }) => {
		// TODO: chat store usage
		if (store$.value?.chat?.privateActive === action.channel) {
			return of();
		}
		return of(privateMessagesSetStream(action.channel));
	},
});

const getStreamEpic = epic('getStreamEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_GET_STREAM),
	callback: ({ action, store$ }) => {
		if (
			store$.value.privateMessages.stream[action.payload] &&
			store$.value.privateMessages.stream[action.payload].length &&
			!action.nonce
		) {
			return of();
		}
		if (
			store$.value.privateMessages.stream[action.payload] &&
			store$.value.privateMessages.stream[action.payload].length &&
			store$.value.privateMessages.stream[action.payload][0].nonce === 1 &&
			action.nonce
		) {
			return of();
		}
		return of(
			fetchApiAction(
				{
					url: `/user/messages/${action.payload}${
						action.nonce ? `/${action.nonce}` : ''
					}`,
					parameters: {
						Authorization: true,
					},
					loadingId: `getPrivateStream-${action.payload}`,
				},
				(data) => {
					if (data.error) {
						return [
							privateMessagesSetHistory(
								store$.value.privateMessages.history.filter(
									(el) => el.channelId !== action.payload,
								),
							),
							privateMessagesCloseActive(
								store$.value.privateMessages.activeList.find(
									(el) => el.channelId === action.payload,
								),
							),
						];
					}
					data.data = data.data.map((el) => el.message);
					const stream = action.nonce
						? filterArrayDuplicates([
								...store$.value.privateMessages.stream[action.payload],
								...data.data,
						  ]).sort((a, b) =>
								sortService.sort(DIRECTION.ASC, a.nonce, b.nonce),
						  )
						: data.data;
					return [
						privateMessagesSetStream(action.payload, stream),
						privateMessagesChannelRead(action.payload),
					];
				},
			),
		);
	},
});

const channelEpic = epic('channelEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_CHANNEL_ID),
	callback: ({ action }) => {
		if (!action.payload) {
			return of();
		}
		return of([
			bindPrivateMessagesSocketEvent(
				WEBSOCKET_EVENTS.PRIVATE_MESSAGE,
				(data, dispatch) => {
					dispatch(
						privateMessagesNewMessages(websocketService.getMessage(data)),
					);
				},
			),
			bindPrivateMessagesSocketEvent(
				WEBSOCKET_EVENTS.PRIVATE_CHANNEL_READ,
				(data, dispatch) => {
					dispatch(
						privateMessagesChannelRead(
							websocketService.getMessage(data).channelId,
						),
					);
				},
			),
		]);
	},
});

const newMessageEpic = epic('newMessageEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_NEW_MESSAGE),
	callback: ({ action, store$ }) => {
		const channelId = action.payload.channelId;
		const result = [];
		if (
			// TODO: chat store usage
			store$.value?.chat?.privateActive !== channelId &&
			action.payload.user.uuid !== store$.value.user.id
		) {
			if (store$.value.preferences.community.show_messages_notifications.bool) {
				result.push(
					showAlert(
						'info',
						trans({
							label: 'New message from {{userName}}!',
							options: {
								userName: action.payload.user.login,
							},
						}),
					),
				);
			}
			result.push(privateMessagesSetUnread(channelId, action.payload.nonce));
		}
		if (
			// TODO: chat store usage
			store$.value?.chat?.privateActive === channelId &&
			action.payload.user.uuid !== store$.value.user.id
		) {
			result.push(privateMessagesChannelReadSend(channelId, true));
		}
		if (
			store$.value.privateMessages.history.some(
				(el) => el.channelId === channelId,
			)
		) {
			result.push(
				privateMessagesSetHistory(
					store$.value.privateMessages.history.map((el) =>
						el.channelId !== channelId
							? el
							: {
									...el,
									last_message: action.payload.message,
									last_message_timestamp: action.payload.published_at,
							  },
					),
				),
			);
		} else {
			result.push(privateMessagesGetHistory());
		}
		if (store$.value.privateMessages.stream[channelId]) {
			result.push(
				privateMessagesSetStream(channelId, [
					...store$.value.privateMessages.stream[channelId],
					action.payload,
				]),
			);
		}
		return of(result);
	},
});

const privateActiveEpic = epic('privateActiveEpic', {
	actions: (ofType) => ofType(chatActions.setPrivateActive().type),
	callback: ({ action, store$ }) => {
		if (
			!action.payload ||
			!store$.value.privateMessages.unread[action.payload] ||
			!store$.value.privateMessages.stream[action.payload]
		) {
			return of();
		}
		return of(privateMessagesChannelReadSend(action.payload));
	},
});

const readChannelEpic = epic('readChannelEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_CHANNEL_READ_SEND),
	callback: ({ action, store$ }) => {
		if (
			!action.payload ||
			(!store$.value.privateMessages.unread[action.payload] && !action.force) ||
			!store$.value.privateMessages.stream[action.payload]
		) {
			return of();
		}
		return of(
			fetchApiAction(
				{
					url: `/user/messages/read/${action.payload}`,
					parameters: {
						Authorization: true,
					},
					loaderId: 'getReadChannel',
				},
				(data) => {
					if (data.error || data.errors) {
						return [];
					}
					if (store$.value.privateMessages.unread[action.payload]) {
						return [privateMessagesChannelRead(action.payload)];
					}
					return [];
				},
			),
		);
	},
});

const fixUnreadEpic = epic('fixUnreadEpic', {
	actions: (ofType) => ofType(PRIVATE_MESSAGES_SET_HISTORY),
	callback: ({ store$ }) => {
		const isUnreadDeleted = Object.keys(
			store$.value.privateMessages.unread,
		).find(
			(id) =>
				!store$.value.privateMessages.history.some((el) => el.channelId === id),
		);
		if (!isUnreadDeleted) {
			return of();
		}
		return of(privateMessagesChannelRead(isUnreadDeleted));
	},
});

const privateMessagesEpic = combineEpics(
	historyEpic,
	getStreamEpic,
	openActiveEpic,
	closeActiveEpic,
	initial,
	clearPrivateEpic,
	clearStreamDataEpic,
	channelEpic,
	newMessageEpic,
	readChannelEpic,
	privateActiveEpic,
	fixUnreadEpic,
);

export { privateMessagesEpic };
