import { useEffect } from 'react';
import { useFetchApi } from '@legacyApp/hooks/fetch/useFetchApi';
import { second } from '@legacyApp/client/modules/app/time';
import { generateUrl, ROUTE } from '../routes';

export const useCheckAccessDenied = () => {
	const { fetchLocal } = useFetchApi();

	useEffect(() => {
		fetchLocal({
			url: generateUrl(ROUTE.apiCheckAccess),
			fromState: 30 * second,
		});
	}, [fetchLocal]);
};
