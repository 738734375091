import { modalOpenInfo, openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { trans } from '../../../modules/translation/translate';
import { DescriptionBoxStyle } from '../../../modules/style/components/descriptionBox.style';
import { TransNamespace } from '../../../modules/translation/TransNamespace';

export const cloudflareError = (data, requestData) => {
	return (dispatch) => {
		if (!data) {
			return;
		}
		if (data.text.indexOf('Checking if the site connection is secure') > -1) {
			dispatch(
				openModal(
					MODAL_ID.REFRESH,
					'cloudflareError',
					{
						content: (
							<DescriptionBoxStyle
								$background={'info'}
								className="no-margin no-padding padding-side-20"
							>
								<p>
									{trans({
										label:
											'Due to the security reasons we need to check site connection, please refresh the page',
										namespace: TransNamespace.VALIDATION,
									})}
								</p>
							</DescriptionBoxStyle>
						),
					},
					true,
				),
			);
			return;
		}
		dispatch(
			modalOpenInfo({
				id: MODAL_ID.CLOUDFLATE_ERROR,
				props: {
					error: data,
					requestData,
					title: 'Connection problem',
				},
			}),
		);
	};
};
