import styled, { css } from 'styled-components';
import { LoaderProps } from './loader';

export const StyledLoader = styled.span.attrs({
	className: 'loader',
})<{
	$active?: boolean;
	$absolutePosition?: LoaderProps['absolutePosition'];
	$pointerEvents?: boolean;
}>`
	${(props) =>
		props.$active
			? css`
					align-items: center;
					background: inherit;
					background-color: inherit;
					border-radius: inherit;
					display: flex !important;
					flex-direction: column;
					height: 100%;
					justify-content: center;
					left: 0;
					pointer-events: none;
					position: absolute;
					top: 0;
					width: 100%;

					> div {
						align-items: center;
						display: flex;
						flex-direction: column;
						justify-content: center;
						margin-top: 10px;

						p {
							text-align: center;
						}
					}

					svg {
						animation: rotation 0.7s linear infinite;
					}
			  `
			: 'display: none!important;'}
	${(props) => (props.$pointerEvents ? 'pointer-events: auto!important;' : '')}

	${(props) =>
		props.$absolutePosition
			? css`
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
			  `
			: ''}
`;

export const StyledLoaderContent = styled.div<{
	$loading: boolean;
}>`
	position: relative;

	* {
		* {
			opacity: ${(props) => (props.$loading ? 0 : 1)};
			transition: ${(props) => props.theme.layout.transition};
		}
	}

	${StyledLoader},
	${StyledLoader} * {
		opacity: ${(props) => (props.$loading ? 1 : 0)};
		z-index: 1;
	}
`;
