import { actionSimple } from '../../modules/app/actionService';
import {
	LOADER_GROUP_LOADED,
	LOADER_GROUP_LOADING,
	LOADER_GROUP_SET_DATA,
	LOADER_MODULE_LOADED,
	LOADER_MODULE_LOADING,
} from './loader.constants';

export const loaderModuleLoaded = actionSimple(LOADER_MODULE_LOADED);

export const loaderModuleLoading = actionSimple(LOADER_MODULE_LOADING);

export const loaderGroupLoaded = actionSimple(LOADER_GROUP_LOADED);

export const loaderGroupLoading = actionSimple(LOADER_GROUP_LOADING);

export const loaderGroupSetData = actionSimple(LOADER_GROUP_SET_DATA);

export const isLoadingThunk = (id) => {
	return (dispatch, getState) => {
		const modules = getState().loader.modules;
		if (typeof id === 'object' && id.length !== undefined) {
			return id.some((el) => modules[el]);
		}
		return modules[id];
	};
};

export const loaderThunk = (bool, id) => {
	return (dispatch) => {
		if (id) {
			return bool
				? dispatch(loaderModuleLoading(id))
				: dispatch(loaderModuleLoaded(id));
		}
	};
};
