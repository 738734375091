import { connect } from 'react-redux';
import {
	loaderGroupLoaded,
	loaderGroupLoading,
	loaderGroupSetData,
} from '../../../store/loader/loader.actions';
import { LoadingComponent } from './loadingComponent';

const mapDispatchToProps = (dispatch) => {
	return {
		loadingGroup: (payload) => dispatch(loaderGroupLoading(payload)),
		loadedGroup: (payload) => dispatch(loaderGroupLoaded(payload)),
		setGroupData: (payload) => dispatch(loaderGroupSetData(payload)),
	};
};

const LoadingComponentContainer = connect(
	null,
	mapDispatchToProps,
)(LoadingComponent);

export { LoadingComponentContainer };
