import { LoginNameType } from '@legacyApp/types/user/LoginNameType';
import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';
import { checkIsBotLogin } from '../constants/BOTS';

export const checkRealUserName = (name: LoginNameType): boolean => {
	if (typeof name === 'string') {
		return name !== HIDDEN_USER && !checkIsBotLogin(name);
	}
	return false;
};
