import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { APP_COMMANDS } from '../app/app.constants';

export const SET_SOCKET_CHANNEL = 'SET_SOCKET_CHANNEL';
export const WEBSOCKET_SET_CONNECTION_STATE = 'WEBSOCKET_SET_CONNECTION_STATE';
export const BIND_SOCKET_EVENT = 'BIND_SOCKET_EVENT';
export const BIND_SOCKET_PRIVATE_CHANNEL_EVENT =
	'BIND_SOCKET_PRIVATE_CHANNEL_EVENT';
export const UNBIND_SOCKET_PRIVATE_CHANNEL_EVENT =
	'UNBIND_SOCKET_PRIVATE_CHANNEL_EVENT';
export const BIND_PRIVATE_MESSAGES_SOCKET_EVENT =
	'BIND_PRIVATE_MESSAGES_SOCKET_EVENT';
export const UNBIND_SOCKET_CHANNEL_EVENT = 'UNBIND_SOCKET_CHANNEL_EVENT';

export const WEBSOCKET_CHANNELS = {
	COMMANDS: 'commands',
	CHAT: `chat`,
	STATS: 'stats',
	ALL_BETS: TABLE_ID.allBets,
	HIGH_ROLLS: TABLE_ID.highRolls,
} as const;

const WEBSOCKET_EVENTS_COMMON = {
	STATS: 'stats',
	// chat room channel
	MESSAGE: 'message',
	// allBets and highRolls channels
	BET_RESULT: 'betResult',
	// private and commands channel
	COMMANDS: 'commands',
	// user channel
	USER_BALANCE: 'userBalance',
	MY_BETS: TABLE_ID.myBets,
} as const;
const WEBSOCKET_EVENTS_FRIENDS = {
	FRIENDS_REQUEST_SENT: 'friendRequestSent',
	FRIENDS_REQUEST_CANCELED: 'friendRequestCancelled',
	FRIENDS_REQUEST_ACCEPTED: 'friendRequestAccepted',
	FRIENDS_FRIEND_DELETED: 'friendDeleted',
} as const;

const WEBSOCKET_EVENTS_WALLET = {
	WALLET_DEPOSIT_ACCOUNTED: 'depositAccounted',
	WALLET_DEPOSIT_DETECTED: 'depositDetected',
	WALLET_WITHDRAW_ACCEPTED: 'withdrawAccepted',
} as const;

const WEBSOCKET_EVENTS_PRIVATE = {
	PRIVATE_MESSAGE: 'privateMessage',
	PRIVATE_CHANNEL_READ: 'privateChannelRead',
} as const;

export const WEBSOCKET_EVENTS = {
	...WEBSOCKET_EVENTS_COMMON,
	...WEBSOCKET_EVENTS_FRIENDS,
	...WEBSOCKET_EVENTS_WALLET,
	...WEBSOCKET_EVENTS_PRIVATE,
	...APP_COMMANDS,
} as const;

type WebsocketChannel = keyof typeof WEBSOCKET_CHANNELS;
export type WebsocketChannelType =
	| `${(typeof WEBSOCKET_CHANNELS)[WebsocketChannel]}.${string}`
	| (typeof WEBSOCKET_CHANNELS)[WebsocketChannel];

type WebsocketEvent = keyof typeof WEBSOCKET_EVENTS;
export type WebsocketEventType = (typeof WEBSOCKET_EVENTS)[WebsocketEvent];
