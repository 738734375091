import {
	PRIVATE_MESSAGES_CHANNEL_ID,
	PRIVATE_MESSAGES_CHANNEL_READ,
	PRIVATE_MESSAGES_CHANNEL_READ_SEND,
	PRIVATE_MESSAGES_CLEAR_STREAM_DATA,
	PRIVATE_MESSAGES_CLOSE_ACTIVE,
	PRIVATE_MESSAGES_GET_HISTORY,
	PRIVATE_MESSAGES_GET_STREAM,
	PRIVATE_MESSAGES_NEW_MESSAGE,
	PRIVATE_MESSAGES_OPEN_ACTIVE,
	PRIVATE_MESSAGES_SET_ACTIVE_LIST,
	PRIVATE_MESSAGES_SET_HISTORY,
	PRIVATE_MESSAGES_SET_STREAM,
	PRIVATE_MESSAGES_SET_UNREAD,
} from './privateMessages.constants';

export const privateMessagesSetHistory = (payload) => ({
	type: PRIVATE_MESSAGES_SET_HISTORY,
	payload,
});

export const privateMessagesGetHistory = () => ({
	type: PRIVATE_MESSAGES_GET_HISTORY,
});

export const privateMessagesSetActiveList = (payload) => ({
	type: PRIVATE_MESSAGES_SET_ACTIVE_LIST,
	payload,
});

export const privateMessagesOpenActive = (payload) => ({
	type: PRIVATE_MESSAGES_OPEN_ACTIVE,
	payload,
});

export const privateMessagesCloseActive = (payload) => ({
	type: PRIVATE_MESSAGES_CLOSE_ACTIVE,
	payload,
});

export const privateMessagesGetStream = (payload, nonce) => ({
	type: PRIVATE_MESSAGES_GET_STREAM,
	payload,
	nonce,
});

export const privateMessagesSetStream = (channel, data) => ({
	type: PRIVATE_MESSAGES_SET_STREAM,
	channel,
	data,
});

export const privateMessagesClearStreamData = (channel) => ({
	type: PRIVATE_MESSAGES_CLEAR_STREAM_DATA,
	channel,
});

export const privateMessagesChannelId = (payload) => ({
	type: PRIVATE_MESSAGES_CHANNEL_ID,
	payload,
});

export const privateMessagesNewMessages = (payload) => ({
	type: PRIVATE_MESSAGES_NEW_MESSAGE,
	payload,
});

export const privateMessagesSetUnread = (channel, nonce) => ({
	type: PRIVATE_MESSAGES_SET_UNREAD,
	channel,
	nonce,
});

export const privateMessagesChannelRead = (channel) => ({
	type: PRIVATE_MESSAGES_CHANNEL_READ,
	channel,
});

export const privateMessagesChannelReadSend = (payload, force) => ({
	type: PRIVATE_MESSAGES_CHANNEL_READ_SEND,
	payload,
	force,
});
