import styled from 'styled-components';

export const StyledMain = styled.div.attrs({ className: 'main' })`
	float: left;
	width: 100%;
	min-height: calc(100vh - ${(props) => props.theme.layout.footerHeight});
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.background};
	color: ${(props) => props.theme.colors.text};
	position: relative;

	.main-dashboard:first-of-type {
		min-height: calc(
			100vh - (2 * ${(props) => props.theme.layout.footerHeight})
		);
	}
`;
