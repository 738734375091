export const PRIVATE_MESSAGES_SET_HISTORY = 'PRIVATE_MESSAGES_SET_HISTORY';
export const PRIVATE_MESSAGES_GET_HISTORY = 'PRIVATE_MESSAGES_GET_HISTORY';

export const PRIVATE_MESSAGES_SET_ACTIVE_LIST =
	'PRIVATE_MESSAGES_SET_ACTIVE_LIST';
export const PRIVATE_MESSAGES_OPEN_ACTIVE = 'PRIVATE_MESSAGES_OPEN_ACTIVE';
export const PRIVATE_MESSAGES_CLOSE_ACTIVE = 'PRIVATE_MESSAGES_CLOSE_ACTIVE';
export const PRIVATE_MESSAGES_GET_STREAM = 'PRIVATE_MESSAGES_GET_STREAM';
export const PRIVATE_MESSAGES_SET_STREAM = 'PRIVATE_MESSAGES_SET_STREAM';
export const PRIVATE_MESSAGES_CLEAR_STREAM_DATA =
	'PRIVATE_MESSAGES_CLEAR_STREAM_DATA';
export const PRIVATE_MESSAGES_CHANNEL_ID = 'PRIVATE_MESSAGES_CHANNEL_ID';
export const PRIVATE_MESSAGES_NEW_MESSAGE = 'PRIVATE_MESSAGES_NEW_MESSAGE';
export const PRIVATE_MESSAGES_SET_UNREAD = 'PRIVATE_MESSAGES_SET_UNREAD';
export const PRIVATE_MESSAGES_CHANNEL_READ = 'PRIVATE_MESSAGES_CHANNEL_READ';
export const PRIVATE_MESSAGES_CHANNEL_READ_SEND =
	'PRIVATE_MESSAGES_CHANNEL_READ_SEND';
