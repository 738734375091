import { LoginNameType } from '@legacyApp/types/user/LoginNameType';
import sortService, {
	DIRECTION,
} from '../../../LegacyApp/client/modules/app/sortService';

export const sortUsers = (users: Array<LoginNameType>): Array<LoginNameType> =>
	users.sort((a, b) =>
		sortService.sort(
			DIRECTION.ASC,
			typeof a === 'string' ? a.toLowerCase() : a,
			typeof b === 'string' ? b.toLowerCase() : b,
		),
	);
