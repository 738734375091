import queryString from 'querystring';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import transactionService, {
	blockGetTransaction,
} from '@modules/transactions/transactionsService';
import { createTableQueryPropertyKeyPage } from '@legacyApp/client/store/transactions/methods/createTableQueryPropertyKey';
import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';
import { capitalize } from '@legacyApp/client/modules/app/appService';
import { parseTransactionsData } from '@modules/transactions/methods/parseTransactionsData';
import {
	transactionsFulfilled,
	transactionsSet,
	transactionsSetMeta,
	transactionsUpdating,
} from '@legacyApp/client/store/transactions/transactions.actions';
import { updateUrlQuery } from '@legacyApp/client/store/app/app.actions';
import { GetTransactionsThunkProps } from '@legacyApp/client/store/transactions/thunks/getTransactions.thunk';

export const fetchTransactionsThunk = (
	props_: GetTransactionsThunkProps,
): AppThunk<Promise<any>> => {
	return async (dispatch, getState) => {
		if (blockGetTransaction(props_, getState())) {
			return null;
		}

		const query = queryString.parse(
			getState().router.location.search.replace('?', ''),
		);
		const tablePageKey = createTableQueryPropertyKeyPage(props_.id);
		const pageFromQuery = Number(query[tablePageKey]);

		const page = props_.page ? props_.page : pageFromQuery || 1;
		const uri = transactionService.getTransactionsUri({
			id: props_.id,
			disable: props_.disable,
			props: props_.props,
			page,
		});

		if (!uri) {
			return null;
		}
		// console.log(props_.id, {
		//   uri,
		//   action,
		//   page,
		// });
		const data = await dispatch(
			fetchApi({
				url: uri,
				loaderId: `get${capitalize(props_.id)}`,
				disableErrorHandler: props_.refresh,
			}),
		);
		if (!data) {
			return null;
		}
		const { data: transactions, meta } = parseTransactionsData({
			id: props_.id,
			data,
		});
		if (!transactions) {
			return null;
		}
		dispatch(transactionsUpdating(props_.id, true));
		if (meta) {
			if (pageFromQuery || meta.current_page > 1) {
				dispatch(
					updateUrlQuery({
						[tablePageKey]: meta.current_page > 1 ? meta.current_page : null,
					}),
				);
			}

			dispatch(transactionsSetMeta(props_.id, meta));
		}
		if (!page) {
			dispatch(transactionsFulfilled(props_.id, false));
		}
		dispatch(transactionsSet(props_.id, transactions, null));
	};
};
