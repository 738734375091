import { jsonToString } from '@common/methods/jsonToString';

class statsClass {
	constructor() {
		this.maxValues = ['betsCounter', 'wonLast24h', 'jackpot'];
	}

	canUpdate(data, key, timestamp, currState) {
		currState = currState.stats;
		const isTimestampNotValid =
			currState.timestamp[key] && timestamp <= currState.timestamp[key];
		let isDataNotValid =
			!data || jsonToString(currState[key]) === jsonToString(data);
		if (
			!isDataNotValid &&
			this.maxValues.indexOf(key) > -1 &&
			currState[key] >= data
		) {
			isDataNotValid = true;
		}
		return !(isTimestampNotValid || isDataNotValid);
	}
}

const statsService = new statsClass();

export default statsService;
