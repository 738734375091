import { remove } from 'local-storage';
import { LocalStorageKeysType } from '@modules/localStorage/types/localStorageKeys.type';

export const lsClear = (key: LocalStorageKeysType) => {
	if (!key) {
		return;
	}
	try {
		remove(key);
	} catch (error) {
		return error;
	}
};
