import { combineReducers } from 'redux';
import { objectBoolReducer } from '../../modules/app/reducers/objectBoolReducer';
import {
	LOADER_GROUP_LOADED,
	LOADER_GROUP_LOADING,
	LOADER_GROUP_SET_DATA,
	LOADER_MODULE_LOADED,
	LOADER_MODULE_LOADING,
} from './loader.constants';

const modulesReducer = objectBoolReducer(
	{},
	LOADER_MODULE_LOADING,
	LOADER_MODULE_LOADED,
);

const groupsReducer = objectBoolReducer(
	{},
	LOADER_GROUP_LOADING,
	LOADER_GROUP_LOADED,
	(bool, payload, state) => {
		if (!payload.element || !payload.group) {
			return state;
		}
		const newState = {
			...state,
		};
		let groupData = newState[payload.group];
		if (bool) {
			if (!groupData) {
				groupData = [];
			}
			if (groupData.indexOf(payload.element) > -1) {
				return newState;
			}
			return {
				...newState,
				[payload.group]: [...groupData, payload.element],
			};
		}
		if (!groupData) {
			return newState;
		}
		if (groupData.indexOf(payload.element) === -1) {
			return newState;
		}
		groupData = groupData.filter((el) => el !== payload.element);
		if (!groupData.length) {
			return false;
		}
		return {
			...newState,
			[payload.group]: [...groupData],
		};
	},
);

const groupsDataReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	const newState = {
		...state,
	};
	switch (action.type) {
		case LOADER_GROUP_SET_DATA: {
			if (!newState[action.payload.group]) {
				newState[action.payload.group] = {};
			}
			if (action.payload.data.error || action.payload.data.timedOut) {
				newState[action.payload.group][action.payload.element] = {
					...action.payload.data,
				};
			} else {
				if (newState[action.payload.group][action.payload.element]) {
					delete newState[action.payload.group][action.payload.element];
				}
				if (!Object.keys(newState[action.payload.group]).length) {
					delete newState[action.payload.group];
				}
			}
			console.log({
				newState,
				action: {
					...action,
				},
			});
			return {
				...newState,
			};
		}
		case LOADER_GROUP_LOADED: {
			if (!newState[action.payload.group]) {
				return newState;
			}
			if (newState[action.payload.group][action.payload.element]) {
				delete newState[action.payload.group][action.payload.element];
			}
			if (!Object.keys(newState[action.payload.group]).length) {
				delete newState[action.payload.group];
			}
			return {
				...newState,
			};
		}
		default: {
			return state;
		}
	}
};

const loaderReducer = combineReducers({
	modules: modulesReducer,
	groups: groupsReducer,
	groupsData: groupsDataReducer,
});

export { loaderReducer };
