import styled from 'styled-components';

export const StyledHeaderLogoWrapper = styled.div.attrs(() => ({
	className: 'header__logo--wrapper',
}))``;

export const StyledHeaderLogo = styled.img.attrs(() => ({
	className: 'header__box__logo',
}))`
	height: 25px;
	float: left;

	@media (max-width: ${(props) => props.theme.media.maxWidthVerySmall}) {
		height: 23px;
	}
`;
