import {
	CLEAR_GTM_PAGE_DATA,
	DAILY_STREAK_GTM_EVENT,
	DEPOSIT_GTM_EVENT,
	FAUCET_GTM_EVENT,
	PUSH_GTM_EVENT,
	REGISTER_GTM_EVENT,
	WITHDRAW_GTM_EVENT,
} from './gtm.constants';

const push = (options, data) => {
	return {
		type: PUSH_GTM_EVENT,
		options,
		data,
	};
};

const depositEvent = (data) => {
	return {
		type: DEPOSIT_GTM_EVENT,
		data,
	};
};

const withdrawEvent = (data) => {
	return {
		type: WITHDRAW_GTM_EVENT,
		data,
	};
};

const registerEvent = (data, location) => {
	return {
		type: REGISTER_GTM_EVENT,
		data,
		location,
	};
};

const faucetEvent = (data) => {
	return {
		type: FAUCET_GTM_EVENT,
		data,
	};
};

const dailyStreakEvent = (data) => {
	return {
		type: DAILY_STREAK_GTM_EVENT,
		data,
	};
};

const clearGtmPageData = () => ({
	type: CLEAR_GTM_PAGE_DATA,
});

export {
	dailyStreakEvent,
	faucetEvent,
	registerEvent,
	withdrawEvent,
	depositEvent,
	push,
	clearGtmPageData,
};
