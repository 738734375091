import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { isFunction } from '@common/methods/isFunction';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import {
	initSupport,
	isSupportLoaded,
	onError,
	onHideSupport,
	onShowSupport,
	onUnreadCountChange,
} from '@modules/support/methods/supportService';
import { loaderThunk } from '@legacyApp/client/store/loader/loader.actions';
import { supportActions } from '../support.actions';

export const supportInit = (callback?: () => void): AppThunk => {
	return async (dispatch, getState) => {
		if (!isSupportLoaded()) {
			dispatch(loaderThunk(true, LOADING_IDS.SUPPORT_APP));
			await initSupport(getState(), () => {
				onShowSupport(() => dispatch(supportActions.setOpen(true)));
				onHideSupport(() => dispatch(supportActions.setOpen(false)));
				onError((/*response*/) => {
					dispatch(supportActions.setError(true));
					// if (response.status === 0 && response.statusText === '') {
					// 	return;
					// }
					// sentryError(
					// 	new Error(`HelpCrunch - ${jsonToString(response)}`),
					// 	{
					// 		response
					// 	},
					// 	getState()
					// );
				});
				dispatch(loaderThunk(false, LOADING_IDS.SUPPORT_APP));
				onUnreadCountChange((event) =>
					dispatch(supportActions.setUnread(event ? event.unreadChats : false)),
				);
				if (isFunction(callback)) {
					callback();
				}
			});
		} else {
			if (isFunction(callback)) {
				callback();
			}
		}
	};
};
