import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { vipReducer } from '../vip.reducer';
import transactionService from '../../../transactions/transactionsService';

export type VipReducerType = ReturnType<typeof vipReducer>;

export const isLevelEqual = (prev, next) =>
	transactionService.isDuplicate(prev, next, ['level', 'threshold']);
export const isLevelsEqual = (prev, next) =>
	!transactionService.isListDifferent(prev, next, ['level', 'threshold']);

export const useVipLevel = () =>
	useAppSelector<VipReducerType['level']>(
		(state) => state.vip.level,
		isLevelEqual,
	);
export const useVipLevels = () =>
	useAppSelector<VipReducerType['levels']>(
		(state) => state.vip.levels,
		isLevelsEqual,
	);
export const useVipStatus = () =>
	useAppSelector<VipReducerType['status']>(
		(state) => state.vip.status,
		shallowEqual,
	);
export const useVipLoaded = () =>
	useAppSelector<VipReducerType['loaded']>(
		(state) => state.vip.loaded,
		shallowEqual,
	);
