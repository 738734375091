import React, { FC, PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { Image, parseImageSrc } from '../../../default/Image/Image';
import { BigPromoStyled } from './Promotions.styled';

export const WolfRaceOnClickWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const dispatch = useDispatch();
	return (
		<div
			className={'onclick-wrapper onclick-wrapper-wolfrace'}
			onClick={() =>
				dispatch(openModal(MODAL_ID.CURRENT_RACE, 'wolfracepromo'))
			}
		>
			{children}
		</div>
	);
};

export const WolfRacePromo: FC = () => {
	return (
		<WolfRacePromoStyled>
			<WolfRaceOnClickWrapper />
			<TrophyImage />
			<ConfettiImage />
			<ConfettiImage2 />
			<h4 className="text-style-h-4">
				{trans({
					label: 'wolf<1/>race',
					namespace: TransNamespace.COMMON,
					components: (
						<>
							wolf
							<br />
							race
						</>
					),
				})}
			</h4>
			<h6 className="text-style-h-7">
				{trans({ label: 'grab your reward', namespace: TransNamespace.COMMON })}
			</h6>
		</WolfRacePromoStyled>
	);
};

export const TrophyImage = styled(Image).attrs({
	src: '/img/promosection/race/trophy.png',
	alt: 'Trophy',
})`
	bottom: -3%;
	position: absolute;
	height: 100%;
	right: 5%;
	z-index: 2;
`;

const Falldown = keyframes`
  0% {
    transform: translateY(-300%);
  }
	15% {
		transform: translateY(300%);
	}
	100% {
		transform: translateY(300%);
	}
`;

const FalldownV2 = keyframes`
  0% {
    transform: translateY(-195%);
  }
	15% {
		transform: translateY(195%);
	}
	100% {
		transform: translateY(195%);
	}
`;

export const ConfettiImage = styled(Image).attrs({
	src: '/img/promosection/race/confetti.png',
	alt: 'Confetti',
})`
	top: -40%;
	transform: translateY(-200%);
	position: absolute;
	height: 150%;
	right: -10%;
	animation-name: ${Falldown};
	animation-duration: 60s;
	animation-timing-function: ease-in;
	animation-iteration-count: infinite;
	z-index: 1;
`;

export const ConfettiImage2 = styled(ConfettiImage)`
	animation-name: ${FalldownV2};
`;

export const WolfRacePromoStyled = styled(BigPromoStyled)`
	background-image: url(${parseImageSrc('/img/promosection/race/bg.png')});
	background-repeat: no-repeat;
	background-size: cover;

	h4,
	h6 {
		z-index: 3;
	}
`;
