import {
	ACTIVATE_2FA,
	CODE_REQUIRED,
	DISABLE_2FA,
	ENABLE_2FA,
	INSTALL_2FA,
	SAVE_QRCODE,
	SAVE_SECRET,
	UNINSTALL_2FA,
} from './twoFactorAuthentication.constants';

const install2fa = () => {
	return {
		type: INSTALL_2FA,
	};
};

const uninstall2fa = () => {
	return {
		type: UNINSTALL_2FA,
	};
};

const saveSecret = (payload) => {
	return {
		type: SAVE_SECRET,
		payload,
	};
};

const saveQrcode = (payload) => {
	return {
		type: SAVE_QRCODE,
		payload,
	};
};

const enable2fa = (payload) => {
	return {
		type: ENABLE_2FA,
		payload,
	};
};

const disable2fa = (payload) => {
	return {
		type: DISABLE_2FA,
		payload,
	};
};

const activate2fa = () => {
	return {
		type: ACTIVATE_2FA,
	};
};

const codeRequired = (url, parameters, method, resolve, loaderId) => {
	return {
		type: CODE_REQUIRED,
		url,
		parameters,
		method,
		resolve,
		loaderId,
	};
};

export {
	install2fa,
	uninstall2fa,
	saveSecret,
	saveQrcode,
	activate2fa,
	enable2fa,
	disable2fa,
	codeRequired,
};
