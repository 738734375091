export enum GameType {
	DICE = 'dice',
	LIMBO = 'limbo',
	HILO = 'hilo',
	PLINKO = 'plinko',
	SLOTS = 'slots',
	SPORTS = 'sports',
}

export const availableGames = [
	// TODO: NEW GAME FLAG
	'dice',
	'limbo',
	'plinko',
	'hilo',
	'sports',
	'slots',
] as const;
