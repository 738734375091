import { set } from 'local-storage';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeysType } from '@modules/localStorage/types/localStorageKeys.type';

export const lsSet = (key: LocalStorageKeysType, data?: any) => {
	if (!key) {
		return;
	}
	if (!data && data !== false && data !== 0) {
		return lsClear(key);
	}
	try {
		set(key, data);
	} catch (error) {
		return error;
	}
};
