import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { BetSlipBet } from '../../types/BetSlip/BetSlipBet';
import transactionService from '../../../transactions/transactionsService';
import { BetSlipTypesType } from '../../types/BetSlip/BetSlipTypesType';

export const useSportsBetSlipBetsList = () =>
	useAppSelector(
		(state) => state.sportsBetSlip?.betsList as BetSlipBet[],
		(prev, next) => !transactionService.isListDifferent(prev, next),
	);
export const useSportsBetSlipType = (): BetSlipTypesType =>
	useAppSelector(
		(state) => state.sportsBetSlip?.type,
		(prev, next) => prev.id === next.id,
	);
export const useSportsBetSlipBetOutcome = ({
	matchId,
	outcomeId,
}: {
	matchId: string;
	outcomeId: string;
}) =>
	useAppSelector(
		(state) => state?.sportsBetSlip?.bets?.[matchId]?.outcome[outcomeId],
		(prev, next) => transactionService.isDuplicate(prev, next),
	);
export const useSportsBetSlipBetData = ({ matchId }: { matchId: string }) =>
	useAppSelector(
		(state) => state?.sportsBetSlip?.bets?.[matchId]?.data,
		(prev, next) => transactionService.isDuplicate(prev, next),
	);
export const useSportsBetSlipPlacingBet = () =>
	useAppSelector((state) => !!state?.sportsBetSlip?.placingBet, shallowEqual);
export const useSportsBetSlipError = () =>
	useAppSelector(
		(state) => state.sportsBetSlip?.error,
		(prev, next) => transactionService.isDuplicate(prev, next),
	);
export const useSportsBetSlipAcceptType = () =>
	useAppSelector(
		(state) => state.sportsBetSlip?.acceptType,
		(prev, next) => transactionService.isDuplicate(prev, next),
	);
export const useSportsBetSlipBetsData = () =>
	useAppSelector(
		(state) => state.sportsBetSlip?.bets,
		(prev, next) => transactionService.isDuplicate(prev, next),
	);
export const useSportsBetSlipPlacedBets = () =>
	useAppSelector(
		(state) => state.sportsBetSlip?.betsList.filter((el) => el.placed_at),
		(prev, next) => !transactionService.isListDifferent(prev, next),
	);
export const useSportsBetSlipReadyToBetBets = () =>
	useAppSelector(
		(state) => state.sportsBetSlip?.betsList.filter((el) => !el.placed_at),
		(prev, next) => !transactionService.isListDifferent(prev, next),
	);

export const useSportsEventsData = () =>
	useAppSelector(
		(state) => state.sportsEventsData.data,
		(prev, next) => transactionService.isDuplicate(prev, next),
	);

export const useSportsSideContent = () =>
	useAppSelector((state) => state.sports?.sideContent, shallowEqual);
export const useSportsActive = () =>
	useAppSelector((state) => state.sports?.active, shallowEqual);
