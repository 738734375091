import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { epic$ } from './root/root.epic';

export const createEpicManager = () => {
	// An array which is used to delete state keys when reducers are removed
	let asyncEpics = [];

	return {
		// Adds a new reducer with the specified key
		add: (key, epic) => {
			if (!key || asyncEpics.indexOf(key) > -1) {
				// console.error('Epic already exist', key);
				return false;
			}

			asyncEpics.push(key);
			epic$.next(epic);
			return true;
		},
		// NOT POSSIBLE TO REMOVE EPIC IF ALREADY CREATED - https://redux-observable.js.org/docs/recipes/HotModuleReplacement.html
		// remove: (key, epic, dispatch) => {
		// 	console.log('removeEpic', {
		// 		key,
		// 		epic,
		// 		dispatch,
		// 	});
		// },
	};
};

export const createReducerManager = (initialReducers) => {
	// Create an object which maps keys to reducers
	const reducers = {
		...initialReducers,
	};

	// Create the initial combinedReducer
	let combinedReducer = combineReducers(reducers);

	// An array which is used to delete state keys when reducers are removed
	let keysToRemove = [];

	return {
		getReducerMap: () => reducers,

		// The root reducer function exposed by this object
		// This will be passed to the store
		reduce: (state, action) => {
			// If any reducers have been removed, clean up their state first
			// console.log('reduce', {state, action});

			if (keysToRemove.length > 0) {
				state = {
					...state,
				};
				for (let key of keysToRemove) {
					delete state[key];
				}
				keysToRemove = [];
			}

			if (action.type === HYDRATE) {
				const nextState = {
					...state, // use previous state
					...(state.app.initialized ? {} : action.payload), // apply delta from hydration
				};
				if (typeof window !== 'undefined' && state?.router) {
					// preserve router value on client side navigation
					nextState.router = state.router;
				}
				return nextState;
			} else {
				// Delegate to the combined reducer
				return combinedReducer(state, action);
			}
		},

		// Adds a new reducer with the specified key
		add: (key, reducer) => {
			if (!key || reducers[key]) {
				// console.error('Reducer already exist', key);
				return false;
			}

			// Add the reducer to the reducer mapping
			reducers[key] = reducer;

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
			return true;
		},

		// Removes a reducer with the specified key
		remove: (key) => {
			if (!key || !reducers[key]) {
				// console.error('Reducer does not exist', key);
				return false;
			}

			// Remove it from the reducer mapping
			delete reducers[key];

			// Add the key to the list of keys to clean up
			keysToRemove.push(key);

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
			return true;
		},
	};
};
