export const countPrecision = (a) => {
	if (typeof a === 'number') {
		if (!isFinite(a)) {
			return 0;
		}
		let e = 1,
			p = 0;
		while (Math.round(a * e) / e !== a) {
			e *= 10;
			p++;
		}
		return p;
	}
	if (typeof a === 'string' && !(!parseFloat(a) && parseFloat(a) !== 0)) {
		const decimals = a.split('.')[1];
		return decimals ? decimals.length : 0;
	}
	return null;
};
