import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';

export type ValueType = string;
const initialState: ValueType = lsGet(LocalStorageKeys.CHAT_ROOM) || null;

const NAME = 'room';

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<{
		name: ValueType;
		lastRoom?: ValueType;
	}>
> = (state, action) => {
	lsSet(LocalStorageKeys.CHAT_ROOM, action.payload.name);
	return action.payload.name;
};

export const roomSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setRoom: setAction,
	},
});
