import { SUPPORT_APP_KEY } from '@modules/support/contants/SUPPORT_APP_KEY';

export interface UpdateUserSupportProps {
	name: string;
	email: string;
	user_id: string;
	user_hash: string;
	created_at: number;
	custom_data: {
		appversion?: string;
		browser_dimension?: string;
		vip?: number;
	};
}

export const updateUserSupport = (user: UpdateUserSupportProps) => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] && window[SUPPORT_APP_KEY]('update', user);
};
