import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { config } from '../../config';
import { RESET_USER_DATA } from '../user/user.constants';
import { hasNumber } from '../../modules/app/appService';
import { epic } from '../../modules/app/epicService';
import { setSocketChannel, unbindSocketChannel } from './websocket.actions';
import {
	BIND_PRIVATE_MESSAGES_SOCKET_EVENT,
	BIND_SOCKET_PRIVATE_CHANNEL_EVENT,
	UNBIND_SOCKET_CHANNEL_EVENT,
	UNBIND_SOCKET_PRIVATE_CHANNEL_EVENT,
} from './websocket.constants';
import { bindSocketChannelEventThunk } from './thunks/bindSocketChannelEvent.thunk';
import { leaveChannelThunk } from './thunks/leaveChannel.thunk';

const bindPrivateEpic = epic('bindPrivateEpic', {
	actions: (ofType) => ofType(BIND_SOCKET_PRIVATE_CHANNEL_EVENT),
	callback: ({ store$, action }) => {
		if (!store$.value.user.socketChannel) {
			return of();
		}
		return of(
			bindSocketChannelEventThunk(
				`${store$.value.user.socketChannel}`,
				action.name,
				(data, dispatch) => {
					action.callback(data, dispatch);
				},
			),
		);
	},
});

const unbindPrivateEpic = epic('unbindPrivateEpic', {
	actions: (ofType) => ofType(UNBIND_SOCKET_PRIVATE_CHANNEL_EVENT),
	callback: ({ store$, action }) => {
		if (!store$.value.user.socketChannel) {
			return of();
		}
		return of(
			unbindSocketChannel(`${store$.value.user.socketChannel}`, action.name),
		);
	},
});

const bindPrivateMessagesEpic = epic('bindPrivateMessagesEpic', {
	actions: (ofType) => ofType(BIND_PRIVATE_MESSAGES_SOCKET_EVENT),
	callback: ({ store$, action }) => {
		return of(
			bindSocketChannelEventThunk(
				`${store$.value.privateMessages.channelId}`,
				action.name,
				(data, dispatch) => action.callback(data, dispatch),
			),
		);
	},
});

const unbindEpic = epic('unbindEpic', {
	actions: (ofType) => ofType(UNBIND_SOCKET_CHANNEL_EVENT),
	callback: ({ action }) => {
		if (!config.websockets) {
			return of();
		}
		if (action.name) {
			return of(
				bindSocketChannelEventThunk(action.channel, action.name, undefined),
			);
		}
		return of([
			leaveChannelThunk(action.channel),
			setSocketChannel(action.channel, false),
		]);
	},
});

const resetUserDataEpic = epic('resetUserDataEpic', {
	actions: (ofType) => ofType(RESET_USER_DATA),
	callback: ({ store$ }) => {
		if (!config.websockets) {
			return of();
		}
		const privateChannelId = Object.keys(store$.value.websocket.channels).find(
			hasNumber,
		);
		if (!privateChannelId) {
			return of();
		}
		return of([
			leaveChannelThunk(privateChannelId),
			setSocketChannel(privateChannelId, false),
		]);
	},
});

const websocketEpic = combineEpics(
	unbindEpic,
	bindPrivateEpic,
	resetUserDataEpic,
	bindPrivateMessagesEpic,
	unbindPrivateEpic,
);

export { websocketEpic };
