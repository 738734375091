import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';

export const checkIsNewFeatures = (onlyCheck, newFeatures) => {
	if (!newFeatures || (newFeatures && !newFeatures.length)) {
		return false;
	}

	let lastRead = lsGet(LocalStorageKeys.NEW_FEATURES_TIMESTAMP);

	if (!onlyCheck) {
		lsSet(LocalStorageKeys.NEW_FEATURES_TIMESTAMP, newFeatures[0].date);
		lastRead = lsGet(LocalStorageKeys.NEW_FEATURES_TIMESTAMP);
	}

	if (!lastRead) {
		return true;
	}
	if (lastRead && newFeatures[0].date > lastRead) {
		return onlyCheck;
	}
	return false;
};
