import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { vipActions } from '@modules/vip/store/vip.actions';
import { USER_LOGOUT } from '../user/user.constants';
import { TRANSACTIONS_SET } from '../transactions/transactions.constants';
import { epic } from '../../modules/app/epicService';
import { initOneSignal } from '../../modules/app/vendorService';
import { ONESIGNAL_INIT } from './onesignal.constants';

export const pushOneSignal = (action) => {
	if (window.OneSignal) {
		if (window.OneSignalActions?.length) {
			window.OneSignalActions.forEach((action) =>
				window.OneSignal.push(action),
			);
		}
		window.OneSignalActions = [];
		return window.OneSignal.push(action);
	}
	window.OneSignalActions = [];
	return window.OneSignalActions.push(action);
};

export const setOneSignalTag = (key, value) => {
	pushOneSignal(() => {
		window.OneSignal?.getTags?.(function () {
			// console.log('OneSignal tags:', tags);
			pushOneSignal(() => {
				window.OneSignal?.sendTag?.(key, value);
			});
		});
	});
};

const oneSignalInitEpic = epic('oneSignalInitEpic', {
	actions: (ofType) => ofType(ONESIGNAL_INIT),
	callback: ({ action }) => {
		if (process.browser) {
			initOneSignal().then(async () => {
				if (action.externalUserId) {
					pushOneSignal(() => {
						window.OneSignal?.setExternalUserId?.(action.externalUserId);
					});
				}
			});
		}
		return of();
	},
});

const depositEpic = epic('depositEpic', {
	actions: (ofType) => ofType(TRANSACTIONS_SET),
	callback: ({ action }) => {
		if (process.browser) {
			if (action.id !== TABLE_ID.depositHistory) {
				return of();
			}
			setOneSignalTag('deposit', !!action.payload.length);
		}
		return of();
	},
});

const vipEpic = epic('vipEpic', {
	actions: (ofType) => ofType(vipActions.levelUpdate().type),
	callback: ({ store$ }) => {
		if (process.browser) {
			if (!store$.value.vip.level.level) {
				return of();
			}
			setOneSignalTag('vip', store$.value.vip.level.level);
		}
		return of();
	},
});

const logoutEpic = epic('logoutEpic', {
	actions: (ofType) => ofType(USER_LOGOUT),
	callback: () => {
		if (process.browser) {
			pushOneSignal(() => {
				// window.OneSignal.logoutEmail();
				window.OneSignal?.removeExternalUserId?.();
			});
		}
		return of();
	},
});

const onesignalEpic = combineEpics(
	oneSignalInitEpic,
	depositEpic,
	vipEpic,
	logoutEpic,
);

export { onesignalEpic };
