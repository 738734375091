const { updateFromLsConfig } = require('./updateFromLsConfig');
const { serverConfig } = require('./server.config');
const cookieConfig = updateFromLsConfig({
	httpOnly: false,
	secure: !serverConfig.isLocal,
	sameSite: serverConfig.isLocal ? null : 'lax',
});

module.exports = {
	cookieConfig,
};
