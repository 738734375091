export const itMayBeTrueTrustMe = (value: any) => {
	switch (value) {
		case 'True':
			return true;
		case 'true':
			return true;
		case true:
			return true;
		case 1:
			return true;
		case '1':
			return true;
		default:
			return false;
	}
};
