import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { updateBalance } from '@modules/balance/store/balance.actions';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { config } from '../../config';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { clearTransactions } from '../transactions/transactions.actions';
import {
	checkBalancesArray,
	updateBalances,
} from '../../modules/app/balanceService';
import { vaultSetBalance, vaultUpdateBalance } from './vault.actions';
import {
	VAULT_DEPOSIT,
	VAULT_GET_BALANCE,
	VAULT_UPDATE_BALANCE,
	VAULT_WITHDRAW,
} from './vault.constants';

const withdrawEpic = epic('withdrawEpic', {
	actions: (ofType) => ofType(VAULT_WITHDRAW),
	callback: ({ store$, action }) => {
		return of(
			fetchApiAction(
				{
					url: '/user/vault/withdraw',
					parameters: {
						Authorization: true,
						body: {
							amount: action.payload.amount,
							currency: store$.value.user.activeCurrency,
							password: action.payload.password,
						},
					},
					method: 'POST',
					loaderId: 'vaultWithdraw',
					lockByModal: true,
				},
				(data) => {
					if (!data) {
						return [];
					}
					const result = [];
					const currency = store$.value.user.activeCurrency;
					if (data.userBalance) {
						result.push(
							updateBalance(
								{
									amount: roundNumber(
										data.userBalance.amount,
										config.decimals.balance,
									),
									currency,
								},
								'vaultWithdraw',
							),
						);
					}
					if (data.vaultBalance) {
						result.push(
							vaultUpdateBalance(
								{
									amount: roundNumber(
										data.vaultBalance.amount,
										config.decimals.balance,
									),
									currency,
								},
								'vaultWithdraw',
							),
							clearTransactions(TABLE_ID.vaultHistory),
							showAlert(
								'info',
								trans({
									label:
										'{{amount}} {{currency}} - has been transferred from the vault',
									options: {
										amount: action.payload.amount,
										currency,
									},
								}),
							),
						);
					}
					return result;
				},
			),
		);
	},
});

const depositEpic = epic('depositEpic', {
	actions: (ofType) => ofType(VAULT_DEPOSIT),
	callback: ({ store$, action }) => {
		return of(
			fetchApiAction(
				{
					url: '/user/vault/deposit',
					parameters: {
						Authorization: true,
						body: {
							amount: action.payload.amount,
							currency: store$.value.user.activeCurrency,
							password: action.payload.password,
						},
					},
					method: 'POST',
					loaderId: 'vaultDeposit',
					lockByModal: true,
				},
				(data) => {
					if (!data) {
						return [];
					}
					const result = [];
					const currency = store$.value.user.activeCurrency;
					if (data.userBalance) {
						result.push(
							updateBalance(
								{
									amount: roundNumber(
										data.userBalance.amount,
										config.decimals.balance,
									),
									currency,
								},
								'vaultDeposit',
							),
						);
					}
					if (data.vaultBalance) {
						result.push(
							vaultUpdateBalance(
								{
									amount: roundNumber(
										data.vaultBalance.amount,
										config.decimals.balance,
									),
									currency,
								},
								'vaultDeposit',
							),
							clearTransactions(TABLE_ID.vaultHistory),
							showAlert(
								'info',
								trans({
									label:
										'{{amount}} {{currency}} - has been transferred to the vault',
									options: {
										amount: action.payload.amount,
										currency,
									},
								}),
							),
						);
					}
					return result;
				},
			),
		);
	},
});

const balanceEpic = epic('balanceEpic', {
	actions: (ofType) => ofType(VAULT_GET_BALANCE),
	callback: ({ store$ }) => {
		return of(
			fetchApiAction(
				{
					url: '/user/vault/balance',
					parameters: {
						Authorization: true,
					},
					loaderId: 'getVaultBalance',
					lockByModal: true,
				},
				(data) => {
					if (!data.vaultBalances || !data.vaultBalances.length) {
						return [];
					}
					const balances = store$.value.balance.balancesArray.map((balance) => {
						const valueBalance = data.vaultBalances.find(
							(el) => el.currency === balance.currency,
						);
						return {
							currency: balance.currency,
							amount: valueBalance ? valueBalance.amount : 0,
						};
					});
					return [vaultSetBalance(checkBalancesArray('vaultEpic', balances))];
				},
			),
		);
	},
});

const updateBalanceEpic = epic('updateBalanceEpic', {
	actions: (ofType) => ofType(VAULT_UPDATE_BALANCE),
	callback: ({ store$, action }) => {
		return of(
			vaultSetBalance(
				updateBalances(action.payload, store$.value.vault.balances, {
					source: 'updateBalanceEpic - vaultEpic',
					action,
				}),
				action.source,
			),
		);
	},
});

const vaultEpic = combineEpics(
	withdrawEpic,
	depositEpic,
	balanceEpic,
	updateBalanceEpic,
);

export { vaultEpic };
