import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { Image, parseImageSrc } from '../../../default/Image/Image';
import { MediumPromoStyled } from './Promotions.styled';

export const RakebackOnClickWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const dispatch = useDispatch();

	return (
		<div
			className={'onclick-wrapper onclick-wrapper-rakeback'}
			onClick={() =>
				dispatch(openModal(MODAL_ID.RAKE_BACK, 'RakebackOnClickWrapper'))
			}
		>
			{children}
		</div>
	);
};

export const RakebackPromo: FC = () => {
	return (
		<RakebackPromoStyled>
			<RakebackOnClickWrapper />
			<RakebackImage />
			<h5 className="text-style-h-6">
				{trans({
					label: 'rakeback<1/>system',
					namespace: TransNamespace.COMMON,
					components: (
						<>
							weekly
							<br />
							system
						</>
					),
				})}
			</h5>
		</RakebackPromoStyled>
	);
};

export const RakebackImage = styled(Image).attrs({
	src: '/img/promosection/rakeback/rakeback.png',
	alt: 'Digital wallet',
})`
	top: 27%;
	position: absolute;
	height: 95%;
	left: 50%;
	transform: translateX(-50%);
	transition: 300ms ease-out;
	z-index: 2;
`;

export const RakebackPromoStyled = styled(MediumPromoStyled)`
	background-image: url(${parseImageSrc('/img/promosection/rakeback/bg.svg')});
	background-repeat: no-repeat;
	background-size: cover;

	h5 {
		z-index: 3;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			${RakebackImage} {
				transform: translateX(-50%) translateY(-10px) scale(1.05);
			}
		}
	}
`;
