import { connect } from 'react-redux';
import React from 'react';
import isArray from 'lodash.isarray';
import { Subject } from 'rxjs/Subject';
import { from, zip } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
	registryEpicInject,
	registryEpicReject,
	registryReducerInject,
	registryReducerReject,
	registryRegisterPackage,
} from '../store/registry/registry.actions';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/zip';
import { capitalize } from '../modules/app/appService';
import { RegistryReducerType } from '../store/registry/registry.reducer';
import { PackageType } from '../store/registry/registry.constants';

interface ModuleConfig {
	id: string;
	callback: () => Promise<any>;
}

export interface AsyncStoreConfig {
	asyncReducers: Array<ModuleConfig>;
	asyncEpics: Array<ModuleConfig>;
	asyncDoNotReject?: boolean;
	asyncPackage?: PackageType;
}

interface AsyncStoreProps extends AsyncStoreConfig {
	packages: RegistryReducerType['packages'];
	injectReducers: any;
	injectEpics: any;
	rejectReducers: any;
	rejectEpics: any;
	registerPackage: any;
}

export enum ModuleType {
	EPIC = 'epic',
	REDUCER = 'reducer',
}

const enhance = (component, props: AsyncStoreProps) => {
	const keys = Object.keys(component);
	const componentName = keys[0];
	const WrappedComponent = component[componentName];

	// console.log('useAsyncStore', {
	//   keys,
	//   componentName,
	//   WrappedComponent,
	//   props,
	//   component,
	// });
	const moduleDefaultExport = (module) => module.default || module;

	const esModule = (module, forceArray) => {
		// console.log('esModule', {module, forceArray});
		if (isArray(module)) {
			return module.map(moduleDefaultExport);
		}
		const defaulted = moduleDefaultExport(module);
		return forceArray ? [defaulted] : defaulted;
	};

	class EnhancedComponent extends React.PureComponent {
		_componentWillUnmountSubject: Subject<unknown>;
		displayName: string;
		props: AsyncStoreProps;
		static ReducersLoaded = false;
		static EpicsLoaded = false;

		state = {
			ReducersLoaded: EnhancedComponent.ReducersLoaded,
			EpicsLoaded: EnhancedComponent.EpicsLoaded,
			mounted: false,
		};

		componentDidMount = () => {
			this.registerPackage();
			this.onInit();
		};

		registerPackage = () => {
			if (props.asyncPackage && !this.props.packages[props.asyncPackage]) {
				this.props.registerPackage(props.asyncPackage, [
					...this.getAsyncModules(ModuleType.REDUCER).map(
						(object) => `reducer_${object.id}`,
					),
					...this.getAsyncModules(ModuleType.EPIC).map(
						(object) => `epic_${object.id}`,
					),
				]);
			}
		};

		onInit = async () => {
			const isAsyncReducers = this.isAsyncModule(ModuleType.REDUCER);
			const isAsyncEpics = this.isAsyncModule(ModuleType.EPIC);

			if (isAsyncReducers || isAsyncEpics) {
				this._componentWillUnmountSubject = new Subject();

				const asyncF = this.getAsync;
				const streams: ReturnType<typeof asyncF> = [];

				if (isAsyncReducers) {
					streams.push(...this.getAsync(ModuleType.REDUCER));
				}

				if (isAsyncEpics) {
					streams.push(...this.getAsync(ModuleType.EPIC));
				}

				zip(...streams)
					.pipe(
						map((data) => {
							// console.log('zip', { data });

							const reducers = data.filter(({ type }) => {
								return type === ModuleType.REDUCER;
							});

							const epics = data.filter(({ type }) => {
								return type === ModuleType.EPIC;
							});

							for (const reducer of reducers) {
								this.handleModule(reducer);
							}

							setTimeout(() => {
								for (const epic of epics) {
									this.handleModule(epic);
								}
							}, 100);
						}),
						takeUntil(this._componentWillUnmountSubject),
					)
					.subscribe(() => {
						// TODO: clear checking is async module exist because it is done in registry.middleware on global state level, pages have async modules and between them there is no need to clear them
						// EnhancedComponent[`${capitalize(ModuleType.REDUCER)}sLoaded`] = true;
						// EnhancedComponent[`${capitalize(ModuleType.EPIC)}sLoaded`] = true;
						this.mounted();
						this._componentWillUnmountSubject.unsubscribe();
					});
			}
		};

		mounted = () => {
			this.setState({
				mounted: true,
			});
		};

		handleModule = (data) => {
			const { module, dataCallback, callback } = data;
			if (module) {
				if (callback) {
					callback({
						...dataCallback,
						module: esModule(module, true)[0],
					});
				}
			}
		};

		componentWillUnmount = () => {
			if (
				this._componentWillUnmountSubject &&
				!this._componentWillUnmountSubject.closed
			) {
				this._componentWillUnmountSubject.next();
				this._componentWillUnmountSubject.unsubscribe();
			}

			this.rejectModules();
		};

		rejectModules = () => {
			const isAsyncReducers = this.isAsyncModule(ModuleType.REDUCER, true);
			const isAsyncEpics = this.isAsyncModule(ModuleType.EPIC, true);

			// console.log('rejectModules', {isAsyncEpics, isAsyncReducers});

			if (isAsyncReducers || isAsyncEpics) {
				if (isAsyncEpics) {
					this.rejectModule(ModuleType.EPIC);
				}

				if (isAsyncReducers) {
					this.rejectModule(ModuleType.REDUCER);
				}
			}
		};

		getAsyncModules = (type: ModuleType): Array<ModuleConfig> => {
			let array = props[`async${capitalize(type)}s`];
			if (!array) {
				array = [];
			}
			if (!Array.isArray(array)) {
				array = [array];
			}
			return array;
		};

		rejectModule = (type) => {
			if (props.asyncDoNotReject) {
				return;
			}
			const array = this.getAsyncModules(type);
			const callback = this.props[`reject${capitalize(type)}s`];
			EnhancedComponent[`${capitalize(type)}sLoaded`] = false;
			// console.log('rejectModule', {type, array, EnhancedComponent});
			array.map((el) => {
				const dataCallback = {
					key: el.id,
					package: props.asyncPackage,
				};
				return callback(dataCallback);
			});
		};

		isAsyncModule = (type: ModuleType, isRemove?: boolean) => {
			// console.log('isAsyncModule', type, {
			// 	state: { ...this.state },
			// 	props: { ...props },
			// 	isRemove,
			// 	bool:
			// 		(!isRemove ? !this.state[`${capitalize(type)}sLoaded`] : true) &&
			// 		!!this.getAsyncModules(type)?.length,
			// });
			return (
				(!isRemove ? !this.state[`${capitalize(type)}sLoaded`] : true) &&
				!!this.getAsyncModules(type)?.length
			);
		};

		getAsync = (type: ModuleType) => {
			const array = this.getAsyncModules(type);
			const callback = this.props[`inject${capitalize(type)}s`];
			return this.getModules(array, callback, type);
		};

		getModules = (
			array: Array<ModuleConfig>,
			callback: any,
			type: ModuleType,
		) => {
			// console.log('getModules', {array, doRemove});
			return array.map((el) => {
				const dataCallback = {
					key: el.id,
					package: props.asyncPackage,
				};
				return from(el.callback()).pipe(
					map((module) => {
						// console.log('getModules', type, module, dataCallback);
						return {
							module,
							dataCallback,
							el,
							type,
							callback,
						};
					}),
					takeUntil(this._componentWillUnmountSubject),
				);
			});
		};

		render = () => {
			return this.state.mounted ? <WrappedComponent {...this.props} /> : false;
		};
	}

	// @ts-expect-error invalid type
	EnhancedComponent.displayName = `asyncStore(${componentName})`;

	const mapStateToProps = (state) => ({
		packages: state.registry.packages,
	});

	const mapDispatchToProps = (dispatch) => {
		return {
			injectReducers: (...argument) =>
				dispatch(registryReducerInject(...argument)),
			injectEpics: (...argument) => dispatch(registryEpicInject(...argument)),
			rejectReducers: (...argument) =>
				dispatch(registryReducerReject(...argument)),
			rejectEpics: (...argument) => dispatch(registryEpicReject(...argument)),
			registerPackage: (id, modules) =>
				dispatch(registryRegisterPackage(id, modules)),
		};
	};

	return connect(mapStateToProps, mapDispatchToProps)(EnhancedComponent);
};

export default enhance;
