import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { GameType } from '../../constants/availableGames';
import transactionService from '../../../../transactions/transactionsService';

export const useIsAutoBetInProgress = () => {
	const gameAutoBet = useAppSelector(
		(state) => state?.gameAutobet?.active,
		shallowEqual,
	);
	if (!gameAutoBet) {
		return false;
	}
	return Object.keys(gameAutoBet).some((key) => gameAutoBet[key] === 'auto');
};
export const useGameInputDisabled = (game?: GameType) =>
	useAppSelector<boolean>(
		(state) =>
			state.gameAutobet?.autoBetRolling[game || state.game?.activeGame],
		shallowEqual,
	);
export const useGameWinChance = (game?: GameType) =>
	useAppSelector<number>(
		(state) =>
			Number(state.game?.winChanceValue[game || state.game?.activeGame]),
		shallowEqual,
	);
export const useGameBetAmount = (game?: GameType) =>
	useAppSelector<number>(
		(state) => Number(state.game?.betAmount[game || state.game?.activeGame]),
		shallowEqual,
	);

export const useGamePayout = (game?: GameType) =>
	useAppSelector<number>(
		(state) => Number(state.game?.payoutValue[game || state.game?.activeGame]),
		shallowEqual,
	);
export const useGameActiveGame = () =>
	useAppSelector<GameType>((state) => state.game?.activeGame, shallowEqual);
export const useGameGamesList = () =>
	useAppSelector(
		(state) => state.game?.gamesList || [],
		(a, b) => !transactionService.isListDifferent(a, b),
	);
export const useGameFormLock = (game: GameType) =>
	useAppSelector((state) => state.game.formLock[game]);
export const useGameBetsAnimated = (game: GameType) =>
	useAppSelector(
		(state) => state.game.betsAnimated?.[game],
		(a, b) => transactionService.isDuplicate(a, b),
	);
