import { useCallback, useEffect } from 'react';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useOnMount } from '@common/hooks/useOnMount';
import { useForceUpdate } from '../app/useForceRerender';
import { translationService } from '../../client/modules/translation/translationService';
import { TransNamespace } from '../../client/modules/translation/TransNamespace';
import { getTranslationFromInstance } from '../../methods/i18n/getTranslationFromInstance';

export const useAddNamespace = ({
	namespace,
	noTranslationCheck,
	key,
}: {
	key?: any;
	namespace: TransNamespace;
	noTranslationCheck?: boolean;
}) => {
	const { i18n } = useTranslation(namespace);
	const forceRerender = useForceUpdate();

	const updateNamespace = useCallback(
		(transValue) => {
			if (!noTranslationCheck && !transValue) {
				translationService.checkTranslationExistence(
					key,
					namespace,
					forceRerender,
				);
			}
		},
		[forceRerender, key, namespace, noTranslationCheck],
	);

	const routeChangeEnd = useCallback(() => {
		const transValue = getTranslationFromInstance({ i18n, namespace, key });
		updateNamespace(transValue);
	}, [i18n, key, namespace, updateNamespace]);

	useOnMount(() => {
		Router.events.on('routeChangeComplete', routeChangeEnd);
		return () => {
			Router.events.off('routeChangeComplete', routeChangeEnd);
		};
	});

	useEffect(() => {
		const transValue = getTranslationFromInstance({ i18n, namespace, key });
		updateNamespace(transValue);
	}, [updateNamespace]);
};
