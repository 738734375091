import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { config } from '@legacyApp/client/config';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { simpleTrans } from '@legacyApp/client/modules/translation/translationService';
import { enhanceComponent } from '@legacyApp/client/wrappers/enhanceComponent';
import Logo from '@public/img/wolf-icon.svg';

import { Button } from '@legacyApp/client/components/button';
import { InstallStyle } from '@legacyApp/client/components/install/install.style';
import { StyledHeaderLogo } from '@modules/header/HeaderLogo.styled';

class Install extends PureComponent {
	static propTypes = {
		allow: PropTypes.bool,
		callback: PropTypes.any,
	};

	render() {
		if (!this.props.allow) {
			return false;
		}

		return (
			<InstallStyle>
				<div>
					<StyledHeaderLogo
						src={Logo}
						alt={simpleTrans("Crypto Casino Wolfbet -  Gambling games online")}
					/>

					<p>
						{trans({
							label: 'Add {{appName}} to Home Screen',
							options: {
								appName: config.appName,
							},
						})}
					</p>
				</div>

				<Button name="install-pwa" onClick={this.props.callback}>
					{trans({ label: 'Install' })}
				</Button>
			</InstallStyle>
		);
	}
}

Install = enhanceComponent({
	Install,
});

export { Install };
