import Redux from 'redux';
import { APP_INITIALIZED } from '../../../store/app/app.constants';
import { getPerGameInitObject, ParseValue } from '../getPerGameInitObject';

export const setPerGame = <T = any>({
	state,
	action,
	initValue,
	setAction,
	parseValue,
	initAction,
}: {
	state: T;
	action: Redux.AnyAction;
	initValue: T;
	setAction: string;
	parseValue?: ParseValue<T>;
	initAction?: string;
}) => {
	switch (action.type) {
		case initAction || APP_INITIALIZED: {
			return getPerGameInitObject(
				initValue,
				undefined,
				undefined,
				true,
				parseValue,
			);
		}
		case setAction:
			if (!action.payload.game) {
				const value =
					typeof action.payload === 'object'
						? action.payload.value
						: action.payload;
				return getPerGameInitObject(
					value,
					undefined,
					undefined,
					true,
					parseValue,
				);
			}
			return {
				...state,
				[action.payload.game]: parseValue
					? parseValue(
							action.payload.game,
							action.payload.value,
							state[action.payload.game],
					  )
					: action.payload.value,
			};
		default:
			return state;
	}
};
