import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
const defaultBetValue = false;

const NAME = 'open';

export type ValueType = boolean;

const initialState: ValueType = defaultBetValue;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const openSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setOpen: setAction,
	},
});
