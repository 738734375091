import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import {
	privateMessagesCloseActive,
	privateMessagesGetHistory,
	privateMessagesSetActiveList,
	privateMessagesSetHistory,
} from '../privateMessages/privateMessages.actions';
import { actionSimple } from '../../modules/app/actionService';
import { fetchApi } from '../fetch/fetch.thunk';
import { getTransactionsThunk } from '../transactions/thunks/getTransactions.thunk';
import {
	FRIENDS_ACCEPTED_REQUEST,
	FRIENDS_CANCELLED_REQUEST,
	FRIENDS_DELETED,
	FRIENDS_GET_LIST,
	FRIENDS_GET_ONLINE,
	FRIENDS_GET_REQUESTS,
	FRIENDS_NEW_REQUEST,
	FRIENDS_REPEAT_ONLINE,
	FRIENDS_SET_DISABLE_ONLINE,
	FRIENDS_SET_LIST,
	FRIENDS_SET_ONLINE,
	FRIENDS_SET_REQUESTS,
} from './friends.constants';

export const friendsSetList = (payload) => ({
	type: FRIENDS_SET_LIST,
	payload,
});

export const friendsSetRequests = (payload) => ({
	type: FRIENDS_SET_REQUESTS,
	payload,
});

export const friendsGetList = () => ({
	type: FRIENDS_GET_LIST,
});

export const friendsGetRequests = () => ({
	type: FRIENDS_GET_REQUESTS,
});

export const friendsNewRequest = (payload) => ({
	type: FRIENDS_NEW_REQUEST,
	payload,
});

export const friendsCancelledRequest = (payload) => ({
	type: FRIENDS_CANCELLED_REQUEST,
	payload,
});

export const friendsAcceptedRequest = (payload) => ({
	type: FRIENDS_ACCEPTED_REQUEST,
	payload,
});

export const friendsDeleted = (payload) => ({
	type: FRIENDS_DELETED,
	payload,
});

export const friendsAcceptRequest = (uuid) => {
	return (dispatch) => {
		if (dispatch(isMyUuid(uuid))) {
			return dispatch(showAlert('info', "Don't do it on yourself"));
		}
		return dispatch(
			fetchApi(
				'/user/friends/request/accept',
				{
					Authorization: true,
					body: {
						uuid,
					},
				},
				'POST',
				'sendFriendRequestAccept',
			),
		).then((data) => {
			dispatch([
				friendsGetList(),
				friendsGetRequests(),
				privateMessagesGetHistory(),
			]);
			return data.state || null;
		});
	};
};

export const friendsRejectRequest = (uuid) => {
	return (dispatch) => {
		if (dispatch(isMyUuid(uuid))) {
			return dispatch(showAlert('info', "Don't do it on yourself"));
		}
		return dispatch(
			fetchApi(
				'/user/friends/request/reject',
				{
					Authorization: true,
					body: {
						uuid,
					},
				},
				'POST',
				'sendFriendRequestReject',
			),
		).then((data) => {
			dispatch(friendsGetRequests());
			return data.state || null;
		});
	};
};

export const friendsRemove = (uuid) => {
	return (dispatch, getState) => {
		if (dispatch(isMyUuid(uuid))) {
			return dispatch(showAlert('info', "Don't do it on yourself"));
		}
		return dispatch(
			fetchApi(
				'/user/friends/remove',
				{
					Authorization: true,
					body: {
						uuid,
					},
				},
				'POST',
				'removeFriend',
			),
		).then((data) => {
			const state = getState();
			const result = [];
			if (state.friends.list.some((el) => el.user.uuid === uuid)) {
				result.push(friendsGetList());
			}
			if (!state.preferences.community.allow_messages_from_all.bool) {
				result.push(
					privateMessagesSetHistory(
						state.privateMessages.history.filter((el) => el.user.uuid !== uuid),
					),
				);
				result.push(
					privateMessagesCloseActive(
						state.privateMessages.activeList.find(
							(el) => el.user.uuid === uuid,
						),
					),
				);
			}
			if (result.length) {
				dispatch(result);
			}
			return data.state || null;
		});
	};
};

export const friendsSendRequest = (uuid) => {
	return (dispatch) => {
		if (dispatch(isMyUuid(uuid))) {
			return dispatch(showAlert('info', "Don't do it on yourself"));
		}
		return dispatch(
			fetchApi(
				'/user/friends/request',
				{
					Authorization: true,
					body: {
						uuid,
					},
				},
				'POST',
				'sendFriendRequest',
			),
		).then((data) => {
			return data.state || null;
		});
	};
};

export const friendsCheckStatus = (uuid) => {
	return (dispatch) => {
		return dispatch(
			fetchApi(
				`/user/friends/status/${uuid}`,
				{
					Authorization: true,
				},
				'GET',
				'getFriendStatus',
				undefined,
				true,
			),
		).then((data) => {
			return data;
		});
	};
};

export const friendsIgnore = (uuid) => {
	return (dispatch, getState) => {
		if (dispatch(isMyUuid(uuid))) {
			return dispatch(showAlert('info', "Don't do it on yourself"));
		}
		return dispatch(
			fetchApi(
				'/user/friends/ignore',
				{
					Authorization: true,
					body: {
						uuid,
					},
				},
				'POST',
				'setIgnoreUser',
			),
		).then((data) => {
			const state = getState();
			if (state.friends.list.some((el) => el.user.uuid === uuid)) {
				dispatch(friendsGetList());
			}
			if (state.friends.requests.some((el) => el.user.uuid === uuid)) {
				dispatch(friendsGetRequests());
			}
			if (state.transactions.active[TABLE_ID.friendsIgnored]) {
				dispatch(
					getTransactionsThunk({
						id: TABLE_ID.friendsIgnored,
						refresh: true,
					}),
				);
			}
			if (data.state === 'ignoring') {
				dispatch(
					showAlert(
						'info',
						trans({
							label: 'User {{userName}} - ignored!',
							options: {
								userName: data.user.login,
							},
						}),
					),
				);
			}
			if (!state.preferences.community.allow_messages_from_all.bool) {
				dispatch(
					privateMessagesSetHistory(
						state.privateMessages.history.filter((el) => el.user.uuid !== uuid),
					),
				);
				dispatch(
					privateMessagesSetActiveList(
						state.privateMessages.activeList.filter(
							(el) => el.user.uuid !== uuid,
						),
					),
				);
			}
			dispatch(friendsGetOnline());
			return data.state || null;
		});
	};
};

export const friendsUnignore = (uuid) => {
	return (dispatch, getState) => {
		if (dispatch(isMyUuid(uuid))) {
			return dispatch(showAlert('info', "Don't do it on yourself"));
		}
		return dispatch(
			fetchApi(
				'/user/friends/unignore',
				{
					Authorization: true,
					body: {
						uuid,
					},
				},
				'POST',
				'setUnignoreUser',
			),
		).then((data) => {
			const state = getState();
			if (state.transactions.active[TABLE_ID.friendsIgnored]) {
				dispatch(
					getTransactionsThunk({
						id: TABLE_ID.friendsIgnored,
						refresh: true,
					}),
				);
			}
			if (data.state !== 'ignoring') {
				dispatch(
					showAlert(
						'info',
						trans({
							label: 'User {{userName}} - unignored!',
							options: {
								userName: data.user.login,
							},
						}),
					),
				);
			}
			if (!state.preferences.community.allow_messages_from_all.bool) {
				dispatch(privateMessagesGetHistory());
			}
			dispatch(friendsGetOnline());
			return data.state || null;
		});
	};
};

const isMyUuid = (uuid) => {
	return (dispatch, getState) => {
		const state = getState();
		return uuid === state.user.id;
	};
};

export const friendsSetOnline = (payload) => ({
	type: FRIENDS_SET_ONLINE,
	payload,
});

export const friendsGetOnline = () => ({
	type: FRIENDS_GET_ONLINE,
});

export const friendsSetDisableOnline = actionSimple(FRIENDS_SET_DISABLE_ONLINE);

export const friendsRepeatOnline = () => ({
	type: FRIENDS_REPEAT_ONLINE,
});
