export const SPORTS_BET_SLIP_SET_BETS = 'SPORTS_BET_SLIP_SET_BETS';
export const SPORTS_BET_SLIP_SET_BETS_LIST = 'SPORTS_BET_SLIP_SET_BETS_LIST';
export const SPORTS_BET_SLIP_UPDATE_BETS_LIST_ELEMENT =
	'SPORTS_BET_SLIP_UPDATE_BETS_LIST_ELEMENT';
export const SPORTS_BET_SLIP_SET_TYPE = 'SPORTS_BET_SLIP_SET_TYPE';
export const SPORTS_BET_SLIP_SET_PLACING_BET =
	'SPORTS_BET_SLIP_SET_PLACING_BET';
export const SPORTS_BET_SLIP_SET_PLACED_BET = 'SPORTS_BET_SLIP_SET_PLACED_BET';
export const SPORTS_BET_SLIP_SET_ACCEPT_TYPE =
	'SPORTS_BET_SLIP_SET_ACCEPT_TYPE';
export const SPORTS_BET_SLIP_SET_FORM_ERROR = 'SPORTS_BET_SLIP_SET_FORM_ERROR';
