const socialConfig = {
	twitter: 'https://x.com/wolfdotbet',
	facebook: 'https://facebook.com/wolfdotbet',
	telegram: 'https://t.me/wolfdotbet',
	bitcointalk: 'https://bitcointalk.org/index.php?topic=5167730.0',
	instagram: 'https://www.instagram.com/wolfdotbet',
	discord: 'https://discord.gg/QPZ8e3zWT4'
};

module.exports = {
	socialConfig,
};
