import { isFunction } from '@common/methods/isFunction';
import { checkIsUrlWithCaptcha } from '@modules/captcha/methods/checkIsUrlWithCaptcha';
import { executeCaptcha } from '@modules/captcha/methods/executeCaptcha';
import { stringToJson } from '@common/methods/stringToJson';
import { jsonToString } from '@common/methods/jsonToString';
import { config } from '../../config';
import { isWindowVisible } from '../../../methods/app/isWindowVisible';
import { parseApiUrl } from '../../../methods/fetch/parseApiUrl';
import { createHeaders } from '../../../methods/fetch/createHeaders';
import { request } from './requestService';

class FetchClass {
	constructor() {
		this.state = {};
		this.requestHistory = {};
		this.waitingRequests = {};
		this.fromStateExpire = {};

		if (process.browser) {
			document.addEventListener('visibilitychange', () => {
				this.releaseWaitingRequests();
			});
		}
	}

	parseApiUrl(uri) {
		return parseApiUrl(uri);
	}

	getParameters(parameters, url, loaderId) {
		if (!checkIsUrlWithCaptcha(url, loaderId)) {
			return this.parseParameters(parameters);
		}
		return executeCaptcha(loaderId)
			.then((token) => {
				if (!parameters.body) {
					parameters.body = {};
				}
				parameters.body['g-recaptcha-response'] = token;
				if (!parameters.method || parameters.method === 'GET') {
					parameters.method = 'POST';
				}
				return this.parseParameters(parameters);
			})
			.catch((error) => {
				console.error('executeCaptcha - error', error);
				throw error;
			});
	}

	parseParameters(parameters) {
		if (parameters.body) {
			parameters.body =
				typeof parameters.body === 'string' ||
				parameters?.headers?.['Content-Type'] === null ||
				(parameters?.headers?.['Content-Type'] &&
					parameters?.headers?.['Content-Type'] !== 'application/json')
					? parameters.body
					: jsonToString(parameters.body);
		}
		const parsed = {
			...parameters,
			headers: {
				...createHeaders({ body: parameters.body }),
				...(parameters.headers || {}),
			},
			credentials: 'same-origin',
		};
		if (parameters?.headers?.['Content-Type'] === null) {
			delete parsed.headers['Content-Type'];
		}
		return parsed;
	}

	fetch = (url, options = {}, fromState, token = {}) => {
		// console.log('fetch', {url, options, fromState, token, x: {...this}});
		// console.log('document.hidden', !isVisible(), this.waitingRequests[url], this);

		if (this.waitingRequests[url] && !this.waitingRequests[url].released) {
			// console.log(url, 'Fetch false - waiting request', this);
			return new Promise((resolve) => {
				this.waitingRequests[url].resolve.push(resolve);
			});
		}

		if (!isWindowVisible() && !this.noRepeatOnMaintenaceUrl(url)) {
			return new Promise((resolve) => {
				this.waitingRequests[url] = {
					resolve: [resolve],
					callback: () => FetchService.fetch(url, options, fromState),
					released: false,
				};
			});
		}

		if (this.state[url] === 'pending') {
			return new Promise((resolve) => {
				// return resolve(false);
				setTimeout(() => {
					resolve(FetchService.fetch(url, options, true));
				}, 100);
			});
		}

		if (this.state[url] && fromState && !this.isFromStateExpired(url)) {
			return new Promise((resolve) => {
				const result = isFunction(this.state[url].clone)
					? this.state[url].clone()
					: this.state[url];
				if (result.status === 200) {
					return resolve(result);
				}
				delete this.state[url];
				resolve(FetchService.fetch(url, options, fromState));
			});
		}

		this.state[url] = 'pending';

		// const timeId = `time - ${url}`;
		// if (!NEXT_CONFIG.PRODUCTION) {
		//   console.time(timeId);
		// }

		// console.log({url, options});
		// if (url.indexOf('auto/play') > -1) {
		//   // console.timeEnd('autobet-request-1');
		//   console.time('autobet-request-2-api');
		// }
		return request(url, options, fromState, token).catch((error) => {
			if (this.state[url]) {
				delete this.state[url];
			}
			// console.log('request - error');
			throw error;
		});
	};

	getResponseData(response) {
		if (!response) {
			return new Promise((resolve) => resolve(false));
		}
		if (isFunction(response.json)) {
			try {
				return response.json();
			} catch (error) {
				console.error('getResponse ERROR', {
					error,
					response,
				});
				return response.text().then((result) =>
					stringToJson(result, {
						text: result,
					}),
				);
			}
		}
		return new Promise((resolve) =>
			resolve(
				stringToJson(response.responseText, {
					text: response.responseText,
				}),
			),
		);
	}

	addFromStateExpire(url, value) {
		if (value && typeof value !== 'boolean') {
			this.fromStateExpire[url] = new Date().getTime() + value;
		}
	}

	isFromStateExpired(url) {
		return (
			this.fromStateExpire[url] &&
			this.fromStateExpire[url] <= new Date().getTime()
		);
	}

	releaseWaitingRequests = () => {
		// console.log('document.hidden - releaseWaiting', !isVisible(), this);
		if (!isWindowVisible()) {
			return;
		}
		const keys = Object.keys(this.waitingRequests);
		if (!keys.length) {
			return;
		}
		keys.map((key) => {
			// console.log('document.hidden - release', key);
			this.waitingRequests[key].released = true;
			const response = this.waitingRequests[key].callback();
			this.waitingRequests[key].resolve.forEach((resolve) => {
				resolve(response);
			});
		});
		this.waitingRequests = {};
	};

	addToHistory(url, status, data, parameters) {
		if (
			// this.noRepeatOnMaintenaceUrl(url) ||
			this.isUrl(url, [
				'/chat/messages',
				'/site/stats',
				'/bet/last',
				'/user/bets',
				'/user/deposits',
				'/stats/race',
				'/user/friends',
				'/user/friends/requests',
				'/user/rakeback/levels',
				'/user/rooms/open',
				'/user/strategies',
				'/user/online',
			]) &&
			status === 200
		) {
			return;
		}
		// console.log('addToHistory', this.requestHistory);
		const json = {
			timestamp: new Date(),
			url,
			status,
			data,
			parameters,
		};
		if (!this.requestHistory[url]) {
			return (this.requestHistory[url] = [json]);
		}
		if (this.requestHistory[url].length > 5) {
			this.requestHistory[url] = this.requestHistory[url].slice(1);
		}
		this.requestHistory[url].push(json);
	}

	wasFetched(id) {
		return !!this.getRequest(id, this.requestHistory);
	}

	getRequest(id, data, strict) {
		const key = Object.keys(data).find((el) =>
			strict ? el === id : el.indexOf(id) > -1,
		);
		if (!key) {
			return false;
		}
		return data[key];
	}

	isWaiting(id) {
		return this.getRequest(id, this.waitingRequests);
	}

	isState(id) {
		return this.getRequest(id, this.state, true);
	}

	isPending(id) {
		return this.getRequest(id, this.state, true) === 'pending';
	}

	isUrl = (url, array) => {
		return array.some((el) => url.indexOf(el) > -1);
	};

	canRetryFailedUrl(url) {
		return this.isUrl(url, ['/manual/play', '/auto/play', '/flash/play']);
	}

	noRepeatOnMaintenaceUrl = (url) => {
		return this.isUrl(url, [
			'/manual/play',
			'/auto/play',
			'/flash/play',
			'/withdraw/order',
			'/user/online',
		]);
	};

	noUnavailableService = (url) => {
		return this.isUrl(url, ['/slots/play']);
	};

	clearState(query) {
		if (!query) {
			this.state = {};
		}
		const keys = Object.keys(this.state);
		keys
			.filter((key) => {
				return key.indexOf(query) > -1;
			})
			.forEach((key) => {
				delete this.state[key];
			});
	}

	isMaintenanceMode = (status, data) => {
		if (
			status === 404 &&
			data &&
			data.text &&
			data.text.indexOf(
				'<a href=//www.google.com/><span id=logo aria-label=Google>',
			) > -1
		) {
			return true;
		}
		return (
			status === 503 && data && data.error === config.errors.maintenanceMode
		);
	};
}

const FetchService = new FetchClass();

export default FetchService;
