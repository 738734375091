import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { RESET_USER_DATA } from '../user/user.constants';
import { epic } from '../../modules/app/epicService';
import {
	resetCampaignForm,
	resetPasswordForm,
	resetUsernameForm,
	resetVerificationForm,
} from './forms.actions';
import { RESET_FORMS } from './forms.constants';

const resetEpic = epic('test', {
	actions: (ofType) => ofType(RESET_FORMS, RESET_USER_DATA),
	callback: () => {
		return of([
			resetPasswordForm(),
			resetUsernameForm(),
			resetVerificationForm(),
			resetCampaignForm(),
		]);
	},
});

const formsEpic = combineEpics(resetEpic);

export { formsEpic };
