import { css } from 'styled-components';
import { isFunction } from '@common/methods/isFunction';

export const chatViewport = (callback: (viewportPadding: string) => any) => {
	if (!callback || !isFunction(callback)) {
		return '';
	}

	return css`
		.chat-visible.left-menu-hidden & {
			@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
				${callback('0px')};
			}

			@media (min-width: ${(props) =>
					props.theme.media.minWidthDesktop}) and (max-width: ${(props) =>
					props.theme.media.maxWidthDesktop}) {
				${(props) => callback(props.theme.layout.chat.width)};
			}

			@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
				${(props) => callback(props.theme.layout.chat.widthDesktop)};
			}
		}

		.chat-hidden.left-menu-visible & {
			@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
				${callback('0px')};
			}

			@media (min-width: ${(props) =>
					props.theme.media.minWidthDesktop}) and (max-width: ${(props) =>
					props.theme.media.maxWidthDesktop}) {
				${(props) => callback(props.theme.layout.chat.leftMenuWidth)};
			}

			@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
				${(props) => callback(props.theme.layout.chat.leftMenuWidthDesktop)};
			}
		}

		.chat-hidden.left-menu-hidden & {
			${callback('0px')};
		}
	`;
};
