import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { isFunction } from '@common/methods/isFunction';
import {
	hasEmailToVerify,
	hasKycToVerify,
	hasPasswordToSet,
} from '../../modules/app/notificationService';
import {
	RESET_USER_DATA,
	SET_HAS_AVAILABLE_BONUS_CODE,
	USER_HAS_EMAIL_TO_VERIFY,
	USER_HAS_KYC_TO_VERIFY,
	USER_HAS_PASSWORD_TO_SET,
} from '../user/user.constants';
import { epic, epicOldState } from '../../modules/app/epicService';
import {
	NOTIFICATION_DISABLE,
	NOTIFICATION_ENABLE,
	NOTIFICATION_ID,
} from './notification.constants';
import {
	notificationDisable,
	notificationEnable,
	setIsAnyNotification,
} from './notification.actions';

const setPasswordNotificationEpic = epic('setPasswordNotificationEpic', {
	actions: (ofType) => ofType(USER_HAS_PASSWORD_TO_SET, RESET_USER_DATA),
	callback: ({ store$ }) => {
		const isEnabled = hasPasswordToSet(store$.value);
		return of([
			isEnabled
				? notificationEnable(NOTIFICATION_ID.HAS_PASSWORD_TO_SET)
				: notificationDisable(NOTIFICATION_ID.HAS_PASSWORD_TO_SET),
		]);
	},
});

const emailVerifyNotificationEpic = epic('emailVerifyNotificationEpic', {
	actions: (ofType) => ofType(USER_HAS_EMAIL_TO_VERIFY, RESET_USER_DATA),
	callback: ({ store$ }) => {
		const isEnabled = hasEmailToVerify(store$.value);
		return of([
			isEnabled
				? notificationEnable(NOTIFICATION_ID.HAS_EMAIL_TO_VERIFY)
				: notificationDisable(NOTIFICATION_ID.HAS_EMAIL_TO_VERIFY),
		]);
	},
});

const kycVerifyNotificationEpic = epic('kycVerifyNotificationEpic', {
	actions: (ofType) => ofType(USER_HAS_KYC_TO_VERIFY, RESET_USER_DATA),
	callback: ({ store$ }) => {
		const isEnabled = hasKycToVerify(store$.value);
		return of([
			isEnabled
				? notificationEnable(NOTIFICATION_ID.HAS_KYC_TO_VERIFY)
				: notificationDisable(NOTIFICATION_ID.HAS_KYC_TO_VERIFY),
		]);
	},
});

const availableBonusCodeNotificationEpic = epic(
	'availableBonusCodeNotificationEpic',
	{
		actions: (ofType) => ofType(SET_HAS_AVAILABLE_BONUS_CODE, RESET_USER_DATA),
		callback: ({ store$ }) => {
			const isEnabled = store$.value.user.hasAvailableBonusCode;
			return of([
				isEnabled
					? notificationEnable(NOTIFICATION_ID.HAS_BONUS_CODE)
					: notificationDisable(NOTIFICATION_ID.HAS_BONUS_CODE),
			]);
		},
	},
);

const isAnyEpic = epicOldState('isAnyEpic', {
	actions: (ofType) => ofType(NOTIFICATION_ENABLE, NOTIFICATION_DISABLE),
	callback: ({ action, oldState, newState }) => {
		const isEnabled = action.type === NOTIFICATION_ENABLE;
		const result = [];
		if (
			oldState.notification.data[action.id] !== isEnabled &&
			isFunction(action.onChangeCallback)
		) {
			action.onChangeCallback();
		}
		if (newState.notification.isAny !== isEnabled) {
			const bool = Object.keys(newState.notification.data).some((el) => {
				if (el === action.id) {
					return isEnabled;
				}
				return newState.notification.data[el];
			});
			result.push(setIsAnyNotification(bool));
		}
		return result;
	},
});

const notificationEpic = combineEpics(
	emailVerifyNotificationEpic,
	setPasswordNotificationEpic,
	availableBonusCodeNotificationEpic,
	isAnyEpic,
	kycVerifyNotificationEpic,
);

export { notificationEpic };
