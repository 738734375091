import { useCallback, useReducer, useState } from 'react';

export const useForceUpdate = (): (() => void) => {
	return useReducer(() => ({}), {})[1] as () => void;
};

export const useForceUpdateWithTimeout = ({
	timeout,
	counter,
}: {
	timeout: number;
	counter: number;
}) => {
	const forceRerender = useForceUpdate();

	const [counter_, setCounter_] = useState(0);

	return useCallback(() => {
		if (counter_ < counter) {
			setTimeout(() => {
				forceRerender();
				setCounter_(counter + 1);
			}, timeout);
		}
	}, [counter, counter_, forceRerender, timeout]);
};
