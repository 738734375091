import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';

import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import { minute } from '@legacyApp/client/modules/app/time';
import { vipActions } from '../vip.actions';

export const vipStatusUpdateThunk = (noError?: boolean): AppThunk => {
	return async (dispatch, getState) => {
		const { user, app, vip } = getState();
		if (!user.isLogged) {
			if (app.initialized && vip.status === null) {
				dispatch(vipActions.statusUpdate(0));
			}
			return;
		}
		const data = await dispatch(
			fetchApi({
				url: `/user/vip/turnover?u=${user.id}`,
				parameters: {
					Authorization: true,
				},
				loaderId: LOADING_IDS.VIP_STATUS,
				lockByModal: true,
				disableErrorHandler: noError,
				fromState: minute,
			}),
		);
		// data = VIP_TURNOVER_MOCK;
		if (!data?.turnover) {
			return;
		}
		const status = Number(data.turnover);
		if (vip.status === status) {
			return;
		}
		dispatch(vipActions.statusUpdate(status));
	};
};
