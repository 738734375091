import { actionSimple } from '../../modules/app/actionService';
import {
	CANCEL_CONFIRM,
	CLEAR_CONFIRM,
	DO_CONFIRM,
	SET_CONFIRM,
	SET_CONFIRM_CALLBACK,
	SET_CONFIRM_CALLBACK_CANCEL,
} from './confirm.constants';
import { Confirm } from './confirm.types';

export const setConfirm = actionSimple<Confirm>(SET_CONFIRM);

export const setConfirmCallback = actionSimple(SET_CONFIRM_CALLBACK);

export const setConfirmCallbackDispatch = (payload) => {
	return (dispatch) => {
		dispatch(setConfirmCallback(() => payload(dispatch)));
	};
};

export const setConfirmCallbackCancel = actionSimple(
	SET_CONFIRM_CALLBACK_CANCEL,
);

export const setConfirmCallbackCancelDispatch = (payload) => {
	return (dispatch) => {
		dispatch(setConfirmCallbackCancel(() => payload(dispatch)));
	};
};

export const clearConfirm = actionSimple(CLEAR_CONFIRM);

export const doConfirm = actionSimple(DO_CONFIRM);

export const cancelConfirm = actionSimple(CANCEL_CONFIRM);
