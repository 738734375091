import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import { generateUserVipWheelFundsUrl } from '../../methods/generateUserVipWheelFundsUrl';
import { VipWheelGetFundsResponse } from '../types/vipWheel.types';

export const vipWheelGetFundsThunk = (): AppThunk<
	Promise<VipWheelGetFundsResponse>
> => {
	return (dispatch) => {
		return dispatch(
			fetchApi(
				generateUserVipWheelFundsUrl(),
				{
					Authorization: true,
				},
				'GET',
				LOADING_IDS.VIP_WHEEL_FUNDS,
			),
		).then((response) => {
			// response = {
			// 	'availableAt': null,
			// 	'multiplier': '2.00',
			// 	'availableFunds': [
			// 		{
			// 			'currency': 'btc',
			// 			'amounts': [ '0.00000150', '0.00000600', '0.00001500', '0.00015000', '0.00037500', '0.00120000' ]
			// 		},
			// 		{
			// 			'currency': 'doge',
			// 			'amounts': [ '0.00000070', '0.00000280', '0.00000700', '0.00007000', '0.00017500', '0.00056000' ]
			// 		},
			// 		{
			// 			'currency': 'shib',
			// 			'amounts': [ '242234240.00000070', '32424242.00000280', '457453.00000700', '3522242.00007000', '45235345.00017500', '0.00056000' ]
			// 		},
			// 		{
			// 			'currency': 'bch',
			// 			'amounts': [ '0.00000001', '0.00000007', '0.00000019', '0.00000190', '0.00000475', '0.00001520' ]
			// 		},
			// 		{
			// 			'currency': 'xrp',
			// 			'amounts': [ '0.00000001', '0.00000006', '0.00000017', '0.00000170', '0.00000425', '0.00001360' ]
			// 		},
			// 		{
			// 			'currency': 'trx',
			// 			'amounts': [ '1000.00000150', '999.00000600', '750.00001500', '1230.00015000', '500.00037500', '1030.00120000' ]
			// 		},
			// 		{
			// 			'currency': 'uni',
			// 			'amounts': [ '0.00000070', '0.00000280', '0.00000700', '0.00007000', '0.00017500', '0.00056000' ]
			// 		},
			// 		{
			// 			'currency': 'xlm',
			// 			'amounts': [ '0.00000001', '0.00000007', '0.00000019', '0.00000190', '0.00000475', '0.00001520' ]
			// 		},
			// 		{
			// 			'currency': 'eth',
			// 			'amounts': [ '0.00000001', '0.00000006', '0.00000017', '0.00000170', '0.00000425', '0.00001360' ]
			// 		}
			// 	]
			// };
			if (!response) {
				response = { ...response };
			}
			if (!response.availableFunds) {
				response.availableFunds = [];
				if (!response.availableAt) {
					response.availableAt = null;
				}
			}
			let availableFunds: VipWheelGetFundsResponse['availableFunds'] = null;
			if (response.availableFunds.length) {
				availableFunds = response.availableFunds.map((fundEl) => ({
					...fundEl,
					amounts: fundEl.amounts.map((amountEl, index) => ({
						amount: amountEl,
						level: index,
					})),
				}));
			}
			return {
				availableAt: response.availableAt,
				availableFunds,
				multiplier: Number(response.multiplier),
			};
		});
	};
};
