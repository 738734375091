import { initialRouterState } from 'connected-next-router';
// eslint-disable-next-line import/named
import { Context, createWrapper, MakeStore } from 'next-redux-wrapper';
import Router from 'next/router';
import { RouterState } from 'connected-next-router/types';
import { SportsBetSlipReducerType } from '@modules/sports/store/sportsBetSlip/sportsBetSlip.reducer';
import { SportsEventsDataReducerType } from '@modules/sports/store/sportsEventsData/sportsEventsData.reducer';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { RaceReducerType } from '@modules/race/types/RaceReducerType';
import { PerformanceReducerType } from '@modules/performance/types/PerformanceReducerType';
import { ModalReducerType } from '@modules/modals/store/modal.types';
import { vipReducer } from '@modules/vip/store/vip.reducer';
import { BalanceReducerType } from '@modules/balance/store/balance.reducer';
import { supportReducer } from '@modules/support/store/support.reducer';
import { alertsReducer } from '@legacyApp/client/store/alerts/alerts.reducer';
import { confirmReducer } from '@legacyApp/client/store/confirm/confirm.reducer';
import { getServerData } from '../../methods/router/getServerData';
import { handleUrlQuery } from '../../methods/router/handleUrlQuery';
import diceReducer from '../../../modules/games/Game/store/dice/dice.reducer';
import hiloReducer from '../../../modules/games/Game/store/hilo/hilo.reducer';
import gameValidationReducer from '../../../modules/games/Game/store/gameValidation/gameValidation.reducer';
import captchaReducer from '../../../modules/captcha/store/captcha.reducer';
import limboReducer from '../../../modules/games/Game/store/limbo/limbo.reducer';
import gameReducer from '../../../modules/games/Game/store/game/game.reducer';
import gameAutobetReducer from '../../../modules/games/Game/store/gameAutobet/gameAutobet.reducer';
import seedReducer from '../store.async/seed/seed.reducer';
import sportsReducer from '../../../modules/sports/store/sports/sports.reducer';
import gameSessionReducer from '../../../modules/games/Game/store/gameSession/gameSession.reducer';
import plinkoReducer from '../../../modules/games/Game/store/plinko/plinko.reducer';
import chatReducer from '../../../modules/chat/store/chat.reducer';
import { createStore } from './create.store';
import { middleware, runEpic } from './middleware';
import { reducer } from './reducer';
import { RegistryReducerType } from './registry/registry.reducer';
import { affiliateReducer } from './affiliate/affiliate.reducer';
import { twoFactorAuthenticationReducer } from './twoFactorAuthentication/twoFactorAuthentication.reducer';
import { vaultReducer } from './vault/vault.reducer';
import { notificationReducer } from './notification/notification.reducer';
import { serverDataReducer } from './serverData/serverData.reducer';
import { tipReducer } from './tip/tip.reducer';
import { loaderReducer } from './loader/loader.reducer';
import { appReducer } from './app/app.reducer';
import { userReducer } from './user/user.reducer';
import { transactionsReducer } from './transactions/transactions.reducer';
import { websocketReducer } from './websocket/websocket.reducer';
import { privateMessagesReducer } from './privateMessages/privateMessages.reducer';
import { friendsReducer } from './friends/friends.reducer';
import { converterReducer } from './converter/converter.reducer';
import { formsReducer } from './forms/forms.reducer';
import { OptionsType } from './options/options.constants';
import { preferencesReducer } from './preferences/preferences.reducer';

export interface RootState {
	websocket: ReturnType<typeof websocketReducer>;
	transactions: ReturnType<typeof transactionsReducer>;
	sports?: ReturnType<typeof sportsReducer>;
	sportsEventsData?: SportsEventsDataReducerType;
	sportsBetSlip?: SportsBetSlipReducerType;
	user: ReturnType<typeof userReducer>;
	chat?: ReturnType<typeof chatReducer>;
	modal?: ModalReducerType;
	app: ReturnType<typeof appReducer>;
	seed?: ReturnType<typeof seedReducer>;
	gameAutobet?: ReturnType<typeof gameAutobetReducer>;
	gameSession?: ReturnType<typeof gameSessionReducer>;
	game?: ReturnType<typeof gameReducer>;
	router: RouterState;
	stats: any;
	loader: ReturnType<typeof loaderReducer>;
	tip: ReturnType<typeof tipReducer>;
	registry: RegistryReducerType;
	vip: ReturnType<typeof vipReducer>;
	balance: BalanceReducerType;
	serverData: ReturnType<typeof serverDataReducer>;
	dice?: ReturnType<typeof diceReducer>;
	limbo?: ReturnType<typeof limboReducer>;
	hilo?: ReturnType<typeof hiloReducer>;
	gameValidation?: ReturnType<typeof gameValidationReducer>;
	captcha?: ReturnType<typeof captchaReducer>;
	race?: RaceReducerType;
	performance?: PerformanceReducerType;
	notification?: ReturnType<typeof notificationReducer>;
	options?: Record<OptionsType, boolean>;
	vault: ReturnType<typeof vaultReducer>;
	twoFA: ReturnType<typeof twoFactorAuthenticationReducer>;
	affiliate: ReturnType<typeof affiliateReducer>;
	privateMessages: ReturnType<typeof privateMessagesReducer>;
	friends: ReturnType<typeof friendsReducer>;
	plinko: ReturnType<typeof plinkoReducer>;
	converter: ReturnType<typeof converterReducer>;
	forms: ReturnType<typeof formsReducer>;
	preferences: ReturnType<typeof preferencesReducer>;
	support: ReturnType<typeof supportReducer>;
	alerts: ReturnType<typeof alertsReducer>;
	confirm: ReturnType<typeof confirmReducer>;
}

const makeStore: MakeStore<RootState> = (context: Context) => {
	console.log();
	handleUrlQuery(context);
	const { asPath } = Router.router || {};
	const serverData = getServerData(context);
	let initialState;
	if (asPath) {
		initialState = {
			router: initialRouterState(asPath),
		};
	}
	// console.log('getStore', {
	// 	asPath,
	// 	Router,
	// 	initialState,
	// 	context,
	// 	serverData,
	// });
	const store = createStore(
		reducer({
			serverData: serverData?.preloadedState,
		}),
		initialState,
		middleware(),
	);
	if (process.browser) {
		runEpic();
	}
	return store;
};

export const wrapper = createWrapper<RootState>(makeStore, {
	debug: !!NEXT_CONFIG.DEV,
});
