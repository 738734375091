import { css } from 'styled-components';
import { lighten, rgba } from 'polished';

import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';

// .cut-text
export const cutTextStyle = css`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

// .box-no-height
export const boxNoHeight = css`
	float: left;
	width: 100%;
`;

// .box
export const box = css`
	${boxNoHeight};
	height: 100%;
`;

// .no-wrap
export const noWrap = css`
	white-space: nowrap;
`;

// @mixin borderRadius
export const borderRadius = css`
	border-radius: ${(props) => props.theme.layout.borderRadius}!important;
`;

// .no-hover
export const noHover = css`
	&:hover {
		background-color: inherit;
		color: inherit;
		opacity: inherit !important;
	}
`;

// .container
export const container = css`
	${box};
	margin: 10px 0;
`;

// .flex
export const flex = css`
	display: flex !important;
`;

// .flex-wrap
export const flexWrap = css`
	${flex};
	flex-wrap: wrap;
`;

// .flex-column
export const flexColumn = css`
	${flex};
	flex-direction: column;
`;

// .flex-center-column
export const flexCenterColumn = css`
	${flex};
	align-items: center !important;
`;

// .flex-start-column
export const flexStartColumn = css`
	${flex};
	align-items: flex-start !important;
`;

// .flex-center
export const flexCenter = css`
	${flex};
	align-items: center !important;
	justify-content: center !important;
`;

// .flex-horizontal-center
export const flexHorizontalCenter = css`
	${flex};
	justify-content: center !important;
`;

// .flex-horizontal-start
export const flexHorizontalStart = css`
	${flex};
	justify-content: flex-start !important;
`;

// .flex-vertical-start
export const flexVerticalStart = css`
	${flex};
	align-items: flex-start !important;
`;

// .flex-vertical-center
export const flexVerticalCenter = css`
	${flex};
	align-items: center !important;
`;

// .flex-space-between
export const flexSpaceBetween = css`
	${flex};
	justify-content: space-between;
`;

// .flex-space-around
export const flexSpaceAround = css`
	${flex};
	justify-content: space-around;
`;

// .hint
export const hint = css`
	border-color: ${(props) => props.theme.colors.secondText};
	color: ${(props) => props.theme.colors.secondText};
`;

// .hint-dark
export const hintDark = css`
	border-color: ${(props) => props.theme.colors.inputBackgroundSolid};
	color: ${(props) => props.theme.colors.inputBackgroundSolid};
`;

export const hintParagraph = css`
	${hint};
	${fontSize({
		sizeDiff: -3,
		sizeDesktopDiff: -4,
		sizeBigScreenDiff: -5,
		important: true,
	})}
`;

// mixin chatIcon
export const chatIcon = css`
	align-items: center;
	background-color: ${(props) => props.theme.colors.inputBackgroundSolid};
	border-radius: 100%;
	bottom: 20px;
	box-shadow: 0 0 17px 0
		${(props) => lighten(0.2, props.theme.colors.inputBackground)};
	cursor: pointer;
	display: flex;
	font-size: 18px;
	height: 50px;
	justify-content: center;
	position: fixed;
	transition: ${(props) => props.theme.layout.transition};
	width: 50px;
	z-index: 999; // 3

	&:hover {
		opacity: 0.8;
	}
`;

export const transition = css`
	transition: ${(props) => props.theme.layout.transition};
`;

// .onclick
export const onclick = css`
	cursor: pointer;
`;

// .noclick
export const noclick = css`
	pointer-events: none !important;

	* {
		pointer-events: none !important;
	}
`;

// .user-select-text
export const userSelectText = css`
	user-select: text !important;

	* {
		user-select: text !important;
	}
`;

// .clearfix
export const clearfix = css`
	&:after {
		clear: both;
		content: '';
		display: table;
	}
`;

export const inputHeightWithMove = (x = 0) => css`
	height: ${(props) => calcStyle({ a: props.theme.layout.input.height, b: x })};

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		height: ${(props) =>
			calcStyle({ a: props.theme.layout.input.heightDesktop, b: x })};
	}
`;

// mixin inputHeight
export const inputHeight = css`
	${inputHeightWithMove()};
`;

export const userSelectTextStyle = css`
	user-select: text !important;

	* {
		user-select: text !important;
	}
`;

export const alignRight = css`
	text-align: right;
`;

export const alignCenter = css`
	text-align: center;
`;

export const textDisabled = css`
	color: ${(props) => props.theme.colors.textDisabled};
`;

export const bannerBox = css`
	${boxNoHeight};
	background-color: ${(props) => props.theme.colors.rowTitleBg};
	border-radius: ${(props) => props.theme.layout.borderRadius};
	padding: 10px;
	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		padding: 15px;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
		padding: 20px;
	}
`;

// .icon__arow-down
export const iconArrowDown = css`
	margin-top: -2px;

	&:after {
		content: '';
		width: 6px;
		height: 6px;
		display: block;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(45deg);
	}

	&.opened {
		margin-top: 2px;

		&:after {
			transform: rotate(-135deg);
		}
	}
`;

export const verticalMatchDetailsLayoutRow = css`
	display: grid;
	font-family: ${(props) => props.theme.text.fonts.Black};
	grid-auto-flow: column;
	grid-template-rows: 50% 50%;
	${fontSize({ size: '12px', sizeDesktop: '14px', sizeBigScreen: '16px' })};

	span {
		display: inline-block;
		${cutTextStyle};
	}
`;

export const verticalMatchDetailsLayoutTitle = css`
	align-items: flex-end;
	color: ${(props) => rgba(props.theme.colors.text, 0.35)};
	display: flex;
	${fontSize({ size: '8px', sizeDesktop: '10px', sizeBigScreen: '12px' })};
	font-family: ${(props) => props.theme.text.fonts.Black};
	left: 0;
	position: absolute;
	top: -18px;
	white-space: nowrap;

	@media (max-width: ${(props) => props.theme.media.maxWidthDesktop}) {
		top: -16px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		top: -14px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		font-size: 7px;
		top: -12px;
	}
`;
