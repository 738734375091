import {
	BIND_PRIVATE_MESSAGES_SOCKET_EVENT,
	BIND_SOCKET_PRIVATE_CHANNEL_EVENT,
	SET_SOCKET_CHANNEL,
	UNBIND_SOCKET_CHANNEL_EVENT,
	UNBIND_SOCKET_PRIVATE_CHANNEL_EVENT,
	WEBSOCKET_SET_CONNECTION_STATE,
	WebsocketChannelType,
} from './websocket.constants';

export const setSocketChannel = (name, data) => {
	return {
		type: SET_SOCKET_CHANNEL,
		name,
		data,
	};
};

export const bindSocketPrivateChannelEvent = (name, callback) => {
	return {
		type: BIND_SOCKET_PRIVATE_CHANNEL_EVENT,
		name,
		callback,
	};
};

export const unbindSocketPrivateChannelEvent = (name) => {
	return {
		type: UNBIND_SOCKET_PRIVATE_CHANNEL_EVENT,
		name,
	};
};

export const bindPrivateMessagesSocketEvent = (name, callback) => {
	return {
		type: BIND_PRIVATE_MESSAGES_SOCKET_EVENT,
		name,
		callback,
	};
};

export const unbindSocketChannel = (
	channel: WebsocketChannelType,
	name?: string,
) => {
	return {
		type: UNBIND_SOCKET_CHANNEL_EVENT,
		channel,
		name,
	};
};

export const websocketSetConnectionState = (state) => {
	return {
		type: WEBSOCKET_SET_CONNECTION_STATE,
		payload: state,
	};
};
