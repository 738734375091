import { css } from 'styled-components';
import { parseImageSrc } from '@common/components/default/Image/Image';
import { FooterStyle } from '@common/components/footer/Footer';

export const transactionsBackground = css`
	position: relative;

	&:after,
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:after {
		background: url(${parseImageSrc('/img/main/transactions-bg.png')});
		background-position: top;
		//background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 0;
	}

	&:before {
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(5, 27, 56, 1) 15%
		);
		z-index: 0;
	}

	.main {
		background-color: transparent;
		z-index: 1;
	}

	.header {
		background-color: transparent;
	}

	${FooterStyle} {
		z-index: 1;
	}
`;
