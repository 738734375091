import {
	NOTIFICATION_DISABLE,
	NOTIFICATION_ENABLE,
	NOTIFICATION_ID,
	SET_IS_ANY_NOTIFICATION,
} from './notification.constants';

export const notificationEnable = (
	id: NOTIFICATION_ID,
	onChangeCallback?: () => void,
) => {
	return {
		type: NOTIFICATION_ENABLE,
		id,
		onChangeCallback,
	};
};

export const notificationDisable = (
	id: NOTIFICATION_ID,
	onChangeCallback?: () => void,
) => {
	return {
		type: NOTIFICATION_DISABLE,
		id,
		onChangeCallback,
	};
};

export const setIsAnyNotification = (payload) => {
	return {
		type: SET_IS_ANY_NOTIFICATION,
		payload,
	};
};
