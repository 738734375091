import { Howl, Howler } from 'howler';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { Sounds } from '@common/constants/config/sounds';
import { isAudioActive } from '../../store/audio/audio.actions';

class audioClass {
	public srcPrefix: string;
	public sounds: Partial<Record<Sounds, Howl>>;
	public intervals: Partial<Record<Sounds, NodeJS.Timeout>>;
	public volume: number;

	constructor() {
		this.srcPrefix = NEXT_CONFIG.PUBLIC_SRC + '/audio/';
		this.sounds = {};
		this.intervals = {};
		this.volume = 0.1;
	}

	play(id: Sounds, interval: number | boolean = false, volume?: number) {
		if (!this.isAudioActive(id) || !this.parseSoundId(id)) {
			return;
		}

		const sound = this.getSound(id);
		if (this.isPlaying(sound)) {
			sound.stop();
		}
		if (volume) {
			this.setVolume(id, volume);
		}
		if (this.intervals[id]) {
			clearInterval(this.intervals[id]);
		}
		if (typeof interval !== 'number') {
			sound.loop(!!interval);
		} else if (interval && interval >= 1) {
			this.intervals[id] = setInterval(() => {
				sound.play();
			}, interval);
			return;
		}
		return sound.play();
	}

	isPlaying(sound: Howl) {
		return sound.playing();
	}

	getSrc(fileName: Sounds): string {
		if (!fileName) {
			return '';
		}
		return `${this.srcPrefix}${this.parseSoundId(fileName)}.mp3`;
	}

	setVolume(id: Sounds, volume: number = this.volume) {
		this.getSound(id).volume(volume);
	}

	initSound(id: Sounds): Howl {
		if (!id || !this.parseSoundId(id)) {
			return;
		}
		this.sounds[id] = new Howl({
			src: [this.getSrc(id)],
		});
		this.setVolume(id);
		return this.sounds[id];
	}

	getSound(id: Sounds): Howl {
		return !this.sounds[id] ? this.initSound(id) : this.sounds[id];
	}

	isAudioActive(id?: Sounds): boolean {
		// console.log('isAudioActive', {
		//   disableSound: !dispatch(isAudioActive()),
		//   bool: dispatch(isAudioActive()),
		// });
		if (!this.parseSoundId(id)) {
			return false;
		}
		return isAudioActive();
	}

	muteAll(bool: boolean) {
		if (!this.isAudioActive()) {
			return;
		}
		Howler.mute(bool);
	}

	stop(id: Sounds) {
		if (!this.isAudioActive(id)) {
			return;
		}
		return this.getSound(id).stop();
	}

	backgroundAudio(bool = true) {
		const func = bool ? 'play' : 'stop';
		this[func](Sounds.background, true);
		this[func](Sounds['every-30-bg'], 10000);
	}

	stopAll() {
		Object.keys(this.sounds).forEach((id) => {
			this.getSound(id as Sounds).stop();
		});
		Object.keys(this.intervals).forEach((id) => {
			clearInterval(this.intervals[id]);
		});
	}

	startAll() {
		this.backgroundAudio(true);
	}

	parseSoundId(id: Sounds) {
		return id;
	}
}

const audioService = new audioClass();

export default audioService;
