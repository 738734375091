// eslint-disable-next-line import/named
import { IntersectionOptions, useInView } from 'react-intersection-observer';

const DISABLE = process.browser ? !('IntersectionObserver' in window) : true;

export const useViewport = (options: IntersectionOptions) => {
	const { ref, inView } = useInView({
		...options,
		skip: DISABLE,
		initialInView: DISABLE,
	});

	return {
		active: inView,
		ref,
	};
};
