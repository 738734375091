import uniloc from 'uniloc';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { removeEmptyProperties } from '../methods/removeEmptyProperties';

interface Routes {
	[key: string]: string;
}

export enum ROUTE {
	slotsHomePage = 'slotsHomePage',
	slotPage = 'slotPage',
	slotAdminPage = 'slotAdminPage',
	slotMobilePage = 'slotMobilePage',
	slotGroupPage = 'slotGroupPage',
	slotCategoryPage = 'slotCategoryPage',
	slotProviderPage = 'slotProviderPage',
	slotSearchPage = 'slotSearchPage',
	slotFavorites = 'slotFavorites',
	slotNewGames = 'slotNewGames',
	slotCasino = 'slotCasino',
	apiSlotGameInfo = 'apiSlotGameInfo',
	apiSlotGameAlternativeSlug = 'apiSlotGameAlternativeSlug',
	apiSlotGamesList = 'apiSlotGamesList',
	apiPlaySlotGame = 'apiPlaySlotGame',
	apiSummarizeBets = 'apiSummarizeBets',
	apiExternalPlaySlotGame = 'apiExternalPlaySlotGame',
	apiExternalPlaySlotDemoGame = 'apiExternalPlaySlotDemoGame',
	apiSlotGamesByCategory = 'apiSlotGamesByCategory',
	apiSlotSearch = 'apiSlotSearch',
	apiSlotProviders = 'apiSlotProviders',
	apiSlotFavoriteGames = 'apiSlotFavoriteGames',
	apiSlotFavoriteGamesAll = 'apiSlotFavoriteGamesAll',
	apiSlotGetFavoriteGames = 'apiSlotGetFavoriteGames',
	apiSlotGetNewGames = 'apiSlotGetNewGames',
	apiSlotsCategoryGroup = 'apiSlotsCategoryGroup',
	apiSlotIsFavoriteGames = 'apiSlotIsFavoriteGames',
	apiSlotCategories = 'apiSlotCategories',
	apiUserVerificationStatus = 'apiUserVerificationStatus',
	apiUserVerificationStep = 'apiUserVerificationStep',
	apiUserVipWheelFunds = 'apiUserVipWheelFunds',
	apiUserVipWheelClaim = 'apiUserVipWheelClaim',
	apiUserBonusCodes = 'apiUserBonusCodes',
	apiCheckVersion = 'apiCheckVersion',
	apiSportsBetslipCashout = 'apiSportsBetslipCashout',
	apiSiteCurrencies = 'apiSiteCurrencies',
	apiSiteWallets = 'apiSiteWallets',
	apiStatsRace = 'apiStatsRace',
	apiStatsRaceLast = 'apiStatsRaceLast',
	apiUserStatsRace = 'apiUserStatsRace',
	apiSportsBookUserBetSlipHistory = 'apiSportsBookUserBetSlipHistory',
	apiCheckAccess = 'apiCheckAccess',
	apiUserBonusSummary = 'apiUserBonusSummary',
	apiCheckInsaneBonusCode = 'apiCheckInsaneBonusCode',
	apiCms = 'apiCms',
	apiCurrenciesCash = 'apiCurrenciesCash',
	apiProvidersCash = 'apiProvidersCash',
	apiDepositOfferCash = 'apiDepositOfferCash',
	apiUserFiatDepositOrder = 'apiUserFiatDepositOrder',
	apiUserFiatDeposits = 'apiUserFiatDeposits',
	apiNewFeatures = 'apiNewFeatures',
	userSportsBetSlipsHistory = 'userSportsBetSlipsHistory',
	userSportsBetSlipsHistoryV1 = 'userSportsBetSlipsHistoryV1',
	userVipRakeBackFunds = 'userVipRakeBackFunds',
	userVipRakeBackFundsClaim = 'userVipRakeBackFundsClaim',
}

const routes: Routes = {
	[ROUTE.slotsHomePage]: '/',
	[ROUTE.slotCategoryPage]: '/slots/:category',
	[ROUTE.slotProviderPage]: '/slots/provider/:provider',
	[ROUTE.slotGroupPage]: '/slots/group/:slug',
	[ROUTE.slotPage]: '/slots/game/:slug',
	[ROUTE.slotAdminPage]: '/slots/game/:slug/admin',
	[ROUTE.slotMobilePage]: '/slots/game/:slug/mobile/:mode',
	[ROUTE.slotSearchPage]: '/slots/search',
	[ROUTE.slotFavorites]: '/slots/favorite',
	[ROUTE.slotNewGames]: '/slots/new-games',
	[ROUTE.slotCasino]: '/casino',
	[ROUTE.apiSlotSearch]: '/v2/slots/search',
	[ROUTE.apiSlotGamesList]: '/v2/slots/game',
	[ROUTE.apiSlotGameInfo]: '/v2/slots/operator/info/:gameSlug',
	[ROUTE.apiSlotGameAlternativeSlug]:
		'/v2/slots/operator/alternative-slug/:gameSlug',
	[ROUTE.apiSlotProviders]: '/v2/slots/provider/list',
	[ROUTE.apiSlotCategories]: '/v2/slots/category/list',
	[ROUTE.apiPlaySlotGame]: '/v2/slots/play/:type/:operatorId/:gameId',
	[ROUTE.apiSlotGamesByCategory]: '/v2/slots/game/list/category/:categoryId',
	[ROUTE.apiSummarizeBets]: '/v2/slots/bets/summarize',
	[ROUTE.apiSlotFavoriteGames]: '/v2/slots/favorite-games',
	[ROUTE.apiSlotFavoriteGamesAll]: '/v2/slots/favorite-games/all',
	[ROUTE.apiSlotGetFavoriteGames]: '/v2/slots/favorite-games/:uuid',
	[ROUTE.apiSlotIsFavoriteGames]: '/v2/slots/favorite-games/check',
	[ROUTE.apiSlotGetNewGames]: '/v2/slots/game/new-games',
	[ROUTE.apiSlotsCategoryGroup]: '/v2/slots/category/group',
	[ROUTE.apiUserVerificationStatus]: '/user/kyc/status',
	[ROUTE.apiUserVerificationStep]: '/user/kyc/step/:level',
	[ROUTE.apiUserVipWheelFunds]: '/user/wheel/funds',
	[ROUTE.apiUserVipWheelClaim]: '/user/wheel/claim',
	[ROUTE.apiUserBonusCodes]: '/user/bonus-codes',
	[ROUTE.apiCheckVersion]: '/check_version',
	[ROUTE.apiCheckAccess]: '/check_access_denied',
	[ROUTE.apiSportsBetslipCashout]: '/betslip/cashout',
	[ROUTE.apiSiteCurrencies]: '/site/currencies',
	[ROUTE.apiSiteWallets]: '/v2/site/wallets',
	[ROUTE.apiStatsRace]: '/stats/race',
	[ROUTE.apiStatsRaceLast]: '/stats/race-last',
	[ROUTE.apiUserStatsRace]: '/user/stats/race',
	[ROUTE.apiSportsBookUserBetSlipHistory]:
		'/v2/sportsbook/user/betslips/:state',
	[ROUTE.userSportsBetSlipsHistory]: '/user/sports/history/:state',
	[ROUTE.userSportsBetSlipsHistoryV1]: '/user/sports/history/:state/v1',
	[ROUTE.userVipRakeBackFunds]: '/user/rakeback/funds',
	[ROUTE.userVipRakeBackFundsClaim]: '/user/rakeback/withdraw',
	[ROUTE.apiUserBonusSummary]: '/user/stats/bonus-summary',
	[ROUTE.apiCheckInsaneBonusCode]: '/user/bonus-codes/check',
	[ROUTE.apiCms]: '/:collection',
	[ROUTE.apiCurrenciesCash]: '/v2/currencies/cash',
	[ROUTE.apiProvidersCash]: '/v2/providers/cash',
	[ROUTE.apiDepositOfferCash]: '/v2/deposit/offer/cash',
	[ROUTE.apiUserFiatDepositOrder]: '/v2/user/fiat-deposit/order',
	[ROUTE.apiUserFiatDeposits]: '/v2/user/fiat-deposits',
	[ROUTE.apiNewFeatures]: '/api/new_features',
};

const getUnilocRoutes = (routesList: Routes, methodType = 'GET'): object => {
	return Object.keys(routesList).reduce(
		(accumulator: any, current: string): object => {
			accumulator[current] = `${methodType} ${routesList[current]}`;
			return accumulator;
		},
		{},
	);
};

export type GenerateUrl = (name: ROUTE, options?: {}) => string;

const ROUTER = uniloc(getUnilocRoutes(routes));
export const generateUrl: GenerateUrl = (name, options) => {
	try {
		return ROUTER.generate(name, removeEmptyProperties(options));
	} catch (error) {
		sentryError(new Error(`Error in generateUrl: ${name}`), {
			name,
			options,
			error,
		});
		return '';
	}
};
