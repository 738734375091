import { combineReducers } from 'redux';
import { RESET_USER_DATA } from '../user/user.constants';
import { PREFERENCES_SET } from './preferences.constants';

const getObject = (text, bool = false) => ({
	bool,
	text,
});

const init = {
	privacy: {
		public_total_profit: getObject('Show total profit in public'),
		public_total_wagered: getObject('Show total wagered in public'),
		public_bets: getObject('Show bets in public'),
	},
	community: {
		block_all_friend_requests: getObject('Block all friend requests'),
		block_friend_requests_notifications: getObject(
			'Block friend request notifications',
		),
		allow_messages_from_all: getObject(
			'Allow private messages from non friends',
		),
		show_messages_notifications: getObject(
			'Show private message notifications',
		),
	},
};

const reducer = (type) => {
	return (
		state = init[type] || {},
		action = {
			type: '',
		},
	) => {
		let newState;
		switch (action.type) {
			case PREFERENCES_SET:
				if (action.data_type !== type) {
					return state;
				}
				newState = {
					...state,
				};
				Object.keys(action.payload).forEach((key) => {
					if (!newState[key]) {
						newState[key] = {
							bool:
								action.payload[key].bool !== undefined
									? action.payload[key].bool
									: action.payload[key],
							text: key,
						};
					} else {
						newState[key].bool =
							action.payload[key].bool !== undefined
								? action.payload[key].bool
								: action.payload[key];
					}
				});
				return newState;
			case RESET_USER_DATA:
				return init[type];
			default:
				return state;
		}
	};
};

const privacyReducer = reducer('privacy');

const communityReducer = reducer('community');

const preferencesReducer = combineReducers({
	privacy: privacyReducer,
	community: communityReducer,
});

export { preferencesReducer };
