import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { theme } from '@legacyApp/client/modules/style/theme';
import { getClientSize } from '@common/methods/getClientSize';

export enum DeviceType {
	DESKTOP_BIG = 'desktopBig',
	DESKTOP = 'desktop',
	TABLET = 'tablet',
	TABLET_SMALL = 'tabletSmall',
	MOBILE = 'mobile',
	MOBILE_SMALL = 'mobileSmall',
}

export const DeviceTypeMinWidth = {
	[DeviceType.DESKTOP_BIG]: Number(
		theme.media.minWidthBigDesktop.replace('px', ''),
	),

	[DeviceType.DESKTOP]: Number(theme.media.minWidthDesktop.replace('px', '')),

	[DeviceType.TABLET]: Number(theme.media.minWidthTablet.replace('px', '')),

	[DeviceType.TABLET_SMALL]: Number(
		theme.media_v2.min_tablet_small.replace('px', ''),
	),

	[DeviceType.MOBILE]: Number(theme.media.minWidthMobile.replace('px', '')),

	[DeviceType.MOBILE_SMALL]: 0,
} as const;

export const getDeviceType = (clientWidth?: number) => {
	if (!process.browser) {
		return DeviceType.DESKTOP;
	}

	if (!isTrueOrZero(clientWidth)) {
		clientWidth = getClientSize().width;
	}

	if (clientWidth >= DeviceTypeMinWidth[DeviceType.DESKTOP_BIG]) {
		return DeviceType.DESKTOP_BIG;
	}

	if (
		clientWidth >= DeviceTypeMinWidth[DeviceType.DESKTOP] &&
		clientWidth < DeviceTypeMinWidth[DeviceType.DESKTOP_BIG]
	) {
		return DeviceType.DESKTOP;
	}

	if (
		clientWidth >= DeviceTypeMinWidth[DeviceType.TABLET] &&
		clientWidth < DeviceTypeMinWidth[DeviceType.DESKTOP]
	) {
		return DeviceType.TABLET;
	}

	if (
		clientWidth >= DeviceTypeMinWidth[DeviceType.TABLET_SMALL] &&
		clientWidth < DeviceTypeMinWidth[DeviceType.TABLET]
	) {
		return DeviceType.TABLET_SMALL;
	}

	if (
		clientWidth >= 350 &&
		clientWidth < DeviceTypeMinWidth[DeviceType.TABLET_SMALL]
	) {
		return DeviceType.MOBILE;
	}

	if (clientWidth < 350) {
		return DeviceType.MOBILE_SMALL;
	}
};
