import React, { FC } from 'react';
import { Trans } from '@legacyApp/client/modules/translation/translate';
import { StyledVipLevel } from './level.styled';

interface LevelProps {
	color?: boolean;
	level: number;
	noUnderline?: boolean;
}

export const Level: FC<LevelProps> = ({ color, level, noUnderline }) => {
	return (
		<StyledVipLevel
			$level={level}
			$isColor={color}
			$isNoUnderline={noUnderline}
			className="vip-label"
		>
			{level ? (
				<>
					{level} <Trans label={'level'} />
				</>
			) : (
				<Trans label={'Unranked'} />
			)}
		</StyledVipLevel>
	);
};
