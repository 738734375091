import { SET_TIP_MIN_VALUES, SUBMIT_TIP } from './tip.constants';

const submitTip = (payload) => {
	return {
		type: SUBMIT_TIP,
		payload,
	};
};

const setTipMinValues = (payload) => {
	return {
		type: SET_TIP_MIN_VALUES,
		payload,
	};
};

export { submitTip, setTipMinValues };
