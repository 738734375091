import BigNumber from 'bignumber.js';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { config } from '../../config';

class MathServiceClass {
	// BigNumber operations
	// Multiply a and b
	multiply(a = 1, b = 1, precision = config.valueMaxPrecision) {
		if (!a) {
			a = 0;
		}
		b = isNaN(parseFloat(b)) ? 1 : parseFloat(b);
		const bigA = new BigNumber(`${a}`);
		const result = bigA.times(`${b}`);
		if (result.decimalPlaces() < precision) {
			precision = result.decimalPlaces();
		}
		return roundNumber(result, precision);
	}

	// Divide a and b
	divide(a, b = 1, precision = config.valueMaxPrecision) {
		if (!a) {
			return 0;
		}
		const bigA = new BigNumber(`${a}`);
		const result = bigA.dividedBy(`${b}`);
		if (result.decimalPlaces() < precision) {
			precision = result.decimalPlaces();
		}
		return roundNumber(result, precision);
	}

	// Add a and b
	add(a = 1, b = 1) {
		if (!a) {
			a = 0;
		}
		const bigA = new BigNumber(`${a}`);
		return parseFloat(bigA.plus(`${b}`));
	}

	// Subtract b from a
	subtract(a = 1, b = 1) {
		if (!a) {
			a = 0;
		}
		const bigA = new BigNumber(`${a}`);
		return parseFloat(bigA.minus(`${b}`));
	}

	// Return percent of number
	percentage(number = 1, percentage = 100) {
		if (!number) {
			number = 1;
		}
		const bigPercentage = new BigNumber(percentage);
		return parseFloat(bigPercentage.times(number).dividedBy(100));
	}

	getMinValueByPrecision(precision = config.decimals.bet, value = 10) {
		return Math.pow(value, precision * -1);
	}

	parseSeparatorFloat(string, separator) {
		if (typeof string === 'number') {
			return string;
		}
		if (typeof string !== 'string') {
			return undefined;
		}
		string = string.split(separator);
		string = string.join('');
		return parseFloat(string);
	}

	getDecimalPartLength(value, separator = '.') {
		value = value.toString();
		return value.split(separator)[0].length;
	}

	getFloatPartLength(value, separator = '.') {
		value = value.toString();
		return value.split(separator)[1] ? value.split(separator)[1].length : 0;
	}

	// Return exact float value of given number (number might be string)
	parseBigFloat(value, precision = 8) {
		value = value.toString();
		value = value.split(',').join('.');
		return parseFloat(new BigNumber(parseFloat(value)).toFixed(precision));
	}

	// Return exact float value of given number (number might be string)
	parseBigFloatWithTrailingZeros(value, precision = 8) {
		value = value.toString();
		value = value.split(',').join('.');
		return parseFloat(
			new BigNumber(parseFloat(value)).toFixed(precision),
		).toFixed(precision);
	}

	toFixedNoRound(a, precision = 8, options) {
		const bigA = new BigNumber(`${a}`);
		let fixedValue = bigA.toFixed(precision, 1);
		if (options && options.fixedZero && bigA.equals(0)) {
			fixedValue = parseFloat(fixedValue).toFixed(precision);
		}
		return fixedValue;
	}

	parseValue(number, precision = 8) {
		if (typeof number === 'undefined') {
			return null;
		}
		const rounded = roundNumber(new BigNumber(`${number}`), precision);
		return isNaN(rounded) ? '' : rounded;
	}

	abs(value) {
		const x = new BigNumber(value);
		return x.abs().toNumber();
	}

	isNumberDiff(a, b) {
		return parseFloat(a) !== parseFloat(b) && a !== b;
	}

	currencyValue(amount, currency, minDecimal, maxDecimal) {
		if (!isTrueOrZero(maxDecimal)) {
			maxDecimal =
				currency && currency.toLowerCase() === 'usd' ? 2 : config.decimals.bet;
		}
		if (!isTrueOrZero(minDecimal)) {
			minDecimal = 0;
		}
		const options = {
			minimumFractionDigits: minDecimal,
			maximumFractionDigits: minDecimal > maxDecimal ? minDecimal : maxDecimal,
		};
		if (!currency) {
			return parseFloat(amount)
				.toLocaleString('en-US', options)
				.split(',')
				.join(' ');
		}
		if (currency.toLowerCase() === 'usd') {
			const value = parseFloat(amount)
				.toLocaleString('en-US', {
					...options,
					style: 'currency',
					currency: 'USD',
				})
				.split(',')
				.join(' ')
				.replace('-', '');

			return amount >= 0
				? value
				: `${value.slice(0, value.indexOf('$') + 1)}-${value.slice(
						value.indexOf('$') + 1,
				  )}`;
		}
		if (currency.length !== 3) {
			return `${parseFloat(amount)
				.toLocaleString('ru-RU', options)
				.split(',')
				.join('.')} ${currency.toUpperCase()}`;
		}
		return parseFloat(amount)
			.toLocaleString('ru-RU', {
				...options,
				style: 'currency',
				currency: currency.toUpperCase(),
			})
			.split(',')
			.join('.');
	}

	randomIntInRange = (min, max) => {
		// min and max included
		return Math.floor(Math.random() * (max - min + 1) + min);
	};
}

const mathService = new MathServiceClass();

export { MathServiceClass, mathService };
