import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '@legacyApp/client/modules/style/theme';
import { MessageTextStyled } from '@modules/chat/components/message/components/text/MessageTextStyled';

export const StyledChatGlobal = createGlobalStyle<{ theme: ThemeType }>`
	.chat {
		&-russian {
			${MessageTextStyled} span {
				font-family: sans-serif;
			}
		}
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		#__next {
			> [class*='page-'] {
				&:not(.page-register):not(.page-login),
				&:not(.page-register):not(.page-login) .header{
					transition: padding-right 50ms, padding-left 50ms;
				}

				&.page-login,
				&.page-register {
					.chat-wrapper {
						display: none;
					}
				}
			}

			> [class*='page-'].chat-visible,
			> [class*='page-'].chat-visible .header {
				margin-right: ${(props) => props.theme.layout.chat.width};
				width: calc(100% - ${(props) => props.theme.layout.chat.width});

				@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
					margin-right: ${(props) => props.theme.layout.chat.widthDesktop};
					width: calc(100% - ${(props) => props.theme.layout.chat.widthDesktop});
				}
			}

			> [class*='page-'].left-menu-visible,
			> [class*='page-'].left-menu-visible .header {
				padding-left: ${(props) => props.theme.layout.chat.leftMenuWidth};

				@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
					padding-left: ${(props) => props.theme.layout.chat.leftMenuWidthDesktop};
				}
			}
		}
	}

	@keyframes FadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0.4;
		}
	}
`;
