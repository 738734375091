import { FC, useCallback, useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled, { css } from 'styled-components';
import { StyledDropdownMenu } from '@legacyApp/client/components/dropdown/dropdownMenu/dropdownMenu.styled';
import { useAppIsMenuVisible } from '@common/selectors/app.selectors';
import { hideMenu } from '@legacyApp/client/store/app/app.actions';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { vipStatusUpdateThunk } from '../../vip/store/thunks/vipStatusUpdate.thunk';
import { VipSection } from './VipSection';
import { MenuList } from './MenuList';

export const HeaderMenu: FC = () => {
	const isMenuVisible = useAppIsMenuVisible();
	const dispatch = useAppDispatch();

	const prevIsMenuVisible = usePrevious(isMenuVisible);

	const hideMenu_ = useCallback(() => dispatch(hideMenu()), [dispatch]);

	const handleClickOutside_ = useCallback(() => {
		setTimeout(() => {
			if (!isMenuVisible) {
				return;
			}
			hideMenu_();
		}, 150);
	}, [hideMenu_, isMenuVisible]);

	const outSideRef = useOnclickOutside(handleClickOutside_);

	useEffect(() => {
		if (isMenuVisible && prevIsMenuVisible !== isMenuVisible) {
			dispatch(vipStatusUpdateThunk(true));
		}
	}, [dispatch, isMenuVisible, prevIsMenuVisible]);

	return (
		<StyledHeaderMenu
			ref={outSideRef}
			as={StyledDropdownMenu}
			visible={isMenuVisible}
		>
			<VipSection />
			<MenuList />
		</StyledHeaderMenu>
	);
};

export const StyledHeaderMenu = styled.div.attrs({
	className: 'overflow-scrollbar--vertical',
})`
	background-color: var(--color-dark-800);
	color: var(--color-white);
	max-height: calc(
		95vh - ${(props) => props.theme.layout.header.heightBigDesktop}
	);
	padding: 24px 8px;
	position: absolute;
	right: 20px;
	top: 50px;
	width: 280px;

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media.maxWidthTablet,
						b: viewportPadding,
					})}) {
				max-height: calc(
					80vh - ${(props) => props.theme.layout.header.heightBigDesktop}
				);
			}
		`,
	)}
`;
