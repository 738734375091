import { regexString } from './regexString';

export const regexStrings = {
	chatUserName: /(^|[^@\w])@(\w{1,100})[:][A-Za-z0-9\-._]*/g,
	chatBetDetails: (games) =>
		new RegExp(`(${games})(:|: )(#|%23)[A-Za-z0-9-._]*`, 'g'),
	chatEmoji: [
		/<span class="emoji emoji-sizer" style="background-image:url\(https:\/\/cdn\.jsdelivr\.net\/emojione\/assets\/3\.0\/png\/32\/[A-Za-z0-9-]*\.png\)" ?data-codepoints="[A-Za-z0-9-]*"><\/span>/gm,
		/<img src=" https:\/\/cdn\.jsdelivr\.net\/emojione\/assets\/3\.0\/png\/32\/[A-Za-z0-9-]*\.png" ?class="emoji" ?data-codepoints="[A-Za-z0-9-]*" \/>/gm,
	],
	chatCurrency: (currencies) =>
		new RegExp(
			`^(${currencies})$|(?<=\\s|^)(${currencies})(?=\\s|$|[\\d])|(?<=[\\s\\d])(${currencies})(?=\\s|$)`,
			'gm',
		),
	chatStrategy: /(STRATEGY)(:|: )(#|%23|)[A-Za-z0-9\-._]*/g,
	trivia: /(trivia)(:|: )[A-Za-z0-9\-._]*/g,
	triviaWinner: /(trivia_winner)(:|: )[A-Za-z0-9\-._]*/g,
	userName: (name) => new RegExp(`(^|[^@\\w])@(${name})\\b`, 'ig'),
	scientificNotationNumber: /\d+\.?\d*e[+-]*\d+/i,
	hasNumber: /\d/,
	url: /(https?:\/\/[^\s]+)/g,
	apiVersion: /(\/v)[0-9]*/g,
};

export const regexStringMulti = (regex, string /*, showLog*/) => {
	let regexMatch;
	const matches = [];
	while ((regexMatch = regexString(regex, string)) != null) {
		matches.push(regexMatch);
		// 	if (showLog)
		// 		// console.log('match found at ' + regexMatch.index, {
		// 		// 	regex,
		// 		// 	regexMatch,
		// 		// 	string,
		// 		// });
	}

	// if (showLog)
	// console.log(`replaceRegexWith matches ${regex}`, {
	// 	matches,
	// 	string
	// });
	return matches;
};
