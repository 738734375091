import Router from 'next/router';
import { languageConfig } from '@server/config/language.config';
import { checkIsLocaleInPathname } from '../checkIsLocalePathname/checkIsLocaleInPathname';

export const getPathLocalized = ({
	pathname,
	locale = Router.router.locale,
}) => {
	if (locale === languageConfig.default) {
		return pathname;
	}
	let _pathname = pathname;
	if (pathname === '/') {
		_pathname = '';
	}
	if (_pathname?.length && _pathname.indexOf('/') !== 0) {
		_pathname = `/${_pathname}`;
	}
	if (_pathname && checkIsLocaleInPathname(pathname, locale)) {
		_pathname = pathname.replace(`/${locale}`, '');
	}
	return `/${locale}${_pathname}` || '/';
};
