import { connect } from 'react-redux';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	converterToNative,
	converterToUsd,
} from '../store/converter/converter.actions';

const enhanceWithCurrencyConverter = (props) => {
	const keys = Object.keys(props);
	const isUpdated =
		keys.length === 1 &&
		!['displayName', 'propTypes'].some((el) => keys.indexOf(el) > -1);
	const componentName = isUpdated ? keys[0] : props.displayName || props.name;
	const WrappedComponent = isUpdated ? props[componentName] : props;

	class EnhancedComponent extends PureComponent {
		static propTypes = {
			value: PropTypes.number,
			activeCurrency: PropTypes.string,
			toUsd: PropTypes.func,
		};

		state = {
			converted: null,
		};

		componentDidMount() {
			this.setConverted();
		}

		componentDidUpdate(prevProps) {
			const isValueChanged = this.props.value !== prevProps.value;
			const isCurrencyChanged =
				this.props.activeCurrency !== prevProps.activeCurrency;

			if (isValueChanged || isCurrencyChanged) {
				this.setConverted();
			}
		}

		setConverted = () => {
			this.setState({
				converted: this.props.toUsd(
					this.props.value,
					this.props.activeCurrency,
					undefined,
					undefined,
					true,
				),
			});
		};

		render() {
			return (
				<WrappedComponent {...this.props} converted={this.state.converted} />
			);
		}
	}

	EnhancedComponent.displayName = `errorIn(${componentName})`;

	const mapStateToProps = (state, props) => {
		return {
			activeCurrency: props.currency || state.user.activeCurrency,
			formCurrency:
				state.converter.currency === 'usd'
					? state.converter.currency
					: props.currency || state.user.activeCurrency,
		};
	};

	const mapDispatchToProps = (dispatch) => {
		return {
			toUsd: (value, currency, precision, direction, force) =>
				dispatch(converterToUsd(value, currency, precision, direction, force)),
			toNative: (value, currency, precision, direction, force) =>
				dispatch(
					converterToNative(value, currency, precision, direction, force),
				),
		};
	};

	return connect(mapStateToProps, mapDispatchToProps)(EnhancedComponent);
};

export default enhanceWithCurrencyConverter;
