export enum Sounds {
	won = 'win-2020-11-09',
	lost = 'lost-soft',
	slider = 'slider',
	'button-click' = 'button-click',
	'handle-hover' = 'handle-hover',
	switch = 'switch',
	roll = 'roll-2020-11-09',
	background = 0,
	'every-30-bg' = 0,
	stop = 'stop-2',
	card = 'card_flip',
	cashout = 'cashout',
	click = 'click',
	pin = 'ui-click',
	mention = 'mention',
}
