import styled from 'styled-components';
import { Property } from 'csstype';

export const TabGroupStyled = styled.div`
	background-color: var(--color-dark-800);
	border-radius: 40px;
	box-shadow: inset 0 0 0 1px var(--color-dark-500);
	display: flex;
	flex-flow: row nowrap;
	float: left;
	padding: 0;
`;

export const TabGroupWrapperStyled = styled.div<{
	align: Property.JustifyContent;
}>`
	align-items: center;
	display: flex;
	justify-content: ${(props) => props.align};
	width: 100%;
`;
