import { combineReducers } from 'redux';
import { Risk } from '@modules/games/PlinkoGame/types';
import { GameType } from '@modules/games/Game/constants/availableGames';
import {
	FORM_VERIFICATION_SET_GAME,
	RESET_CAMPAIGN_FORM,
	RESET_PASSWORD_FORM,
	RESET_USERNAME_FORM,
	RESET_VERIFICATION_FORM,
	SET_CLIENT_SEED,
	SET_NONCE,
	SET_SERVER_SEED,
	UPDATE_CAMPAIGN_CODE,
	UPDATE_CAMPAIGN_NAME,
	UPDATE_FORM,
	UPDATE_PASSWORD_CONFIRM_VALUE,
	UPDATE_PASSWORD_VALIDATE,
	UPDATE_PASSWORD_VALUE,
	UPDATE_USERNAME_VALIDATE,
	UPDATE_USERNAME_VALUE,
} from './forms.constants';

const passwordReducer = (
	state = {
		value: '',
		confirm: '',
		isValid: false,
	},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case UPDATE_FORM: {
			if (action.payload.section !== 'password') {
				return state;
			}
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		}
		case UPDATE_PASSWORD_VALUE:
			return {
				...state,
				value: action.payload,
			};
		case UPDATE_PASSWORD_CONFIRM_VALUE:
			return {
				...state,
				confirm: action.payload,
			};
		case UPDATE_PASSWORD_VALIDATE:
			return {
				...state,
				isValid: action.payload,
			};
		case RESET_PASSWORD_FORM:
			return {
				value: '',
				confirm: '',
				isValid: false,
			};
		default:
			return state;
	}
};

const campaignReducer = (
	state = {
		name: '',
		code: '',
	},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case UPDATE_FORM: {
			if (action.payload.section !== 'campaign') {
				return state;
			}
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		}
		case UPDATE_CAMPAIGN_NAME:
			return {
				...state,
				name: action.payload,
			};
		case UPDATE_CAMPAIGN_CODE:
			return {
				...state,
				code: action.payload,
			};
		case RESET_CAMPAIGN_FORM:
			return {
				name: '',
				code: '',
			};
		default:
			return state;
	}
};

const usernameReducer = (
	state = {
		value: '',
		isValid: false,
	},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case UPDATE_FORM: {
			if (action.payload.section !== 'username') {
				return state;
			}
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		}
		case UPDATE_USERNAME_VALUE:
			return {
				...state,
				value: action.payload,
			};
		case UPDATE_USERNAME_VALIDATE:
			return {
				...state,
				isValid: action.payload,
			};
		case RESET_USERNAME_FORM:
			return {
				value: '',
				isValid: false,
			};
		default:
			return state;
	}
};

const verificationReducer = (
	state = {
		client_seed: '',
		server_seed: '',
		nonce: '',
		game: GameType.DICE,
		rows: 8,
		risk: Risk.MEDIUM,
	},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case UPDATE_FORM: {
			if (action.payload.section !== 'bet_verification') {
				return state;
			}
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		}
		case SET_CLIENT_SEED:
			return {
				...state,
				client_seed: action.payload,
			};
		case SET_SERVER_SEED:
			return {
				...state,
				server_seed: action.payload,
			};
		case SET_NONCE:
			return {
				...state,
				nonce: action.payload,
			};
		case FORM_VERIFICATION_SET_GAME:
			return {
				...state,
				game: action.payload,
			};
		case RESET_VERIFICATION_FORM:
			return {
				client_seed: '',
				server_seed: '',
				nonce: '',
				game: state.game,
				rows: 8,
				risk: Risk.MEDIUM,
			};
		default:
			return state;
	}
};

const formsReducer = combineReducers({
	password: passwordReducer,
	username: usernameReducer,
	bet_verification: verificationReducer,
	campaign: campaignReducer,
});

export { formsReducer };
