import { getTimestamp } from '../../modules/app/timeService';
import { checkIsNoBalance } from '../../modules/app/balanceService';
import {
	CLAIM_FAUCET,
	SET_FAUCET_DELAY_UNTIL,
	SET_FAUCET_REMAINING,
} from './faucet.constants';

const setFaucetRemaining = (payload) => {
	return {
		type: SET_FAUCET_REMAINING,
		payload,
	};
};

const setFaucetDelayUntil = (payload) => {
	return {
		type: SET_FAUCET_DELAY_UNTIL,
		payload,
	};
};

const claimFaucet = (error) => {
	return {
		error,
		type: CLAIM_FAUCET,
	};
};

const verifyFaucet = () => {
	return (dispatch, getState) => {
		const state = getState();

		if (!checkIsNoBalance(state.balance.balancesArray)) {
			return 'noEmptyWallets';
		}

		if (state.faucet.remaining === 0 && !state.user.email) {
			return 'setEmail';
		}

		if (state.faucet.remaining === 0 && state.user.hasEmailToVerify) {
			return 'emailVerify';
		}

		if (state.faucet.remaining === 0) {
			return 'dailyLimitExceeded';
		}

		if (state.faucet.delayUntil && state.faucet.delayUntil > getTimestamp()) {
			return 'delayUntil';
		}

		return false;
	};
};

const getFaucetErrorText = (type) => {
	return (dispatch) => {
		if (!type) {
			type = dispatch(verifyFaucet());
		}

		switch (type) {
			case 'setEmail':
				return 'You have to set and verify your email to claim faucet.';
			case 'emailVerify':
				return 'You have to verify your email to claim faucet.';
			case 'delayUntil':
				return 'You cannot claim faucet until delay expires.';
			case 'noEmptyWallets':
				return 'Your balances must be empty to claim faucet.';
			case 'dailyLimitExceeded':
				return "You've exceeded daily faucet claim limit, try again tomorrow.";
			default:
				return false;
		}
	};
};

export {
	setFaucetRemaining,
	claimFaucet,
	setFaucetDelayUntil,
	verifyFaucet,
	getFaucetErrorText,
};
