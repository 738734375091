import { Button } from '@ui/button';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { NotificationIcon } from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { useSideMenu } from './hooks/useSideMenu';

export const SideMenuToggle = () => {
	const { isOpen, setIsOpen } = useSideMenu();

	const toggleMenu_ = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen, setIsOpen]);

	return (
		<>
			<SideMenuToggleStyled
				onClick={toggleMenu_}
				styleType={ButtonStyleType.CLEAR}
				iconBefore={
					<>
						<NotificationIcon
							types={[ROUTING_ID.USER_VIP, MODAL_ID.NEW_FEATURES]}
						/>
						<Svg
							content={
								<>
									<rect x="6" y="9" width="14" height="1" fill="white" />
									<rect x="8" y="5" width="10" height="1" fill="white" />
									<path
										d="M1 1L5 5.5L1 10"
										stroke="#8392AA"
										fill="transparent"
										style={{
											...(isOpen && {
												transform: 'rotateY(225deg) translateX(-6px)',
											}),
											transition: '300ms',
										}}
									/>
									<rect x="6" y="1" width="14" height="1" fill="white" />
								</>
							}
							height={11}
							width={20}
						/>
					</>
				}
			/>
		</>
	);
};

export const SideMenuToggleStyled = styled(Button)``;
