export const USER_GET_DATA = 'USER_GET_DATA';
export const USER_GET_BETS = 'USER_GET_BETS';

export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';

export const USER_LOGGING = 'USER_LOGGING';
export const USER_HANDLE_LOGIN_RESPONSE = 'USER_HANDLE_LOGIN_RESPONSE';
export const USER_LOGGED = 'USER_LOGGED';
export const USER_SESSION_ENDED = 'USER_SESSION_ENDED';
export const USER_NOT_LOGGED = 'USER_NOT_LOGGED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_HAS_EMAIL_TO_VERIFY = 'USER_HAS_EMAIL_TO_VERIFY';
export const USER_HAS_PASSWORD_TO_SET = 'USER_HAS_PASSWORD_TO_SET';
export const USER_HAS_KYC_TO_VERIFY = 'USER_HAS_KYC_TO_VERIFY';

export const USER_REGISTER_SEND = 'USER_REGISTER_SEND';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const CLEAR_FIRST_LOGIN = 'CLEAR_FIRST_LOGIN';
export const USER_HANDLE_REGISTER_RESPONSE = 'USER_HANDLE_REGISTER_RESPONSE';

export const SET_SOCKET_PRIVATE_CHANNEL = 'SET_SOCKET_PRIVATE_CHANNEL';

export const SET_TOKEN = 'SET_TOKEN';
export const RESET_TOKEN = 'RESET_TOKEN';

export const RESET_USER_DATA = 'RESET_USER_DATA';

export const SET_USERNAME = 'SET_USERNAME';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_JOINED_DATE = 'SET_JOINED_DATE';
export const SET_EMAIL = 'SET_EMAIL';

export const SET_CURRENCY = 'SET_CURRENCY';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

export const USER_SET_WITHDRAW_BLOCKED_TIMESTAMP =
	'USER_SET_WITHDRAW_BLOCKED_TIMESTAMP';
export const USER_SET_WITHDRAW_BLOCKED = 'USER_SET_WITHDRAW_BLOCKED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';

export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';
export const SEND_NEW_PASSWORD = 'SEND_NEW_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

export const SET_USER_ADMIN = 'SET_USER_ADMIN';
export const SET_USER_MODERATOR = 'SET_USER_MODERATOR';
export const SET_USER_HIGH_ROLLER = 'SET_USER_HIGH_ROLLER';
export const SET_USER_VIP_LEVEL = 'SET_USER_VIP_LEVEL';
export const SET_USER_RACE_BADGE = 'SET_USER_RACE_BADGE';

export const CHECK_AM_I_UNIQUE = 'CHECK_AM_I_UNIQUE';

export const SET_LAST_REGISTER_TIMESTAMP = 'SET_LAST_REGISTER_TIMESTAMP';

export const SET_HAS_AVAILABLE_BONUS_CODE = 'SET_HAS_AVAILABLE_BONUS_CODE';
