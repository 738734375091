// import LoadableLibrary from 'react-code-split';
import dynamic from 'next/dynamic';
import { Loader } from 'next/dist/shared/lib/dynamic';
import { Loading } from './Loading';

interface LoadableOpts<P> {
	loader: Loader<P>;
	ssr?: boolean;
}

export const Loadable = <P>(
	opts: LoadableOpts<P>,
	id: string,
	group = false,
	loadingElement = null,
) => {
	return dynamic<P>(opts.loader, {
		ssr: opts.ssr,
		loading: (props) =>
			loadingElement === true
				? null
				: loadingElement || Loading({ data: props, id, group }),
	});
};
