import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { vipEpic } from '@modules/vip/store/vip.epic';
import { balanceEpic } from '@modules/balance/store/balance.epic';
import { supportEpic } from '@modules/support/store/support.epic';
import { appEpic } from '../app/app.epic';
import { userEpic } from '../user/user.epic';
import { confirmEpic } from '../confirm/confirm.epic';
import { transactionsEpic } from '../transactions/transactions.epic';
import { twoFactorAuthenticationEpic } from '../twoFactorAuthentication/twoFactorAuthentication.epic';
import { globalStatisticsEpic } from '../globalStatistics/globalStatistics.epic';
import { formsEpic } from '../forms/forms.epic';
import { notificationEpic } from '../notification/notification.epic';
import { affiliateEpic } from '../affiliate/affiliate.epic';
import { faucetEpic } from '../faucet/faucet.epic';
import { tipEpic } from '../tip/tip.epic';
import { websocketEpic } from '../websocket/websocket.epic';
import { gtmEpic } from '../gtm/gtm.epic';
import { alertsEpic } from '../alerts/alerts.epic';
import { rainEpic } from '../rain/rain.epic';
import { vaultEpic } from '../vault/vault.epic';
import { privateMessagesEpic } from '../privateMessages/privateMessages.epic';
import { friendsEpic } from '../friends/friends.epic';
import { preferencesEpic } from '../preferences/preferences.epic';
import { onesignalEpic } from '../onesignal/onesignal.epic';
import { converterEpic } from '../converter/converter.epic';
import { fetchEpic } from '../fetch/fetch.epic';
import { optionsEpic } from '../options/options.epic';
import { pwaEpic } from '../pwa/pwa.epic';
import { registryEpic } from '../registry/registry.epic';

export const epic$ = new BehaviorSubject(
	combineEpics(
		appEpic,
		userEpic,
		confirmEpic,
		transactionsEpic,
		twoFactorAuthenticationEpic,
		globalStatisticsEpic,
		formsEpic,
		notificationEpic,
		faucetEpic,
		affiliateEpic,
		tipEpic,
		websocketEpic,
		gtmEpic,
		alertsEpic,
		vipEpic,
		rainEpic,
		vaultEpic,
		privateMessagesEpic,
		friendsEpic,
		preferencesEpic,
		onesignalEpic,
		converterEpic,
		balanceEpic,
		fetchEpic,
		optionsEpic,
		supportEpic,
		pwaEpic,
		registryEpic,
	),
);

export const rootEpic = (action$, store$) => {
	return epic$.pipe(
		mergeMap((epic) => {
			return epic(action$, store$);
		}),
	);
};
