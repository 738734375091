import React, { FC } from 'react';
import styled from 'styled-components';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import {
	ConfettiImage,
	ConfettiImage2,
	TrophyImage,
	WolfRaceOnClickWrapper,
	WolfRacePromoStyled,
} from '@common/components/home/WelcomSection/components/WolfRacePromo';
import {
	BonusCodeOnClickWrapper,
	BonusCodesPromoStyled,
	BonusImage,
} from '@common/components/home/WelcomSection/components/BonusCodesPromo';
import {
	VipWheelOnClickWrapper,
	VipWheelPromoStyled,
	WheelImage,
} from '@common/components/home/WelcomSection/components/VipWheelPromo';
import {
	RakebackImage,
	RakebackOnClickWrapper,
	RakebackPromoStyled,
} from '@common/components/home/WelcomSection/components/RakebackPromo';
import { NOTIFICATION_ID } from '@legacyApp/client/store/notification/notification.constants';
import {
	NOTIFICATION_ICON_CLASS_NAME,
	NotificationIcon,
} from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import {
	BigPromoStyled,
	MediumPromoStyled,
} from '@common/components/home/WelcomSection/components/Promotions.styled';
import { useSideMenu } from '../hooks/useSideMenu';

export const SideMenuQuickAccess: FC = () => {
	const { closeOnMobile } = useSideMenu();
	return (
		<SideMenuQuickAccessStyled>
			<RakebackOnClickWrapper>
				<SideMenuQuickAccessBoxStyled onClick={closeOnMobile}>
					<RakebackPromoStyled>
						<RakebackImage />
						<span>
							{trans({
								label: 'rakeback<1/>system',
								namespace: TransNamespace.COMMON,
								components: (
									<>
										weekly
										<br />
										system
									</>
								),
							})}
						</span>
					</RakebackPromoStyled>
				</SideMenuQuickAccessBoxStyled>
			</RakebackOnClickWrapper>
			<VipWheelOnClickWrapper>
				<SideMenuQuickAccessBoxStyled onClick={closeOnMobile}>
					<NotificationIcon
						notification={NOTIFICATION_ID.HAS_VIP_WHEEL_TO_SPIN}
					/>
					<VipWheelPromoStyled>
						<WheelImage />
						<span>
							{trans({
								label: 'daily<1/>charge',
								namespace: TransNamespace.COMMON,
								components: (
									<>
										daily
										<br />
										charge
									</>
								),
							})}
						</span>
					</VipWheelPromoStyled>
				</SideMenuQuickAccessBoxStyled>
			</VipWheelOnClickWrapper>
			<BonusCodeOnClickWrapper>
				<SideMenuQuickAccessBoxStyled onClick={closeOnMobile}>
					<NotificationIcon notification={NOTIFICATION_ID.HAS_BONUS_CODE} />
					<BonusCodesPromoStyled>
						<BonusImage src="/img/promosection/bonus/code.png" />
						<span>
							{trans({
								label: 'bonus<1/>codes',
								namespace: TransNamespace.COMMON,
								components: (
									<>
										bonus
										<br />
										codes
									</>
								),
							})}
						</span>
					</BonusCodesPromoStyled>
				</SideMenuQuickAccessBoxStyled>
			</BonusCodeOnClickWrapper>
			<WolfRaceOnClickWrapper>
				<SideMenuQuickAccessBoxStyled onClick={closeOnMobile}>
					<WolfRacePromoStyled>
						<TrophyImage />
						<ConfettiImage />
						<ConfettiImage2 />
						<span>
							{trans({
								label: 'wolf<1/>race',
								namespace: TransNamespace.COMMON,
								components: (
									<>
										wolf
										<br />
										race
									</>
								),
							})}
						</span>
					</WolfRacePromoStyled>
				</SideMenuQuickAccessBoxStyled>
			</WolfRaceOnClickWrapper>
		</SideMenuQuickAccessStyled>
	);
};

const SideMenuQuickAccessStyled = styled.div`
	display: grid;
	grid-column-gap: 8px;
	grid-row: auto;
	grid-row-gap: 8px;
	grid-template-columns: calc(50% - 4px) calc(50% - 4px);
	height: auto;
	width: 100%;
`;

export const SideMenuQuickAccessBoxStyled = styled.div.attrs(() => {
	return {
		className: 'text-style-h-7',
	};
})`
	align-items: center;
	position: relative;
	cursor: pointer;
	color: #fff;
	display: flex;
	justify-content: flex-start;
	text-transform: uppercase;

	span {
		z-index: 3;
	}

	.${NOTIFICATION_ICON_CLASS_NAME} {
		left: -7px;
		right: unset;
		z-index: 1;
		top: -7px !important;
	}

	${BigPromoStyled}, ${MediumPromoStyled} {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		padding: 17px 8px;
	}

	${VipWheelPromoStyled} {
		${WheelImage} {
			right: -10px;
			left: unset;
			transform: unset;
			top: 12%;
		}
	}

	${RakebackPromoStyled} {
		${RakebackImage} {
			top: 3%;
			height: 100%;
			left: unset;
			right: -5px;
			transform: unset;
		}
	}

	${BonusCodesPromoStyled} {
		${BonusImage} {
			top: 0;
			height: 100%;
			right: -2%;
			left: unset;
			transform: unset;
		}
	}
`;
