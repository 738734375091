export const jsonToString = (json: any, space?: number): string => {
	if (typeof json === 'object') {
		try {
			return JSON.stringify(json, undefined, space);
		} catch (error) {
			return `${error}`;
		}
	}
	return json;
};
