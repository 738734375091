import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Check: FC<IconProps> = ({ fill }) => {
	return (
		<Svg
			content={
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.3909 0.213929C13.7329 0.521811 13.7607 1.04872 13.4528 1.39081L5.95279 9.72415C5.79476 9.89974 5.56962 10 5.33338 10C5.09714 10 4.87201 9.89974 4.71397 9.72415L0.547303 5.09452C0.23942 4.75242 0.267152 4.22552 0.609244 3.91763C0.951336 3.60975 1.47824 3.63748 1.78613 3.97957L5.33338 7.92097L12.214 0.27587C12.5219 -0.0662218 13.0488 -0.0939538 13.3909 0.213929Z"
					fill={fill || 'white'}
				/>
			}
			width={14}
			height={10}
		/>
	);
};
