import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import statsService from '../../modules/app/statsService';
import { NEW_HIGH_ROLL } from '../transactions/transactions.constants';
import { config } from '../../config';
import websocketService from '../../modules/app/websocketService';
import { APP_INITIALIZED } from '../app/app.constants';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import {
	WEBSOCKET_CHANNELS,
	WEBSOCKET_EVENTS,
} from '../websocket/websocket.constants';
import { bindSocketChannelEventThunk } from '../websocket/thunks/bindSocketChannelEvent.thunk';
import {
	GET_STATS_DATA,
	GLOBAL_STATS_GET_LAST_WEEK,
	HANDLE_STATS_UPDATE,
	UPDATE_STATS_DATA,
} from './globalStatistics.constants';
import {
	globalStatsSetLastWeekRegistrations,
	globalStatsSetLastWeekWagered,
	handleStatsUpdate,
	setBetsTotal,
	setLastHighWin,
	setOnlineUsers,
	setPrice,
	setStatsTimestamp,
	setWonLast24h,
	updateStatsData,
} from './globalStatistics.actions';

const getStatsDataEpic = epic('getStatsDataEpic', {
	actions: (ofType) => ofType(GET_STATS_DATA, APP_INITIALIZED),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/site/stats',
					loaderId: 'getGlobalStats',
				},
				(data) => {
					let result = [handleStatsUpdate()];
					if (data) {
						result.push(updateStatsData(data));
					}
					return result;
				},
			),
		);
	},
});

const getWeekStatsDataEpic = epic('getWeekStatsDataEpic', {
	actions: (ofType) => ofType(GLOBAL_STATS_GET_LAST_WEEK),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/site/stats/week',
					loaderId: 'getGlobalStatsLastWeek',
				},
				(data) => {
					if (!data) {
						return [];
					}
					const result = [];
					if (data.registrations) {
						result.push(
							globalStatsSetLastWeekRegistrations(data.registrations),
						);
					}
					if (data.waggered) {
						result.push(globalStatsSetLastWeekWagered(data.waggered));
					}
					return result;
				},
			),
		);
	},
});

const handleUpdateEpic = epic('handleUpdateEpic', {
	actions: (ofType) => ofType(HANDLE_STATS_UPDATE),
	callback: () => {
		return of(
			bindSocketChannelEventThunk(
				WEBSOCKET_CHANNELS.STATS,
				WEBSOCKET_EVENTS.STATS,
				(data, dispatch) =>
					dispatch(updateStatsData(websocketService.getMessage(data))),
			),
		);
	},
});

const updateStatsDataEpic = epic('updateStatsDataEpic', {
	actions: (ofType) => ofType(UPDATE_STATS_DATA),
	callback: ({ action, store$ }) => {
		const data = action.payload;
		const state = store$.value;
		let result = [];
		if (
			statsService.canUpdate(
				data.betsTotal,
				'betsCounter',
				data.updatedTimestamp,
				state,
			)
		) {
			result.push(setStatsTimestamp(data.updatedTimestamp, 'betsCounter'));
			result.push(setBetsTotal(data.betsTotal));
		}
		if (
			statsService.canUpdate(
				data.coinsCurrentPrice,
				'price',
				data.updatedTimestamp,
				state,
			)
		) {
			result.push(setStatsTimestamp(data.updatedTimestamp, 'price'));
			result.push(setPrice(data.coinsCurrentPrice));
		}
		if (
			statsService.canUpdate(
				data.highestWonLastDay,
				'wonLast24h',
				data.updatedTimestamp,
				state,
			)
		) {
			result.push(setStatsTimestamp(data.updatedTimestamp, 'wonLast24h'));
			result.push(setWonLast24h(data.highestWonLastDay));
		}
		// if (statsService.canUpdate(data.jackpot, 'jackpot', data.updatedTimestamp, state)) {
		//   result.push(setStatsTimestamp(data.updatedTimestamp, 'jackpot'));
		//   result.push(setJackpot(data.jackpot));
		// }
		if (
			statsService.canUpdate(
				data.onlineUsers,
				'online',
				data.updatedTimestamp,
				state,
			)
		) {
			result.push(setStatsTimestamp(data.updatedTimestamp, 'online'));
			result.push(setOnlineUsers(data.onlineUsers));
		}
		if (
			statsService.canUpdate(
				data.lastHighWin,
				'lastHighWin',
				data.updatedTimestamp,
				state,
			)
		) {
			result.push(setStatsTimestamp(data.updatedTimestamp, 'lastHighWin'));
			result.push(setLastHighWin(data.lastHighWin));
		}
		return of(result);
	},
});

const newHighRollerEpic = epic('newHighRollerEpic', {
	actions: (ofType) => ofType(NEW_HIGH_ROLL),
	callback: ({ action }) => {
		const data = action.payload;
		if (data.length !== undefined) {
			return of();
		}
		const result = [];
		if (data.state === 'win' && data.user.login !== HIDDEN_USER) {
			const stats = {
				lastHighWin: {
					user: data.user.login,
					value: roundNumber(data.profit, config.decimals.bet),
					betData: data,
					currency: data.currency,
				},
				updatedTimestamp: data.published_at,
			};
			result.push(updateStatsData(stats));
		}
		return of(result);
	},
});

const globalStatisticsEpic = combineEpics(
	getStatsDataEpic,
	updateStatsDataEpic,
	newHighRollerEpic,
	handleUpdateEpic,
	getWeekStatsDataEpic,
);

export { globalStatisticsEpic };
