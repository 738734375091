export const SET_IS_ANY_NOTIFICATION = 'SET_IS_ANY_NOTIFICATION';
export const NOTIFICATION_ENABLE = 'NOTIFICATION_ENABLE';
export const NOTIFICATION_DISABLE = 'NOTIFICATION_DISABLE';

export enum NOTIFICATION_ID {
	HAS_EMAIL_TO_VERIFY = 'hasEmailToVerify',
	HAS_PASSWORD_TO_SET = 'hasPasswordToSet',
	NEW_FEATURES = 'newFeatures',
	HAS_BONUS_CODE = 'hasBonusCode',
	HAS_KYC_TO_VERIFY = 'hasKycToVerify',
	HAS_VIP_WHEEL_TO_SPIN = 'hasVipWheelToSpin',
}
