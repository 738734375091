import { useCallback, useEffect, useState } from 'react';
import { getId } from '@legacyApp/client/modules/app/appService';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';

const createIdProps = (id, index) => {
	const id_ = `${id}_${index || 0}`;
	return { id: id_, url: `url(#${id_})` };
};

const createUniqueId = (id) => {
	return `${id}_${getId(12)}`;
};

export const useId = ({ id }: { id: string }) => {
	const [id_, setId] = useState(id);

	const prevId = usePrevious(id);

	const get = useCallback(
		(index = 0) => {
			return createIdProps(id_, index);
		},
		[id_],
	);

	useEffect(() => {
		if (id !== prevId || !id_ || id_ === id) {
			setId(createUniqueId(id));
		}
	}, [id, id_, prevId]);

	return {
		get,
	};
};
