import { FC } from 'react';
import { enhanceComponent } from '@legacyApp/client/wrappers/enhanceComponent';
import { PackageType } from '@legacyApp/client/store/registry/registry.constants';

const ChatStoreController: FC = () => {
	return null;
};

export const chatStoreProps = {
	asyncReducers: [
		{
			id: 'chat',
			callback: () => import('./chat.reducer'),
		},
	],
	asyncEpics: [
		{
			id: 'chat',
			callback: () => import('./chat.epic'),
		},
	],
	asyncPackage: PackageType.CHAT,
};

export default enhanceComponent({
	SeedStoreController: ChatStoreController,
	props: chatStoreProps,
});
