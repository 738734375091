const { DOMAIN } = require('../constants/DOMAIN');
const { updateFromLsConfig } = require('./updateFromLsConfig');
const apiUrl = process.env.API_URL || `https://${DOMAIN}/api`;

const apiConfig = updateFromLsConfig({
	apiUrl,
	sportsUrl: process.env.SPORTS_URL || apiUrl,
	autobetUrl: process.env.AUTOBET_URL || apiUrl,
	slotsUrl: process.env.SLOTS_URL || apiUrl,
	diceUrl: process.env.DICE_URL || apiUrl,
	cmsUrl: process.env.CMS_URL,
	sportsIframeUrl: process.env.SPORTS_IFRAME_URL || `https://sports.${DOMAIN}`,
	payUrl: process.env.PAY_URL || apiUrl,
});

module.exports = {
	apiConfig,
};
