import { applyMiddleware } from 'redux';
import { batchedSubscribe } from 'redux-batched-subscribe';
import debounce from 'lodash.debounce';
import multi from 'redux-multi';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { createRouterMiddleware } from 'connected-next-router';
import { config } from '../config';
import { logger } from './logger';
import { rootEpic } from './root/root.epic';
import { registryMiddleware } from './registry/registry.middleware';

const epicMiddleware = createEpicMiddleware();

const enhancers = [];
const middlewares = [
	multi,
	thunk,
	registryMiddleware(),
	createRouterMiddleware(),
];

// NEXT_CONFIG.DEV is a global defined by webpack
// when we work in NEXT_CONFIG.DEV environment
if (config.logger && process.browser) {
	middlewares.push(logger);
}

if (process.browser) {
	middlewares.unshift(epicMiddleware);
}

export const middleware = () => {
	return composeWithDevTools(
		applyMiddleware(...middlewares),
		...enhancers,
		batchedSubscribe(
			debounce((notify) => {
				if (config.logger) {
					console.log('------------');
				}
				notify();
			}),
		),
	);
};

export const runEpic = () => {
	return epicMiddleware.run(rootEpic);
};
