import { useAuth } from '@legacyApp/hooks/app/useAuth';
import { useAppInitialized } from '../selectors/app.selectors';

export const useAuthInitialized = ({ id }: { id: string }) => {
	const { token } = useAuth(id);
	const isInitialized = useAppInitialized();

	return {
		isInitialized: token?.value ? isInitialized : true,
	};
};
