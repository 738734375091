import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import {
	cardsValuesArray,
	countCardsType,
	countCardsTypeLetter,
	countCardsTypeNumber,
	getValueFromCardCode,
} from './cardsService';
import { getWinChanceDecimal } from './gameService';

export const getHiloWinChance = (type, activeCard) => {
	if (['red', 'black'].indexOf(type) > -1) {
		return 50;
	}

	const winChanceDecimal = getWinChanceDecimal('hilo');

	if (type === 'number') {
		return roundNumber(
			(countCardsTypeNumber / countCardsType) * 100,
			winChanceDecimal,
		);
	}

	if (type === 'letter') {
		return roundNumber(
			(countCardsTypeLetter / countCardsType) * 100,
			winChanceDecimal,
		);
	}

	let index = cardsValuesArray.indexOf(getValueFromCardCode(activeCard));

	if (type === 'high') {
		if (index === 0) {
			index++;
		}
		return roundNumber(
			((cardsValuesArray.length - index) / cardsValuesArray.length) * 100,
			winChanceDecimal,
		);
	}

	if (type === 'low') {
		if (index === cardsValuesArray.length - 1) {
			index--;
		}
		return roundNumber(
			((index + 1) / cardsValuesArray.length) * 100,
			winChanceDecimal,
		);
	}

	return 0;
};

export const getHiloStatus = (status) => {
	switch (status) {
		case 1: {
			return 'win';
		}
		case 0: {
			return 'lose';
		}
		case 2: {
			return 'skip';
		}
		case 3: {
			return 'ongoing';
		}
		case 4: {
			return 'initialized';
		}
		default: {
			return '';
		}
	}
};

export const checkHiloLoadingModules = (modules) => {
	return (
		modules.getHiloSession || modules.cashoutHiloBet || modules.placeHiloBet
	);
};

// const checkHiloWinChance = () => {
//   ['♦A', '♦2', '♦3', '♦4', '♦5', '♦6', '♦7', '♦8', '♦9', '♦10', '♦J', '♦Q', '♦K'].forEach(activeCard => {
//     console.log(`checkHiloWinChance - ${activeCard}`, {
//       high: getHiloWinChance('high', activeCard),
//       low: getHiloWinChance('low', activeCard),
//     });
//   });
// };
//
// checkHiloWinChance();
