import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { updateBalance } from '@modules/balance/store/balance.actions';
import { closeModal } from '@modules/modals/store/modal.actions';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { SUBMIT_RAIN } from './rain.constants';

const submitRainEpic = epic('submitRainEpic', {
	actions: (ofType) => ofType(SUBMIT_RAIN),
	callback: ({ action, store$ }) => {
		return of(
			fetchApiAction(
				{
					url: '/v2/user/rain',
					parameters: {
						Authorization: true,
						body: {
							amount: action.payload.amount,
							currency: action.payload.currency,
							users_amount: action.payload.usersAmount,
							// TODO: chat store usage
							room: store$.value?.chat?.room,
						},
					},
					method: 'POST',
					loaderId: 'submitRain',
					lockByModal: true,
				},
				(data) => {
					if (!data.amount) {
						return [];
					}
					return [updateBalance(data.userBalance, 'submitRain'), closeModal()];
				},
			),
		);
	},
});

const rainEpic = combineEpics(submitRainEpic);

export { rainEpic };
