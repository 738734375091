import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { epic } from '../../modules/app/epicService';
import {
	PWA_INSTALL_ACCEPTED,
	PWA_INSTALL_DECLINED,
	PWA_SET_CALLBACK,
} from './pwa.constants';
import { pwaSetActive, pwaSetCallback } from './pwa.actions';

const installEpic = epic('installEpic', {
	actions: (ofType) => ofType(PWA_INSTALL_DECLINED, PWA_INSTALL_ACCEPTED),
	callback: () => {
		return of(pwaSetCallback(false));
	},
});

const activeEpic = epic('activeEpic', {
	actions: (ofType) => ofType(PWA_SET_CALLBACK),
	callback: ({ action }) => {
		return of(pwaSetActive(!!action.payload));
	},
});

const pwaEpic = combineEpics(installEpic, activeEpic);

export { pwaEpic };
