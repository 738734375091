import { Loadable } from '../../loader/loadable/loadable';

export const DropdownMenu = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "dropdownMenu.container" */ './dropdownMenu.container'
			),
		ssr: false,
	},
	'./dropdownMenu.container',
);
