import React from 'react';
import PropTypes from 'prop-types';
import Wolf from '@icons/wolf';
import { enhanceComponent } from '../../wrappers/enhanceComponent';

class LoadingLogo extends React.PureComponent {
	static propTypes = {
		height: PropTypes.number,
		className: PropTypes.any,
	};

	render() {
		return (
			<div
				style={this.props.height ? { height: this.props.height } : undefined}
				className={`${CSSClassNameLoadingLogo} [&_svg]:h-full [&_svg]:animate-spin ${this.props.className}`}
			>
				<Wolf />
			</div>
		);
	}
}

LoadingLogo = enhanceComponent({
	LoadingLogo,
});

export const CSSClassNameLoadingLogo = 'loading-logo';

export { LoadingLogo };
