import { Svg } from '@legacyApp/client/components/svg/Svg';

const COLOR = 'white';

export const Wolf = () =>
	Svg({
		content: (
			<g>
				<path
					fill={COLOR}
					d="M56.9,17.7c0.3,1.9,0.6,3.8,0.9,5.6c0.1,0.7,0.5,1.3,0.8,1.9c0.8,1.7,1.7,3.4,2.6,5.2c0.8,1.6,1.5,3.1,2.3,4.7
		c0.9,1.7,1.7,3.5,2.6,5.2c0.3,0.7,0.7,1.4,1,2c0.1,0.3,0.1,0.4-0.1,0.6c-1.2,1.3-24.9,30.4-25.6,31.2c-0.1,0.1-0.4,0.3-0.6,0.3
		c-4.8,0-9.7,0-14.5,0c-0.2,0-0.4-0.1-0.6-0.2C18.5,63.8,1.1,43.9,0.1,42.8C0,42.6,0,42.5,0.1,42.3c1.3-2.5,2.5-5.1,3.8-7.6
		c0.9-1.8,1.9-3.7,2.8-5.5c0.9-1.7,1.7-3.4,2.5-5.1c0.3-0.6,0.3-1.2,0.4-1.9c0.4-2.2,0.7-4.4,1.1-6.6c0.3-2.2,0.7-4.3,1-6.5
		c0.3-1.9,0.6-3.7,0.9-5.5C12.8,2.4,13,1.2,13.2,0c0.6,0.4,1.1,0.7,1.6,1.1c2.5,1.8,5,3.5,7.5,5.3c2.4,1.7,4.8,3.4,7.2,5.1
		c0.7,0.5,1.3,1,2.1,1.4c0.3,0.2,0.7,0.1,1.1,0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1
		c0.9,0.1,1.6-0.4,2.3-0.9c3.2-2.3,6.4-4.5,9.7-6.8C48.8,3.6,51.4,1.8,54,0c0.8,4.7,1.5,9.4,2.3,14.1 M19,56.4
		c0.1,0.3,0.1,0.6,0.3,0.8c2.7,3.2,5.5,6.3,8.2,9.4c0.2,0.2,0.4,0.4,0.7,0.5c0.7,0.3,1.4,0.5,2.1,0.7c0.6,0.2,1.2,0.4,1.9,0.6
		c0-0.5-0.1-0.9-0.1-1.3c0-0.2-2.5-6.6-3.4-7.5c0.1-0.1,0.1-0.1,0.2-0.1c0.8-0.3,7.9-0.6,8.9-0.2c0.2,0.1,0.4,0.2,0.7,0.3
		c-0.1,0.1-3.4,7.2-3.4,7.3c-0.1,0.5-0.1,0.9-0.1,1.4c0.1,0,0.2,0,0.3-0.1c1-0.3,2-0.6,3-1c0.5-0.2,1-0.3,1.3-0.6
		c2.8-3.1,7.1-8.6,9.9-11.8c0.5-3.8,0.5-8.4,0.5-8.6c0,0,0-0.6,0.1-0.6c0.9,4.4,1,5,1.7,7.4c0.3-0.3,2.7-2.9,2.9-3.2
		c2.2-2.6,4.5-5.2,6.7-7.8c0.1-0.2,0.2-0.3,0.1-0.5c-0.6-1.4-1.1-2.7-1.7-4.1c-0.1-0.2-0.2-0.4-0.4-0.5c-1.3-0.9-16.9-10.5-20-11.8
		c-0.1,0.1-5.9,5.9-6.1,6.2c-0.6-1.2-4.9-5.9-5.3-6.2c-4.1,1.9-21.6,15-22.1,16.2C5.6,41.8,5.7,42,6,42.3c2,2.3,10.2,11.2,10.6,11.7
		c0.9-3,0.7-2,2.1-6.3 M52.3,17.6c-0.2-0.9-0.3-1.8-0.4-2.7c-0.2-1.4-0.5-2.9-0.7-4.3c-0.1-0.7-0.3-1.5-0.4-2.3
		c-2.2,3.9-8.3,9.5-10.4,13.3c3,1.4,14.5,9.1,17.6,10.5c-0.1-0.5-4.8-9.1-4.9-9.6 M27.1,20.9c-2.2-3.8-8.4-8.8-10.5-12.6
		c0,0-0.1,0-0.1,0c-0.8,5.1-4.1,16.1-4.9,21.3C14.6,28.3,24.1,22.3,27.1,20.9z"
				/>
				<path fill={COLOR} d="M53,20.3" />
				<path fill={COLOR} d="M16.5,8.4c0,0,0.1,0,0.1,0" />
				<path
					fill={COLOR}
					d="M18.9,39.8c0.4,0,0.8,0,1.1,0c1.5,0,2.9,0,4.4,0c0.2,0,0.5,0.2,0.7,0.3c1.3,1.2,2.6,2.5,4,3.7c0,0,0,0,0,0.1
		c-0.4,0-0.9,0.1-1.3,0.1c-1.6,0.1-3.1,0.1-4.7,0.1c-0.1,0-0.3-0.1-0.4-0.2c-1.1-1.2-2.1-2.3-3.2-3.5C19.3,40.2,19.1,40,18.9,39.8z"
				/>
				<path
					fill={COLOR}
					d="M48.4,40.1c-0.7,0.7-1.2,1.2-1.8,1.8c-0.7,0.7-1.4,1.4-2.1,2.1c-0.3,0.3-0.7,0.2-1,0.2c-1.5-0.1-3.1-0.3-4.6-0.4
		c-0.3,0-0.5-0.1-0.9-0.1c0.6-0.5,1-0.9,1.5-1.3c0.9-0.8,1.8-1.5,2.7-2.3c0.3-0.2,0.5-0.3,0.9-0.3c1.1,0.1,2.2,0.1,3.4,0.2
		C47.1,40,47.7,40,48.4,40.1z"
				/>
			</g>
		),
		width: 67.2,
		height: 74.4,
	});

export default Wolf;
