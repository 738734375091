import { combineReducers } from 'redux';
import { SERVER_DATA_CLEAR } from './serverData.constants';

const dataReducer = (data = {}) => {
	// console.log('dataReducer', data);
	return (
		state = data,
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case SERVER_DATA_CLEAR: {
				return {};
			}
			default: {
				return state;
			}
		}
	};
};

const serverDataReducer = (data) =>
	combineReducers({
		data: dataReducer(data),
	});

export { serverDataReducer };
