import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { ModalReducerType } from './modal.types';

export const useModalIsOpened = () =>
	useAppSelector<boolean>(({ modal }): boolean => {
		if (modal?.id) {
			return true;
		}
		if (typeof modal?.info === 'object' && 'id' in modal?.info) {
			return !!modal?.info?.id;
		}
		return false;
	}, shallowEqual);
export const useModalId = () =>
	useAppSelector<ModalReducerType['id']>(
		({ modal }) => modal?.id,
		shallowEqual,
	);
export const useModalClose = () =>
	useAppSelector<ModalReducerType['close']>(
		({ modal }) => modal?.close,
		shallowEqual,
	);
export const useModalProps = () =>
	useAppSelector<ModalReducerType['props']>(({ modal }) => modal?.props);
export const useModalTimestamp = () =>
	useAppSelector<ModalReducerType['timestamp']>(
		({ modal }) => modal?.timestamp,
	);
