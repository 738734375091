import { actionSimple } from '../../modules/app/actionService';
import {
	SET_AFFILIATE_CODE,
	SET_AFFILIATE_UTM_SOURCE,
	SUBMIT_CAMPAIGN,
	WITHDRAW_AVAILABLE_COMMISSIONS,
} from './affiliate.constants';

export const submitCampaign = actionSimple(SUBMIT_CAMPAIGN);

export const withdrawAvailableCommissions = actionSimple(
	WITHDRAW_AVAILABLE_COMMISSIONS,
);

export const setAffiliateCode = actionSimple(SET_AFFILIATE_CODE);

export const setAffiliateUtmSource = actionSimple(SET_AFFILIATE_UTM_SOURCE);
