import { FC } from 'react';
import { LoadingComponentContainer } from '../loadingComponent/loadingComponent.container';

export const Loading: FC<{
	data: any;
	id: string;
	group: string | boolean;
}> = (props) => {
	return <LoadingComponentContainer {...props} />;
};
