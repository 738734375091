import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

export type ValueType = string;
const initialState: ValueType = null;
const NAME = 'privateActive';

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	if (!action.payload) {
		return null;
	}
	return action.payload;
};

export const privateActiveSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setPrivateActive: setAction,
	},
});
