const { apiConfig } = require('./api.config');

const apiServerConfig = {
	apiUrl: process.env.API_SERVER_URL || apiConfig.apiUrl,
	sportsUrl: process.env.SPORTS_SERVER_URL || apiConfig.sportsUrl,
	slotsUrl: process.env.SLOTS_SERVER_URL || apiConfig.slotsUrl,
	cmsUrl: process.env.CMS_SERVER_URL || apiConfig.cmsUrl,
};

module.exports = {
	apiServerConfig,
};
