import styled, { css } from 'styled-components';
import { StyledTabsTableWrapper } from '@common/components/layout/Table/components/Table.styled';
import { HeaderHeight } from '@modules/header/Header.styled';
import { clearfix } from '../defaults';
import { dashboard, dashboard_v2 } from '../mixins/dashboard';

export interface StyledMainDashboardProps {
	className?: string;
	noBigDesktop?: boolean;
	fullSizeTopComponent?: boolean;
}

const mainDashboardMixin = css`
	padding-bottom: 7px;

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		padding-bottom: 25px;
	}

	${StyledTabsTableWrapper} {
		@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
			display: block;
			width: ${(props) => props.theme.layout.container.big};
			max-width: 100%;
			margin: 0 auto;
			${clearfix};
		}
	}

	&:nth-of-type(1) {
		${HeaderHeight(
			(height) => css`
				padding-top: ${height};
			`,
		)};
	}
`;

export const StyledMainDashboard = styled.section.attrs({
	className: 'main-dashboard' as string,
})<StyledMainDashboardProps>`
	${(props) => dashboard(undefined, props.noBigDesktop)}
	${mainDashboardMixin};
`;

export const StyledMainDashboardV2 = styled.section.attrs({
	className: 'main-dashboard' as string,
})<StyledMainDashboardProps>`
	${(props) => dashboard_v2(undefined, props.noBigDesktop)}
	${mainDashboardMixin};
`;
