import { combineReducers } from 'redux';
import { setReducer } from '@legacyApp/methods/store/setReducer';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import {
	CONVERTER_SET_CONFIRM,
	CONVERTER_SET_CURRENCY,
} from './converter.constants';

const currencyReducer = setReducer(
	false,
	CONVERTER_SET_CURRENCY,
	`localstorage-${LocalStorageKeys.CURRENCY_CONVERTER}`,
);
const confirmReducer = setReducer(
	false,
	CONVERTER_SET_CONFIRM,
	`localstorage-${LocalStorageKeys.ENABLE_CONVERTER}`,
);

const converterReducer = combineReducers({
	currency: currencyReducer,
	confirm: confirmReducer,
});

export { converterReducer };
