import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '@legacyApp/client/modules/style/theme';
import {
	box,
	boxNoHeight,
	clearfix,
} from '@legacyApp/client/modules/style/defaults';
import { spaces } from '@legacyApp/client/modules/style/mixins';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';

export const StyledDefaultGlobal = createGlobalStyle<{ theme: ThemeType }>`
	[data-nextjs-dialog-overlay] {
		z-index: 2147483647;
	}

	html {
		min-height: 100vh;
	}

	html, body {
		float: left;
		width: 100%;
		background-color: ${(props) => props.theme.colors.background};

		&.locked-view {
			position: fixed;

			@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
				height: 100%;
				overflow: hidden;
			}
		}
	}

	svg {
		padding: 0 !important;
		max-width: 100%;

		&[class*="svg-inline"] {
			width: 1em;
		}
	}

	.grecaptcha-badge {
		display: none !important;
	}

	* {
		scrollbar-width: none;
		-ms-overflow-style: none;
		-webkit-overflow-scrolling: touch;
		-webkit-font-smoothing: antialiased;

		//&::-webkit-scrollbar {
		//  display: none;
		//}

		&::-webkit-scrollbar {
			width: 0;
			height: 5px;
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: rgba(172, 172, 172, 0.59);
			transition: ${(props) => props.theme.layout.transition};
		}

		&::-webkit-scrollbar-thumb:window-inactive {
			background: rgba(172, 172, 172, 0.26);
			transition: ${(props) => props.theme.layout.transition};
		}
	}

	.overflow-scrollbar {
		-ms-overflow-style: auto !important;
		scrollbar-width: thin;
		-webkit-overflow-scrolling: auto;

		&--vertical {
			-ms-overflow-style: auto !important;
			scrollbar-width: thin;
			-webkit-overflow-scrolling: auto;

			&::-webkit-scrollbar {
				width: 5px;
			}
		}
	}

	body {
		position: relative;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		font-weight: ${(props) => props.theme.text.weight.light};
		margin: 0;
		color: ${(props) => props.theme.colors.text};
		background-color: ${(props) => props.theme.colors.background};
		min-height: 100%;
		min-width: 100%;

		[class*='language-'] {
			font-family: ${(props) => props.theme.text.fonts.Bold};
		}

		#__next {
			float: left;
			width: 100%;
			//height: 100vh;
			min-width: 100%;
			min-height: 100%;
			overflow-x: hidden;
		}

		&.locked-view {
			@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
				#__next {
					height: 100%;
					overflow: hidden;
				}
			}
		}
	}

	@media screen and (max-device-width: 480px) {
		body {
			-webkit-text-size-adjust: none;
		}
	}

	dialog {
		display: block;
		position: unset;
		left: unset;
		right: unset;
		width: unset;
		height: unset;
		color: unset;
		margin: unset;
		border-width: unset;
		border-style: unset;
		border-color: unset;
		border-image: unset;
		padding: unset;
		background: unset;
	}

	* {
		a {
			color: inherit;
			text-decoration: none;
			cursor: pointer;
			transition: ${(props) => props.theme.layout.transition};
		}
	}

	* {
		box-sizing: border-box;
		outline: none;
	}

	.montserrat {
		font-family: 'Montserrat', sans-serif !important;
	}

	p:not([class*="text-style"]) {
		${boxNoHeight};
		margin-top: 0;
		${fontSize({ important: true })};

		&:first-of-type {
			margin-top: 1em;
		}

		&[class*="hint"] {
			${fontSize({
				important: true,
				sizeDiff: -3,
				sizeDesktopDiff: -4,
				sizeBigScreenDiff: -5,
			})};
		}
	}

	.hint {
		color: ${(props) => props.theme.colors.secondText};
		border-color: ${(props) => props.theme.colors.secondText};

		&-dark {
			color: ${(props) => props.theme.colors.inputBackgroundSolid};
			border-color: ${(props) => props.theme.colors.inputBackgroundSolid};
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	p,
	span,
	div {
		user-select: none;
	}

	.user-select-all {
		user-select: all !important;

		* {
			user-select: all !important;
		}
	}

	.user-select-text {
		user-select: text !important;

		* {
			user-select: text !important;
		}
	}

	.break-all {
		word-break: break-all;
	}

	.onclick {
		cursor: pointer;
	}

	.width-auto {
		width: auto !important;
	}

	.display-none {
		display: none;
	}

	.display-inline-block {
		display: inline-block !important;
	}

	.display-inline {
		display: inline !important;
	}

	.break-word-all {
		word-break: break-all;
	}

	.opacity-50 {
		opacity: 0.5 !important;
	}

	.no-transition {
		transition: none !important;
	}

	.transition {
		transition: ${(props) => props.theme.layout.transition} !important;
	}

	.hidden {
		opacity: 0;
		height: 0 !important;
		overflow: hidden;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.active {
		color: ${(props) => props.theme.colors.active} !important;

		&-bg {
			background-color: ${(props) => props.theme.colors.active} !important;
		}
	}

	.z-field {
		height: 100%;

		div {
			height: 100%;
			display: block !important;
		}
	}

	.box {
		float: left;
		width: 100%;
		height: 100%;
	}

	.box-no-height {
		${box};
		height: unset;
	}

	.no-height {
		height: unset !important;
	}

	.no-width {
		width: unset !important;
	}

	.box-margin-bottom {
		${box};
		height: auto;
		margin-bottom: 30px;
	}

	.width-100 {
		width: 100% !important;
	}

	.width-80 {
		width: 80%;
	}

	.width-75 {
		width: 75%;
	}

	.width-70 {
		width: 70%;
	}

	.width-66 {
		width: 66.66%;
	}

	.width-65 {
		width: 65%;
	}

	.width-60 {
		width: 60%;
	}

	.width-55 {
		width: 55%;
	}

	.width-50 {
		width: 50% !important;
	}

	.width-45 {
		width: 45%;
	}

	.width-40 {
		width: 40%;
	}

	.width-35 {
		width: 35%;
	}

	.width-33 {
		width: 33.3%;
	}

	.width-30 {
		width: 30%;
	}

	.width-25 {
		width: 25%;
	}

	.width-20 {
		width: 20%;
	}

	.width-16 {
		width: 16.66%;
	}

	.width-16 {
		width: 16.66%;
	}

	.width-15 {
		width: 15%;
	}

	.width-10 {
		width: 10%;
	}

	.width-5 {
		width: 5%;
	}

	.height-50 {
		height: 50% !important;
	}

	b {
		font-weight: ${(props) => props.theme.text.weight.bold};
	}

	.no-select {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Chrome/Safari/Opera */
		-khtml-user-select: none; /* Konqueror */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		/* Non-prefixed version, currently
																		 not supported by any browser */
	}

	.no-pointer {
		cursor: default !important;
	}

	.no-click {
		pointer-events: none !important;

		* {
			pointer-events: none !important;
		}
	}

	.no-hover {
		&:hover {
			background-color: inherit;
			color: inherit;
			opacity: inherit !important;
		}
	}

	.no-side-padding {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.no-top-padding {
		padding-top: 0 !important;
	}

	.no-padding {
		padding: 0 !important;
	}

	.padding-bottom-10 {
		padding-bottom: 10px !important;
	}

	.padding-bottom-20 {
		padding-bottom: 20px !important;
	}

	.padding-top-10 {
		padding-top: 10px !important;
	}

	.padding-top-20 {
		padding-top: 20px !important;
	}

	.padding-side-5 {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}

	.padding-side-10 {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.padding-side-20 {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.no-margin {
		margin: 0 !important;
	}

	.no-margin-bottom {
		margin-bottom: 0 !important;
	}

	.no-margin-top {
		margin-top: 0 !important;
	}

	.no-max-width {
		max-width: unset !important;
	}

	.no-width {
		width: unset !important;
	}

	${spaces(0)};
	${spaces(4)};
	${spaces(8)};
	${spaces(12)};
	${spaces(16)};
	${spaces(20)};
	${spaces(24)};
	${spaces(28)};
	${spaces(32)};
	${spaces(36)};
	${spaces(40)};
	${spaces(48)};
	${spaces(60)};

	.margin-top {
		margin-top: 20px !important;
	}

	.margin-bottom {
		margin-bottom: 20px !important;
	}

	.margin-left {
		margin-left: 20px !important;
	}

	.margin-right {
		margin-right: 20px !important;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthMobile}) {
		.margin-top {
			margin-top: 10px !important;
		}

		.margin-bottom {
			margin-bottom: 10px !important;
		}

		.margin-left {
			margin-left: 10px !important;
		}

		.margin-right {
			margin-right: 10px !important;
		}
	}

	.small-margin-left {
		margin-left: 5px !important;
	}

	.small-margin-right {
		margin-right: 5px !important;
	}

	.small-margin-top {
		margin-top: 5px !important;
	}

	.small-margin-bottom {
		margin-bottom: 5px !important;
	}

	.padding-top {
		padding-top: 20px !important;
	}

	.padding-bottom {
		padding-bottom: 20px !important;
	}

	.padding-left {
		padding-left: 20px !important;
	}

	.small-padding-left {
		padding-left: 5px !important;
	}

	.small-padding-right {
		padding-right: 5px !important;
	}

	.small-padding-top {
		padding-top: 5px !important;
	}

	.small-padding-bottom {
		padding-bottom: 5px !important;
	}

	.text-logo {
		font-family: ${(props) => props.theme.text.fonts.Bold};
		font-size: 24px;
	}

	.text-small {
		${fontSize({ size: '10px', sizeDesktop: '12px', important: true })};
	}

	.text-medium {
		${fontSize({ size: '12px', sizeDesktop: '14px', important: true })};
	}

	.text-gold {
		color: ${(props) => props.theme.colors.gold} !important;
	}

	.text-button {
		color: ${(props) => props.theme.colors.inputButton} !important;
	}

	.text-blue {
		color: ${(props) => props.theme.colors.inputButton} !important;
	}

	.text-skyblue {
		color: ${(props) => props.theme.colors.skyblue} !important;
	}

	.text-black {
		color: black !important;
	}

	.text-turquoise {
		color: ${(props) => props.theme.colors.turquoise} !important;
	}

	.text-green {
		color: ${(props) => props.theme.colors.green} !important;
	}

	.text-green::first-letter {
		color: ${(props) => props.theme.colors.green} !important;
	}

	.text-red {
		color: ${(props) => props.theme.colors.red} !important;
	}

	.text-active {
		color: ${(props) => props.theme.colors.active} !important;
	}

	.text-grey {
		color: ${(props) => props.theme.colors.grey} !important;
	}

	.text-orange {
		color: ${(props) => props.theme.colors.orange} !important;
	}

	.text-positive {
		color: ${(props) => props.theme.colors.positive} !important;
	}

	.text-negative {
		color: ${(props) => props.theme.colors.negative} !important;
	}

	.text-second {
		color: ${(props) => props.theme.colors.secondText} !important;
	}

	.text-orange::first-letter {
		color: ${(props) => props.theme.colors.orange} !important;
	}

	.text-disabled {
		color: ${(props) => props.theme.colors.text}-disabled !important;
	}

	.text-uppercase {
		text-transform: uppercase;
	}

	.border-positive {
		border: 1px solid ${(props) => props.theme.colors.positive} !important;
	}

	.border-negative {
		border: 1px solid ${(props) => props.theme.colors.negative} !important;
	}

	.border-fat-positive {
		border: 5px solid ${(props) => props.theme.colors.positive} !important;
	}

	.border-fat-negative {
		border: 5px solid ${(props) => props.theme.colors.negative} !important;
	}

	.text-overflow-ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.text-notransform {
		text-transform: none;
	}

	.text-underline {
		text-decoration: underline;
	}

	.text-validation {
		color: ${(props) => props.theme.colors.passwordValidationColor};
	}

	.text-sm {
		font-size: 0.7em;
	}

	.relative {
		position: relative;
	}

	.text-bold {
		font-weight: ${(props) => props.theme.text.weight.bold};
	}

	.text-line {
		text-decoration: line-through;
	}

	.text-capitalize {
		text-transform: capitalize;
	}

	.text-unset {
		text-transform: unset;
	}

	.inline {
		display: inline-block;
		width: auto;
	}

	.align-center {
		text-align: center !important;
	}

	.align-left {
		text-align: left !important;
	}

	.align-right {
		text-align: right !important;
	}

	.no-border {
		border: ${(props) => props.theme.layout.noBorder} !important;
	}

	.no-wrap {
		white-space: nowrap;
	}

	img:not([src]) {
		visibility: hidden;
	}

	@-moz-document url-prefix() {
		img:-moz-loading {
			visibility: hidden;
		}
	}

	.text-elipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.show-mobile {
		display: none!important;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		.show-mobile {
			display: block!important;
		}

		.show-desktop {
			display: none!important;
		}
	}

	.clearfix {
		${clearfix};
	}

	.no-scroll, .no-scroll > #__next {
		overflow: hidden;
	}

	.cut-text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	span.cut-text {
		display: inline-block !important;
		max-width: 100%;
	}

	.test-gtm {
		position: fixed;
		bottom: 0;
		z-index: 2147483647;
		padding: 5px;
		background-color: rgba(orange, 0.3);
		width: 100%;

		.button {
			padding: 0 10px;
			background-color: orange;
		}
	}

	.auto-overflow {
		overflow: auto;
	}

	.click-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}

	i, svg {
		&.hover-icon {
			&:hover {
				opacity: 0.5;
				transition: ${(props) => props.theme.layout.transition};
			}
		}
	}

	button {
		outline: none;
		border: 0 none;
		cursor: pointer;
		background-color: transparent;
		color: inherit;
		vertical-align: top;
		font-size: inherit;
		font-family: inherit;
		position: relative;

		&:hover,
		&:active,
		&:focus {
			outline: none;
		}
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		.mobile {
			display: none !important;
		}
	}


	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		.desktop {
			display: none !important;
		}
	}
`;
