import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { getPerGameInitObject } from './getPerGameInitObject';

export const getSessionInitObject = (value, type) => {
	return getPerGameInitObject(value, type, LocalStorageKeys.STATS);
};

export const getSessionIdFromBet = (bet) => {
	return `${bet.game.name}`;
};

export const updateStatsLocalStorage = (data) => {
	lsSet(LocalStorageKeys.STATS, data);
};

export const clearStatsLocalStorage = () => {
	lsClear(LocalStorageKeys.STATS);
};

export const parseAutobetProfitHistory = (data, initValue) => {
	return data.map((el, index) => {
		return {
			...el,
			x: index + 1,
			y: roundNumber(el.y - initValue),
		};
	});
};
