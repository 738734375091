export const APP_INITIALIZED = 'APP_INITIALIZED';
export const APP_CALLBACK = 'APP_CALLBACK';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const LISTEN_COMMANDS = 'LISTEN_COMMANDS';
export const HANDLE_COMMANDS = 'HANDLE_COMMANDS';

export const SET_MOBILE = 'SET_MOBILE';
export const SET_MOBILE_CHAT = 'SET_MOBILE_CHAT';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const SET_CONTENT_DEVICE_TYPE = 'SET_CONTENT_DEVICE_TYPE';
export const SET_WIDTH = 'SET_WIDTH';
export const SET_HEIGHT = 'SET_HEIGHT';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_EXTENDED_ACCESS = 'SET_EXTENDED_ACCESS';

export const UPDATE_URL_QUERY = 'UPDATE_URL_QUERY'; // to url and store

export const MENU_HIDE = 'MENU_HIDE';
export const MENU_TOGGLE = 'MENU_TOGGLE';

export const SIDE_MENU_HIDE = 'SIDE_MENU_HIDE';
export const SIDE_MENU_TOGGLE = 'SIDE_MENU_TOGGLE';

export const APP_GO_TO = 'APP_GO_TO';

export const SET_PATH = 'SET_PATH';

export const SET_LOCKED_VIEW = 'SET_LOCKED_VIEW';

export const CHECK_NEW_FEATURES = 'CHECK_NEW_FEATURES';

export const APP_CLOSES = 'APP_CLOSES';

export const UPDATE_SENTRY_STATE = 'UPDATE_SENTRY_STATE';

export const TOGGLE_MAINTENANCE_MODE = 'TOGGLE_MAINTENANCE_MODE';

export const APP_EXPORT_DATA = 'APP_EXPORT_DATA';
export const APP_IMPORT_DATA = 'APP_IMPORT_DATA';

export const APP_CHECK_VERSION = 'APP_CHECK_VERSION';
export const APP_NEW_VERSION = 'APP_NEW_VERSION';
export const APP_SET_CSRF = 'APP_SET_CSRF';
export const APP_SET_SOURCE = 'APP_SET_SOURCE';

export const APP_COMMANDS = {
	CHAT_REFRESH: 'command.chat.refresh',
	APP_REFRESH: 'command.frontend.refresh',
	RAKEBACK_LEVEL_INCREASED: 'command.rakeback.openLevelUpdateModal',
	BONUS_CODE_GRANTED: 'command.bonusCode.new',
	OPEN_INSANE_BONUS_CODE_MODAL: 'command.bonusCodes.openBonusCodeInsaneModal',
	OPEN_INSANE_BONUS_CODE_ALERT: 'command.bonusCodes.openBonusCodeInsaneAlert',
	SLOTS_BET_AMOUNT_LIMIT: 'slots.alert.bet.amount.limit',
	SLOTS_GAME_ACTION: 'slots.game.action',
} as const;
type AppCommandsKeys = keyof typeof APP_COMMANDS;
export type AppCommandsType = (typeof APP_COMMANDS)[AppCommandsKeys];
