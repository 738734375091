import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const CaretDown: FC<IconProps> = ({ fill, height, width }) => {
	return (
		<Svg
			content={
				<path
					d="M9.82843 13.0784L12.6569 10.25H7L9.82843 13.0784Z"
					fill={fill || '#6983A5'}
				/>
			}
			width={width || 20}
			height={height || 21}
		/>
	);
};
