import Router from 'next/router';

export const getPathnameWithoutLang = (
	pathname: string,
	locale = Router?.router?.locale,
) => {
	if (pathname.indexOf(locale) > -1) {
		return (
			pathname
				.split('/')
				.filter((el, index) => !(el === locale && index === 1))
				.join('/') || '/'
		);
	}
	return pathname;
};
