import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { VipLevelType } from '@modules/vip/constants/vip.config';

export const StyledVipIconLabel = styled.div`
	color: ${(props) => props.theme.colors.text};
	letter-spacing: unset;
	position: absolute;
	transform: translate(-50%, -50%);
	z-index: 1;
`;

interface StyledVipIconProps {
	$size?: number;
	$type?: VipLevelType;
	$level?: number;
}

export const StyledVipIcon = styled.div.attrs<StyledVipIconProps>((props) => {
	return {
		...props,
		$size: props.$size || 20,
		className: classNames(props.className, 'vip-icon'),
	};
})<StyledVipIconProps>`
	align-items: center;
	display: inline-flex !important;
	font-size: unset;
	height: ${(props) => props.$size}px;
	justify-content: center;
	line-height: unset;
	min-width: ${(props) => props.$size}px;
	position: relative;
	transition: ${(props) => props.theme.layout.transition};

	div[role='button'] {
		margin: -25% !important;
	}

	${StyledVipIconLabel} {
		font-size: ${(props) => props.$size / 2.5}px;
		line-height: ${(props) => props.$size / 2.5}px;

		${(props) =>
			props.$level >= 10
				? css`
						${props.$level === 11
							? css`
									left: 51%;
							  `
							: ''}
						left: 48.5%;
						top: 58%;
				  `
				: css`
						left: 50%;
						top: 57%;
				  `}

		${(props) =>
			props.$type === VipLevelType.BRONZE &&
			css`
				color: #623b24;
			`};

		${(props) =>
			props.$type === VipLevelType.SILVER &&
			css`
				color: #3e3e44;
			`};

		${(props) =>
			props.$type === VipLevelType.GOLD &&
			css`
				color: #8a4004;
			`};

		${(props) =>
			props.$type === VipLevelType.DIAMOND &&
			css`
				color: #2f015b;
				left: 48%;
				top: 47%;
			`};
	}

	img,
	svg {
		height: ${(props) => props.$size}px;
		left: 50%;
		max-width: unset;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		z-index: 0;
	}
`;
