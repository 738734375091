import { useEffect } from 'react';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { useVipLevels } from '../store/selectors/vip.selectors';
import { vipGetLevelsThunk } from '../store/thunks/vipGetLevels.thunk';
import { vipStatusUpdateThunk } from '../store/thunks/vipStatusUpdate.thunk';

export const useVip = () => {
	const dispatch = useAppDispatch();
	const levels = useVipLevels();
	const isLogged = useUserIsLogged();

	useEffect(() => {
		if (!levels?.length) {
			dispatch(vipGetLevelsThunk());
		}
		if (isLogged) {
			dispatch(vipStatusUpdateThunk());
		}
	}, [dispatch, isLogged, levels?.length]);

	return {
		levels,
	};
};
