const updateFromLsConfig = (config) => {
	if (process.browser && !process.env.PRODUCTION) {
		const keys = Object.keys(config);
		const lsData = {};
		const fromServerConfig = { ...config };

		keys.forEach(key => {
			lsData[key] = process.browser ? window.localStorage[key] : null;
			if (lsData[key]) {
				config[key] = lsData[key];
			}
		});

		console.log('updateFromLsConfig', keys, {
			lsData,
			config,
			fromServerConfig,
		});
	}

	return config;
};

module.exports = {
	updateFromLsConfig,
};
