import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const NAME = 'message';
export type ValueType = string;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

const updateAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	const input = process.browser ? document.getElementById('chat-input') : false;
	if (input) {
		input.focus();
	}
	return state + action.payload;
};

export const messageSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setMessage: setAction,
		updateMessage: updateAction,
	},
});
