import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

export enum ContentType {
	STREAM = 'stream',
	PRIVATE = 'private',
	FRIENDS = 'friends',
}

const initialState = ContentType.STREAM;

const NAME = 'contentType';

const setAction: ReduxToolkit.CaseReducer<
	ContentType,
	ReduxToolkit.PayloadAction<ContentType>
> = (state, action) => {
	if (!action.payload) {
		return ContentType.STREAM;
	}
	return action.payload;
};

export const contentTypeSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setContentType: setAction,
	},
});
