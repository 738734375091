import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Wallet: FC<IconProps> = ({ fill }) => {
	const fill_ = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M13 3.5H3C2.44772 3.5 2 3.94772 2 4.5V11.5C2 12.0523 2.44772 12.5 3 12.5H13C13.5523 12.5 14 12.0523 14 11.5V4.5C14 3.94772 13.5523 3.5 13 3.5Z"
						stroke={fill_}
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M2 7.50001H5.55C5.66515 7.49937 5.7769 7.539 5.86592 7.61205C5.95495 7.68509 6.01564 7.78695 6.0375 7.90001C6.13298 8.34971 6.38001 8.75297 6.73726 9.04233C7.0945 9.33168 7.54028 9.48957 8 9.48957C8.45972 9.48957 8.9055 9.33168 9.26274 9.04233C9.61999 8.75297 9.86702 8.34971 9.9625 7.90001C9.98436 7.78695 10.0451 7.68509 10.1341 7.61205C10.2231 7.539 10.3348 7.49937 10.45 7.50001H14"
						stroke={fill_}
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M2 5.5H14"
						fill="transparent"
						stroke={fill_}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			}
			width={16}
			height={16}
		/>
	);
};
