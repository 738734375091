import { css } from 'styled-components';
import { darken } from 'polished';
import { theme } from '../theme';
import { fontSize } from './fontSize';

export const buttonSubmit = (color: string) => {
	return css`
		${fontSize({
			sizeDiff: 4,
			sizeDesktopDiff: 4,
			sizeBigScreenDiff: 4,
			important: true,
		})};
		align-items: center;
		background-color: ${color};
		border-radius: ${(props) => props.theme.layout.borderRadius};
		display: flex;
		height: unset !important;
		justify-content: center;
		margin: 0;
		overflow: hidden;
		padding: 15px 20px;
		position: relative;
		text-align: center;
		width: 100%;

		.loader--active {
			position: absolute;

			i,
			svg {
				z-index: 2;
			}
		}

		&:disabled,
		&.button-disabled {
			&:before {
				background: ${darken(0.07, color)};
			}

			background-color: ${darken(0.1, color)};
			color: #dbdbdb;
		}

		@media (max-width: ${theme.media.maxWidthDesktop}) {
			padding: 10px 15px;
		}
	`;
};
