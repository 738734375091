import { IconProps } from '@icons/all-bets';
import { FC } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Transactions: FC<IconProps> = ({ fill }) => {
	const color = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M9 7L11 9L9 11"
						fill="transparent"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M1 9H11"
						fill="transparent"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M3 5L1 3L3 1"
						fill="transparent"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M11 3H1"
						fill="transparent"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			}
			width={12}
			height={12}
		/>
	);
};
