import { config } from '@legacyApp/client/config';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';

export const roundNumber = (
	oldAmount,
	decimal: number = config.valueMaxPrecision,
	direction = 'round',
): number => {
	if (!oldAmount && oldAmount !== 0) {
		return oldAmount;
	}
	const amount = Number(oldAmount);
	if (countPrecision(oldAmount) <= decimal) {
		return amount;
	}
	decimal = Math.pow(10, decimal);
	const result = Math[direction](amount * decimal) / decimal;
	return result === 0 ? 0 : result;
};
