import { jsonToString } from '@common/methods/jsonToString';
import { mathService } from '../math/mathService';
import { config } from '../../config';
import { CurrencyType } from '../../../types/wallet/CurrencyType';
import { BalanceElement } from '../../../types/balance/BalancesArray';
import { sentryError } from './sentryService';
import { isCurrencyDisabled } from './currencyService';
import { getBalanceNative } from './userService';

export const getBalance = (
	balances: BalanceElement[],
	currency: CurrencyType,
) => {
	return balances.find(
		(el) => el.currency.toLowerCase() === currency.toLowerCase(),
	);
};
export const getRealBalanceNative = (
	balances,
	currency: CurrencyType,
): number => {
	if (Array.isArray(balances)) {
		const value = getBalance(balances, currency);
		return value && value.amount !== undefined
			? Number(value.amount)
			: undefined;
	}
	return balances[currency] ? parseFloat(balances[currency]) : undefined;
};

export const checkIsNoBalance = (balances: BalanceElement[]) => {
	return !balances.some(
		(el) =>
			Number(el.amount) >
			mathService.getMinValueByPrecision(config.decimals.bet),
	);
};
export const checkBalancesArray = (source, balances: BalanceElement[] = []) => {
	const verified = [];
	balances.forEach((el) => {
		if (!getBalanceNative(verified, el.currency)) {
			if (!isCurrencyDisabled(el.currency)) {
				verified.push(el);
			}
		}
	});
	return verified;
};
export const updateBalance = (
	balance: BalanceElement = {
		currency: null,
		amount: null,
		amount_usd: null,
	},
	balances: BalanceElement[],
) => {
	const array = [...balances];
	const index = array.findIndex(
		(el) => el.currency.toLowerCase() === balance.currency.toLowerCase(),
	);
	if (index > -1) {
		array[index] = {
			...array[index],
			...balance,
		};
		return array;
	}
	return [...array, balance];
};
export const updateBalances = (
	curr: BalanceElement[] | BalanceElement,
	prev: BalanceElement[],
	from: any,
) => {
	if (!curr) {
		sentryError('No current balance on updateBalances', {
			curr: jsonToString(curr),
			prev: jsonToString(prev),
			from: jsonToString(from),
		});
	}
	if (!Array.isArray(curr)) {
		return updateBalance(curr, prev);
	}
	let array = [...prev];
	if (Array.isArray(curr)) {
		curr.forEach((balance) => (array = updateBalance(balance, array)));
	}
	return array;
};
export const balancesChanged = (
	balances: BalanceElement[],
	lastBalances: BalanceElement[],
) => {
	const changedArray = [];
	for (let i = 0; i < balances.length; i++) {
		const balance = balances[i];
		const lastBalance = getBalance(lastBalances, balance.currency);
		if (
			!lastBalance ||
			(lastBalance && lastBalance.amount !== balance.amount)
		) {
			changedArray.push(balance.currency);
		}
	}
	return changedArray;
};
export const isBalancesChanged = (
	balances: BalanceElement[],
	lastBalances: BalanceElement[],
): boolean => {
	return !!balancesChanged(balances, lastBalances).length;
};
