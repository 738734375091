export const getClientSize = () => {
	if (!process?.browser) {
		return { width: null, height: null };
	}

	const element =
		document.body?.parentElement || document.querySelector('html');

	if (!element) {
		return { width: null, height: null };
	}

	return {
		width: element?.clientWidth,
		height: element?.clientHeight,
	};
};
