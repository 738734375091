export const stringToJson = (string: any, defaultValue?: any) => {
	if (typeof string !== 'string') {
		return string || defaultValue;
	}
	let obj;
	if (['[', '{'].findIndex((el) => string.indexOf(el) > -1) === -1) {
		return defaultValue;
	}
	try {
		obj = JSON.parse(string);
	} catch (e) {
		obj = defaultValue || false;
	}
	return obj;
};
