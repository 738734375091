import { useCallback } from 'react';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { DEFAULT_NAMESPACE } from '@legacyApp/client/modules/translation/translate.constants';
import { useMetaData } from '@legacyApp/hooks/seo/useMetaData';
import { capitalize } from '@legacyApp/client/modules/app/appService';
import { config } from '../../client/config';
import { useStringTrans } from '../translation/useStringTrans';
import { simpleTrans } from '../../client/modules/translation/translationService';

export const useMeta = ({
	title,
	titleTemplate,
	description,
	titleProps = {},
	descriptionProps = null,
	namespace = DEFAULT_NAMESPACE,
}) => {
	const metaData = useMetaData();
	const namespace_ = useStateEffect(
		useCallback(
			() => metaData?.[title]?.namespace || namespace,
			[metaData, namespace, title],
		),
	);
	const trans = useStringTrans(namespace_);
	const title_ = useStateEffect(
		useCallback(
			() =>
				metaData?.[title]
					? metaData?.[title]?.title || metaData?.default?.title
					: title,
			[metaData, title],
		),
	);

	const metaTitle = useStateEffect(
		useCallback(() => {
			const titleTemplate_ =
				titleTemplate ||
				(metaData?.[title_] && metaData?.[title_]?.titleTemplate
					? metaData[title_].titleTemplate
					: false);
			const title = titleTemplate_
				? trans(titleTemplate_, titleProps)
				: `${title_ ? '%s - ' : ''}${simpleTrans(
						metaData?.default?.titleTemplate,
						{
							appName: capitalize(config.appName),
							...(titleProps || {}),
						},
				  )}`;
			if (title_) {
				return title.replace('%s', trans(title_, titleProps));
			}
			return title;
		}, [metaData, titleProps, titleTemplate, title_, trans]),
	);

	const metaDescription = useStateEffect(
		useCallback(() => {
			const description_ =
				description ||
				(metaData?.[title_] && metaData?.[title_]?.description
					? metaData?.[title_]?.description
					: metaData?.default?.description);
			const descriptionProps_ =
				descriptionProps ||
				(metaData?.[title_] && metaData?.[title_]?.descriptionProps
					? metaData?.[title_]?.descriptionProps
					: metaData?.default?.descriptionProps);
			return simpleTrans(description_, descriptionProps_);
		}, [metaData, description, descriptionProps, title_]),
	);
	// console.log('useMeta', { meta, title_, titleProps, titleTemplate, description, descriptionProps, namespace_, trans: trans(title_, titleProps) });
	return {
		title: metaTitle,
		description: metaDescription,
	};
};
