import React from 'react';
import PropTypes from 'prop-types';
import { config } from '@legacyApp/client/config';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { enhanceComponent } from '@legacyApp/client/wrappers/enhanceComponent';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';
import { getValueWithElse } from '@common/methods/getValueWithElse';
import { toFixed } from '@common/methods/toFixed/toFixed';
import { roundStringNumber } from '@common/methods/roundNumber/roundStringNumber';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { getStateColor } from '../StyledProfit';
import { PrettyNumber } from '../../default/PrettyNumber/PrettyNumber';
import { CurrencyValue } from '../CurrencyValue';

class BalanceAmount extends React.PureComponent {
	static propTypes = {
		balanceConverted: PropTypes.string,
		value: PropTypes.any,
		isClass: PropTypes.any,
		hideSmall: PropTypes.bool,
		color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		colorDecimals: PropTypes.number,
		state: PropTypes.string,
		currency: PropTypes.string,
		noFormat: PropTypes.bool,
		simpleFormat: PropTypes.bool,
		fullDecimals: PropTypes.number,
		currencyInFront: PropTypes.bool,
		className: PropTypes.string,
		t: PropTypes.func,
		satoshiMode: PropTypes.bool,
		roundBigValue: PropTypes.number,
		prettyNumberProps: PropTypes.object,
		allowTokenLogo: PropTypes.bool,
	};

	getValueProps = () => {
		const fullDecimals = getValueWithElse(
			this.props.fullDecimals,
			config.decimals.balance,
		);
		const cut = fullDecimals - config.decimals.bet;

		// console.log('getValueProps --------------------', {
		// 	cut,
		// 	fullDecimals,
		// 	props: this.props,
		// });

		let { noFormat, simpleFormat } = this.props;

		const precision = countPrecision(
			roundNumber(this.props.value || 0, fullDecimals),
		);

		if (precision > config.decimals.bet) {
			simpleFormat = false;
		}

		return {
			fullDecimals,
			cut,
			noFormat,
			simpleFormat,
			precision,
		};
	};

	parseSatoshiMode = (value) => {
		if (value < 0.0001) {
			const value_ = roundNumber(value * 1e8, 0);
			return (
				<>
					<span className="text-style-sm-medium">{value_}</span>

					<span className="text-style-sm-medium">sat</span>
				</>
			);
		}
		return this.props.roundBigValue &&
			parseFloat(this.props.value) > this.props.roundBigValue ? (
			<span
				className="text-style-sm-medium round"
				data-value={this.props.value || 0}
			>
				<PrettyNumber
					params={{
						shortFormatMinValue: this.props.roundBigValue,
						shortFormat: true,
						...(this.props.prettyNumberProps || {}),
					}}
				>
					{parseFloat(this.props.value)}
				</PrettyNumber>
			</span>
		) : (
			<span className="text-style-sm-medium">
				{roundNumber(value, value > 100 ? 0 : 4, 'floor')}
			</span>
		);
	};

	parseSimpleFormat = (value) => {
		const { simpleFormat, fullDecimals } = this.getValueProps();
		if (!simpleFormat) {
			return value.toFixed(fullDecimals);
		}
		return mathService.currencyValue(
			value,
			undefined,
			value % 1 === 0 ? 4 : config.decimals.bet,
			fullDecimals,
		);
	};

	parseValue = (value) => {
		value = parseFloat(value);
		if (this.props.noFormat) {
			return this.props.roundBigValue &&
				parseFloat(this.props.value) > this.props.roundBigValue ? (
				<span
					className="text-style-sm-medium round"
					data-value={this.props.value || 0}
				>
					<PrettyNumber
						params={{
							shortFormatMinValue: this.props.roundBigValue,
							shortFormat: true,
							...(this.props.prettyNumberProps || {}),
						}}
					>
						{parseFloat(this.props.value)}
					</PrettyNumber>
				</span>
			) : (
				<span className="text-style-sm-medium" data-value={value || 0}>
					{this.props.value}
				</span>
			);
		}
		value = this.parseSimpleFormat(value);
		if (this.props.satoshiMode) {
			return this.parseSatoshiMode(value);
		}
		return this.props.roundBigValue &&
			parseFloat(this.props.value) > this.props.roundBigValue ? (
			<span
				className="text-style-sm-medium round"
				data-value={this.props.value || 0}
			>
				<PrettyNumber
					params={{
						shortFormatMinValue: this.props.roundBigValue,
						shortFormat: true,
						...(this.props.prettyNumberProps || {}),
					}}
				>
					{parseFloat(this.props.value)}
				</PrettyNumber>
			</span>
		) : (
			<span className="text-style-sm-medium" data-value={value || 0}>
				{value}
			</span>
		);
	};

	getValueElement = () => {
		const { noFormat, simpleFormat, cut, fullDecimals } = this.getValueProps();

		if (noFormat || simpleFormat || this.props.satoshiMode) {
			return this.parseValue(this.props.value || 0);
		}
		const value = toFixed(this.props.value || 0, fullDecimals);
		const bigValue = roundStringNumber(value, fullDecimals - cut, 'floor');
		const smallValue = value.replace(bigValue, '');
		return (
			<>
				{this.props.roundBigValue &&
				parseFloat(this.props.value || 0) > this.props.roundBigValue ? (
					<span
						className="text-style-sm-medium round"
						data-value={this.props.value || 0}
					>
						<PrettyNumber
							params={{
								shortFormatMinValue: this.props.roundBigValue,
								shortFormat: true,
								...(this.props.prettyNumberProps || {}),
							}}
						>
							{parseFloat(this.props.value || 0)}
						</PrettyNumber>
					</span>
				) : (
					<>
						<span className="balance__currency--value-big text-style-sm-medium" data-value={value || 0}>
							{mathService.currencyValue(
								bigValue,
								undefined,
								fullDecimals < config.decimals.bet &&
									(!smallValue || this.props.hideSmall)
									? fullDecimals
									: config.decimals.bet,
							)}
						</span>

						{!this.props.hideSmall ? (
							<span className="balance__currency--value-small text-style-sm-medium scale-75 translate-x-[-12%] translate-y-[8%] inline-block">
								{smallValue}
							</span>
						) : null}
					</>
				)}
			</>
		);
	};

	isValuePositive = () => {
		return this.props.colorDecimals
			? roundNumber(this.props.value, this.props.colorDecimals, 'floor') > 0
			: parseFloat(this.props.value) > 0;
	};

	getColor = () => {
		if (typeof this.props.color === 'boolean' && this.props.state) {
			return getStateColor(this.props.state, this.props.value);
		}
		return this.isValuePositive() && this.props.color;
	};

	render() {
		if (this.props.value === undefined) {
			return null;
		}
		const Element = this.getValueElement;

		return (
			<CurrencyValue
				className={this.props.className}
				currency={this.props.currency}
				isToken={this.props.currency && this.props.allowTokenLogo}
				isCurrencyInFront={this.props.currencyInFront}
			>
				<div
					className={`${CSSClassNameBalanceCurrencyValue} overflow-hidden text-right whitespace-nowrap text-ellipsis [&_*]:whitespace-nowrap [&_.text-style-sm-medium]:relative`}
					style={{ color: this.getColor() }}
				>
					{this.props.balanceConverted && (
						<span className="small-padding-right">$</span>
					)}
					<Element />
				</div>
			</CurrencyValue>
		);
	}
}

BalanceAmount = enhanceComponent({
	BalanceAmount,
});

const CSSClassNameBalanceCurrencyValue = 'balance__currency--value';

export { BalanceAmount, CSSClassNameBalanceCurrencyValue };
