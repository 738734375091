import { jsonToString } from '@common/methods/jsonToString';
import { sentryError } from '../../modules/app/sentryService';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import { actionSimple } from '../../modules/app/actionService';
import {
	APP_CALLBACK,
	APP_CHECK_VERSION,
	APP_CLOSES,
	APP_EXPORT_DATA,
	APP_GO_TO,
	APP_IMPORT_DATA,
	APP_INITIALIZED,
	APP_NEW_VERSION,
	APP_SET_CSRF,
	APP_SET_SOURCE,
	CHECK_NEW_FEATURES,
	HANDLE_COMMANDS,
	LISTEN_COMMANDS,
	MENU_HIDE,
	MENU_TOGGLE,
	SIDE_MENU_HIDE,
	SIDE_MENU_TOGGLE,
	SET_CONTENT_DEVICE_TYPE,
	SET_DEVICE_TYPE,
	SET_EXTENDED_ACCESS,
	SET_HEIGHT,
	SET_LANGUAGE,
	SET_LOCKED_VIEW,
	SET_MOBILE,
	SET_MOBILE_CHAT,
	SET_PATH,
	SET_VISIBLE,
	SET_WIDTH,
	TOGGLE_MAINTENANCE_MODE,
	UPDATE_SENTRY_STATE,
	UPDATE_URL_QUERY,
} from './app.constants';

export const appExportData = (payload) => ({
	type: APP_EXPORT_DATA,
	payload,
});

export const appCallback = (callback) => {
	return {
		type: APP_CALLBACK,
		callback,
	};
};

export const appImportData = (payload) => ({
	type: APP_IMPORT_DATA,
	payload,
});

export const appGoTo = (id, validUri = false) => {
	return {
		type: APP_GO_TO,
		id,
		validUri,
	};
};

export const checkNewFeatures = (onlyCheck) => {
	return {
		type: CHECK_NEW_FEATURES,
		onlyCheck,
	};
};

export const setLanguage = (payload) => {
	return {
		type: SET_LANGUAGE,
		payload,
	};
};

export const listenCommands = () => {
	return {
		type: LISTEN_COMMANDS,
	};
};

export const handleCommands = (payload) => {
	return {
		type: HANDLE_COMMANDS,
		payload,
	};
};

export const appInitialized = (source) => {
	return {
		type: APP_INITIALIZED,
		source,
	};
};

export const setPath = (payload) => {
	return {
		type: SET_PATH,
		payload,
	};
};

export const setMobile = (bool) => {
	return {
		type: SET_MOBILE,
		bool,
	};
};

export const setMobileChat = (bool) => {
	return {
		type: SET_MOBILE_CHAT,
		bool,
	};
};

export const setVisible = (bool) => {
	return {
		type: SET_VISIBLE,
		bool,
	};
};

export const hideMenu = () => {
	return {
		type: MENU_HIDE,
	};
};

export const toggleMenu = (bool) => {
	return {
		type: MENU_TOGGLE,
		bool,
	};
};

export const hideSideMenu = () => {
	return {
		type: SIDE_MENU_HIDE,
	};
};

export const toggleSideMenu = (bool) => {
	return {
		type: SIDE_MENU_TOGGLE,
		bool,
	};
};

export const setLockedView = (id, payload) => {
	if (typeof id !== 'string') {
		payload = id;
		id = 'all';
	}
	return {
		type: SET_LOCKED_VIEW,
		payload,
		id,
	};
};

export const setHeight = (payload) => {
	return {
		type: SET_HEIGHT,
		payload,
	};
};

export const setWidth = (payload) => {
	return {
		type: SET_WIDTH,
		payload,
	};
};

export const setDeviceType = (payload) => {
	return {
		type: SET_DEVICE_TYPE,
		payload,
	};
};

export const setContentDeviceType = (payload) => ({
	type: SET_CONTENT_DEVICE_TYPE,
	payload,
});

export const updateUrlQuery = (payload) => {
	return {
		type: UPDATE_URL_QUERY,
		payload,
	};
};

export const toggleMaintenanceMode = (payload) => {
	return {
		type: TOGGLE_MAINTENANCE_MODE,
		payload,
	};
};

export const appCloses = () => {
	return {
		type: APP_CLOSES,
	};
};

export const updateSentryState = () => {
	return {
		type: UPDATE_SENTRY_STATE,
	};
};

export const getComponentErrorName = (instance) => {
	const _reactInternalFiber = instance._reactInternalFiber;
	let name = '';
	if (_reactInternalFiber && _reactInternalFiber.elementType) {
		if (
			_reactInternalFiber.elementType['[[Scopes]]'] &&
			_reactInternalFiber.elementType['[[Scopes]]'].find(
				(el) => el.WrappedComponent,
			)
		) {
			name = _reactInternalFiber.elementType['[[Scopes]]'].find(
				(el) => el.WrappedComponent,
			).name;
		}
		name = _reactInternalFiber.elementType.displayName;
	}
	return name;
};

export const handleComponentError = ({ instance, error, info }) => {
	return (dispatch, getState) => {
		// console.log(instance);
		// You can also log the error to an error reporting service
		const name = getComponentErrorName(instance);
		const errorInfo = {
			info,
			alert: `Something went wrong, please refresh the page and try again. //${name}`,
			error,
			componentInstance: {
				props: jsonToString({
					...instance.props,
					handleComponentError: null,
				}),
				state: jsonToString(instance.state),
				context: jsonToString(instance.context),
				refs: jsonToString(instance.refs),
			},
		};
		// console.log({...errorInfo});
		sentryError(error, errorInfo, getState());
		dispatch(
			showAlert(
				'error',
				trans({
					label:
						'Something went wrong, please refresh the page and try again. [{{data}}]',
					options: {
						data: name,
					},
				}),
			),
		);
	};
};

export const getState = () => {
	return (dispatch, getState) => {
		return getState();
	};
};

export const setExtendedAccess = (payload) => ({
	type: SET_EXTENDED_ACCESS,
	payload,
});

export const appCheckVersion = () => ({
	type: APP_CHECK_VERSION,
});

export const appNewVersion = () => ({
	type: APP_NEW_VERSION,
});

export const appSetCsrf = (payload) => ({
	type: APP_SET_CSRF,
	payload,
});

export const appSetSource = actionSimple(APP_SET_SOURCE);
