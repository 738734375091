import FetchService from '../../../LegacyApp/client/modules/app/fetchService';

export const checkIsUrlWithCaptcha = (url) => {
	// console.log('captchaUrl', {url, loaderId});
	return FetchService.isUrl(url, [
		// '/login',
		// '/register',
		'/user/change/login',
		'/user/change/email',
		'/user/email/change',
		'/user/change/password',
		'/2fa/install',
		'/2fa/disable',
		'/user/new-password',
		'/user/set-new-password',
		'/user/resend/email-verification',
		'/user/daily-streak/claim',
		'/withdraw/order',
	]);
};
