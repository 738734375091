import { combineReducers } from 'redux';
import {
	callbackUserReducer,
	setUserReducerOld,
} from '../../modules/app/reducers/reducerService';
import {
	FRIENDS_REPEAT_ONLINE,
	FRIENDS_SET_DISABLE_ONLINE,
	FRIENDS_SET_LIST,
	FRIENDS_SET_ONLINE,
	FRIENDS_SET_REQUESTS,
} from './friends.constants';

export const listReducer = setUserReducerOld([], FRIENDS_SET_LIST);
export const requestsReducer = setUserReducerOld([], FRIENDS_SET_REQUESTS);
export const onlineReducer = setUserReducerOld([], FRIENDS_SET_ONLINE);
export const disableOnlineReducer = setUserReducerOld(
	false,
	FRIENDS_SET_DISABLE_ONLINE,
);
export const repeatOnlineActiveReducer = callbackUserReducer(
	false,
	FRIENDS_REPEAT_ONLINE,
	() => true,
);

const friendsReducer = combineReducers({
	list: listReducer,
	requests: requestsReducer,
	online: onlineReducer,
	repeatOnlineActive: repeatOnlineActiveReducer,
	disableOnline: disableOnlineReducer,
});

export { friendsReducer };
