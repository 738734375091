import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const NAME = 'width';

export type ValueType = number;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const widthSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setWidth: setAction,
	},
});
