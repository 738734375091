import React, { FC } from 'react';

import { VipIcon } from '@ui/vip-icon';
import { EmptyIcon } from '@common/components/icons/Icon';

import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import {
	useVipLevel,
	useVipLoaded,
} from '../vip/store/selectors/vip.selectors';
import { useVip } from '../vip/hooks/useVip';

export const MyVipIcon: FC<{
	size: number;
}> = ({ size }) => {
	useVip();
	const level = useVipLevel();
	const loaded = useVipLoaded();

	if (!isTrueOrZero(level?.level) && !loaded) {
		return <EmptyIcon />;
	}

	return <VipIcon size={size} nonLevel level={level?.level} />;
};
