import { combineReducers } from 'redux';
import { getDeviceType } from '@common/methods/getDeviceType';
import { getTimestamp } from '../../modules/app/timeService';
import { getLocationData, saveLanguage } from '../../modules/app/appService';
import {
	setReducer,
	staticReducer,
} from '../../modules/app/reducers/reducerService';
import {
	APP_INITIALIZED,
	APP_SET_CSRF,
	APP_SET_SOURCE,
	MENU_HIDE,
	MENU_TOGGLE,
	SIDE_MENU_HIDE,
	SIDE_MENU_TOGGLE,
	SET_CONTENT_DEVICE_TYPE,
	SET_DEVICE_TYPE,
	SET_EXTENDED_ACCESS,
	SET_HEIGHT,
	SET_LANGUAGE,
	SET_LOCKED_VIEW,
	SET_MOBILE,
	SET_MOBILE_CHAT,
	SET_PATH,
	SET_VISIBLE,
	SET_WIDTH,
	TOGGLE_MAINTENANCE_MODE,
} from './app.constants';

const menuVisibleReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case MENU_HIDE: {
			return false;
		}
		case MENU_TOGGLE: {
			return action.bool === undefined ? !state : action.bool;
		}
		default: {
			return state;
		}
	}
};

const sideMenuVisibleReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SIDE_MENU_HIDE: {
			return false;
		}
		case SIDE_MENU_TOGGLE: {
			return action.bool === undefined ? !state : action.bool;
		}
		default: {
			return state;
		}
	}
};


const visibleReducer = (
	state = true,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_VISIBLE: {
			return action.bool;
		}
		default: {
			return state;
		}
	}
};

const mobileReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_MOBILE: {
			return action.bool;
		}
		default: {
			return state;
		}
	}
};

const mobileChatReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_MOBILE_CHAT: {
			return action.bool;
		}
		default: {
			return state;
		}
	}
};

const pathReducer = (
	state = '',
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_PATH: {
			return action.payload;
		}
		default:
			return state;
	}
};

const lockedViewReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	let index = null;
	let newState = null;
	switch (action.type) {
		case SET_LOCKED_VIEW: {
			if (action.id === 'all') {
				return action.payload ? ['all'] : false;
			}
			if (action.payload) {
				if (state && state.length) {
					index = state.indexOf(action.id);
					if (index > -1) {
						return state;
					}
					return [...state, action.id];
				}
				return [action.id];
			}
			if (!state) {
				return state;
			}
			index = state.indexOf(action.id);
			if (index === -1) {
				return state;
			}
			if (state.length === 1) {
				return false;
			}
			newState = [...state];
			newState.splice(index, 1);
			return newState;
		}
		default:
			return state;
	}
};

const firstTimestampReducer = (state = getTimestamp()) => state;

const widthReducer = (
	state = 0,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_WIDTH: {
			return action.payload;
		}
		default:
			return state;
	}
};

const heightReducer = (
	state = 0,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_HEIGHT: {
			return action.payload;
		}
		default:
			return state;
	}
};

const deviceTypeReducer = (
	state = getDeviceType(),
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_DEVICE_TYPE: {
			return action.payload;
		}
		default:
			return state;
	}
};

const maintenanceModeReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case TOGGLE_MAINTENANCE_MODE: {
			return action.payload;
		}
		default:
			return state;
	}
};

const initializedReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case APP_INITIALIZED: {
			return true;
		}
		default:
			return state;
	}
};

const languageReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_LANGUAGE: {
			saveLanguage(action.payload);
			return action.payload;
		}
		default:
			return state;
	}
};

const contentDeviceTypeReducer = setReducer(
	getDeviceType(),
	SET_CONTENT_DEVICE_TYPE,
);

const extendedAccessReducer = setReducer(
	false,
	SET_EXTENDED_ACCESS,
	'extendedAccess',
);

const locationReducer = staticReducer(getLocationData());

const csrfReducer = setReducer(false, APP_SET_CSRF);

const sourceReducer = setReducer(false, APP_SET_SOURCE);

const appReducer = combineReducers({
	initialized: initializedReducer,
	mobile: mobileReducer,
	mobileChat: mobileChatReducer,
	visible: visibleReducer,
	isMenuVisible: menuVisibleReducer,
	isSideMenuVisible: sideMenuVisibleReducer,
	path: pathReducer,
	isViewLocked: lockedViewReducer,
	firstTimestamp: firstTimestampReducer,
	width: widthReducer,
	height: heightReducer,
	deviceType: deviceTypeReducer,
	maintenanceMode: maintenanceModeReducer,
	language: languageReducer,
	contentDeviceType: contentDeviceTypeReducer,
	extendedAccess: extendedAccessReducer,
	location: locationReducer,
	csrf: csrfReducer,
	source: sourceReducer,
});

export { appReducer };
