import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { epic } from '@legacyApp/client/modules/app/epicService';
import { updateBalances } from '@legacyApp/client/modules/app/balanceService';
import { setBalancesArray } from './balance.actions';
import { BALANCE_FETCH, UPDATE_BALANCE } from './balance.constants';
import { getBalancesThunk } from './thunks/getBalances.thunk';

const updateBalanceEpic = epic('updateBalanceEpic', {
	actions: (ofType) => ofType(UPDATE_BALANCE),
	callback: ({ action, store$ }) => {
		const updatedBalances = updateBalances(
			action.payload,
			store$.value.balance.balancesArray,
			{
				source: 'updateBalanceEpic - balanceEpic',
				action,
			},
		);
		return of(setBalancesArray(updatedBalances, action.source));
	},
});

const fetchEpic = epic('fetchEpic', {
	actions: (ofType) => ofType(BALANCE_FETCH),
	callback: () => {
		return of(getBalancesThunk());
	},
});

const balanceEpic = combineEpics(updateBalanceEpic, fetchEpic);

export { balanceEpic };
