import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

export type ValueType = boolean;
const NAME = 'loaded';
const initialState: ValueType = false;

const loadedUpdate: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const loadedSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		loadedUpdate,
	},
});
