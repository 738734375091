import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useOnMount } from '@common/hooks/useOnMount';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { useAppMobile } from '@common/selectors/app.selectors';
import { useLocationChange } from '@common/hooks/useLocationChange';
import { useAuthInitialized } from '@common/hooks/useAuthInitialized';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { chatActions } from '@modules/chat/store/chat.actions';
import { useChatVisible } from '@modules/chat/store/selectors/useChatVisible';
import { useSideMenu } from '@modules/SideMenu/hooks/useSideMenu';

const INIT_LS_VALUE = lsGet(LocalStorageKeys.SIDE_MENU_IS_OPEN, null);

export const SideMenuProvider: FC<PropsWithChildren<{ disabled: boolean }>> = ({
	children,
	disabled,
}) => {
	const isMobile = useAppMobile();
	const { isOpen, setIsOpen } = useSideMenu();
	const isChatVisible = useChatVisible();
	const dispatch = useDispatch();
	const isLogged = useUserIsLogged();
	const { isInitialized } = useAuthInitialized({ id: ROUTING_ID.HOME });

	const prevIsInitialized = usePrevious(isInitialized);

	const setIsOpen_ = useCallback(
		(_isOpen) => {
			if (_isOpen === isOpen) {
				return;
			}
			if (disabled) {
				_isOpen = false;
			}

			setIsOpen(_isOpen);

			if (isChatVisible && _isOpen) {
				dispatch(chatActions.setVisible({ isVisible: false }));
			}
		},
		[disabled, dispatch, isChatVisible, isOpen],
	);

	useLocationChange(() => {
		if (isMobile && isOpen) {
			console.log('useLocationChange');
			setIsOpen_(false);
		}
	});

	useOnMount(() => {
		const isOpen_ =
			disabled || isMobile
				? false
				: !!lsGet(LocalStorageKeys.SIDE_MENU_IS_OPEN);
		setIsOpen_(isOpen_);
	});

	useEffect(() => {
		if (
			!prevIsInitialized &&
			isInitialized &&
			isLogged &&
			!isOpen &&
			INIT_LS_VALUE === null &&
			!disabled &&
			!isMobile
		) {
			setIsOpen_(true);
		}
	}, [
		disabled,
		isInitialized,
		isLogged,
		isMobile,
		isOpen,
		prevIsInitialized,
		setIsOpen_,
	]);

	return <>{children}</>;
};
