const { DOMAIN } = require('../constants/DOMAIN');
const { updateFromLsConfig } = require('./updateFromLsConfig');

const servicesConfig = updateFromLsConfig({
	socketHost: process.env.SOCKET_HOST,
	socketPort: process.env.SOCKET_PORT,
	pusherId: process.env.PUSHER_ID,
	// hCaptchaKey: process.env.API_URL.indexOf(`https://${DOMAIN}`) > -1 ? '589fb50c-c399-47e6-b917-895e3096943f' : '25197566-0097-4ee4-8732-4fc955007eb7',
	captchaSiteKey:
		process.env.API_URL && process.env.API_URL.indexOf(`https://${DOMAIN}`) > -1
			? '6Lfm6dEUAAAAACvu9PAbATy8FMTe9Tr2a7wG3mkQ'
			: '6LfaFNIUAAAAAJjjrrpTcjdJezfBKwztb9F4BQ9N',
	captchaSiteKeyV2:
		process.env.API_URL && process.env.API_URL.indexOf(`https://${DOMAIN}`) > -1
			? '6LdDf9MUAAAAADih1UuozSe_lvsCusJu7u1JYH5R'
			: '6LdxOI4UAAAAALIYb4FpNtU1OqgGFdCmem6ohlj8',
	licenseId: 'apg-10281cf8-35d4-4f92-926f-7573b336f155',
	sentryDns: {
		server: 'https://cdc3a73d540140d88c6056f30e6d4276@sentry.io/2961146',
		client:
			'https://101d822f06c14ec6b650593462b50ec8@o271445.ingest.sentry.io/6699233',
	},
	releaseVersion: process.env.RELEASE_VERSION,
});

module.exports = {
	servicesConfig,
};
