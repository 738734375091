import { FC, PropsWithChildren, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { PORTAL } from './portal.constants';

export const Portal: FC<PropsWithChildren<{ id: PORTAL; subId?: string }>> = ({
	children,
	id,
	subId,
}) => {
	const id_ = subId ? `${id}-${subId}` : id;
	const [dom, setDom] = useState(
		process.browser ? document.getElementById(id_) : null,
	);

	useLayoutEffect(() => {
		if (!dom) {
			const div = document.createElement('div');
			div.id = id_;
			const next = document.getElementById('__next');
			next.parentNode.insertBefore(div, next);
			setDom(document.getElementById(id_));
		}
	}, [dom, id_]);

	if (!dom) {
		return null;
	}

	return createPortal(children, dom);
};
