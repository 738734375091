import dynamic from 'next/dynamic';
import { RectangleLoader } from '@common/components/contentLoaders/RectangleLoader';

export const Balance = dynamic(() => import('./components/Balance'), {
	ssr: false,
	loading: () => (
		<RectangleLoader
			passToWrapper
			height={25}
			width={150}
			id={'balanceElement'}
		/>
	),
});
export const BalanceContainer = dynamic(
	() => import('./components/Balance.container'),
	{
		ssr: false,
		loading: () => (
			<RectangleLoader
				passToWrapper
				height={25}
				width={150}
				id={'balanceElement'}
			/>
		),
	},
);
