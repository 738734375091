import { rgba } from 'polished';

const getCardVariables = () => {
	const widthScale = 2.3;
	const heightScale = 3.5;
	const heightToWidthScale = heightScale / widthScale;
	const cardScale = 70;
	const smallScale = cardScale / 3;
	return {
		widthScale,
		heightScale,
		heightToWidthScale,
		cardScale: `${cardScale}px`,
		smallScale: `${smallScale}px`,
		smallHeight: `${heightScale * smallScale}px`,
		smallWidth: `${widthScale * smallScale}px`,
		height: `${heightScale * cardScale}px`,
		width: `${widthScale * cardScale}px`,
	};
};

const header = {
	height: '50px', // with stats bar was 70px
	heightDesktop: '60px', // with stats bar was 75px
	heightBigDesktop: '60px', // with stats bar was 82px
	color: '#ffffff',
	mobile: `${45 * 0.7}px`,
} as const;

const colors = {
	green: '#00bb39',
	blue: '#343434',
	red: '#FF0000',
	lightblue: '#0553c5',
	turquoise: '#1bd6fe',
	skyblue: '#1eaffc',
	background: '#0c1420',
	backgroundLight: '#003ea7',
	headerActiveBackground: '#051b36',
	// headerActiveBackground: '#152234',
	tableBorder: '#495763',
	tableBorderLight: '#3a4959',
	inputBackgroundSolid: '#072349',
	inputBackground: 'rgba(7, 35, 73, 0.87)',
	inputBackgroundActive: '#002a5b',
	inputButton: '#0a58b9',
	inputBackgroundDisabled: '#011429',
	inputLightBackgroundSolid: '#013672',
	inputLightBackground: `${rgba('#013672', 0.87)}`,
	inputLightBackgroundActive: '#023f86',
	modalBackground: `${rgba('rgb(3, 27, 60)', 0.93)}`,
	modalBorderColor: '#1f1f1f',
	modalBackgroundSolid: 'rgb(3, 27, 60)',
	buttonDefaultBackground: 'rgba(7, 35, 73, 0.87)',
	veryLightBlue: '#a0ccff',
	secondText: '#4272b4',
	placeholderText: '#3d70ca',
	gold: '#e0b201',
	grey: '#9c9c9c',
	active: 'rgba(64,139,252,1)',
	borderColor: '#495763',
	passwordValidationColor: '#2d5482',
	modalBg: '#001126',
	sectionsBorder: '#0e386b',
	text: '#ffffff',
	textDisabled: '#a8a8a8',
	alertError: '#990015',
	alertInfo: '#1d5dca',
	negative: '#d00226',
	negativeLight: '#f1001c',
	positive: '#1AA529',
	positiveLight: '#1dbb2f',
	rowTitleBg: '#072349',
	rowContentBg: '#0e2f5c',
	settingsMenuBg: '#132136',
	settingsPanelBg: '#080f1a',
	dropdownBlue: '#0554ca',
	dropdownSelectBlue: '#0043a6',
	tableBorderColorLight: '#3a4959',
	darkActive: '#0F3460',
	autobetButtonsBackground: '#0e3b6f',
	orange: '#FFA500',
	orangeBanner: '#ff8400',
	level: {
		unranked: '#4d4d4d',
		bronze: '#bf623d',
		silver: '#bfbfbf',
		gold: '#c1a73d',
		diamond: '#713899',
	},
} as const;

export const theme = {
	ui: {
		colors: {
			white: 'rgba(255,255,255,1)',
			'blue-dark': 'rgba(50,109,203,1)',
			'blue-brand-blue': 'rgba(13,110,255,1)',
			'blue-blue-light': 'rgba(64,139,252,1)',
			'blue-blue-lightest': 'rgba(194,219,255,1)',
			'white-white-50': 'rgba(255,255,255,0.500)',
			'white-white-20': 'rgba(255,255,255,0.200)',
			'white-white-5': 'rgba(255,255,255,0.050)',

			/* App BG */
			'dark-900': 'rgba(17,26,41,1)',
			'dark-800': 'rgba(23,30,41,1)',
			'dark-700': 'rgba(28,37,51,1)',

			/* Border */
			'dark-600': 'rgba(34,47,66,1)',

			/* Button border */
			'dark-500': 'rgba(40,55,77,1)',
			'dark-400': 'rgba(47,69,102,1)',
			'dark-300': 'rgba(77,93,118,1)',

			/* Secondary btn disabled */
			'dark-200': 'rgba(109,125,151,1)',
			'dark-100': 'rgba(131,146,170,1)',
			'dark-50': 'rgba(184,198,220,1)',
			'gradient-secondary-button': '#263752',
			'gradient-secondary-button-hover': '#22334c',
			'other-primary-gradient-from': '#3d87f9',
			'other-primary-gradient-to': '#1566df',
			'gradient-primary-button':
				'linear-gradient(to right, #3d87f9 0%,#1566df 100%)',
			'gradient-primary-button-hover':
				'linear-gradient(to right, #1c6eed 0%,#0144a8 100%)',
			'gradient-tab-icon-active':
				'linear-gradient(to right, #4b8bf1 0%,#184ea3 99.99%,#184ca0 100%)',
			'gradient-green': 'linear-gradient(to right, #4cc274 0%,#18d458 100%)',
			'gradient-pink': 'linear-gradient(to right, #f04cc2 0%,#ff08ba 100%)',
			'gradient-blue': 'linear-gradient(to right, #3c87f9 0%,#1370ff 100%)',
			'other-success': 'rgba(38,168,82,1)',
			'other-pink': 'rgba(240,76,194,1)',
			'other-cyan': 'rgba(79,200,210,1)',
		},
	},
	colors: {
		...colors,
		buttonDefaultBackground: colors.inputBackground,
	},
	text: {
		size: {
			default: '12px',
			desktop: '16px',
			bigScreen: '18px',
		},
		weight: {
			thin: 100,
			extraLight: 200,
			light: 300,
			regular: 400,
			medium: 500,
			semiBold: 600,
			bold: 700,
			extraBold: 800,
			black: 900,
		},
		height: {
			regular: 1.25,
		},
		fonts: {
			Black: 'LatoBlack, Arial Black, sans-serif',
			Bold: 'FontBold, LatoBlack, Arial Black, sans-serif',
			Regular: 'FontRegular, Maven Pro, Inter, Arial, sans-serif',
		},
	},
	layout: {
		noBorder: '0 solid transparent',
		borderRadius: '4px',
		transition: '300ms',
		boxShadow: '0 0 45px -15px black',
		bottomMenuHeight: '60px',
		footerHeight: '180px',
		sideFullPadding: '20px',
		verticalPadding: '15px',
		buttonMargin: '15px',
		container: {
			small: '1080px',
			big: '1440px',
		},
		game: {
			bottomIndentation: '70px',
			sideMargin: '40px',
			mobile: '138px',
			mobileAutobet: '78px',
			tablet: '244px',
			tabletAutobet: '246px',
		},
		header,
		table: {
			minTableWidth: '1050px',
			cellHeight: '30px',
		},
		chat: {
			width: '320px',
			widthDesktop: '320px',
			inputHeightBigDesktop: '100px',
			inputHeightDesktop: '85px',
			inputHeight: '75px',
			headerHeight: header.height, // with stats bar $headerHeight - 15px
			headerHeightDesktop: header.heightDesktop, // with stats bar $headerHeightDesktop - 29px
			headerHeightBigDesktop: header.heightBigDesktop, // with stats bar $headerHeightBigDesktop - 37px
			leftMenuWidth: '320px',
			leftMenuWidthDesktop: '320px',
		},
		card: {
			...getCardVariables(),
		},
		input: {
			height: '35px',
			heightMobile: '30px',
			heightDesktop: '44px',
			heightBigDesktop: '54px',
		},
	},
	media: {
		maxWidthVerySmall: '359px',
		maxWidthMobile: '420px',
		maxWidthSmallTablet: '620px',
		maxWidthTablet: '820px',
		maxWidthDesktop: '1450px',
		minWidthMobile: '360px',
		minWidthSmallTablet: '421px',
		minWidthTablet: '621px',
		minWidthDesktop: '821px',
		minWidthBigDesktop: '1451px',
	},
	media_v2: {
		min_mobile_medium: '321px',
		min_mobile_large: '421px',
		min_tablet_small: '541px',
		min_tablet_medium: '631px',
		min_tablet_large: '721px',
		min_desktop_small: '991px',
		min_desktop_medium: '1281px',
		min_desktop_large: '1321px',
		max_mobile_small: '320px',
		max_mobile_medium: '420px',
		max_mobile_large: '540px',
		max_tablet_small: '630px',
		max_tablet_medium: '720px',
		max_tablet_large: '990px',
		max_desktop_small: '1280px',
		max_desktop_medium: '1320px',
	},
	media_specific: {
		max_alerts_mobile: '600px',
	},
	empty: '',
} as const;
export type ThemeType = typeof theme;
