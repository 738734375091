import ContentLoader from 'react-content-loader';
import { FC } from 'react';
import { config } from './loaders.config';

export const RectangleLoader: FC<{
	height?: number;
	width?: number;
	id: string;
	borderRadius?: number;
	passToWrapper?: boolean;
	className?: string;
}> = ({
	height = 150,
	width = 250,
	borderRadius = 3,
	passToWrapper,
	...props
}) => {
	return (
		<ContentLoader
			width={passToWrapper && width}
			height={passToWrapper && height}
			viewBox={`0 0 ${width} ${height}`}
			uniqueKey={`${props.id || 0}-rectangleLoader`}
			{...config}
			{...props}
		>
			<rect
				x="0"
				y="0"
				rx={borderRadius}
				ry={borderRadius}
				width={width}
				height={height}
			/>
		</ContentLoader>
	);
};
