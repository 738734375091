import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { actionSimple } from '../../modules/app/actionService';
import {
	CONVERTER_SET_CONFIRM,
	CONVERTER_SET_CURRENCY,
	CONVERTER_SWITCH_CURRENCY,
} from './converter.constants';

export const converterSetCurrency = actionSimple(CONVERTER_SET_CURRENCY);
export const converterSetConfirm = actionSimple(CONVERTER_SET_CONFIRM);
export const converterSwitchCurrency = actionSimple(CONVERTER_SWITCH_CURRENCY);

export const converterToUsd = (
	value,
	currency,
	precision,
	direction,
	force,
) => {
	return (dispatch, getState) => {
		const state = getState();
		if (!currency) {
			currency = state.user.activeCurrency;
		}
		// console.log('converterToUsd - 1', {
		//   wallet, value: parseFloat(value), precision
		// });
		if (currency === 'usd' || (state.converter.currency !== 'usd' && !force)) {
			return value;
		}
		const price = state.stats.price[currency];
		if (!price) {
			return 0;
		}
		// console.log('converterToUsd - 2', {
		//   price, wallet, value: parseFloat(value), precision, result: round(parseFloat(value) * price, precision)
		// });
		return roundNumber(parseFloat(value) * price, precision, direction);
	};
};

export const converterToNative = (
	value,
	currency,
	precision,
	direction,
	force,
) => {
	return (dispatch, getState) => {
		const state = getState();
		if (!currency) {
			currency = state.user.activeCurrency;
		}
		// console.log('converterToNative - 1', {
		//   wallet, value: parseFloat(value), precision
		// });
		if (currency === 'usd' || (state.converter.currency !== 'usd' && !force)) {
			return value;
		}
		const price = state.stats.price[currency];
		if (!price) {
			return 0;
		}
		// console.log('converterToNative - 2', {
		//   price, wallet, value: parseFloat(value), precision, result: round(parseFloat(value) / price, precision)
		// });
		return roundNumber(parseFloat(value) / price, precision, direction);
	};
};
