import React, { FC, ReactNode } from 'react';
import MultiCarousel, {
	CarouselProps as MultiCarouselProps,
} from 'nuka-carousel';

export interface CarouselElement {
	id: string;
	content: ReactNode;
}

export interface CarouselProps extends MultiCarouselProps {
	list: Array<CarouselElement>;
}

export const Carousel: FC<CarouselProps> = (props) => {
	const { list, ...restProps } = props;
	return (
		<>
			<MultiCarousel {...restProps}>
				{list.map((element) => {
					return <div key={element.id}>{element.content}</div>;
				})}
			</MultiCarousel>
		</>
	);
};
