import styled, { css } from 'styled-components';
import { InputStyleType } from '@uiTypes/InputStyleType';
import { handleHover } from '@legacyApp/client/modules/style/mixins';
import { InputBorderRadius } from './mixins/input.border-radius';
import { InputProps } from './index';

const PROPS_DEFAULTS = {
	$type: InputStyleType.SQUARE,
};

export interface InputStyledProps extends InputProps {
	$icon: boolean;
	$isLabel: boolean;
}

export const InputLabelActionStyled = styled.span`
	color: var(--color-blue-blue-light);
	cursor: pointer;
`;

export const InputLabelStyled = styled.label`
	color: var(--color-dark-300);
	padding-bottom: 12px;
	width: 100%;

	${InputLabelActionStyled} {
		float: right;
	}
`;

export const InputIconWrapperStyled = styled.span`
	left: 8px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	* {
		float: left;
		height: 20px !important;
		width: 20px !important;
	}
`;

export const InputStyled = styled.input<InputStyledProps>`
	background-color: var(--color-dark-500);
	border: 1px solid transparent;
	box-shadow: none;
	color: var(--color-white);
	margin: 0;
	outline: none;
	padding: 10px ${(props) => (props.$icon ? '8px 10px 36px' : '16px')};
	touch-action: manipulation;
	transition: 300ms;
	::placeholder {
		color: var(--color-dark-300);
	}

	// ::-webkit-input-placeholder {
	// 	/* Chrome/Opera/Safari */
	// 	color: ${(props) => props.theme.colors.placeholderText};
	// }
	//
	// ::-moz-placeholder {
	// 	/* Firefox 19+ */
	// 	color: ${(props) => props.theme.colors.placeholderText};
	// }
	//
	// :-ms-input-placeholder {
	// 	/* IE 10+ */
	// 	color: ${(props) => props.theme.colors.placeholderText};
	// }
	//
	// :-moz-placeholder {
	// 	/* Firefox 18- */
	// 	color: ${(props) => props.theme.colors.placeholderText};
	// }

	${InputBorderRadius};

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	${(props) =>
		props.disabled
			? css`
					color: var(--color-dark-200);

					::placeholder {
						color: var(--color-dark-200);
					}
			  `
			: css`
					:focus {
						border-color: var(--color-blue-brand-blue);
					}

					${handleHover(css`
						background-color: var(--color-dark-800);
						border-color: var(--color-blue-brand-blue);
					`)}
			  `}
`;

InputStyled.defaultProps = PROPS_DEFAULTS;

export const InputWrapperStyled = styled.div<InputStyledProps>`
	position: relative;
`;

InputWrapperStyled.defaultProps = PROPS_DEFAULTS;

export const InputBoxStyled = styled.div<InputStyledProps>`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
`;

InputBoxStyled.defaultProps = PROPS_DEFAULTS;
