import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputWrapperStyled } from '@ui/input/Input.styled';
import { CurrencySymbol } from '@legacyApp/client/components/currencySymbol/currencySymbol';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { CurrenciesLayout } from '@legacyApp/types/wallet/CurrencyType';
import { useUserActiveCurrency } from '../../selectors/user.selectors';
import { NumberInput } from '../input/NumberInput';
import { useStatsPrice } from '../../selectors/stats.selectors';
import { roundNumber } from '../../methods/roundNumber/roundNumber';

export const PriceConverter: FC = () => {
	const activeCurrency = useUserActiveCurrency();
	const price = useStatsPrice(activeCurrency);
	const prevPrice = usePrevious(price);

	const [amount, setAmount] = useState(1);
	const [converted, setConverted] = useState(1);
	const [lastChanged, setLastChanged] = useState(activeCurrency);

	const onChange = useCallback(
		(value, currency) => {
			const isConverted = currency === CurrenciesLayout.usd;
			const converted = isConverted ? value : roundNumber(price * value, 6);
			const amount = isConverted ? roundNumber(value / price, 8) : value;
			setAmount(amount);
			setConverted(converted);
			setLastChanged(currency);
		},
		[price],
	);

	useEffect(() => {
		if (prevPrice !== price) {
			onChange(
				lastChanged === CurrenciesLayout.usd ? converted : amount,
				lastChanged,
			);
		}
	}, [amount, converted, lastChanged, onChange, prevPrice, price]);

	if (!price) {
		return null;
	}

	return (
		<PriceConverterStyled>
			<NumberInput
				icon={
					<i>
						<CurrencySymbol currency={activeCurrency} />
					</i>
				}
				value={amount}
				onChange={(event) => onChange(event.target.value, activeCurrency)}
			/>
			<span className="align-center">≈</span>
			<NumberInput
				icon={
					<i>
						<CurrencySymbol currency={CurrenciesLayout.usd} />
					</i>
				}
				value={converted}
				onChange={(event) => onChange(event.target.value, 'usd')}
			/>
		</PriceConverterStyled>
	);
};

export const PriceConverterStyled = styled.div`
	align-items: center;
	display: grid;
	gap: 12px;
	grid-template-columns: calc(50% - 16px) 10px calc(50% - 16px);

	${InputWrapperStyled},
	input {
		width: 100%;
	}
`;
