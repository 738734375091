import { css } from 'styled-components';
import { importantFunc } from './importantFunc';

export const currencyLogoStyle = ({ size, important = true }) => {
	const importantLabel = importantFunc(important);
	return css`
		height: ${size}${importantLabel};
		min-width: ${size}${importantLabel};
		width: ${size}${importantLabel};

		svg {
			height: ${size}${importantLabel};
			min-width: ${size}${importantLabel};
			width: ${size}${importantLabel};
		}
	`;
};
