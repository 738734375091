import moment from 'dayjs';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { day, minute, second } from './time';

const getMidnightDate = (date = new Date()) => {
	date = getDate(date);
	console.log(
		'getMidnightDate',
		`${date.getFullYear()}-${dateNumber(date.getMonth() + 1)}-${dateNumber(
			date.getDate(),
		)}T00:00:00.000Z`,
	);
	return new Date(
		`${date.getFullYear()}-${dateNumber(date.getMonth() + 1)}-${dateNumber(
			date.getDate(),
		)}T00:00:00.000Z`,
	);
};

const getNonOffsetMidnightDate = (date = new Date()) => {
	date = getDate(date);
	return new Date(
		`${date.getFullYear()}-${dateNumber(date.getMonth() + 1)}-${dateNumber(
			date.getDate(),
		)}T00:00:00.000Z`,
	);
};

const getNonOffsetDate = (date = new Date()) => {
	date = getDate(date);
	return getTimestamp(date) + getOffset(date);
};

const getOffset = (date = new Date()) => {
	return date.getTimezoneOffset() * minute * -1;
};

const getTimezoneDateFromUnix = (date = new Date()) => {
	date = getDate(date);
	return getTimestamp(date) - getOffset(date);
};

const getDayFromDate = (value = 0, date = new Date()) => {
	date = getDate(date);
	return new Date(date.getTime() + day * value);
};

const getYearFromDate = (value = 0, date = new Date()) => {
	date = getDate(date);
	return new Date(
		`${date.getFullYear() + value}-${dateNumber(
			date.getMonth() + 1,
		)}-${dateNumber(date.getDate())}T00:00:00.000Z`,
	);
};

const dateNumber = (number) => {
	return parseFloat(number) >= 10 ? number : `0${number}`;
};

const getTimestamp = (date) => {
	if (typeof date === 'number') {
		// console.log('getTimestamp', {
		// 	date,
		// 	rounded: roundNumber(date, -9, 'ceil'),
		// 	curr: new Date().getTime(),
		// });
		if (roundNumber(date, -9, 'ceil') > new Date().getTime()) {
			return date;
		} else {
			return date * second;
		}
	}
	return getDate(date).getTime();
};

const getDate = (string) => {
	if (!string) {
		return new Date();
	}
	return isDateObject(string) ? string : new Date(moment(string).valueOf());
};

const isDateObject = (date) => {
	return typeof date === 'object';
};

const formatToTime = (date, seconds) => {
	const format = seconds ? 'HH:mm:ss' : 'HH:mm';
	return moment(date).format(format);
};

const formatToFullDate = (date) => {
	return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const formatToFullDateNoSeconds = (date) => {
	return moment(date).format('YYYY-MM-DD HH:mm');
};

const formatDate = (date, format) => {
	return moment(date).format(format);
};

const formatToDate = (date) => {
	return moment(date).format('YYYY-MM-DD');
};

const parseTimestamp = (timestamp) => {
	return timestamp
		? typeof timestamp === 'number'
			? timestamp * 1000
			: getTimezoneDateFromUnix(timestamp)
		: timestamp;
};

export {
	getMidnightDate,
	getDayFromDate,
	getYearFromDate,
	getTimestamp,
	getDate,
	isDateObject,
	formatToTime,
	formatToFullDate,
	formatToDate,
	getNonOffsetDate,
	getNonOffsetMidnightDate,
	getOffset,
	getTimezoneDateFromUnix,
	parseTimestamp,
	formatDate,
};
