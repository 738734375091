import { trans } from '../translation/translate';

export const parseApiInfo = (info, noTranslationCheck, namespace) => {
	if (!info) {
		return info || null;
	}
	if (typeof info === 'string') {
		return trans({
			label: info,
			noTranslationCheck: noTranslationCheck,
			namespace: namespace,
		});
	}
	if (typeof info === 'object') {
		if (!Object.keys(info).length) {
			return null;
		}
		let message = info;
		if (info) {
			if (info.message) {
				message = info.message;
			}
			if (info.error) {
				message = info.error;
			}
			if (info.errors) {
				message = info.errors[0];
			}
		}
		return trans({
			label: message,
			options: info?.values || message?.values,
			components: info?.components || message?.components,
			noTranslationCheck: noTranslationCheck,
			namespace: info?.namespace || namespace,
		});
	}
	return info || null;
};

export const getStringApiInfo = (info) => {
	if (!info) {
		return info;
	}
	if (typeof info === 'string') {
		return info;
	}
	if (typeof info === 'object' && info.message) {
		return info.message;
	}
	return info;
};
