export const FRIENDS_GET_LIST = 'FRIENDS_GET_LIST';
export const FRIENDS_GET_REQUESTS = 'FRIENDS_GET_REQUESTS';
export const FRIENDS_SET_LIST = 'FRIENDS_SET_LIST';
export const FRIENDS_SET_REQUESTS = 'FRIENDS_SET_REQUESTS';
export const FRIENDS_NEW_REQUEST = 'FRIENDS_NEW_REQUEST';
export const FRIENDS_CANCELLED_REQUEST = 'FRIENDS_CANCELLED_REQUEST';
export const FRIENDS_ACCEPTED_REQUEST = 'FRIENDS_ACCEPTED_REQUEST';
export const FRIENDS_DELETED = 'FRIENDS_DELETED';
export const FRIENDS_SET_ONLINE = 'FRIENDS_SET_ONLINE';
export const FRIENDS_SET_DISABLE_ONLINE = 'FRIENDS_SET_DISABLE_ONLINE';
export const FRIENDS_GET_ONLINE = 'FRIENDS_GET_ONLINE';
export const FRIENDS_REPEAT_ONLINE = 'FRIENDS_REPEAT_ONLINE';

// available friends statuses
export const IGNORING = 'ignoring'; // ignored by me
export const IGNORED = 'ignored'; // ignored by user
export const NONE = null; // no relation
export const SENT = 'sent'; // sent friend request
export const FRIEND = 'friend'; // friend relation
export const REQUEST = 'request'; // received friend request
