import { countPrecision } from '@legacyApp/methods/math/countPrecision';
import { roundStringNumber } from '../roundNumber/roundStringNumber';

export const toFixed = (
	num: number | string = 0,
	precision: number,
): string => {
	let num_ = roundStringNumber(num, precision);
	const [int, decimal] = num_.split('.');
	if (precision === 0) {
		return int;
	}
	if (precision < 0 || decimal?.length === precision) {
		return num_;
	}
	if (!decimal) {
		num_ += '.0';
	}
	while (countPrecision(num_) < precision) {
		num_ += '0';
	}
	return num_;
};
