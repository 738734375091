import { generateUrl, GenerateUrl, ROUTE } from '../../../common/routes/Routes';
import { generateSlotsApiUrl } from '../../slots/services/Slots.service';
import { BetSlipHistoryStatusType } from '../types/BetSlipHistory/BetSlipHistoryTypes';

const generateSportsBookApiUrl: GenerateUrl = (name, options) => {
	return generateSlotsApiUrl(name, options);
};

export const generateSportsBookUserBetSlipsHistory = ({
	page,
	type,
}: {
	page?: number;
	type: BetSlipHistoryStatusType;
}) => {
	return generateSportsBookApiUrl(ROUTE.apiSportsBookUserBetSlipHistory, {
		...(page && { page }),
		state: type,
	});
};

export const generateUserSportsBetSlipsHistory = ({
	state,
}: {
	state: BetSlipHistoryStatusType;
}) => {
	return generateUrl(ROUTE.userSportsBetSlipsHistory, { state });
};

export const generateUserSportsBetSlipsHistoryV1 = ({
	state,
}: {
	state: BetSlipHistoryStatusType;
}) => {
	return generateUrl(ROUTE.userSportsBetSlipsHistoryV1, { state });
};
