import Redux from 'redux';
import { cookieService } from '@common/controllers/cookieService';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeysType } from '@modules/localStorage/types/localStorageKeys.type';

import { stringToJson } from '@common/methods/stringToJson';

export type SetReducerType<T = unknown, A = Redux.AnyAction> = (
	init: T,
	set:
		| string
		| {
				action: string;
				callback: (data: T) => T;
		  },
	cookieKey?: `localstorage-${LocalStorageKeysType}`,
	clearAction?: string,
	stateCallback?: (state: T, action: A) => T,
) => Redux.Reducer;

export const setReducer: SetReducerType = (
	init,
	set,
	cookieKey,
	clearAction,
	stateCallback,
) => {
	let getStorage = cookieService.get;
	let setStorage = cookieService.set;
	let clearStorage = cookieService.clear;
	let cookieKey_: LocalStorageKeysType = null;
	if (cookieKey && cookieKey.indexOf('localstorage-') > -1) {
		cookieKey_ = cookieKey.replace('localstorage-', '') as LocalStorageKeysType;
		getStorage = lsGet;
		setStorage = lsSet;
		clearStorage = lsClear;
	}
	const cookie = cookieKey_ ? getStorage(cookieKey) : null;
	let setAction = set;
	let setCallback = null;
	if (typeof set !== 'string') {
		if (set.action) {
			setAction = set.action;
		}
		if (set.callback) {
			setCallback = set.callback;
		}
	}
	return (
		state = stringToJson(cookie, cookie) || init,
		action: Redux.AnyAction,
	) => {
		if (action.type === setAction) {
			if (cookieKey) {
				setStorage(cookieKey, action.payload);
			}
			return setCallback ? setCallback(action.payload) : action.payload;
		}
		if (clearAction && action.type === clearAction) {
			if (cookieKey) {
				clearStorage(cookieKey);
			}
			return init;
		}
		if (stateCallback) {
			return stateCallback(state, action);
		}
		return state;
	};
};
