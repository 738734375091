import { FC } from 'react';
import { TabButton } from '@ui/tab-button';
import { TabNavigationProps } from '@ui/tab-navigation';
import { TabGroupStyled } from '@ui/tab-group/TabGroup.styled';

export type TabGroupProps = TabNavigationProps;

export const TabGroup: FC<TabGroupProps> = ({
	tabs,
	activeTabId,
	onClick,
	children,
	small,
}) => {
	if (!tabs?.length) {
		return null;
	}

	return (
		<TabGroupStyled>
			{tabs.map((tab) => (
				<TabButton
					key={tab.id}
					{...tab}
					active={activeTabId === tab.id}
					{...{
						...(onClick && { onClick }),
						...(small && { small }),
					}}
				/>
			))}
			{children}
		</TabGroupStyled>
	);
};
