import isPlainObject from 'lodash.isplainobject';
import { epicManager, reducerManager } from '../create.store';
import { second } from '../../modules/app/time';
import {
	REGISTRY_EPIC_INJECT,
	REGISTRY_EPIC_REJECT,
	REGISTRY_REDUCER_INJECT,
	REGISTRY_REDUCER_REJECT,
} from './registry.constants';
import {
	registryEpicInjected,
	registryEpicRejected,
	registryReducerInjected,
	registryReducerRejected,
} from './registry.actions';

const REJECT_TIMEOUT = 5 * second;

const injectedCounter = {};

const rejectTimouts = {};

const handle = (
	action,
	dispatch,
	injectConst,
	rejectConst,
	injectedAction,
	rejectedAction,
	manager,
) => {
	if (
		isPlainObject(action) &&
		action.type &&
		[injectConst, rejectConst].indexOf(action.type) > -1
	) {
		if (action.payload) {
			const isAdding = action.type === injectConst;
			const managerFunc = isAdding ? manager.add : manager.remove;
			const actionFunc = isAdding ? injectedAction : rejectedAction;
			const id = `${rejectConst}_${action.payload.key}`;

			// console.log(action.type, {
			// 	action,
			// 	manager,
			// 	id,
			// 	injectedCounter: injectedCounter[id],
			// 	rejectTimouts,
			// });

			const callback = () => {
				const finnished =
					managerFunc &&
					managerFunc(action.payload.key, action.payload.module, dispatch);
				if (finnished) {
					dispatch(
						actionFunc({
							key: action.payload.key,
							package: action.payload.package,
						}),
					);
				}
			};
			if (rejectTimouts[id]) {
				clearTimeout(rejectTimouts[id]);
				rejectTimouts[id] = null;
			}
			if (!isAdding) {
				injectedCounter[id] = injectedCounter[id] - 1 || 0;
				if (!injectedCounter[id]) {
					delete injectedCounter[id];
					rejectTimouts[id] = setTimeout(() => {
						callback();
					}, REJECT_TIMEOUT);
				}
			} else {
				injectedCounter[id] = injectedCounter[id] ? injectedCounter[id] + 1 : 1;
				callback();
			}

			// console.log('handleRegistry', {
			// 	action: { ...action },
			// 	manager,
			// 	isAdding,
			// 	managerFunc,
			// 	actionFunc,
			// 	id,
			// 	injectedCounter,
			// 	rejectTimouts: { ...rejectTimouts },
			// });
		}
		return true;
	}
	return false;
};

export const registryMiddleware = () => {
	return (store$) => (next) => (action) => {
		if (!action?.type) {
			console.trace();
			throw new Error('registryMiddleware - Missing type in action');
		}
		if (
			handle(
				action,
				store$.dispatch,
				REGISTRY_REDUCER_INJECT,
				REGISTRY_REDUCER_REJECT,
				registryReducerInjected,
				registryReducerRejected,
				reducerManager,
			)
		) {
			return;
		}
		if (
			handle(
				action,
				store$.dispatch,
				REGISTRY_EPIC_INJECT,
				REGISTRY_EPIC_REJECT,
				registryEpicInjected,
				registryEpicRejected,
				epicManager,
			)
		) {
			return;
		}
		return next(action);
	};
};
