export const NEW_BET = 'NEW_BET' as const;
export const NEW_HIGH_ROLL = 'NEW_HIGH_ROLL' as const;
export const NEW_MY_BET = 'NEW_MY_BET' as const;
export const NEW_RESULT = 'NEW_RESULT' as const;
export const NEW_DEPOSIT = 'NEW_DEPOSIT' as const;
export const NEW_DEPOSIT_DETECTED = 'NEW_DEPOSIT_DETECTED' as const;
export const WITHDRAW_CONFIRMED = 'WITHDRAW_CONFIRMED' as const;
export const GET_ALL_BETS = 'GET_ALL_BETS' as const;
export const SET_ALL_BETS = 'SET_ALL_BETS' as const;
export const GET_HIGH_ROLLS = 'GET_HIGH_ROLLS' as const;
export const SET_HIGH_ROLLS = 'SET_HIGH_ROLLS' as const;
export const TRANSACTIONS_GET_VAULT_HISTORY =
	'TRANSACTIONS_GET_VAULT_HISTORY' as const;
export const SET_NEW_MY_BETS = 'SET_NEW_MY_BETS' as const;
export const TRANSACTIONS_FULFILLED = 'TRANSACTIONS_FULFILLED' as const;
export const TRANSACTIONS_UPDATING = 'TRANSACTIONS_UPDATING' as const;
export const TRANSACTIONS_ACTIVE = 'TRANSACTIONS_ACTIVE' as const;
export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS' as const;
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS' as const;
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS' as const;
export const PENDING_TRANSACTIONS = 'PENDING_TRANSACTIONS' as const;
export const RESET_PENDING_TRANSACTIONS = 'RESET_PENDING_TRANSACTIONS' as const;
export const HANDLE_UPDATE_INTERVAL = 'HANDLE_UPDATE_INTERVAL' as const;
export const TRANSACTIONS_SET = 'TRANSACTIONS_SET' as const;
export const TRANSACTIONS_UPDATE = 'TRANSACTIONS_UPDATE' as const;
export const HANDLE_WITHDRAWS = 'HANDLE_WITHDRAWS' as const;
export const TRANSACTIONS_SET_META = 'TRANSACTIONS_SET_META' as const;
