import {
	currenciesSettings,
	DEFAULT_CURRENCY,
} from '@common/controllers/currency/currenciesSettings';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import sortService, { DIRECTION } from './sortService';

export const isCurrencyDisabled = (currency) => {
	// console.log('isCurrencyDisabled', wallet, !isFeatureAvailable(wallet.toLowerCase());
	return !currenciesSettings.getSettings(currency);
};

export const getInitialCurrency = (availableCurrencies = []) => {
	return getCurrency(availableCurrencies);
};

export const getCurrency = (availableCurrencies = []) => {
	availableCurrencies = availableCurrencies.filter(
		(el) => !isCurrencyDisabled(el.currency),
	);
	let currency = lsGet(LocalStorageKeys.ACTIVE_CURRENCY);
	const index = availableCurrencies.findIndex((el) => el.currency === currency);
	if (index > -1) {
		return currency;
	}
	if (availableCurrencies.length) {
		return availableCurrencies.sort((a, b) =>
			sortService.sort(DIRECTION.DESC, a.amount, b.amount),
		)[0].currency;
	}
	return DEFAULT_CURRENCY;
};

export const saveActiveCurrency = (currency) => {
	lsSet(LocalStorageKeys.ACTIVE_CURRENCY, currency);
};

export const resetActiveCurrency = () => {
	lsClear(LocalStorageKeys.ACTIVE_CURRENCY);
};
