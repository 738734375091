import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { config } from '../../config';
import { getRealBalanceNative } from './balanceService';
import { sentryError } from './sentryService';

const getBalanceNative = (balances, currency) => {
	return getRealBalanceNative(balances, currency) || 0;
};

export const getBalanceObject = (balances, currency) => {
	return balances.find(
		(el) => el.currency.toLowerCase() === currency.toLowerCase(),
	);
};

const isRemind2fa = () => {
	const counter = lsGet(LocalStorageKeys.REMIND_TFA_COUNTER);
	if (!counter || counter + 1 < config.remind2faCounter) {
		!counter
			? lsSet(LocalStorageKeys.REMIND_TFA_COUNTER, 1)
			: lsSet(LocalStorageKeys.REMIND_TFA_COUNTER, counter + 1);
		return false;
	}
	lsClear(LocalStorageKeys.REMIND_TFA_COUNTER);
	return true;
};

export const checkSeeds = (seedHashed, seed, uuid) => {
	let seedHashedList = lsGet(LocalStorageKeys.SEED_HASHED_LIST) || uuid;

	// console.log('checkSeeds', {
	//   seed, seedHashed, uuid, seedHashedList, bool: seedHashedList.indexOf(seedHashed) === -1,
	// });

	if (seedHashedList.indexOf(uuid) === -1) {
		seedHashedList = `${uuid}`;
		lsSet(LocalStorageKeys.SEED_HASHED_LIST, seedHashedList);
	}

	const bool = seedHashedList.indexOf(seedHashed) === -1;

	if (!seed) {
		if (!bool) {
			sentryError('New seed_hashed leaked', {
				seedHashed,
				seed,
				uuid,
				seedHashedList,
				bool,
			});
		}
		return bool;
	}

	if (bool) {
		seedHashedList += `_${seedHashed}`;
	}
	lsSet(LocalStorageKeys.SEED_HASHED_LIST, seedHashedList);
	return true;
};

const getLastRegisterTimestampFromLocalStorage = () => {
	return lsGet(LocalStorageKeys.LAST_REGISTER_TIMESTAMP) || false;
};

const setLastRegisterTimestampToLocalStorage = (value) => {
	return lsSet(LocalStorageKeys.LAST_REGISTER_TIMESTAMP, value);
};

export const createAuthToken = (token) => (token ? `Bearer ${token}` : '');

export const parsePrivateData = (id, data, preferences, userData) => {
	// console.log('parsePrivateData', {
	//   data: {...data},
	//   preferences,
	//   userData,
	// });
	if (!preferences.privacy.public_bets.bool) {
		if (data && data.user && data.user.uuid && userData.id === data.user.uuid) {
			data.user = {
				login: HIDDEN_USER,
				uuid: data.user.uuid,
			};
		}
	}
	data.data_id = id;
	// console.log('parsePrivateData', {
	//   data,
	// });
	return data;
};

export {
	getBalanceNative,
	isRemind2fa,
	getLastRegisterTimestampFromLocalStorage,
	setLastRegisterTimestampToLocalStorage,
};
