import { servicesConfig } from '@server/config/services.config';
import { isFunction } from '@common/methods/isFunction';
import { CAPTCHA_RETRY_LIMIT } from '../captcha.constants';
import { installRecaptcha } from './installRecaptcha';

export const getCaptcha = (siteKey?: string, counter = 0) => {
	if (!servicesConfig.captchaSiteKey) {
		return '9e4a9bb91dd36ebeb665beb2d413b7b1f6d0876f37b238b797415233ad05a749';
	}
	const grecaptcha = window?.['grecaptcha'];
	if (!grecaptcha) {
		return new Promise((resolve) => {
			installRecaptcha(
				() => {
					resolve(getCaptcha());
				},
				() => {
					return resolve(false);
				},
				siteKey,
			);
		});
	}
	if (!isFunction(grecaptcha?.execute)) {
		if (counter > CAPTCHA_RETRY_LIMIT) {
			return Promise.resolve(false);
		}
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(getCaptcha(null, counter + 1));
			}, 500);
		});
	}
	return grecaptcha;
};
