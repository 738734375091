import React, { FC, useCallback, useState } from 'react';

import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { useWindowEventListener } from '@common/hooks/useEventListener';
import { useAppIsViewLocked } from '@common/selectors/app.selectors';
import { config } from '@legacyApp/client/config';
import routingService from '@legacyApp/client/modules/app/routingService';
import { simpleTrans } from '@legacyApp/client/modules/translation/translationService';

import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { StyledHeader } from '@modules/header/Header.styled';
import { StyledHeaderBox } from '@modules/header/HeaderBox.styled';
import { StyledHeaderLogo, StyledHeaderLogoWrapper } from '@modules/header/HeaderLogo.styled';
import { HeaderMenu } from '@modules/header/HeaderMenu/HeaderMenu';
import { StyledHeaderRow } from '@modules/header/HeaderRow.styled';
import { HeaderChat } from '@modules/header/HeaderUser/HeaderChat';
import { HeaderUser } from '@modules/header/HeaderUser/HeaderUser';
import { SectionSwitch } from '@modules/header/SectionSwitch';
import { SideMenuToggle } from '@modules/SideMenu/SideMenuToggle';
import Logo from '@public/img/wolf-icon.svg';

const HOME_URL = routingService.to(ROUTING_ID.HOME);

export const Header: FC = () => {
	const isLockedView_ = useAppIsViewLocked();

	const [scrolled, setScrolled] = useState(false);

	const checkIfScrolled_ = useCallback(
		(e: Event) => {
			if (!e) {
				return;
			}

			if (!isLockedView_) {
				const scrolled_ = window.scrollY > 1;

				if (scrolled_ !== scrolled) {
					setScrolled(scrolled_);
				}

				return;
			}

			if (scrolled) {
				setScrolled(false);
			}
		},
		[isLockedView_, scrolled],
	);

	const logoOnClick_ = useCallback(() => {
		window.scrollTo(0, 0);
	}, []);

	useWindowEventListener('scroll', checkIfScrolled_);

	return (
		<StyledHeader $scrolled={scrolled}>
			<StyledHeaderRow>
				<StyledHeaderBox $noWrap>
					<SideMenuToggle />

					<LinkTo href={HOME_URL} onClick={logoOnClick_}>
						<StyledHeaderLogoWrapper>
							<StyledHeaderLogo
								src={Logo}
								alt={simpleTrans("Crypto Casino Wolfbet -  Gambling games online")}
							/>{' '}

							<span className="text-logo show-desktop">{config.logo}</span>
						</StyledHeaderLogoWrapper>
					</LinkTo>

					<SectionSwitch />
				</StyledHeaderBox>

				<StyledHeaderBox>
					<HeaderUser />

					<HeaderChat />
				</StyledHeaderBox>

				<HeaderMenu />
			</StyledHeaderRow>
		</StyledHeader>
	);
};
