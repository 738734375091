import { css } from 'styled-components';
import { darken } from 'polished';
import { theme } from '../theme';
import { fontSize } from './fontSize';

export const button = (color: string = theme.colors.inputButton) => {
	return css`
		${fontSize({ sizeBigScreen: theme.text.size.desktop })}
		background-color: ${color};
		border: 1px solid transparent;
		border-radius: ${theme.layout.borderRadius};
		color: ${theme.colors.text};
		cursor: pointer;
		font-family: ${(props) => props.theme.text.fonts.Regular};
		font-weight: ${theme.text.weight.medium};
		line-height: 1;
		margin: 0 5px;
		padding: 10px 18px;
		position: relative;
		touch-action: manipulation;
		transition: ${theme.layout.transition};

		.right-logo {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:disabled {
			background-color: ${darken(0.2, color)};
			cursor: default;
		}

		@media (min-width: ${theme.media.minWidthDesktop}) {
			border-radius: ${theme.layout.borderRadius};
		}

		@media (max-width: ${theme.media.maxWidthSmallTablet}) {
			padding: 7px 10px;
		}

		& > div > span {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	`;
};
