export const SPORTS_ID = 'sports_v1';

export const SPORTS_PAGE_ID = {
	INDEX: SPORTS_ID,
	MENU: `${SPORTS_ID}_menu`,
	LIVE: `${SPORTS_ID}_live`,
	EVENT: `${SPORTS_ID}_event`,
	SPORT: `${SPORTS_ID}_sport`,
	TOURNAMENT: `${SPORTS_ID}_tournament`,
	CATEGORY: `${SPORTS_ID}_category`,
	TEAM: `${SPORTS_ID}_team`,
} as const;

export const SPORTS_META = {
	[SPORTS_PAGE_ID.INDEX]: {
		title: 'Bitcoin Sports Betting - Crypto Sports Betting',
	},
	[SPORTS_PAGE_ID.MENU]: {
		title: 'Bitcoin Sports Betting - Crypto Sports Betting',
	},
	[SPORTS_PAGE_ID.LIVE]: {
		title: 'Bitcoin Live Sports Betting - Crypto Live Sports Betting',
	},
	[SPORTS_PAGE_ID.EVENT]: {
		title: 'Bitcoin Match Betting - Crypto Match Betting',
	},
	[SPORTS_PAGE_ID.SPORT]: {
		title: 'Bitcoin {{section_name}} Betting - Crypto {{section_name}} Betting',
	},
	[SPORTS_PAGE_ID.TOURNAMENT]: {
		title: 'Bitcoin {{section_name}} Betting - Crypto {{section_name}} Betting',
	},
	[SPORTS_PAGE_ID.CATEGORY]: {
		title: 'Bitcoin {{section_name}} Betting - Crypto {{section_name}} Betting',
	},
	[SPORTS_PAGE_ID.TEAM]: {
		title: 'Bitcoin {{section_name}} Betting - Crypto {{section_name}} Betting',
	},
} as const;
