import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { NotificationIcon } from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { Button } from '@legacyApp/client/components/button';
import { DropdownElementStyled } from '@legacyApp/client/components/dropdown/dropdownMenu/DropdownElement.styled';
import { ModalIdType } from '@common/constants/modal/ids.modal.constants';
import routingService from '../../../LegacyApp/client/modules/app/routingService';

export interface MenuElementType {
	id: string;
	icon: ReactNode;
	name: string | (() => string);
	isModal?: boolean;
	checkNotification?: Array<string> | boolean;
}

export interface MenuElementProps {
	menuElement: MenuElementType;
	openModalCallback: (id: ModalIdType) => void;
	closeCallback: () => void;
	className?: string;
}

const getName = (menuElement: MenuElementType): string => {
	return typeof menuElement.name === 'function'
		? menuElement.name()
		: menuElement.name;
};

const getProps = (props: MenuElementProps) => {
	const { menuElement, className, openModalCallback, closeCallback } = props;

	const tag =
		menuElement.isModal || menuElement.id.indexOf('http') > -1
			? undefined
			: LinkTo;

	const defaultProps = {
		as: tag,
		className,
		href: null,
	};

	if (menuElement.id.indexOf('http') > -1) {
		return {
			...defaultProps,
			target: '_blank',
			rel: 'noopener noreferrer',
			href: menuElement.id,
		};
	}

	if (menuElement.isModal) {
		return {
			...defaultProps,
			onClick: () => openModalCallback(menuElement.id as ModalIdType),
		};
	}

	return {
		...defaultProps,
		href: routingService.to(menuElement.id),
		onClick: closeCallback,
	};
};

export const MenuElement: FC<MenuElementProps> = (props) => {
	const { menuElement } = props;

	const name = getName(menuElement);
	const props_ = getProps(props);

	return (
		<MenuElementStyled key={menuElement.id} {...props_}>
			<Button
				className="relative"
				name={`header-${menuElement.id}`}
				noClassName
			>
				<MenuElementIcon>{menuElement.icon}</MenuElementIcon>
				<span className="text-style-sm-medium capitalize">
					{trans({ label: name })}
				</span>
				{menuElement.checkNotification && (
					<NotificationIcon type={menuElement.id} />
				)}
			</Button>
		</MenuElementStyled>
	);
};

export const MenuElementIcon = styled.div`
	align-items: center;
	background-color: var(--color-dark-500);
	border-radius: 100%;
	display: flex;
	height: 32px;
	justify-content: center;
	width: 32px;

	svg {
		height: 14px;
		width: 14px;
	}
`;

export const MenuElementStyled = styled(DropdownElementStyled)`
	border-radius: 4px;
	padding: 0;
	position: relative;
	width: 100%;

	&:hover {
		background-color: var(--color-dark-400);
	}

	button {
		align-items: center;
		display: flex;
		gap: 8px;
		justify-content: flex-start;
		max-width: unset;
		padding: 6px 8px;
		width: 100%;
	}

	&:last-of-type {
		${MenuElementIcon} {
			background-color: transparent;
		}

		color: var(--color-dark-200);

		&:hover {
			background-color: var(--color-dark-500);
		}
	}
`;
