import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';

export const HeaderHeight = (callback) => {
	return css`
		${(props) => callback(props.theme.layout.header.height)};

		@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
			${(props) => callback(props.theme.layout.header.heightDesktop)};
		}

		@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
			${(props) => callback(props.theme.layout.header.heightBigDesktop)};
		}
	`;
};

export const StyledHeader = styled.header.attrs<{
	$scrolled: boolean;
}>((props) => ({
	className: classnames('header', { scrolled: props.$scrolled }),
}))<{
	$scrolled: boolean;
}>`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: ${(props) =>
		props.$scrolled
			? css`var(--color-dark-700)!important`
			: props.theme.colors.background};
	color: ${(props) => props.theme.colors.text};
	z-index: 9998; // 4
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	margin-top: 0;
	transition: background-color 0.3s linear, box-shadow 0.3s linear;

	${(props) =>
		props.$scrolled
			? css`
					box-shadow: 0px -2px 8px 0px black;
			  `
			: null};

	${HeaderHeight(
		(height) => css`
			height: ${height};
		`,
	)};

	.show-mobile {
		&__dropdown-icon {
			font-size: 25px;
		}
	}

	.user-icon {
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			max-width: 20px;
			min-width: unset;
		}
	}

	.text-logo {
		margin-left: 5px;

		${chatViewport(
			(viewportPadding) => css`
				@media (max-width: ${(props) =>
						calcStyle({
							a: props.theme.media_v2.max_tablet_large,
							b: viewportPadding,
						})}) {
					display: none;
				}
			`,
		)}
	}
`;
