import { combineReducers } from 'redux';
import {
	DROPDOWN_ADD,
	DROPDOWN_DELETE,
	DROPDOWN_SET_ACTIVE,
	DROPDOWN_SET_CONTENT,
	DROPDOWN_TOGGLE,
} from './dropdown.constants';

const activeReducer = (state = {}, action = { type: '' }) => {
	const newState = { ...state };
	switch (action.type) {
		case DROPDOWN_SET_ACTIVE: {
			return {
				...state,
				[action.payload.id]: true,
			};
		}
		case DROPDOWN_TOGGLE: {
			const bool =
				action.payload.bool === undefined
					? !state[action.payload.id]
					: action.payload.bool;
			if (state[action.payload.id] === bool) {
				return state;
			}
			if (!bool) {
				delete newState[action.payload.id];
			} else {
				newState[action.payload.id] = bool;
			}
			return newState;
		}
		case DROPDOWN_DELETE: {
			if (newState[action.payload]) {
				delete newState[action.payload];
			}
			return newState;
		}
		default:
			return state;
	}
};

const contentReducer = (state = {}, action = { type: '' }) => {
	const newState = { ...state };
	switch (action.type) {
		case DROPDOWN_SET_CONTENT: {
			return {
				...state,
				[action.payload.id]: action.payload.content,
			};
		}
		case DROPDOWN_ADD: {
			return {
				...state,
				[action.payload.id]: action.payload.content,
			};
		}
		case DROPDOWN_DELETE: {
			if (newState[action.payload]) {
				delete newState[action.payload];
			}
			return newState;
		}
		default:
			return state;
	}
};

const dropdownReducer = combineReducers({
	active: activeReducer,
	content: contentReducer,
});

export { dropdownReducer };
