import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';

export const useLocationChange = (callback) => {
	const router = useRouter();

	const { asPath } = router;

	const asPathPrev = usePrevious(asPath);

	useEffect(() => {
		if (asPath !== asPathPrev) {
			callback();
		}
	}, [callback, asPathPrev, asPath]);
};
