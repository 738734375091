export const GET_STATS_DATA = 'GET_STATS_DATA';
export const UPDATE_STATS_DATA = 'UPDATE_STATS_DATA';
export const HANDLE_STATS_UPDATE = 'HANDLE_STATS_UPDATE';

export const SET_PRICE = 'SET_PRICE';
export const SET_BETS_TOTAL = 'SET_BETS_TOTAL';
export const SET_WON_LAST_24H = 'SET_WON_LAST_24H';
export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';
export const SET_TIMESTAMP = 'SET_TIMESTAMP';
export const SET_LAST_HIGH_WIN = 'SET_LAST_HIGH_WIN';
export const GLOBAL_STATS_GET_LAST_WEEK = 'GLOBAL_STATS_GET_LAST_WEEK';
export const GLOBAL_STATS_SET_LAST_WEEK_WAGERED =
	'GLOBAL_STATS_SET_LAST_WEEK_WAGERED';
export const GLOBAL_STATS_SET_LAST_WEEK_REGISTRATIONS =
	'GLOBAL_STATS_SET_LAST_WEEK_REGISTRATIONS';
