import { isFunction } from '@common/methods/isFunction';
import { config } from '../../../config';
import websocketService from '../../../modules/app/websocketService';
import {
	WebsocketChannelType,
	WebsocketEventType,
} from '../websocket.constants';
import { getInstanceThunk } from './getInstance.thunk';

export const bindSocketChannelEventThunk = (
	channel: WebsocketChannelType,
	event: WebsocketEventType,
	callback: (data, dispatch) => void,
) => {
	return (dispatch, getState) => {
		const state = getState();
		if (
			!config.websockets ||
			!process.browser ||
			websocketService.isEventExist(channel, event, state.websocket.channels)
		) {
			return;
		}
		const channelInstance = dispatch(getInstanceThunk(channel));
		if (channelInstance && channelInstance.on) {
			channelInstance.on(event, (data) => {
				// console.log(`SOCKET - ${event}`, {
				// 	data,
				// 	channel,
				// 	event
				// });
				if (isFunction(callback)) {
					callback(data, dispatch);
				}
			});
		}
		// if (event === 'depositAccounted') console.log('bindSocketEvent', {channel}, {event}, channelInstance, state.websocket.channels);
	};
};
