export const INSTALL_2FA = 'INSTALL_2FA';
export const UNINSTALL_2FA = 'UNINSTALL_2FA';

export const SAVE_SECRET = 'SAVE_SECRET';
export const SAVE_QRCODE = 'SAVE_QRCODE';

export const ACTIVATE_2FA = 'ACTIVATE_2FA';

export const ENABLE_2FA = 'ENABLE_2FA';
export const DISABLE_2FA = 'DISABLE_2FA';

export const CODE_REQUIRED = 'CODE_REQUIRED';
