import styled from 'styled-components';
import { rgba } from 'polished';
import { StyledUserName } from '@ui/user-name/user-name.styled';
import {
	borderRadius,
	boxNoHeight,
} from '@legacyApp/client/modules/style/defaults';
import { StyledExpertConfig } from '@legacyApp/client/components/strategy/strategyAdvancedElementV3/ExpertConfig.styled';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';

export const MessageTextStyled = styled.span`
	color: ${(props) => props.theme.colors.text};
	white-space: pre-line;
	word-break: break-word;
	h5 {
		margin: 0 !important;

		i,
		.game-icon {
			font-size: 12px !important;

			img,
			svg {
				height: 9px;
				margin-right: 3px;
			}
		}

		.small-text {
			${fontSize({
				size: '10px',
				sizeDesktop: '10px',
			})}
		}
	}

	a:not(${StyledUserName}) {
		text-decoration: underline;
	}

	> ${StyledUserName} {
		background: ${(props) => rgba(props.theme.colors.inputButton, 0.41)};
		border-radius: 3px;
		margin-top: -1px;
		padding: 1px 3px 1px 2px;
		white-space: pre;
		word-break: break-word;

		@-moz-document url-prefix() {
			white-space: pre-line;
		}

		&.user-name--my {
			background-color: ${(props) =>
				rgba(props.theme.colors.level.bronze, 0.66)}!important;
			border: 1px solid ${(props) => props.theme.colors.level.bronze};
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	.bet-id {
		padding: 5px;

		.bet-result--small {
			margin-bottom: 5px;
		}
	}

	.emoji-sizer,
	.emoji-inner {
		background-size: 100%;
		display: inline-block;
		height: 13px;
		margin: 0 2px -3px 0;
		width: 13px;
	}

	.balance__currency--wrapper {
		display: inline-flex !important;

		.currency-logo {
			margin-left: 2px !important;
			margin-right: 2px;
			margin-top: -7px;
		}
	}

	${StyledExpertConfig} {
		.currency-logo {
			margin-top: 0;
		}
	}

	.box-wrapper {
		${boxNoHeight};
		background-color: #051426;
		border: 1px solid ${(props) => rgba(props.theme.colors.inputButton, 0.5)};
		display: inline;
		margin: 5px 0;
		max-width: 100%;
		overflow: hidden;
		position: static;
		${borderRadius};

		h5 {
			border-radius: 0 !important;
		}

		.strategy {
			padding: 5px;
			width: calc(100% + 10px);

			.input-wrapper {
				padding: 0 0 5px;
			}
		}
	}

	> * {
		margin-right: 3px;
	}
`;

export const MessageTextBoxStyled = styled.span`
	display: inline-block;
	margin: 0;
	width: 100%;
`;
