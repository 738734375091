import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Trending: FC<IconProps> = ({ fill }) => {
	const _fill = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M14.5 3.5L8.5 9.5L6 7L1.5 11.5"
						stroke={_fill}
						fill="transparent"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M14.5 7.5V3.5H10.5"
						stroke={_fill}
						fill="transparent"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			}
			width={16}
			height={16}
		/>
	);
};
