import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { Image, parseImageSrc } from '../../../default/Image/Image';
import { MediumPromoStyled } from './Promotions.styled';

export const BonusCodeOnClickWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const dispatch = useDispatch();

	return (
		<div
			className={'onclick-wrapper onclick-wrapper-bonuscodes'}
			onClick={() =>
				dispatch(openModal(MODAL_ID.BONUS_CODE, 'BonusCodeOnClickWrapper'))
			}
		>
			{children}
		</div>
	);
};

export const BonusCodesPromo: FC = () => {
	return (
		<BonusCodesPromoStyled>
			<BonusCodeOnClickWrapper />
			<BonusImage />
			<h5 className="text-style-h-6">
				{trans({
					label: 'weekly<1/>bonus codes',
					namespace: TransNamespace.COMMON,
					components: (
						<>
							weekly
							<br />
							bonus codes
						</>
					),
				})}
			</h5>
		</BonusCodesPromoStyled>
	);
};

export const BonusImage = styled(Image).attrs((props) => ({
	src: props.src || '/img/promosection/bonus/ticket.png',
	alt: 'Bonus Ticket',
}))`
	top: 30%;
	position: absolute;
	height: 85%;
	left: 50%;
	transform: translateX(-50%);
	transition: 300ms ease-out;
	z-index: 1;
`;

export const BonusCodesPromoStyled = styled(MediumPromoStyled)`
	background-image: url(${parseImageSrc('/img/promosection/bonus/bg.svg')});
	background-repeat: no-repeat;
	background-size: cover;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			${BonusImage} {
				transform: translateX(-50%) rotate(-4deg) scale(1.1) translateY(-5px);
			}
		}
	}
`;
