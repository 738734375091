import {
	ChangeEventHandler,
	FC,
	KeyboardEventHandler,
	useCallback,
} from 'react';
import { Input, InputProps } from '@ui/input';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';
import { regexString } from '@legacyApp/client/modules/app/regexString';
import { isFunction } from '../../methods/isFunction';
import { isTrueOrZero } from '../../methods/isTrueOrZero';

const CHAR_PATTERN = '([0-9,.-])?';

export interface NumberInputProps extends Omit<InputProps, 'type'> {
	precision?: number;
}

export const NumberInput: FC<NumberInputProps> = (props) => {
	const { precision, ...restProps } = props;

	const verifyPrecision = useCallback(
		(value: string) => {
			console.log('verifyPrecision', {
				value,
				precision,
				count: countPrecision(value),
			});
			return !(isTrueOrZero(precision) && countPrecision(value) > precision);
		},
		[precision],
	);

	const onKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
		(event) => {
			const pattern = CHAR_PATTERN;

			if (!pattern) {
				return true;
			}

			const charCode =
				typeof event.which == 'undefined' ? event.keyCode : event.which;
			const charStr = String.fromCharCode(charCode);
			const regex = new RegExp(pattern, 'g');
			const regexResult = regexString(regex, charStr);

			// console.log(this.props.id, 'onKeyPress', { pattern, event, charCode, charStr, regex, regexResult });

			if (!regexResult[1]) {
				event.preventDefault();
			}
		},
		[],
	);

	const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		(event) => {
			if (!verifyPrecision(event.target.value)) {
				console.log('stopPropagation');
				event.persist();
				event.stopPropagation();
				event.preventDefault();
				return;
			}

			if (isFunction(props?.onChange)) {
				props.onChange(event);
			}
		},
		[props, verifyPrecision],
	);

	return (
		<Input
			{...restProps}
			step={
				props.step || isTrueOrZero(precision)
					? mathService.getMinValueByPrecision(precision)
					: undefined
			}
			type="number"
			onChange={_onChange}
			onKeyPress={onKeyPress}
		/>
	);
};
