export const CookiesKeys = {
	NEW_COIN_BONK_FLOKI_PEPE: 'newcoin_bonk_floki_pepe',
	DEPRECATED_LANG_CLEARED: 'deprecatedLangCleared',
	DEPRECATED_CHAT_ROOM_CLEARED: 'deprecatedChatRoomCleared',
	INSP_FRGCH: '__insp_frgch',
	VER_28: 'ver-28',
	AFFILIATE_CODE: 'affiliate-code',
	UTM_SOURCE: 'utm_source',
	EXTENDED_ACCESS: 'extendedAccess',
	PRELOADED_STATE: 'preloaded_state',
	CSRF_TOKEN: 'csrf_token',
};
