import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { CaretDown } from '@icons/ui/caret-down';

export const DropdownArrow: FC<PropsWithChildren<any>> = ({ children }) => {
	return <DropdownArrowStyled>{children || <CaretDown />}</DropdownArrowStyled>;
};

export const DropdownArrowStyled = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
`;

export const DropdownArrowParentMixin = css`
	padding-right: 35px !important;
	position: relative;

	${DropdownArrowStyled} {
		svg {
			height: 31.5px;
			width: 30px;
		}
	}
`;
