import { createApiHash } from './createApiHash';

export const createHeaders = ({ body }: { body?: any }) => {
	return {
		['Content-Type']: 'application/json',
		['X-Client-Type']: 'Web-Application',
		['X-Requested-With']: 'XMLHttpRequest',
		['X-Hash-Api']: createApiHash(body),
	};
};
