import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { Bet } from '@modules/games/Game/store/game/@types/PlaceBetResponse';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { newMyBet } from '../../transactions/transactions.actions';
import transactionService from '../../../../../modules/transactions/transactionsService';
import { DIRECTION } from '../../../modules/app/sortService';

export const newMyBetThunk = (
	bet: Bet,
	options?: { reset?: boolean; isAutobet?: boolean },
): AppThunk => {
	return (dispatch, getState) => {
		const { reset = false, isAutobet = false } = options || {};
		const { transactions } = getState();
		const myBetsMeta = transactions.meta[TABLE_ID.myBets];
		if (!myBetsMeta?.current_page || myBetsMeta.current_page > 1) {
			return;
		}
		if (isAutobet) {
			lsSet(
				LocalStorageKeys.NEW_MY_BETS,
				transactionService.addElementToList({
					label: TABLE_ID.myBets,
					element: bet,
					list: lsGet(LocalStorageKeys.NEW_MY_BETS, transactions.myBets),
					direction: DIRECTION.DESC,
					limit: myBetsMeta.per_page,
					disableSort: true,
				}),
			);
			return;
		}
		dispatch(newMyBet(bet, myBetsMeta.per_page, reset));
	};
};
