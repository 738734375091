import { delay } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import queryString from 'query-string';
import { chatActions } from '@modules/chat/store/chat.actions';
import { vipActions } from '@modules/vip/store/vip.actions';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { generateUrl, ROUTE } from '@common/routes';
import { isPageStatic } from '@modules/page/PageConfig/PageConfig.provider';
import { vipIncreaseLevelThunk } from '@modules/vip/store/thunks/vipIncreaseLevel.thunk';
import { getDeviceType } from '@common/methods/getDeviceType';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { getRoomStreamThunk } from '@modules/chat/store/thunks/getRoomStream.thunk';
import { cookieService } from '@common/controllers/cookieService';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { CookiesKeys } from '@modules/cookies/types/cookiesKeys.type';
import { handleGetNewFeaturesWithNotificationThunk } from '@common/store/thunks/getNewFeatures.thunk';
import {
	RESET_USER_DATA,
	SET_EMAIL,
	SET_SOCKET_PRIVATE_CHANNEL,
	SET_USERNAME,
} from '../user/user.constants';
import websockets from '../../modules/app/websocketService';
import audioService from '../../modules/app/audioService';
import routingService from '../../modules/app/routingService';
import { sentryUpdateState } from '../../modules/app/sentryService';
import { bindSocketPrivateChannelEvent } from '../websocket/websocket.actions';
import { config } from '../../config';
import { showAlert } from '../alerts/alerts.actions';
import {
	currentVersion,
	setLocationData,
	updateLockedView,
} from '../../modules/app/appService';
import { SERVER_DATA_CLEAR } from '../serverData/serverData.constants';
import { epic } from '../../modules/app/epicService';
import { initVendor } from '../../modules/app/vendorService';
import {
	setAffiliateCode,
	setAffiliateUtmSource,
} from '../affiliate/affiliate.actions';
import { fetchLocalAction } from '../fetch/fetch.thunk';
import { serverDataClear } from '../serverData/serverData.actions';
import { setQueryParams } from '../../modules/app/urlService';
import { checkIfUserCanAccess } from '../../modules/app/featureService';
import { setHasAvailableBonusCode } from '../user/user.actions';
import { bonusInfo } from '../user/thunks/user.thunk';
import { getTimestamp } from '../../modules/app/timeService';
import {
	WEBSOCKET_CHANNELS,
	WEBSOCKET_EVENTS,
} from '../websocket/websocket.constants';
import { bindSocketChannelEventThunk } from '../websocket/thunks/bindSocketChannelEvent.thunk';
import {
	appCheckVersion,
	appInitialized,
	appNewVersion,
	appSetCsrf,
	appSetSource,
	checkNewFeatures,
	handleCommands,
	setContentDeviceType,
	setExtendedAccess,
	updateSentryState,
} from './app.actions';
import {
	APP_CALLBACK,
	APP_CHECK_VERSION,
	APP_CLOSES,
	APP_COMMANDS,
	APP_GO_TO,
	APP_INITIALIZED,
	APP_NEW_VERSION,
	CHECK_NEW_FEATURES,
	HANDLE_COMMANDS,
	LISTEN_COMMANDS,
	SET_DEVICE_TYPE,
	SET_LOCKED_VIEW,
	SET_MOBILE,
	SET_VISIBLE,
	SET_WIDTH,
	UPDATE_SENTRY_STATE,
	UPDATE_URL_QUERY,
} from './app.constants';

const listenCommandsEpic = epic('listenCommandsEpic', {
	actions: (ofType) => ofType(LISTEN_COMMANDS),
	callback: () => {
		return of(
			bindSocketChannelEventThunk(
				WEBSOCKET_CHANNELS.COMMANDS,
				WEBSOCKET_EVENTS.COMMANDS,
				(data, dispatch) =>
					dispatch(handleCommands(websockets.getMessage(data))),
			),
		);
	},
});

const listenPrivateCommands = epic('listenPrivateCommands', {
	actions: (ofType) => ofType(SET_SOCKET_PRIVATE_CHANNEL),
	callback: ({ store$ }) => {
		if (store$.value.user.socketChannel) {
			return of(
				bindSocketPrivateChannelEvent(
					WEBSOCKET_EVENTS.COMMANDS,
					(data, dispatch) =>
						dispatch(handleCommands(websockets.getMessage(data))),
				),
			);
		}
		return of();
	},
});

const commandsEpic = epic('commandsEpic', {
	actions: (ofType) => ofType(HANDLE_COMMANDS),
	callback: ({ action, store$ }) => {
		// logger('command', {command: action.payload});
		if (action.payload === APP_COMMANDS.CHAT_REFRESH) {
			return of(getRoomStreamThunk(true));
		}
		if (action.payload === APP_COMMANDS.APP_REFRESH) {
			return of(appNewVersion());
		}
		if (action.payload.indexOf(APP_COMMANDS.RAKEBACK_LEVEL_INCREASED) === 0) {
			const level = action.payload.replace(
				`${APP_COMMANDS.RAKEBACK_LEVEL_INCREASED}.`,
				'',
			);
			if (level) {
				return of(vipIncreaseLevelThunk(parseFloat(level)));
			}
		}
		if (action.payload === APP_COMMANDS.BONUS_CODE_GRANTED) {
			return of([
				store$.value.user.hasAvailableBonusCode
					? bonusInfo()
					: setHasAvailableBonusCode(true),
			]);
		}
		if (action.payload === APP_COMMANDS.OPEN_INSANE_BONUS_CODE_MODAL) {
			return of(openModal(MODAL_ID.INSANE_BONUS_CODE_INFO, HANDLE_COMMANDS));
		}
		if (action.payload === APP_COMMANDS.OPEN_INSANE_BONUS_CODE_ALERT) {
			return of(
				showAlert(
					'info',
					'Insane bonus code is available to claim! Ask our support agent for BONUS CODE',
					true,
				),
			);
		}
		return of();
	},
});

const goToEpic = epic('goToEpic', {
	actions: (ofType) => ofType(APP_GO_TO),
	callback: ({ action }) => {
		const split = routingService.splitId(action.id);
		const route = action.validUri
			? routingService.getRoute(split[0])
			: routingService.getRouteById(split[0]);
		// console.log('APP_GO_TO', {action, route, to: routingService.splitId(action.id)});
		!route?.static && !isPageStatic(route?.id)
			? routingService.dynamicRedirect(action.id, action.validUri)
			: routingService.redirect(action.id, action.validUri);
		return of();
	},
});

const visibleEpic = epic('visibleEpic', {
	actions: (ofType) => ofType(SET_VISIBLE),
	callback: ({ action }) => {
		audioService.muteAll(!action.bool);
		return of();
	},
});

const sentryEpic = epic('sentryEpic', {
	actions: (ofType) => ofType(UPDATE_SENTRY_STATE),
	callback: ({ store$ }) => {
		sentryUpdateState(store$.value);
		if (NEXT_CONFIG.DEV) {
			return of();
		}
		return of(updateSentryState()).pipe(
			delay(config.sentryStateUpdateInterval),
		);
	},
});

const sentryUserEpic = epic('sentryUserEpic', {
	actions: (ofType) => ofType(SET_USERNAME, SET_EMAIL, RESET_USER_DATA),
	callback: ({ store$ }) => {
		sentryUpdateState(store$.value);
		return of();
	},
});

const checkFeaturesEpic = epic('checkFeaturesEpic', {
	actions: (ofType) => ofType(CHECK_NEW_FEATURES),
	callback: ({ action, store$, }) => {
		return of(handleGetNewFeaturesWithNotificationThunk(action.onlyCheck, store$.value.app.language));
	},
});

const initializedEpic = epic('initializedEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: () => {
		setLocationData();
		initVendor();
		const result = [checkNewFeatures(true), updateSentryState()];
		const csrf = cookieService.get(CookiesKeys.CSRF_TOKEN) || window.__CSRF__;
		if (csrf) {
			result.push(appSetCsrf(csrf));
		}
		return of(result);
	},
});

const contentDeviceTypeEpic = epic('contentDeviceTypeEpic', {
	actions: (ofType) =>
		ofType(
			SET_WIDTH,
			SET_DEVICE_TYPE,
			SET_MOBILE,
			chatActions.setWidth().type,
			APP_INITIALIZED,
		),
	callback: ({ store$ }) => {
		// TODO: chat store usage
		const type = store$.value.app.mobile
			? store$.value.app.deviceType
			: getDeviceType(store$.value.app.width - store$.value?.chat?.width);
		if (type === store$.value.app.contentDeviceType) {
			return of();
		}
		return of(setContentDeviceType(type));
	},
});

const extendedAccessEpic = epic('extendedAccessEpic', {
	actions: (ofType) =>
		ofType(
			SET_USERNAME,
			vipActions.levelUpdate().type,
			RESET_USER_DATA,
			APP_INITIALIZED,
		),
	callback: ({ store$ }) => {
		const bool = checkIfUserCanAccess(store$.value);
		const result = [setExtendedAccess(bool)];
		bool
			? cookieService.set(CookiesKeys.EXTENDED_ACCESS, bool, { expires: 1 })
			: cookieService.clear(CookiesKeys.EXTENDED_ACCESS);
		return of(result);
	},
});

const staticLoadedEpic = epic('staticLoadedEpic', {
	actions: (ofType) => ofType(SERVER_DATA_CLEAR),
	callback: ({ store$ }) => {
		if (store$.value.app.initialized) {
			return of();
		}
		return of(appInitialized('staticLoadedEpic'));
	},
});

const checkVersionEpic = epic('checkVersionEpic', {
	actions: (ofType) => ofType(SET_VISIBLE, APP_INITIALIZED),
	callback: ({ action }) => {
		if (action.bool || action.type === APP_INITIALIZED) {
			return of(appCheckVersion());
		}
		return of();
	},
});

const versionEpic = epic('versionEpic', {
	actions: (ofType) => ofType(APP_CHECK_VERSION),
	callback: () => {
		const timestamp = lsGet(LocalStorageKeys.VERSION_TIMESTAMP);
		const currentTimestamp = getTimestamp();
		// console.log('checkVersion', { timestamp, currentTimestamp, bool: timestamp && currentTimestamp < timestamp + 0 });
		if (!timestamp) {
			lsSet(LocalStorageKeys.VERSION_TIMESTAMP, currentTimestamp);
			return of();
		}
		if (currentTimestamp < timestamp + config.versionCheckTimeout) {
			return of();
		}
		const version = currentVersion();
		// console.log('checkVersion', { version });
		if (!version) {
			return of();
		}
		return of(
			fetchLocalAction(
				{
					url: generateUrl(ROUTE.apiCheckVersion, { version }),
					disableErrorHandler: true,
					fromState: config.versionCheckTimeout,
				},
				(data) => {
					// console.log(data);
					const result = [];
					lsSet(LocalStorageKeys.VERSION_TIMESTAMP, currentTimestamp);
					if (data.deprecated) {
						result.push(appNewVersion());
					}
					return result;
				},
				() => {
					return of();
				},
			),
		);
	},
});

const newVersionEpic = epic('newVersionEpic', {
	actions: (ofType) => ofType(APP_NEW_VERSION),
	callback: () => {
		lsSet(LocalStorageKeys.VERSION_TIMESTAMP, getTimestamp());
		return of(
			showAlert(
				'info',
				'New version available, please refresh the page!',
				true,
			),
		);
	},
});

const defaultsEpic = epic('defaultsEpic', {
	actions: (ofType) => ofType(APP_INITIALIZED),
	callback: ({ store$ }) => {
		const actions = [];
		const storeQuery = queryString.parse(store$.value.router.location.search);
		const urlQueryData = {
			...storeQuery,
		};
		if (storeQuery.source) {
			urlQueryData.source = undefined;
			actions.push(appSetSource(storeQuery.source));
		}
		if (storeQuery.c) {
			urlQueryData.c = undefined;
			actions.push(setAffiliateCode(storeQuery.c));
		}
		if (storeQuery['utm_source']) {
			actions.push(setAffiliateUtmSource(storeQuery['utm_source']));
		}
		const query = queryString.stringify(urlQueryData);
		if (store$.value.router.location.search !== (query ? `?${query}` : '')) {
			// console.log('setQueryParams - appInitialized', store$.value.router.location.search, `?${queryString.stringify(urlQueryData)}`);
			setQueryParams(urlQueryData);
		}
		return of(actions);
	},
});

const appCallbackEpic = epic('appCallbackEpic', {
	actions: (ofType) => ofType(APP_CALLBACK),
	callback: ({ action }) => {
		if (action.callback) {
			action.callback();
		}
		return of();
	},
});

const closesEpic = epic('closesEpic', {
	actions: (ofType) => ofType(APP_CLOSES, RESET_USER_DATA),
	callback: () => {
		cookieService.clear(CookiesKeys.PRELOADED_STATE);
		return of(serverDataClear());
	},
});

const lockedViewEpic = epic('lockedViewEpic', {
	actions: (ofType) => ofType(SET_LOCKED_VIEW),
	callback: ({ action, store$ }) => {
		updateLockedView(!!store$.value.app.isViewLocked, action);
		return of();
	},
});

const urlQueryEpic = epic('urlQueryEpic', {
	actions: (ofType) => ofType(UPDATE_URL_QUERY),
	callback: ({ store$, action }) => {
		const storeQuery = queryString.parse(store$.value.router.location.search);
		// console.log('setQueryParams - UPDATE_URL_QUERY', store$.value.router.location.search, storeQuery, action);
		setQueryParams({
			...storeQuery,
			...action.payload,
		});
		return of();
	},
});

const appEpic = combineEpics(
	lockedViewEpic,
	visibleEpic,
	goToEpic,
	sentryEpic,
	sentryUserEpic,
	commandsEpic,
	listenCommandsEpic,
	checkFeaturesEpic,
	initializedEpic,
	contentDeviceTypeEpic,
	extendedAccessEpic,
	staticLoadedEpic,
	newVersionEpic,
	versionEpic,
	checkVersionEpic,
	defaultsEpic,
	appCallbackEpic,
	listenPrivateCommands,
	closesEpic,
	urlQueryEpic,
);

export { appEpic };
