import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppInitialized } from '@common/selectors/app.selectors';
import { useUserTokenValue } from '@common/selectors/user.selectors';
import { handlePageAuth } from '../../methods/router/handlePageAuth';
import { setToken } from '../../client/store/user/user.actions';
import {
	checkToken,
	tokenService,
} from '../../client/modules/app/tokenService';
import { appInitialized } from '../../client/store/app/app.actions';
import { useAppSelector } from '../store/useAppSelector';
import { useAppDispatch } from '../store/useAppDispatch';

export const useAuth = (id) => {
	const dispatch = useAppDispatch();
	const { data } = useAppSelector((state) => state.serverData);
	const initialized = useAppInitialized();
	const tokenValue = useUserTokenValue();
	const refTokenValue = useRef(tokenValue);

	const getTokenCallback = useCallback(() => {
		if (initialized) {
			return refTokenValue.current;
		}
		return handlePageAuth(
			id,
			data.status === 200 && data?.data?.access_token ? data.data : undefined,
		);
	}, [data.data, data.status, id, initialized]);

	const [token] = useState(getTokenCallback());

	useEffect(() => {
		if (initialized) {
			return;
		}
		// console.log('useAuth - useEffect', { id, token: token?.value, initialized, refToken: refTokenValue?.current, tokenValue: token_?.value });
		if (token?.value && token?.value !== refTokenValue?.current) {
			tokenService.set(token?.value ? token : checkToken());
			refTokenValue.current = token?.value;
			dispatch(setToken(token));
			return;
		}
		if (!token?.value) {
			dispatch(appInitialized('useAuth'));
		}
	}, [dispatch, id, initialized, token, token?.value]);

	return { token };
};
