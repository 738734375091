import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';

export const setOption = (id, bool) => {
	lsSet(LocalStorageKeys.OPTIONS, {
		...lsGet(LocalStorageKeys.OPTIONS, {}),
		[id]: bool,
	});
};

export const parseOptions = () => {
	const options = lsGet(LocalStorageKeys.OPTIONS, {});

	const optionsData = {
		sound: lsGet(LocalStorageKeys.DISABLE_SOUND),
		maxBet: lsGet(LocalStorageKeys.DISABLE_MAX_BUTTON),
		hotkeys: lsGet(LocalStorageKeys.DISABLE_HOTKEYS),
		chart: lsGet(LocalStorageKeys.DISABLE_CHART),
		autobetSummary: lsGet(LocalStorageKeys.DISABLE_AUTOBET_SUMMARY),
		animation: lsGet(LocalStorageKeys.DISABLE_ANIMATIONS),
	};

	Object.keys(optionsData).forEach((key) => {
		const data = optionsData[key];
		if (data !== null) {
			options[key] = !data;
		}
	});

	[
		LocalStorageKeys.DISABLE_STATS,
		LocalStorageKeys.DISABLE_SOUND,
		LocalStorageKeys.DISABLE_MAX_BUTTON,
		LocalStorageKeys.DISABLE_HOTKEYS,
		LocalStorageKeys.DISABLE_CHART,
		LocalStorageKeys.DISABLE_AUTOBET_SUMMARY,
		LocalStorageKeys.DISABLE_ANIMATIONS,
	].forEach((el) => lsClear(el));

	return options;
};
