// eslint-disable-next-line import/named
import styled, { css, StyledComponentProps } from 'styled-components';
import classname from 'classnames';
import { borderRadius, boxNoHeight, userSelectText } from '../defaults';

export enum DescriptionBoxBackground {
	INFO = 'info',
	ERROR = 'error',
	GOLD = 'gold',
}

export interface DescriptionBoxProps
	extends StyledComponentProps<any, any, any, any> {
	$background?: boolean | string | DescriptionBoxBackground;
	$small?: boolean;
	className?: string;
}

const getBackground = (props: DescriptionBoxProps) => {
	if (!props.$background) {
		return '';
	}
	let color =
		typeof props.$background === 'boolean'
			? props.theme.ui.colors['dark-500']
			: props.$background;
	if (props.$background === DescriptionBoxBackground.INFO) {
		color = props.theme.colors.alertInfo;
	}
	if (props.$background === DescriptionBoxBackground.ERROR) {
		color = props.theme.colors.alertError;
	}
	if (props.$background === DescriptionBoxBackground.GOLD) {
		color = props.theme.colors.gold;
	}
	return css`
		background-color: ${color};
		margin-bottom: 20px;
		padding: ${props.$small ? '0 15px' : '25px 30px'};
	`;
};

export const DescriptionBoxStyle = styled.div.attrs<DescriptionBoxProps>(
	(props) => ({
		className: classname(
			'description-box text-style-md-regular',
			props.className,
		),
	}),
)<DescriptionBoxProps>`
	${boxNoHeight};
	${userSelectText};
	${borderRadius};
	${getBackground};
	${(props) => (props.$small && !props.$background ? 'padding: 0 15px;' : '')}
`;
