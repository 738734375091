import { VipLevel, VipLevelsResponse } from '../store/types/vip.types';

export const mapLevelsDTO = (
	levelsDTO: VipLevelsResponse['levels'],
): Array<VipLevel> => {
	return levelsDTO.map((el) => ({
		...el,
		commission: Number(el.commission),
		threshold: Number(el.threshold),
		level: el.id,
		dailyBonus: {
			multiplier: Number(el.dailyBonus?.multiplier),
			spins: Number(el.dailyBonus?.spins),
		},
		rankBonus: Number(el.rankBonus),
	}));
};
