import { apiServerConfig } from '@server/config/api.server.config';
import { MatchType } from '@modules/sports/types/Match/MatchType';
import { getIdFromSlug } from '@modules/sports/methods/getIdFromSlug';
import {
	BetSlipDetailsProgressState,
	BetSlipDetailsState,
} from '@modules/sports/types/BetSlipDetails/BetSlipDetailsType';
import { filterArrayDuplicates } from './appService';
import FetchService from './fetchService';
import { getTimestamp } from './timeService';

export const isLive = (
	match: Partial<MatchType>,
	state?: BetSlipDetailsState,
): boolean => {
	if (match?.type === 'live') {
		return true;
	}
	if (state && state !== BetSlipDetailsProgressState.PENDING) {
		return false;
	}
	return !!(
		match?.open_for_live && getTimestamp(match?.start_at) < getTimestamp()
	);
};

export const isScore = (match: Partial<MatchType>): boolean => {
	return isLive(match) && match?.has_scoring;
};

export const checkMultiOnMatch = (list = []): boolean => {
	if (!list?.length) {
		return true;
	}
	const filteredList = filterArrayDuplicates(list.map((el) => el.matchId));
	return list?.length !== filteredList?.length;
};

export const getSportsApiUrl = (uri: string): string => {
	return FetchService.parseApiUrl({
		apiUrl: apiServerConfig.sportsUrl,
		uri: `/v2/sportsbetting${uri}`,
	});
	// console.log('getSportsApiUrl', path, apiServerConfig, uri);
	// return path;
};

export const getSportsListApiUrl = (type?: 'live' | 'upcoming'): string => {
	return getSportsApiUrl(`/sports/${type || ''}`);
};

export const getTournamentsListApiUrl = (
	sportId: number,
	type?: 'featured',
): string => {
	return getSportsApiUrl(`/tournaments/${sportId}${type ? `/${type}` : ''}`);
};

export const getSportUri = (id: number | string, slug: string): string => {
	return `/sports/${slug}`;
};

export const getTeamUri = (sportData, teamData) => {
	return `${getSportUri(sportData.id, sportData.slug)}/team/${teamData.slug}-${
		teamData.id
	}`;
};

export const getCategoryUri = (sportData, categoryData) => {
	return `${getSportUri(sportData.id, sportData.slug)}/${categoryData.slug}-${
		categoryData.id
	}`;
};

export const getTournamentUri = (sportData, categoryData, tournamentData) => {
	const categoryUri = getCategoryUri(sportData, categoryData);
	if (!tournamentData.slug) {
		return categoryUri;
	}
	return `${categoryUri}/${tournamentData.slug}-${tournamentData.id}`;
};

export const getSportFromUri = (uri: string): [number, string] => {
	const slugs = uri.split('/');
	const sportIndex = slugs.indexOf('sports');
	// console.log('getSportFromUri _______________________');
	// console.log('getSportFromUri', {uri, slugs, sportIndex});
	if (sportIndex === -1) {
		throw new Error(`Uri not from sports app in getSportFromUri - ${uri}`);
	}
	if (!slugs[sportIndex + 1]) {
		throw new Error(`No sport in uri in getSportFromUri - ${uri}`);
	}
	const sports =
		slugs[sportIndex + 1].indexOf('-') > -1
			? slugs[sportIndex + 1].split('-')
			: [slugs[sportIndex + 1]];
	// console.log('getSportFromUri', {sports, slugs, sportIndex, uri});
	const id = parseFloat(sports[sports.length - 1]);
	// console.log('getSportFromUri', {uri, id});
	if (id) {
		sports.pop();
	}
	let slug = sports.join('-');
	// console.log('getSportFromUri', {slug, id: id || getIdFromSlug(slug)});
	if (slug === 'undefined') {
		slug = '';
	}
	return [id || getIdFromSlug(slug), slug];
};
