import {
	catchError,
	map,
	mergeMap,
	pairwise,
	withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { isFunction } from '@common/methods/isFunction';
import { RootState } from '../../store/configure.store';
import { sentryError } from './sentryService';

export interface CallBackData<P = any> {
	action: P;
	store$: {
		value: RootState;
	};
	action$: any;
	id: string;
}

export const epic = <P = any>(
	id: string,
	{
		actions,
		callback,
	}: {
		actions: any;
		callback: (data: CallBackData<P>) => any;
	},
) => {
	const ofTypeWrapper = getOfTypeWrapper(id, actions);
	return (action$, store$) => {
		return action$.pipe(
			ofTypeWrapper(),
			mergeMap((action: P) => {
				const data: CallBackData<P> = {
					action,
					store$,
					action$,
					id,
				};
				let callbackResponse;
				try {
					callbackResponse = callback(data);
				} catch (error) {
					sentryError(error, {
						id,
						action,
						action$,
						store$,
					});
					if (!NEXT_CONFIG.PRODUCTION) {
						throw error;
					}
					return of();
				}
				if (!callbackResponse) {
					sentryError(new Error(`Invalid epic response - ${id}`), {
						...data,
						callbackResponse,
					});
					return of();
				}
				return callbackResponse || of();
			}),
			catchError((error) => {
				sentryError(error, {
					id,
					store$,
					action$,
				});
				if (!NEXT_CONFIG.PRODUCTION) {
					throw error;
				}
				return of();
			}),
		);
	};
};

export interface OldStateCallBackData<P = any> extends CallBackData<P> {
	oldState: RootState;
	newState: RootState;
}

export const epicOldState = <P = any>(
	id: string,
	{
		actions,
		callback,
	}: {
		actions: any;
		callback: (data: OldStateCallBackData<P>) => any;
	},
) => {
	const ofTypeWrapper = getOfTypeWrapper(id, actions);
	return (action$, store$) => {
		const statePairs$ = store$.pipe(pairwise());
		return action$.pipe(
			ofTypeWrapper(),
			withLatestFrom(statePairs$),
			map(([_, [oldState, newState]]) => {
				const data = {
					action: _,
					newState,
					oldState,
					store$,
					action$,
					id,
				};
				const callbackResponse = callback(data);
				if (!callbackResponse) {
					// console.log(callbackResponse);
					sentryError(`Invalid epicOldState response - ${id}`, {
						...data,
						callbackResponse,
					});
					return [];
				}
				// console.log(id, {actions, callbackResponse});
				return callbackResponse;
			}),
			catchError((error) => {
				sentryError(error, {
					id,
				});
				if (!NEXT_CONFIG.PRODUCTION) {
					throw error;
				}
				return [];
			}),
		);
	};
};

const getOfTypeWrapper = (id, actions) => {
	return isFunction(actions) ? () => actions(ofType) : () => ofType(actions);
};
