import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

import { LoginNameType } from '@legacyApp/types/user/LoginNameType';
import { sortUsers } from '../../methods/sortUsers';

export type ValueType = Array<LoginNameType>;
const initialState: ValueType = [];

const NAME = 'users';

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

const addAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<LoginNameType>
> = (state, action) => {
	if (state.indexOf(action.payload) > -1) {
		return state;
	}
	return sortUsers([...state, action.payload]);
};

export const usersSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setUsers: setAction,
		addUser: addAction,
	},
});
