import { get } from 'local-storage';
import { LocalStorageKeysType } from '@modules/localStorage/types/localStorageKeys.type';

export const lsGet = (key: LocalStorageKeysType, defaultValue = null) => {
	if (!key) {
		return defaultValue;
	}
	try {
		const value = get(key);
		if (value === null) {
			return defaultValue;
		}
		return value;
	} catch (error) {
		return defaultValue;
	}
};
