import Redux from 'redux';

import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeysType } from '@modules/localStorage/types/localStorageKeys.type';

export const actionSimple = <T = any, S = any>(
	actionType: string,
	options?: {
		lsKey?: LocalStorageKeysType;
	},
): ((payload?: T, props?: S) => Redux.AnyAction) => {
	return (payload, props) => {
		// console.log(actionType, jsonToString(payload));
		if (options && options.lsKey) {
			lsSet(options.lsKey, payload);
		}
		return {
			type: actionType,
			payload,
			props,
		};
	};
};
