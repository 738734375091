import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Loader } from '../loader/loader';
import { Tooltip } from '../tooltip';
import { enhanceForwardRef } from '../../wrappers/ehanceForwardRef';
import { ButtonStyle } from './button.style';

class Button extends React.PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		type: PropTypes.string,
		className: PropTypes.string,
		style: PropTypes.object,
		loader: PropTypes.bool,
		children: PropTypes.any,
		loadingId: PropTypes.string,
		loadingMessage: PropTypes.any,
		loadingActive: PropTypes.bool,
		debounce: PropTypes.any,
		disabled: PropTypes.bool,
		noClassName: PropTypes.bool,
		noButtonClass: PropTypes.bool,
		onClick: PropTypes.func,
		tooltip: PropTypes.any,
		localTheme: PropTypes.any,
	};

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	onClick = (e) => {
		if (!this.props.onClick) {
			return;
		}
		if (this.props.loader) {
			return;
		}
		console.log('button_onclick', this.props.name, e);
		this.props.onClick(e);
		e.stopPropagation();
	};

	render() {
		// console.log('Button.jsx', this.props.name, this.props.className, {...this.props});
		const button = (
			<ButtonStyle
				localTheme={this.props.localTheme}
				disabled={this.props.disabled}
				name={this.props.name}
				aria-label={this.props.name}
				noClasses={this.props.noClassName}
				noButtonClass={this.props.noButtonClass}
				type={this.props.type || 'button'}
				style={this.props.style || {}}
				data-class={`button-${this.props.className}`}
				className={classnames(this.props.className, {
					active: this.props?.localTheme?.active,
					button: !(this.props.noClassName || this.props.noButtonClass),
				})}
				onClick={this.onClick}
			>
				{this.props.loadingId || this.props.loadingActive !== undefined ? (
					<Fragment>
						<Loader
							active={this.props.loadingActive}
							message={this.props.loadingMessage}
							module={this.props.loadingId}
						/>
						<span>{this.props.children}</span>
					</Fragment>
				) : this.props.localTheme && this.props.localTheme.submit ? (
					<span>{this.props.children}</span>
				) : (
					this.props.children
				)}
			</ButtonStyle>
		);
		return this.props.tooltip ? (
			<Tooltip content={this.props.tooltip}>{button}</Tooltip>
		) : (
			button
		);
	}
}

Button = enhanceForwardRef(Button);

const mapStateToProps = (state, props) => ({
	loader: state.loader.modules[props.loadingId],
});

Button = connect(mapStateToProps, null, null, { forwardRef: true })(Button);

export { Button };
