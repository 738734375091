import { FC } from 'react';
import dynamic from 'next/dynamic';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { DeveloperDataComponentProps } from './DeveloperDataComponent';

const ENABLED_DEV_VIEW = lsGet(LocalStorageKeys.WOLF_DEV_VIEW);

const DeveloperDataComponent = dynamic(
	() => import('./DeveloperDataComponent'),
);

export const DeveloperData: FC<DeveloperDataComponentProps> = (props) => {
	if (!ENABLED_DEV_VIEW) {
		return null;
	}
	return <DeveloperDataComponent {...props} />;
};
