import {
	VAULT_DEPOSIT,
	VAULT_GET_BALANCE,
	VAULT_SET_BALANCE,
	VAULT_UPDATE_BALANCE,
	VAULT_WITHDRAW,
} from './vault.constants';

const vaultDeposit = (payload) => ({
	type: VAULT_DEPOSIT,
	payload,
});

const vaultWithdraw = (payload) => ({
	type: VAULT_WITHDRAW,
	payload,
});

const vaultSetBalance = (payload) => ({
	type: VAULT_SET_BALANCE,
	payload,
});

const vaultUpdateBalance = (payload) => ({
	type: VAULT_UPDATE_BALANCE,
	payload,
});

const vaultGetBalance = () => ({
	type: VAULT_GET_BALANCE,
});

export {
	vaultDeposit,
	vaultWithdraw,
	vaultSetBalance,
	vaultGetBalance,
	vaultUpdateBalance,
};
