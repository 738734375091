import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { Image, parseImageSrc } from '../../../default/Image/Image';
import { MediumPromoStyled } from './Promotions.styled';

export const VipWheelOnClickWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const dispatch = useDispatch();

	return (
		<div
			className={'onclick-wrapper onclick-wrapper-vipwheel'}
			onClick={() => dispatch(openModal(MODAL_ID.VIP_WHEEL, 'vipwheelpromo'))}
		>
			{children}
		</div>
	);
};

export const VipWheelPromo: FC = () => {
	return (
		<VipWheelPromoStyled>
			<VipWheelOnClickWrapper />
			<WheelImage />
			<h5 className="text-style-h-6">
				{trans({
					label: 'daily<1/>charge',
					namespace: TransNamespace.COMMON,
					components: (
						<>
							daily
							<br />
							charge
						</>
					),
				})}
			</h5>
		</VipWheelPromoStyled>
	);
};

export const WheelImage = styled(Image).attrs({
	src: '/img/promosection/wheel/wheel.png',
	alt: 'Coins',
})`
	top: 20%;
	position: absolute;
	height: 120%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
`;

export const VipWheelPromoStyled = styled(MediumPromoStyled)`
	background-image: url(${parseImageSrc('/img/promosection/wheel/bg.png')});
	background-repeat: no-repeat;
	background-size: cover;

	h5 {
		z-index: 3;
	}
`;
