import { combineEpics } from 'redux-observable';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { LOCATION_CHANGE } from 'connected-next-router';
import { CLOSE_MODAL, OPEN_MODAL } from '@modules/modals/store/modal.constants';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { getCurrencyGtmId } from '../../modules/app/appService';
import { SET_USERNAME } from '../user/user.constants';
import { NEW_DEPOSIT } from '../transactions/transactions.constants';
import { mathService } from '../../modules/math/mathService';
import { epic } from '../../modules/app/epicService';
import { sentryError } from '../../modules/app/sentryService';
import { scientificToDecimal } from '../../../methods/math/scientificToDecimal';
import { clearGtmPageData, push } from './gtm.actions';
import {
	CLEAR_GTM_PAGE_DATA,
	DAILY_STREAK_GTM_EVENT,
	DEPOSIT_GTM_EVENT,
	FAUCET_GTM_EVENT,
	PUSH_GTM_EVENT,
	REGISTER_GTM_EVENT,
} from './gtm.constants';

const dailyStreakEpic = epic('dailyStreakEpic', {
	actions: (ofType) => ofType(DAILY_STREAK_GTM_EVENT),
	callback: ({ action, store$ }) => {
		return of(
			push(
				{
					event: 'dailyStreakClaimed',
					step: action.data.step,
					currency: action.data.currency,
					amount: action.data.amount,
					fiat_amount: mathService.multiply(
						parseFloat(
							store$.value.stats.price[action.data.currency.toLowerCase()],
						),
						parseFloat(action.data.amount),
					),
					id: getCurrencyGtmId(action.data.currency),
				},
				action.data,
			),
		);
	},
});

const depositEpic = epic('depositEpic', {
	actions: (ofType) => ofType(DEPOSIT_GTM_EVENT),
	callback: ({ action, store$ }) => {
		if (!action.data.new) {
			return of();
		}
		const currency = action.data.currency;
		const price = parseFloat(store$.value.stats.price[currency.toLowerCase()]);
		const amount = parseFloat(action.data.amount);
		const divider =
			price < 0.0001 ? 1 : price < 1 ? 100 : price < 1000 ? 10000 : 100000;
		// console.log({divider, price, amount});
		const satoshiPrice = roundNumber(price / divider, 6);
		const satoshiAmount = roundNumber(amount * divider, 0);
		const revenue = roundNumber(satoshiAmount * satoshiPrice, 6);
		if (satoshiPrice === 0 || satoshiPrice < 0.00001) {
			sentryError(
				new Error(`Invalid deposit price - ${currency.toLowerCase()}`),
				{
					action,
					price,
					amount,
					divider,
					satoshiPrice,
					satoshiAmount,
					revenue,
				},
				store$.value,
			);
			return of();
		}
		return of(
			push(
				{
					event: 'depositCompleted',
					ecommerce: {
						purchase: {
							actionField: {
								id: `${action.data.uuid}`,
								revenue: `${scientificToDecimal(revenue)}`,
							},
							products: [
								{
									name: currency,
									id: getCurrencyGtmId(currency),
									quantity: `${scientificToDecimal(satoshiAmount)}`,
									price: `${scientificToDecimal(satoshiPrice)}`,
								},
							],
						},
					},
				},
				action.data,
			),
		);
	},
});

// const withdrawEpic = (action$, store$) => {
//   return action$.pipe(
//     ofType(WITHDRAW_GTM_EVENT),
//     mergeMap(action => {
//       if (!action.data.new) return;
//       const wallet = currencySymbols(action.data.wallet).wallet
//       return of(push(
//         {
//           event: 'withdraw',
//           ecommerce: {
//             checkout: {
//               actionField: {
//                 step: action.data.state,
//               },
//               products: [
//                 {
//                   name: wallet,
//                   price: `${store$.value.stats.price[wallet.toLowerCase()]}`,
//                   quantity: `${action.data.amount}`,
//                   id: `${action.data.uuid}`,
//                 },
//               ],
//             },
//           },
//         },
//         action.data
//       ));
//     })
//   );
// };

const registerEpic = epic('registerEpic', {
	actions: (ofType) => ofType(REGISTER_GTM_EVENT),
	callback: ({ action }) => {
		if (!action.location) {
			action.location = 'interaction';
		}
		return of(
			push(
				{
					event: 'registerCompleted',
					login: action.data.login,
					signup_location: action.location,
				},
				action.data,
			),
		);
	},
});

const faucetEpic = epic('faucetEpic', {
	actions: (ofType) => ofType(FAUCET_GTM_EVENT),
	callback: ({ action, store$ }) => {
		const currency = action.data.currency;
		return of(
			push(
				{
					event: 'faucetClaimed',
					currency: currency,
					amount: action.data.amount,
					fiat_amount: mathService.multiply(
						parseFloat(store$.value.stats.price[currency.toLowerCase()]),
						parseFloat(action.data.amount),
					),
					id: getCurrencyGtmId(currency),
				},
				action.data,
			),
		);
	},
});

const loginEpic = epic('loginEpic', {
	actions: (ofType) => ofType(SET_USERNAME),
	callback: ({ action }) => {
		return of(
			push(
				{
					userId: action.name,
				},
				action.data,
			),
		);
	},
});

const openModalEpic = epic('openModalEpic', {
	actions: (ofType) => ofType(OPEN_MODAL),
	callback: ({ action }) => {
		return of(
			push(
				{
					event: 'pageview_change',
					page: `/modal/${action.name}`,
					title: process.browser ? document.title : 'server',
				},
				action.data,
			),
		);
	},
});

const closeModalEpic = epic('closeModalEpic', {
	actions: (ofType) => ofType(CLOSE_MODAL),
	callback: () => {
		return of(clearGtmPageData());
	},
});

const pushEpic = epic('pushEpic', {
	actions: (ofType) => ofType(PUSH_GTM_EVENT),
	callback: ({ action }) => {
		if (process.browser && window.appWolfLayer) {
			window.appWolfLayer.push(action.options);
		}
		return of();
	},
});

const newDepositEpic = epic('newDepositEpic', {
	actions: (ofType) => ofType(NEW_DEPOSIT),
	callback: ({ action }) => {
		return of(
			push(
				{
					event: 'pageview_change',
					page: '/new/deposit/',
					title: process.browser ? document.title : 'server',
				},
				action.data,
			),
		);
	},
});

const depositPageCloseEpic = epic('depositPageCloseEpic', {
	actions: (ofType) => ofType(NEW_DEPOSIT),
	callback: () => {
		return of(clearGtmPageData()).pipe(delay(5000));
	},
});

const locationChangeEpic = epic('locationChangeEpic', {
	actions: (ofType) => ofType(CLEAR_GTM_PAGE_DATA, LOCATION_CHANGE),
	callback: ({ action }) => {
		return of(
			push(
				{
					event: 'pageview_change',
					page: process.browser ? document.location.pathname : 'server',
					title: process.browser ? document.title : 'server',
				},
				action.data,
			),
		);
	},
});

const gtmEpic = combineEpics(
	dailyStreakEpic,
	depositEpic,
	// withdrawEpic,
	registerEpic,
	faucetEpic,
	pushEpic,
	loginEpic,
	openModalEpic,
	closeModalEpic,
	newDepositEpic,
	depositPageCloseEpic,
	locationChangeEpic,
);

export { gtmEpic };
