import { RootState } from '@legacyApp/client/store/configure.store';

export const isBetSlipToOpen = (state: RootState): boolean => {
	// open chat with bet_slip to set your stake:
	// - desktop always
	// - mobile on single mode always
	// - mobile on multi mode only on first bet

	// TODO: chat store usage
	if (!(state.sports?.sideContent !== 'bet_slip' || !state?.chat?.visible)) {
		return false;
	}

	if (!state.app.mobile) {
		return true;
	}

	if (state.sportsBetSlip?.type.id === 'single') {
		return true;
	}

	return !state.sportsBetSlip?.betsList?.length;
};
