export const BET_WON = 'BET_WON';
export const BET_LOST = 'BET_LOST';
export const ADD_PROFIT = 'ADD_PROFIT';
export const ADD_PROFIT_HISTORY = 'ADD_PROFIT_HISTORY';
export const ADD_WAGERED = 'ADD_WAGERED';
export const ADD_WIN_STREAK = 'ADD_WIN_STREAK';
export const ADD_LOSE_STREAK = 'ADD_LOSE_STREAK';
export const SET_TOP_WIN_STREAK = 'SET_TOP_WIN_STREAK';
export const SET_TOP_LOSE_STREAK = 'SET_TOP_LOSE_STREAK';
export const SET_TOP_WIN_STREAK_AUTO = 'SET_TOP_WIN_STREAK_AUTO';
export const SET_TOP_LOSE_STREAK_AUTO = 'SET_TOP_LOSE_STREAK_AUTO';
export const SET_TOP_BET = 'SET_TOP_BET';
export const SET_TOP_WIN = 'SET_TOP_WIN';
export const SET_TOP_LOST = 'SET_TOP_LOST';
export const SET_TOP_BET_AUTO = 'SET_TOP_BET_AUTO';
export const SET_TOP_WIN_AUTO = 'SET_TOP_WIN_AUTO';
export const SET_TOP_LOST_AUTO = 'SET_TOP_LOST_AUTO';
export const RESET_GAME_SESSION = 'RESET_GAME_SESSION';
export const RESET_AUTO_SESSION = 'RESET_AUTO_SESSION';
export const SET_AUTO_INIT_PROFIT = 'SET_AUTO_INIT_PROFIT';
export const ADD_PROFIT_HISTORY_AUTO = 'ADD_PROFIT_HISTORY_AUTO';
