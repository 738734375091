import styled from 'styled-components';
import { dashboard_v2 } from '@legacyApp/client/modules/style/mixins/dashboard';

export const FooterTextStyle = styled.div``;

export const FooterColumnStyle = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const FooterRowStyle = styled.div<{
	currencies?: boolean;
}>`
	${dashboard_v2()}
`;

export const FooterLabelStyled = styled.div.attrs(() => ({
	className: 'text-style-label-cap',
}))`
	color: var(--color-dark-200);
	margin-bottom: 12px;
`;
