import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { pushOneSignal } from '../../store/onesignal/onesignal.epic';
import { onLoad } from './appService';
import { isFeatureAvailable } from './featureService';
import { fetchScript } from './fetchScript';

export const initSpinePlayer = () => {
	fetchScript(
		'https://esotericsoftware.com/files/spine-player/3.7/spine-player.js',
	);
};

// export const initInspectLet = (user) => {
//   if (user.uuid !== 'a7d5cbf5-1952-4a76-902b-6db0e70d3f5f') return;
//   window.__insp = window.__insp || [];
//   __insp.push(['wid', 695676246]);
//   var ldinsp = function(){
//     if(typeof window.__inspld != 'undefined') return;
//     window.__inspld = 1;
//     var insp = document.createElement('script');
//     insp.type = 'text/javascript';
//     insp.async = true;
//     insp.id = 'inspsync';
//     insp.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=695676246&r=' + Math.floor(new Date().getTime()/3600000);
//     var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
//   };
//   setTimeout(ldinsp, 0);
// };

export const initOneSignal = async () => {
	if (!NEXT_CONFIG.PRODUCTION) {
		return;
	}
	return await fetchScript(
		'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
	).then(() => {
		pushOneSignal(() => {
			window.OneSignal.init({
				autoResubscribe: true,
				path: '/OneSignalSDKUpdaterWorker.js',
				appId: !NEXT_CONFIG.PRODUCTION
					? '64a5ba80-f8ac-49c6-866d-b2bc02fd1451'
					: 'e1ed1a8e-bb03-4c25-b1b3-1daa4f9d62fd',
			});
		});
	});
};

export const initLicense = (callback) => {
	if (!NEXT_CONFIG.PRODUCTION) {
		return;
	}
	if (isFeatureAvailable(MODAL_ID.LICENSE)) {
		fetchScript(
			'https://10281cf8-35d4-4f92-926f-7573b336f155.snippet.antillephone.com/apg-seal.js',
			{
				callback,
			},
		);
	}
};

const initGTM = () => {
	if (!NEXT_CONFIG.PRODUCTION) {
		return;
	}
	(function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			dl = l !== 'dataLayer' ? '&l=' + l : '';
		j.async = true;
		j.defer = true;
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'appWolfLayer', 'GTM-MLW94ZL');
};

const initVendor = () => {
	onLoad(() => {
		initGTM();
		// initInspectLet();
	});
};

export { initVendor };
