import { FC, InputHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { InputStyleType } from '@uiTypes/InputStyleType';
import {
	InputBoxStyled,
	InputIconWrapperStyled,
	InputLabelActionStyled,
	InputLabelStyled,
	InputStyled,
	InputWrapperStyled,
} from './Input.styled';

type InputElementAttributes = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends PropsWithChildren<InputElementAttributes> {
	label?: string | ReactNode;
	labelAction?: string | ReactNode;
	$type?: InputStyleType;
	disabled?: InputElementAttributes['disabled'];
	icon?: ReactNode;
	placeholder?: InputElementAttributes['placeholder'];
	ref?: any;
}

export const Input: FC<InputProps> = (props) => {
	const { label, labelAction, icon, children, ...restProps } = props;

	const inputProps_ = {
		$icon: !!icon,
		$isLabel: !!label,
		...restProps,
	};

	return (
		<InputBoxStyled ref={props.ref} {...inputProps_}>
			{label && (
				<InputLabelStyled className="text-style-label-cap">
					{label}
					{!!labelAction && (
						<InputLabelActionStyled>{labelAction}</InputLabelActionStyled>
					)}
				</InputLabelStyled>
			)}
			<InputWrapperStyled {...inputProps_}>
				{icon && <InputIconWrapperStyled>{icon}</InputIconWrapperStyled>}
				<InputStyled className={'text-style-sm-medium'} {...inputProps_} />
				{children}
			</InputWrapperStyled>
		</InputBoxStyled>
	);
};
