import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';

export const TABLE_MOCKS = {
	[TABLE_ID.allBets]: [
		{
			hash: 'eec86615-4cc5-4ddb-9f12-a83c16c7aa71',
			nonce: 2131267,
			user_seed: 'kebokebo123e123',
			currency: 'trx',
			amount: '0.00000003',
			profit: '-0.0000000300',
			multiplier: '18.0000',
			bet_value: '5.50',
			result_value: '89.89',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'4f22b5065652bf65f81f67cdbaffde3299fafbba9d482215809f79e79aaae244',
			game: {
				name: 'dice',
			},
			user: {
				login: '4d1t',
				uuid: '2c684953-9266-4fa6-8bc3-35b24fdc5a0d',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 0,
				joined: '2021-02-07',
				preferences: {
					privacy: {
						public_total_profit: false,
						public_total_wagered: false,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: '5a8be5b5-3796-43e9-87ae-a6af463d733c',
			nonce: 77991242,
			user_seed: 'ff6a7073390e7412e9fb50115c7c260c',
			currency: 'trx',
			amount: '0.00001654',
			profit: '-0.0000165400',
			multiplier: '4.9500',
			bet_value: '20.00',
			result_value: '91.53',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'7a162bd6ed250ca1c8a63f0b7c8b47d925ac4f6f77993d206619bc567f0f275e',
			game: {
				name: 'dice',
			},
			user: {
				login: 'Luis30',
				uuid: '0075b5ad-52c9-44fc-8187-1c396cdeef64',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 1,
				joined: '2020-05-27',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: '45be2831-4573-40b0-a887-1408b7557665',
			nonce: 227395451,
			user_seed: '726683a7eaa08d88cff081a3fb321d8f',
			currency: 'doge',
			amount: '0.00000032',
			profit: '0.0000003200',
			multiplier: '2.0000',
			bet_value: '49.50',
			result_value: '31.20',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.24913742',
			state: 'win',
			published_at: 1625063060,
			server_seed_hashed:
				'2d26cf2b36385b7f9dae340bec17d7bd405d1a4cd17cd1c26a9f872f9945d3f2',
			game: {
				name: 'dice',
			},
			user: {
				login: HIDDEN_USER,
				uuid: '87922747-2e9d-49f0-a47c-f42650855f6c',
			},
		},
		{
			hash: 'a3beb8c5-ffa5-4a7b-af0e-5e00e9e27021',
			nonce: 49,
			user_seed: '308875bd2e688d04a17873642889aeed',
			currency: 'xlm',
			amount: '0.00000662',
			profit: '0.0000662000',
			multiplier: '11.0000',
			bet_value: '90.99',
			result_value: '92.59',
			rule: 'over',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.27304135',
			state: 'win',
			published_at: 1625063060,
			server_seed_hashed:
				'1b5eed6074656850e11ab3c8c87b51109b68097eb9ae000660e90df59c3ca1b1',
			game: {
				name: 'dice',
			},
			user: {
				login: 'DOSPERRIN',
				uuid: '0a175e12-e91c-474f-98d5-41fb105b5691',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 0,
				joined: '2021-04-30',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: 'e44d89e3-b9cb-4821-9eb7-58978c575b58',
			nonce: 7001713,
			user_seed: '11306846965611382306',
			currency: 'trx',
			amount: '0.00041230',
			profit: '-0.0004123000',
			multiplier: '1.7368',
			bet_value: '57.00',
			result_value: '99.77',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'b93dff77060f3a7d890da98641b3a9e330f7db133be42f6fac7265176a955403',
			game: {
				name: 'dice',
			},
			user: {
				login: 'leolalia',
				uuid: '5012d39f-f713-4821-84be-4ffcae03f0c0',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 1,
				joined: '2020-08-07',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: 'ae46be39-c4d7-4620-a189-b20feabd89a2',
			nonce: 6943925,
			user_seed: '093c80471a71737ff4b2aaef8cd0979a',
			currency: 'trx',
			amount: '0.00001271',
			profit: '-0.0000127100',
			multiplier: '19.8000',
			bet_value: '5.00',
			result_value: '73.56',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'8fe0fb1627fd3497848ed42dae3182fb58b84268a59875adf412b9f9ad86a06e',
			game: {
				name: 'dice',
			},
			user: {
				login: 'levi77',
				uuid: 'd98c5d5c-80a0-436c-9d87-f7bf03dcc6b8',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 0,
				joined: '2021-03-27',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: 'db28f4a6-df45-499f-a106-6f495c745dd5',
			nonce: 2266697,
			user_seed: 'jIl6Aih3yead89R51J0CurclhYcn37',
			currency: 'usdt',
			amount: '0.00000037',
			profit: '-0.0000003700',
			multiplier: '17.3380',
			bet_value: '94.28',
			result_value: '12.96',
			rule: 'over',
			created_at: '2021-06-30 14:24:20',
			price_usd: '1.00000000',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'1cb76710e3e6c24f5da1f67be502240f9cf1237b5681ebcf6eeb468bfa258301',
			game: {
				name: 'dice',
			},
			user: {
				login: 'elbisivni',
				uuid: '1cbc8ca7-d7e1-44c9-a604-612567662023',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 2,
				joined: '2019-10-26',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: '4308ef12-c27b-414c-8265-6755d8f57a2e',
			nonce: 9393438,
			user_seed: '03dae67defb2292c9f543c49973b88d3',
			currency: 'trx',
			amount: '0.00000250',
			profit: '0.0000140000',
			multiplier: '6.6000',
			bet_value: '15.00',
			result_value: '3.16',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'win',
			published_at: 1625063060,
			server_seed_hashed:
				'eaa3cef6272156194a52a8a7e108f5aba47b6ac7e220bae4fcda9b290dd2e427',
			game: {
				name: 'dice',
			},
			user: {
				login: 'zambore',
				uuid: 'e54c9ac9-36f3-4b08-aca9-ec6278735535',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 0,
				joined: '2021-06-03',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: 'efbec22e-3d12-46a4-945a-350c0c1d0775',
			nonce: 41275073,
			user_seed: 'e0c4b8b3861cc935d4c78ba29abf742e',
			currency: 'xlm',
			amount: '0.00000112',
			profit: '0.0000011428',
			multiplier: '2.0204',
			bet_value: '49.00',
			result_value: '36.12',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.27304135',
			state: 'win',
			published_at: 1625063060,
			server_seed_hashed:
				'b7b114bb1906dbec025be2baf83f684738d79e4ac778c3878e71806761ac25ce',
			game: {
				name: 'dice',
			},
			user: {
				login: 'BlackBox',
				uuid: '0becfe6a-2141-4698-a7a7-56d90e73c71b',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 0,
				joined: '2021-03-13',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: '6026a9c2-bae1-4946-96cb-4c6fbc487910',
			nonce: 41275071,
			user_seed: 'e0c4b8b3861cc935d4c78ba29abf742e',
			currency: 'trx',
			amount: '0.00016768',
			profit: '-0.0001676800',
			multiplier: '2.0204',
			bet_value: '49.00',
			result_value: '80.83',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'b7b114bb1906dbec025be2baf83f684738d79e4ac778c3878e71806761ac25ce',
			game: {
				name: 'dice',
			},
			user: {
				login: 'BlackBox',
				uuid: '0becfe6a-2141-4698-a7a7-56d90e73c71b',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 0,
				joined: '2021-03-13',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: 'c91c83d5-141b-40d9-96e3-d10de96fabb6',
			nonce: 830083,
			user_seed: '8bb24a4123495a932480',
			currency: 'eth',
			amount: '0.00000001',
			profit: '0.0000000098',
			multiplier: '1.9800',
			bet_value: '50.00',
			result_value: '34.53',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '2149.34227032',
			state: 'win',
			published_at: 1625063060,
			server_seed_hashed:
				'b6e5b8fcfb062b681ba46874f735238609010ce8b6b1afbca603b7fadf77d188',
			game: {
				name: 'dice',
			},
			user: {
				login: HIDDEN_USER,
				uuid: 'c8926a84-24b5-4d91-b684-9dfb6beecb47',
			},
		},
		{
			hash: '64d4f79f-10ee-4fb0-9b0b-c2905bdcf9ce',
			nonce: 153549209,
			user_seed: '7dfdb10d0bf99897c337736857a2ea35',
			currency: 'ltc',
			amount: '0.00000001',
			profit: '-0.0000000100',
			multiplier: '2.0000',
			bet_value: '49.50',
			result_value: '77.31',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '140.61529966',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'5fc1e1e3bb95de29ef2ad9f6b38431fe2a0898453b7df73b8cbeabff361aabcc',
			game: {
				name: 'dice',
			},
			user: {
				login: 'Lvice',
				uuid: '8c308adc-3891-4e8f-8ec4-a22940a22a05',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 2,
				joined: '2020-11-01',
				preferences: {
					privacy: {
						public_total_profit: true,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: '4b509e64-3b8e-45de-b827-d79e392679da',
			nonce: 587536,
			user_seed: '58255fe95f45637207262850372991c2',
			currency: 'trx',
			amount: '0.00004843',
			profit: '0.0000437710',
			multiplier: '1.9038',
			bet_value: '52.00',
			result_value: '42.18',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'win',
			published_at: 1625063060,
			server_seed_hashed:
				'72dd60d10d0529613eba1358c2736902b04d326042ba4bae561a74054c6f5b70',
			game: {
				name: 'dice',
			},
			user: {
				login: 'acelogic',
				uuid: '89d04be6-e4d0-4c5b-8dcc-a2a2554c9572',
				is_admin: false,
				is_moderator: false,
				is_high_roller: false,
				vip_level: 1,
				joined: '2020-09-08',
				preferences: {
					privacy: {
						public_total_profit: false,
						public_total_wagered: true,
						public_bets: true,
					},
					community: {
						block_all_friend_requests: false,
						block_friend_requests_notifications: false,
						allow_messages_from_all: false,
						show_messages_notifications: false,
					},
				},
				race_badge: null,
			},
		},
		{
			hash: 'c54b0efd-2133-4c7d-addd-2ad5ef8ac0e0',
			nonce: 53648,
			user_seed: '5413b091bb015082a130',
			currency: 'trx',
			amount: '0.00010952',
			profit: '-0.0001095200',
			multiplier: '2.0308',
			bet_value: '48.75',
			result_value: '76.31',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'a9ed8ec71f4b840b4afef0492cea33106dcc8da6538100e79aa4c5f7660d3d52',
			game: {
				name: 'dice',
			},
			user: {
				login: HIDDEN_USER,
				uuid: '3f69df61-d8bb-489e-a109-be6aab15d6f8',
			},
		},
		{
			hash: 'c4f915e1-2297-4eef-bd75-00b255047bd9',
			nonce: 14117883,
			user_seed: '011231efa3ccee3f89be7a18a970313c',
			currency: 'trx',
			amount: '0.00001480',
			profit: '-0.0000148000',
			multiplier: '2.0000',
			bet_value: '49.50',
			result_value: '63.13',
			rule: 'under',
			created_at: '2021-06-30 14:24:20',
			price_usd: '0.06589859',
			state: 'loss',
			published_at: 1625063060,
			server_seed_hashed:
				'43398e3865a2dc5ca3239a6a325be54ab75e3e3952236b24fdf9dd258196542e',
			game: {
				name: 'dice',
			},
			user: {
				login: HIDDEN_USER,
				uuid: '1969ea8d-f0e7-4c03-b158-924ff393467f',
			},
		},
	],
};
