export const createSlug = (text: string): string => {
	if (!text) {
		return '';
	}
	return text
		.toLowerCase()
		.normalize('NFD')
		.trim()
		.replace(/\s+/g, '-')
		.replace(/[^\w\\-]+/g, '')
		.replace(/\\-\\-+/g, '-');
};
