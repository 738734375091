import { useCallback, useRef } from 'react';
import { useOnMount } from '@common/hooks/useOnMount';
import { notUndefOrNull } from '@common/components/layout/ScrollCards/ScrollMenu/utils';

export const useConditionalTimeout = (condition: Boolean) => {
	const timeoutHandler = useRef(null);

	const _setTimeout = useCallback(
		(callback, timeout) => {
			if (timeoutHandler.current) {
				clearTimeout(timeoutHandler.current);
				timeoutHandler.current = null;
			}

			if (notUndefOrNull(timeout) && condition) {
				timeoutHandler.current = setTimeout(() => {
					callback();
				}, timeout);
			}
		},
		[condition],
	);

	useOnMount(() => () => {
		clearTimeout(timeoutHandler.current);
	});

	return {
		setTimeout: _setTimeout,
	};
};
