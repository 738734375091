import React, { FC } from 'react';
import styled from 'styled-components';
import { useUserName } from '@common/selectors/user.selectors';
import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { useVipLevel } from '../../vip/store/selectors/vip.selectors';
import { Level } from '../../vip/components/level/level';
import { VipIconProgress } from '../../vip/components/VipIconProgress';
import { VipLeftToNextLevel } from '../../vip/components/VipLeftToNextLevel';
import routingService from '../../../LegacyApp/client/modules/app/routingService';

export const VipSection: FC = () => {
	const userName = useUserName();
	const { level } = useVipLevel();

	return (
		<VipSectionStyled>
			<VipSectionTitle>
				<LinkTo forceATag href={routingService.to(ROUTING_ID.USER_VIP)}>
					<VipIconProgress />
				</LinkTo>
				<VipSectionTitleName>
					<span className="text-style-md-semibold">{userName}</span>
					<LinkTo forceATag href={routingService.to(ROUTING_ID.USER_VIP)}>
						<span
							className="text-style-xs-regular"
							style={{ color: 'var(--color-dark-100)' }}
						>
							<Level level={level} />
						</span>
					</LinkTo>
				</VipSectionTitleName>
			</VipSectionTitle>
			<VipLeftToNextLevel />
		</VipSectionStyled>
	);
};

export const VipSectionTitleName = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
`;

export const VipSectionTitle = styled.div`
	align-items: center;
	display: flex;
	gap: 6px;
	justify-content: flex-start;
	margin-bottom: 8px;
`;

export const VipSectionProgress = styled.div`
	margin-bottom: 16px;
	padding-left: 4px;
	position: relative;
`;

export const VipSectionStyled = styled.div`
	padding: 0 8px;
`;
