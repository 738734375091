import styled from 'styled-components';

export const StyledHeaderRow = styled.div.attrs(() => ({
	className: 'header--row',
}))`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	padding: 0 ${(props) => props.theme.layout.sideFullPadding};
	align-items: center;
	height: 100%;
`;
