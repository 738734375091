import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server.browser';
import { isFunction } from '@common/methods/isFunction';

const Svg: FC<{
	width?: number;
	height?: number;
	content: any;
	className?: string;
	viewBox?: string;
	minHeight?: number;
	minWidth?: number;
	fill?: any;
	wrapperFill?: any;
	style?: any;
}> = (props) => (
	<svg
		viewBox={props.viewBox ?? `0 0 ${props.width} ${props.height}`}
		width={props.width}
		fill={props.wrapperFill}
		height={props.height}
		className={props.className}
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		style={{
			...(props.minWidth && { minWidth: `${props.minWidth}px` }),
			...(props.minHeight && { minHeight: `${props.minHeight}px` }),
			...props.style,
		}}
	>
		{isFunction(props.content) ? props.content(props) : props.content}
	</svg>
);

export { Svg };

export function encodeSvg(reactElement) {
	return (
		'data:image/svg+xml,' +
		escape(ReactDOMServer.renderToStaticMarkup(reactElement))
	);
}
