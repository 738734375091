import { servicesConfig } from '@server/config/services.config';

let moduleResolve = null;
let sentryInstance = null;

const getSentry = async () => {
	if (sentryInstance) {
		return sentryInstance;
	}
	if (moduleResolve) {
		return moduleResolve;
	}
	moduleResolve = new Promise(async (resolve) => {
		// eslint-disable-next-line @next/next/no-assign-module-variable
		const module = await import('./sentryClass');
		sentryInstance = new module.sentryClass(servicesConfig.sentryDns.client);
		resolve(sentryInstance);
	});
	return moduleResolve;
};

// window.onerror = (
//   message,
//   source,
//   lineno,
//   colno,
//   error
// ) => {
//   const data = {
//     message,
//     source,
//     lineno,
//     colno,
//   };
//   if (sentryService.excludeError(data)) return;
//   sentryError(error, data);
// };

// const sentryService = null;

export const sentryUpdateState = async (state) => {
	const instance = await getSentry();
	instance.updateState(state);
};

export const sentryUpdateUser = async (state) => {
	const instance = await getSentry();
	instance.updateUser(state);
};

export const sentryError = async (error, errorInfo, state) => {
	const instance = await getSentry();

	if (state) {
		sentryUpdateUser(state);
		sentryUpdateState(state);
	}
	instance.sendError(error, errorInfo);
};
