export const AUTOBET = 'AUTOBET';
export const AUTOBET_SET_ID = 'AUTOBET_SET_ID';
export const AUTOBET_SET_CURRENCY = 'AUTOBET_SET_CURRENCY';
export const AUTOBET_PLAY = 'AUTOBET_PLAY';
export const AUTOBET_STOP = 'AUTOBET_STOP';
export const AUTOBET_STOPPED = 'AUTOBET_STOPPED';
export const AUTOBET_CLEAR = 'AUTOBET_CLEAR';
export const AUTOBET_PAUSE = 'AUTOBET_PAUSE';
export const AUTOBET_SET_PAUSE = 'AUTOBET_SET_PAUSE';
export const AUTOBET_SET_PAUSE_DATA = 'AUTOBET_SET_PAUSE_DATA';

export const AUTOBET_RESET_TO_DEFAULT = 'AUTOBET_RESET_TO_DEFAULT';
export const AUTOBET_SET_DEFAULTS = 'AUTOBET_SET_DEFAULTS';
export const AUTOBET_SET_MODE = 'AUTOBET_SET_MODE';
export const AUTOBET_SET_CONFIG = 'AUTOBET_SET_CONFIG';
export const AUTOBET_SET_EDIT_MODAL = 'AUTOBET_SET_EDIT_MODAL';
export const AUTOBET_SET_CONFIG_EASY = 'AUTOBET_SET_CONFIG_EASY';
export const AUTOBET_UPDATE_CONFIG_EASY = 'AUTOBET_UPDATE_CONFIG_EASY';

export const EDIT_AUTOBET_STRATEGY = 'EDIT_AUTOBET_STRATEGY';
export const SAVE_AUTOBET_STRATEGY = 'SAVE_AUTOBET_STRATEGY';
export const SET_AUTOBET_STRATEGIES = 'SET_AUTOBET_STRATEGIES';
export const GET_AUTOBET_STRATEGIES = 'GET_AUTOBET_STRATEGIES';

export const SET_ACTIVE_AUTOBET_STRATEGY = 'SET_ACTIVE_AUTOBET_STRATEGY';
export const DISABLE_CHECK_AUTOBET_STRATEGY = 'DISABLE_CHECK_AUTOBET_STRATEGY';

export const AUTOBET_SET_ROLLS = 'AUTOBET_SET_ROLLS';
export const AUTOBET_SET_ROLLS_COUNTER = 'AUTOBET_SET_ROLLS_COUNTER';
export const DELETE_AUTOBET_STRATEGY = 'DELETE_AUTOBET_STRATEGY';
export const AUTOBET_SET_AUTO_STATS = 'AUTOBET_SET_AUTO_STATS';

export const AUTOBET_SUMMARY_CONNECTION_ERROR =
	'AUTOBET_SUMMARY_CONNECTION_ERROR';

export const AUTOBET_SET_ACTIVE = 'AUTOBET_SET_ACTIVE';
export const AUTOBET_ROLLING = 'AUTOBET_ROLLING';
export const AUTOBET_SET_THROTTLE = 'AUTOBET_SET_THROTTLE';
export const AUTOBET_SET_MANUAL = 'AUTOBET_SET_MANUAL';
