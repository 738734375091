import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import { hour } from '@legacyApp/client/modules/app/time';
import { vipActions } from '../vip.actions';
import { mapLevelsDTO } from '../../methods/mapLevelsDTO';
import { VipLevel } from '../types/vip.types';

export const vipGetLevelsThunk = (): AppThunk<Promise<Array<VipLevel>>> => {
	return async (dispatch, getState) => {
		const { vip } = getState();
		if (vip.levels?.length) {
			return vip.levels;
		}
		const response = await dispatch(
			fetchApi({
				url: '/user/vip/levels',
				loaderId: LOADING_IDS.VIP_LEVELS,
				fromState: hour,
			}),
		);
		// data = VIP_LEVELS_MOCK;
		if (!response?.levels?.length) {
			return vip.levels;
		}
		const levels = mapLevelsDTO(response?.levels);
		dispatch(vipActions.levelsUpdate(levels));
		return levels;
	};
};
