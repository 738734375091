import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';

export const CASINO_PAGE_ID = {
	INDEX: 'casino',
};

export const CASINO_META = {
	[CASINO_PAGE_ID.INDEX]: {
		title: 'In-house Games',
		namespace: TransNamespace.COMMON,
	},
};
