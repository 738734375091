import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { memo } from 'react';
import { theme } from '@legacyApp/client/modules/style/theme';
import { RectangleLoader } from '../contentLoaders/RectangleLoader';

export const EmptyIcon = ({ size = 15, id, className }) => (
	<StyledEmptyIcon
		width={size}
		height={size}
		id={id}
		className={`${id} ${className}`}
	/>
);

const Icon = ({
	id,
	color = '',
	className = undefined,
	noColor = false,
	noPlaceholder = false,
	height = undefined,
	width = undefined,
	noIcon = undefined,
	props = undefined,
	fallbackImg = '',
	name = '',
}) => {
	// console.log('Icon', id, { color, className, noColor, noPlaceholder });
	if (!id) {
		return noPlaceholder ? (
			''
		) : (
			<EmptyIcon size={height} className={className} id={id} />
		);
	}

	const Component = dynamic(
		() =>
			import(`../../icons/${id.toLowerCase().replace(' ', '-')}`)
				.then((el) => {
					return (
						el ||
						(noPlaceholder
							? () => null
							: () => EmptyIcon({ id, className, size: height }))
					);
				})
				.catch(() => {
					// console.log('IconCatch', error);
					return () => {
						const name_ = name || id.split('/').pop();
						if (noIcon) {
							if (typeof noIcon === 'boolean') {
								return null;
							}
							return noIcon;
						}
						if (fallbackImg) {
							return <img src={fallbackImg} alt={name_} />;
						}
						return (
							<span className="text-style-xs-medium" data-src={id}>
								{name_}
							</span>
						);
					};
				}),
		{
			loading: noPlaceholder
				? () => null
				: () => EmptyIcon({ id, className, size: height }),
		},
	);
	// console.log('Icon - Component', {Component});
	// if (!isFunction(Component)) return <EmptyIcon/>;
	return (
		<Component
			style={{ height: '15px' }}
			height={height}
			width={width}
			className={className}
			color={color || (!noColor && theme.colors.active)}
			id={id}
			{...props}
		/>
	);
};

const StyledEmptyIcon = styled(RectangleLoader)`
	width: ${(props) => props.width || 15}px;
`;

EmptyIcon.propTypes = {
	size: PropTypes.number,
	id: PropTypes.string,
	className: PropTypes.string,
};

Icon.propTypes = {
	id: PropTypes.string.isRequired,
	color: PropTypes.string,
	className: PropTypes.string,
	noColor: PropTypes.bool,
	noPlaceholder: PropTypes.bool,
	height: PropTypes.number,
	width: PropTypes.number,
	noIcon: PropTypes.any,
	props: PropTypes.any,
	fallbackImg: PropTypes.string,
	name: PropTypes.string,
};

export default memo(Icon);
