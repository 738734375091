import Router from 'next/router';

import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { config } from '@legacyApp/client/config';
import { game_config } from '@legacyApp/client/game_config';
import { getBalanceNative } from '@legacyApp/client/modules/app/userService';
import { getMarginMultiplier } from '@legacyApp/client/modules/app/validateService';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { getLimits } from '@legacyApp/methods/currency/getLimits';
import { scientificToDecimal } from '@legacyApp/methods/math/scientificToDecimal';
import { getGamesList } from '@modules/games/Game/methods/getGamesList';

const getGameValueDecimal = (type, game) => {
	if (!game) {
		return config.decimals[type];
	}

	if (!game_config[game]) {
		console.warn(`Brak konfiguracji dla gry: ${game}`);
		return config.decimals[type];
	}

	return game_config[game][`${type}Decimal`]
		? game_config[game][`${type}Decimal`]
		: config.decimals[type];
};

export const getWinChanceDecimal = (game) => {
	return getGameValueDecimal('winChance', game);
};

export const getPayoutDecimal = (game) => {
	return getGameValueDecimal('payout', game);
};

export const getPayout = (winChance, game = 'dice') => {
	if (!game) {
		throw new Error(`game is undefined for ${winChance}`);
	}

	if (typeof game !== 'string') {
		sentryError(new Error('Invalid game format'), {
			game,
			winChance,
		});
		return false;
	}

	if (!game_config[game]) {
		throw new Error(`Invalid game in getPayout: ${game} / ${winChance}`);
	}

	if (!isTrueOrZero(game_config[game].minPayout)) {
		throw new Error(`No minimum payout value for game: ${game} / ${winChance}`);
	}

	if (!isTrueOrZero(game_config[game].maxPayout)) {
		throw new Error(`No maximum payout value for game: ${game} / ${winChance}`);
	}

	if (!winChance) {
		return winChance;
	}

	const decimal = getPayoutDecimal(game);

	const result = roundNumber(
		(100 / winChance) * getMarginMultiplier(game),
		decimal,
	);

	return result < game_config[game].minPayout
		? game_config[game].minPayout
		: result > game_config[game].maxPayout
		? game_config[game].maxPayout
		: result;
};

export const getProfit = (bet, payout) => {
	if (!bet) {
		return 0;
	}

	return roundNumber(
		parseFloat(bet) * (parseFloat(payout) - 1),
		config.decimals.balance,
	);
};

export const getWinChanceFromPayout = (payout, game) => {
	if (!game) {
		throw new Error('game is undefined');
	}

	if (!game_config[game]) {
		throw new Error(
			`Invalid game in getWinChanceFromPayout: ${game} / ${payout}`,
		);
	}

	if (!isTrueOrZero(game_config[game].minPayout)) {
		throw new Error(`No minimum payout value for game: ${game} / ${payout}`);
	}

	if (!isTrueOrZero(game_config[game].maxPayout)) {
		throw new Error(`No maximum payout value for game: ${game} / ${payout}`);
	}

	payout = mathService.parseValue(payout);

	const decimal = getWinChanceDecimal(game);

	if (roundNumber(payout, decimal) <= game_config[game].minPayout) {
		return config.games.maxWinChance;
	}

	if (payout >= game_config[game].maxPayout) {
		payout = game_config[game].maxPayout;
	}

	return roundNumber(
		(getMarginMultiplier(game) / mathService.parseValue(payout)) * 100,
		decimal,
	);
};

export const validateProfitAmount = ({ value, currency, game }) => {
	value = mathService.parseValue(value);

	const max = getLimits({ currency, game })?.profit_maximum;

	if (max && value && value > max) {
		return {
			error: {
				message: 'Exceeds max profit for this game',
				values: {
					value: max,
				},
			},
		};
	}

	return {
		error: null,
	};
};

export const getMinBetAmount = ({ currency, game }) => {
	return (
		getLimits({ currency, game })?.amount_minimum ||
		mathService.getMinValueByPrecision(config.decimals.bet)
	);
};

export const validateBetAmount = ({
	value,
	balances,
	isLogged,
	currency,
	checkBalance,
	game,
}) => {
	if (value === '') {
		return {
			error: 'Please, check your bet amount',
		};
	}

	const balance =
		balances && currency ? getBalanceNative(balances, currency) : false;

	const min = getMinBetAmount({ currency, game });

	if (!parseFloat(value) || (parseFloat(value) < min && value <= balance)) {
		return {
			error: {
				message: 'Amount must be greater than {{min}} {{currency}}',
				values: {
					min: scientificToDecimal(min),
					currency: currency ? currency.toUpperCase() : '',
				},
			},
		};
	}

	if (
		balances === undefined ||
		currency === undefined ||
		isLogged === undefined
	) {
		return {
			error: false,
		};
	}

	const max = getLimits({ currency, game })?.amount_maximum;

	if (max && value && parseFloat(value) > max) {
		return {
			error: 'Exceeds max bet amount for this game',
		};
	}

	if (isLogged && balance < min && checkBalance) {
		return {
			error: 'You have insufficient balance. Please deposit first',
		};
	}

	if (
		isLogged &&
		balance > min &&
		(!value || parseFloat(value) > balance) &&
		checkBalance
	) {
		return {
			error: {
				message: 'Amount exceeded your {{currency}} account balance',
				values: {
					currency: currency.toUpperCase(),
				},
			},
		};
	}

	return {
		error: false,
	};
};

export const isAmountReset = (value) => {
	return !value && parseFloat(value) !== 0;
};

export const validBetData = (payload) => {
	// TODO: NEW GAME FLAG
	if (payload.game === 'dice' && !payload.rule && !payload.bet_value) {
		return false;
	}

	if (payload.game === 'plinko' && !payload.risk && !payload.rows) {
		return false;
	}

	const isMultiplierCorrect =
		payload.game === 'plinko' ? true : payload.multiplier;
	return (
		payload &&
		isMultiplierCorrect &&
		payload.amount &&
		payload.game &&
		payload.currency
	);
};

export const getBetProfit = (state, amount, payout) => {
	if (state === 'loss') {
		return `-${amount}`;
	}

	return getProfit(amount, payout);
};

export const isGameSessionActive = (state, game) => {
	// TODO: NEW GAME FLAG
	if (game === 'hilo') {
		return state.hilo?.sessionActive;
	}

	return state.gameAutobet?.id[game];
};

export const mapGameBetResult = (game, bet) => {
	// TODO: NEW GAME FLAG
	if (game === 'limbo') {
		return `${bet.result_value}x`;
	}

	if (game === 'plinko') {
		return `${Number(bet.multiplier).toFixed(1)}x`;
	}

	return bet.result_value;
};

export const isEveryGame = (data, callback) => {
	if (!data) {
		if (callback) {
			return callback(false);
		}

		return false;
	}

	return Object.keys(data).every((game) =>
		callback ? callback(data[game]) : data[game],
	);
};

export const isInputActive = (id, state) => {
	if (!state.game?.activeInputs[id]) {
		return false;
	}

	if (state.game?.activeInputs[id].modal === undefined) {
		return state.game?.activeInputs[id].default;
	}

	return state.game?.activeInputs[id].modal;
};

export const getGameFromUrl = () => {
	const path =
		Router?.router?.asPath || (process.browser ? window.location.href : null);

	const game = getGamesList().find(
		(game) => path.split('?')[0].indexOf(game) > -1,
	);

	return game || null;
};
