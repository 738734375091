import { Button } from '@ui/button';
import { Chat } from '@icons/chat';
import { FC } from 'react';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { theme } from '@legacyApp/client/modules/style/theme';
import { useAppMobile } from '@common/selectors/app.selectors';
import { ChatToggleWrapper } from '../../chat/components/chatToggleWrapper/ChatToggleWrapper';

export const HeaderChat: FC = () => {
	const isMobile_ = useAppMobile();
	if (isMobile_) {
		return null;
	}
	return (
		<ChatToggleWrapper className={'x'}>
			<Button
				styleType={ButtonStyleType.SECONDARY}
				iconBefore={<Chat fill={theme.colors.text} />}
			/>
		</ChatToggleWrapper>
	);
};
