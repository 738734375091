import { FC } from 'react';
import { Dropdown, StyledDropdownButtonWrapper } from '@ui/dropdown';
import styled from 'styled-components';
import { CSSClassNameButton } from '@ui/button';
import { changeLanguage } from '@legacyApp/client/modules/translation/translationService';
import { languageConfig } from '@server/config/language.config';
import { Size } from '@uiTypes/Size';
import { useAppNoDefaultLanguage } from '@common/selectors/app.selectors';
import { FlagLang } from '@common/components/langPicker/flagLink/flagLang';

export interface LanguageType {
	id: string;
	name: string;
}

const { available } = languageConfig;

export const LangPicker: FC = () => {
	const current = useAppNoDefaultLanguage();
	const onClick = (element) => changeLanguage(element.id);
	if (!current) {
		return null;
	}
	return (
		<StyledLangPickerDropdown>
			<Dropdown
				list={available.map((lang) => {
					return {
						id: lang.id,
						content: <FlagLang key={lang.id} lang={lang} />,
					};
				})}
				popperOptions={{
					placement: 'top-start',
				}}
				size={Size.LARGE}
				defaultActiveId={current}
				onSelect={onClick}
			/>
		</StyledLangPickerDropdown>
	);
};

export const StyledLangPickerDropdown = styled.div<{ className?: string }>`
	width: 100%;

	${StyledDropdownButtonWrapper} {
		width: 100%;
	}

	.${CSSClassNameButton} {
		padding-left: 16px;
		width: 100%;
	}
`;
