import { combineReducers } from 'redux';
import { setUserReducerOld } from '../../modules/app/reducers/reducerService';
import { RESET_USER_DATA } from '../user/user.constants';
import {
	PRIVATE_MESSAGES_CHANNEL_ID,
	PRIVATE_MESSAGES_CHANNEL_READ,
	PRIVATE_MESSAGES_SET_ACTIVE_LIST,
	PRIVATE_MESSAGES_SET_HISTORY,
	PRIVATE_MESSAGES_SET_STREAM,
	PRIVATE_MESSAGES_SET_UNREAD,
} from './privateMessages.constants';

const historyReducer = setUserReducerOld([], PRIVATE_MESSAGES_SET_HISTORY);
const activeListReducer = setUserReducerOld(
	[],
	PRIVATE_MESSAGES_SET_ACTIVE_LIST,
);
const channelIdReducer = setUserReducerOld(false, PRIVATE_MESSAGES_CHANNEL_ID);

const streamReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case PRIVATE_MESSAGES_SET_STREAM:
			return {
				...state,
				[action.channel]: action.data,
			};
		case RESET_USER_DATA: {
			return {};
		}
		default:
			return state;
	}
};

const unreadReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case PRIVATE_MESSAGES_SET_UNREAD:
			return {
				...state,
				[action.channel]: action.nonce || true,
			};
		case PRIVATE_MESSAGES_CHANNEL_READ:
			if (state[action.channel]) {
				delete state[action.channel];
			}
			return {
				...state,
			};
		case RESET_USER_DATA: {
			return {};
		}
		default:
			return state;
	}
};

const privateMessagesReducer = combineReducers({
	history: historyReducer,
	activeList: activeListReducer,
	stream: streamReducer,
	channelId: channelIdReducer,
	unread: unreadReducer,
});

export { privateMessagesReducer };
