import styled from 'styled-components';

export const PaginationStyled = styled.div.attrs({
	className: 'pagination__box box-no-height',
})`
	display: flex;
	align-items: center;
	justify-content: center;

	.pagination__element {
		cursor: pointer;
		height: 44px;
		margin: 0 10px !important;
		padding: 0 15px !important;

		&.active {
			background-color: ${(props) => props.theme.colors.inputBackgroundActive};
			color: var(--color-white) !important;
			cursor: default;
		}

		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			margin: 0 7px !important;
			padding: 0 10px !important;
			height: 34px;
		}
	}
`;
