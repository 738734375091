import { actionSimple } from '@legacyApp/client/modules/app/actionService';
import { PayloadActionCall } from '@legacyApp/client/store/store.types';
import { checkBalancesArray } from '@legacyApp/client/modules/app/balanceService';
import {
	BALANCE_FETCH,
	BALANCE_SET_CONVERTED,
	SET_BALANCES_ARRAY,
	UPDATE_BALANCE,
} from './balance.constants';
import { BalanceReducerType } from './balance.reducer';
import { UserBalance } from './UserBalance.type';

export const setBalancesArray = (payload, source) => ({
	type: SET_BALANCES_ARRAY,
	payload: checkBalancesArray(source, payload),
});

export const updateBalance = (
	payload: UserBalance[] | UserBalance,
	source?: string,
) => ({
	type: UPDATE_BALANCE,
	payload,
	source,
});

export const balanceSetConverted: PayloadActionCall<
	BalanceReducerType['balanceConverted']
> = (payload) => {
	return {
		type: BALANCE_SET_CONVERTED,
		payload,
	};
};

export const balanceFetch = actionSimple(BALANCE_FETCH);
