import { jsonToString } from '@common/methods/jsonToString';
import { config } from '../../config';
import FetchService from './fetchService';

// const timeArray = [];

export const request = (url, options, fromState, token = {}) => {
	// const start = window.performance.now();
	// if (url.indexOf('auto/play') > -1) {
	let timeout = null;
	return new Promise((resolve, reject) => {
		if (!process.browser) {
			return false;
			// const method = options.method ? options.method : options.data ? 'POST' : 'GET';
			// const cancelTokenSource = axios.CancelToken.source();
			// if (!FetchService.isUrl(url, ['/flash/play'])) {
			// 	timeout = setTimeout(() => {
			// 		delete FetchService.state[url];
			// 		cancelTokenSource.cancel();
			// 		return resolve({
			// 			status: 0,
			// 			responseText: jsonToString({
			// 				error: 'Connection timeout, please check your internet connection',
			// 			}),
			// 		});
			// 	}, config.serverConnectionTimeout);
			// }
			// return axios({
			// 	...options,
			// 	method,
			// 	url,
			// 	cancelToken: cancelTokenSource.token
			// }).then(response => {
			// 	if (timeout) clearTimeout(timeout);
			// 	FetchService.state[url] = response;
			// 	FetchService.addFromStateExpire(url, fromState);
			// 	return resolve(response);
			// }).catch(error => {
			// 	delete FetchService.state[url];
			// 	console.error('axios - error', error);
			// 	if (timeout) clearTimeout(timeout);
			// 	return resolve({
			// 		error: {
			// 			...error
			// 		},
			// 		status: 0,
			// 		responseText: jsonToString({
			// 			error: 'Connection failed, please check your internet connection',
			// 		}),
			// 	});
			// });
		}

		// console.log(url, options);
		const xhr = new XMLHttpRequest();
		token.cancel = function () {
			// SPECIFY CANCELLATION
			delete FetchService.state[url];
			xhr.abort(); // abort request
			reject('canceled'); // reject the promise
		};
		if (!FetchService.isUrl(url, ['/flash/play'])) {
			timeout = setTimeout(() => {
				// sentryError(new Error(`Request abort - ${url}`), {
				//   url,
				//   options,
				//   fromState,
				//   xhr,
				// });
				delete FetchService.state[url];
				xhr.abort();
				resolve({
					xhr,
					status: 0,
					responseText: jsonToString({
						error: 'Connection timeout, please check your internet connection',
					}),
				});
			}, config.serverConnectionTimeout);
		}
		xhr.open(options.method, url);
		if (options.headers) {
			Object.keys(options.headers).forEach((key) =>
				xhr.setRequestHeader(key, options.headers[key]),
			);
		}
		// if (options.credentials) xhr.withCredentials = true;
		xhr.onload = () => {
			token.cancel = null;
			if (timeout) {
				clearTimeout(timeout);
			}
			FetchService.state[url] = xhr;
			FetchService.addFromStateExpire(url, fromState);
			// console.timeEnd('autobet-request-2-api');
			// const end = window.performance.now();
			// timeArray.push(end - start);
			// console.log(`autobet-request-2-api - ${end-start}ms / ${timeArray.reduce((previousValue, currentValue) => previousValue + currentValue) / timeArray.length}ms`);
			// console.time('autobet-request-3');
			// console.log('loaded', url, xhr.getAllResponseHeaders(), { xhr });
			// if (url.indexOf('auto/start') > -1) {
			// 	const data = '';
			// 	resolve({
			// 		responseText: data,
			// 		response: data,
			// 		status: null,
			// 	});
			// }
			resolve(xhr);
		};
		xhr.onerror = (error) => {
			token.cancel = null;
			delete FetchService.state[url];
			console.error('xhr - error', error, xhr);
			if (timeout) {
				clearTimeout(timeout);
			}
			resolve({
				xhr,
				error: {
					...error,
				},
				status: 0,
				responseText: jsonToString({
					error: `Connection failed, please check your internet connection - (${url})`,
				}),
			});
		};
		// console.log('options - body', url, options.body, options);
		xhr.send(options.body);
	});
	// }
	// return fetch(url, options).then(response => {
	//   // if (!NEXT_CONFIG.PRODUCTION) console.timeEnd(timeId);
	//   // if (url.indexOf('auto/play') > -1) {
	//   // console.timeEnd('autobet-request-2-api');
	//   // const end = window.performance.now();
	//   // timeArray.push(end - start);
	//   // console.log(`autobet-request-2-api - ${end-start}ms / ${timeArray.reduce((previousValue, currentValue) => previousValue + currentValue) / timeArray.length}ms`);
	//   // console.time('autobet-request-3');
	//   // }
	//   FetchService.state[url] = response;
	//   FetchService.addFromStateExpire(url, fromState);
	//   return response.clone();
	// }).catch(error => {
	//   // if (!NEXT_CONFIG.PRODUCTION) console.timeEnd(timeId);
	//   delete FetchService.state[url];
	//   throw error;
	// });
};
