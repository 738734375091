import { combineReducers } from 'redux';
import { RESET_USER_DATA } from '../user/user.constants';
import {
	ACTIVATE_2FA,
	SAVE_QRCODE,
	SAVE_SECRET,
	UNINSTALL_2FA,
} from './twoFactorAuthentication.constants';

const isActiveReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case ACTIVATE_2FA:
			return true;
		case UNINSTALL_2FA:
			return false;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const secretReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SAVE_SECRET:
			return action.payload;
		case UNINSTALL_2FA:
			return false;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const qrcodeReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SAVE_QRCODE:
			return action.payload;
		case UNINSTALL_2FA:
			return false;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const twoFactorAuthenticationReducer = combineReducers({
	isActive: isActiveReducer,
	secret: secretReducer,
	qrCode: qrcodeReducer,
});

export { twoFactorAuthenticationReducer };
