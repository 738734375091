import { actionSimple } from '../../modules/app/actionService';
import {
	GET_STATS_DATA,
	GLOBAL_STATS_GET_LAST_WEEK,
	GLOBAL_STATS_SET_LAST_WEEK_REGISTRATIONS,
	GLOBAL_STATS_SET_LAST_WEEK_WAGERED,
	HANDLE_STATS_UPDATE,
	SET_BETS_TOTAL,
	SET_LAST_HIGH_WIN,
	SET_ONLINE_USERS,
	SET_PRICE,
	SET_TIMESTAMP,
	SET_WON_LAST_24H,
	UPDATE_STATS_DATA,
} from './globalStatistics.constants';

export const getStatsData = (token) => {
	return {
		type: GET_STATS_DATA,
		token,
	};
};

export const handleStatsUpdate = actionSimple(HANDLE_STATS_UPDATE);

export const setPrice = actionSimple(SET_PRICE);

export const setBetsTotal = actionSimple(SET_BETS_TOTAL);

export const setOnlineUsers = actionSimple(SET_ONLINE_USERS);

export const setStatsTimestamp = (payload, key) => {
	return {
		type: SET_TIMESTAMP,
		payload,
		key,
	};
};

export const setWonLast24h = actionSimple(SET_WON_LAST_24H);

export const updateStatsData = actionSimple(UPDATE_STATS_DATA);

export const setLastHighWin = actionSimple(SET_LAST_HIGH_WIN);

export const globalStatsSetLastWeekWagered = actionSimple(
	GLOBAL_STATS_SET_LAST_WEEK_WAGERED,
);

export const globalStatsSetLastWeekRegistrations = actionSimple(
	GLOBAL_STATS_SET_LAST_WEEK_REGISTRATIONS,
);

export const globalStatsGetLastWeek = actionSimple(GLOBAL_STATS_GET_LAST_WEEK);
