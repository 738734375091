import { combineReducers } from 'redux';
import { RESET_USER_DATA } from '../user/user.constants';
import {
	NOTIFICATION_DISABLE,
	NOTIFICATION_ENABLE,
	SET_IS_ANY_NOTIFICATION,
} from './notification.constants';

const dataReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case NOTIFICATION_ENABLE:
			return {
				...state,
				[action.id]: true,
			};
		case NOTIFICATION_DISABLE:
			return {
				...state,
				[action.id]: false,
			};
		case RESET_USER_DATA:
			return {};
		default:
			return state;
	}
};

const isAnyReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_IS_ANY_NOTIFICATION:
			return action.payload;
		case RESET_USER_DATA:
			return false;
		default:
			return state;
	}
};

const notificationReducer = combineReducers({
	data: dataReducer,
	isAny: isAnyReducer,
});

export { notificationReducer };
