export const isWithdrawBlocked = (state) => {
	if (!state.user.email || (state.user.email && state.user.hasEmailToVerify)) {
		return true;
	}

	if (!state.twoFA.isActive) {
		return true;
	}

	if (state.user.withdrawBlockedTimestamp) {
		return true;
	}

	return false;
};
