import { useCallback, useState } from 'react';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { useFetchApi } from '@legacyApp/hooks/fetch/useFetchApi';
import { generateUrl, ROUTE } from '@common/routes';
import { handleCommands } from '@legacyApp/client/store/app/app.actions';
import { useInterval } from '@legacyApp/hooks/app/useInterval';
import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { AppCommandsType } from '@legacyApp/client/store/app/app.constants';
import { hour, minute } from '@legacyApp/client/modules/app/time';
import { VipBenefits } from '../constants/vip.config';
import { useVipLevel, useVipLoaded } from '../store/selectors/vip.selectors';
import { useVip } from './useVip';
import { getVipBenefitValue } from './useVipBenefitState';

export const useInsaneBonusCode = () => {
	const dispatch = useAppDispatch();
	const isLogged = useUserIsLogged();
	useVip();
	const level = useVipLevel();
	const loaded = useVipLoaded();
	const [lastCommand, setLastCommand] = useState<AppCommandsType>(null);
	const [existTimestamp, setExistTimestamp] = useState<number>(null);
	const { fetch } = useFetchApi();

	const validate = useCallback((): boolean => {
		return !!(
			isLogged &&
			(!existTimestamp || existTimestamp + hour <= getTimestamp()) &&
			loaded &&
			getVipBenefitValue(level, VipBenefits.INSANE_BONUS_CODE)
		);
	}, [existTimestamp, isLogged, level, loaded]);

	const check = useCallback(
		async (force?: boolean) => {
			if (!validate() && !force) {
				return;
			}
			const response: {
				command?: AppCommandsType;
			} = await fetch({
				url: generateUrl(ROUTE.apiCheckInsaneBonusCode),
			}).catch((error) => {
				console.log(error);
				return {
					command: null,
				};
			});
			if (response?.command) {
				setExistTimestamp(getTimestamp());
				dispatch(handleCommands(response.command));
			}
			setLastCommand(response?.command);
		},
		[dispatch, fetch, validate],
	);

	useInterval({
		callback: check,
		interval: validate ? 10 * minute : null,
		id: `${ROUTE.apiCheckInsaneBonusCode}_${level?.level}`,
	});

	return {
		check,
		lastCommand,
	};
};
