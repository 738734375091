import { IconProps } from '@icons/all-bets';
import { FC } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../hooks/useId';

export const Sport: FC<IconProps> = ({ fill, fillStop }) => {
	const { get } = useId({ id: 'sport' });
	const color = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M8 4.71875L4.88281 6.98438M8 4.71875L11.1172 6.98438M8 4.71875V3M4.88281 6.98438L6.07031 10.6562M4.88281 6.98438L3.24219 6.45312M6.07031 10.6562H9.92969M6.07031 10.6562L5.0625 12.0469M9.92969 10.6562L11.1172 6.98438M9.92969 10.6562L10.9375 12.0469M11.1172 6.98438L12.7578 6.45312M8 3L10.7344 1.01562M8 3L5.26562 1.01562M3.24219 6.45312L2.20312 3.24219M3.24219 6.45312L0.507812 8.44531M5.0625 12.0469H1.67969M5.0625 12.0469L6.10938 15.2578M10.9375 12.0469L9.89062 15.2578M10.9375 12.0469H14.3203M12.7578 6.45312L15.4922 8.44531M12.7578 6.45312L13.7969 3.24219M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
						stroke={get(0).url}
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<defs>
						<linearGradient
							id={get(0).id}
							x1="15.5"
							y1="0.5"
							x2="0.499999"
							y2="15.5"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor={fillStop || color} />
							<stop offset="1" stopColor={color} />
						</linearGradient>
					</defs>
				</>
			}
			width={16}
			height={16}
		/>
	);
};
