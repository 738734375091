export type LinesType = 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

export enum Risk {
	LOW = 'low',
	MEDIUM = 'medium',
	HIGH = 'high',
}

export type MultiplierValues = number;

type MultiplierLabelType = `floor||${MultiplierValues}||0||${number}`;

export type MultiplierType = {
	label: MultiplierLabelType;
	img: string;
	value: number;
	risk: Risk;
	lines: LinesType;
	payoutIndex: number;
};
