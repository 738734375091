import { combineReducers } from 'redux';
import { RESET_USER_DATA } from '../user/user.constants';
import {
	ADD_PENDING_ALERT,
	DELETE_ALERT,
	SHOW_ALERT,
	TOGGLE_SERVER_SEED_ALERT,
} from './alerts.constants';

const serverSeedReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case TOGGLE_SERVER_SEED_ALERT: {
			if (action.bool === undefined) {
				action.bool = !state;
			}
			return action.bool;
		}
		case RESET_USER_DATA:
			return false;
		default: {
			return state;
		}
	}
};

const listReducer = (
	state = [],
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SHOW_ALERT:
			if (!action.message) {
				return state;
			}
			return [
				{
					type: action.alertType,
					message: action.message,
					id: action.id,
				},
				...state,
			];
		case DELETE_ALERT:
			if (action.force) {
				return [];
			}
			return state.filter((el) => el.id !== action.id);
		default:
			return state;
	}
};

const pendingCloseReducer = (
	state = [],
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case ADD_PENDING_ALERT:
			return [...state, action.payload];
		default:
			return state;
	}
};

const alertsReducer = combineReducers({
	serverSeed: serverSeedReducer,
	list: listReducer,
	pendingClose: pendingCloseReducer,
});

export { alertsReducer };
