import React, { FC, PropsWithChildren, ReactElement, useMemo } from 'react';
// import styled from 'styled-components';
import { useAppSelector } from '../../../hooks/store/useAppSelector';
// import gif from './LoadingGifBase64';

const TRIGGERS = ['getUserData'];

const isLoaderActive = ({ appInitialized, modules }) => {
	if (!appInitialized) {
		return true;
	}
	if (!Object.keys(modules).length) {
		return false;
	}
	return !!TRIGGERS.find((el) => modules[el]);
};

export const UserLoadingWrapper: FC<
	PropsWithChildren<{
		loader: ReactElement;
	}>
> = ({ children, loader }) => {
	const { appInitialized, modules } = useAppSelector((state) => ({
		modules: state.loader.modules,
		appInitialized: state.app.initialized,
	}));

	const active = useMemo(() => {
		return isLoaderActive({ appInitialized, modules });
	}, [appInitialized, modules]);

	if (!active) {
		return <>{children}</>;
	}
	return loader;
};
