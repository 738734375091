import React, { ReactChildren, ReactElement } from 'react';
import { TranslateWrapper } from '@common/components/localization/Translate/Translate';
import { parseApiInfo } from '../app/errorsService';
import { OmitInterface } from '../../../types/default/OmitInterface';
import { TransNamespace } from './TransNamespace';
import { DEFAULT_NAMESPACE } from './translate.constants';
import { mapTransData } from './translationService';

export type TransOptions = Record<string, string | number>;

export type TransLabelType =
	| string
	| boolean
	| {
			message?: string;
			values?: TransOptions;
			props?: {
				translateKey?: string;
				children?: ReactChildren;
			};
	  };

export interface TransProps {
	label:
		| string
		| [TransLabelType, TransNamespace]
		| TransLabelType
		| TransProps;
	options?: TransOptions;
	components?: ReactElement;
	noTranslationCheck?: boolean;
	namespace?: TransNamespace;
}

export const trans = ({
	label,
	options = null,
	components = null,
	noTranslationCheck = null,
	namespace = DEFAULT_NAMESPACE,
}: TransProps) => {
	if (Array.isArray(label)) {
		[label, namespace] = label;
	} else if (typeof label === 'object') {
		if (!label) {
			return null;
		}
		if ('label' in label) {
			return trans(label);
		}
		if (!label?.message) {
			if (label?.props?.translateKey) {
				return (
					// @ts-expect-error invalid type
					<TranslateWrapper {...label.props} />
				);
			} // trans component saved to localstorage (ex autobet expert mode error)
			if (label?.props) {
				return label;
			}
			console.error('trans', { label });
			return null;
		}
	}
	if (typeof label !== 'string') {
		return parseApiInfo(label, noTranslationCheck, namespace);
	}
	return (
		<>
			<TranslateWrapper
				options={mapTransData(options)}
				components={components}
				translateKey={label}
				namespace={namespace}
				noTranslationCheck={noTranslationCheck}
			/>
		</>
	);
};
export const Trans = trans;

export const createNamespaceTrans =
	(namespace: TransProps['namespace']) =>
	(props: OmitInterface<TransProps, 'namespace'>) =>
		trans({
			...props,
			namespace,
		});

export const transUser = createNamespaceTrans(TransNamespace.USER);
export const TransUser = transUser;

export const transSports = createNamespaceTrans(TransNamespace.SPORTS);
export const TransSports = transSports;

export const transSlots = createNamespaceTrans(TransNamespace.SLOTS);
export const TransSlots = transSlots;

export const transValidation = createNamespaceTrans(TransNamespace.VALIDATION);

export const transChat = createNamespaceTrans(TransNamespace.CHAT);
export const transWallet = createNamespaceTrans(TransNamespace.WALLET);
export const TransVip = createNamespaceTrans(TransNamespace.VIP);
