import { combineReducers } from 'redux';
import { setReducer } from '@legacyApp/methods/store/setReducer';
import {
	GLOBAL_STATS_SET_LAST_WEEK_REGISTRATIONS,
	GLOBAL_STATS_SET_LAST_WEEK_WAGERED,
	SET_BETS_TOTAL,
	SET_LAST_HIGH_WIN,
	SET_ONLINE_USERS,
	SET_PRICE,
	SET_TIMESTAMP,
	SET_WON_LAST_24H,
} from './globalStatistics.constants';

const priceReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_PRICE:
			return action.payload;
		default:
			return state;
	}
};

const onlineReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_ONLINE_USERS:
			return action.payload;
		default:
			return state;
	}
};

const betsCounterReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_BETS_TOTAL:
			return action.payload;
		default:
			return state;
	}
};

const wonLast24hReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_WON_LAST_24H:
			return action.payload;
		default:
			return state;
	}
};

const timestampReducer = (
	state = {
		price: false,
		online: false,
		betsCounter: false,
		wonLast24h: false,
		lastHighWin: false,
	},
	action = {
		type: '',
	},
) => {
	let newState = {};
	switch (action.type) {
		case SET_TIMESTAMP:
			if (!action.key) {
				Object.keys(state).forEach((key) => {
					newState[key] = action.payload;
				});
				return newState;
			}
			return {
				...state,
				[action.key]: action.payload,
			};
		default:
			return state;
	}
};

const lastHighWinReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_LAST_HIGH_WIN:
			return action.payload;
		default:
			return state;
	}
};

const lastWeekWageredReducer = setReducer(
	0,
	GLOBAL_STATS_SET_LAST_WEEK_WAGERED,
);

const lastWeekRegistrationsReducer = setReducer(
	0,
	GLOBAL_STATS_SET_LAST_WEEK_REGISTRATIONS,
);

const globalStatisticsReducer = combineReducers({
	price: priceReducer,
	online: onlineReducer,
	betsCounter: betsCounterReducer,
	wonLast24h: wonLast24hReducer,
	lastHighWin: lastHighWinReducer,
	timestamp: timestampReducer,
	lastWeekWagered: lastWeekWageredReducer,
	lastWeekRegistrations: lastWeekRegistrationsReducer,
});

export { globalStatisticsReducer };
