import { FC } from 'react';
import { mapApiCurrencyToLayoutCurrency } from '@common/controllers/currency/currenciesSettings';
import {
	CurrencyTokenType,
	CurrencyType,
} from '../../../types/wallet/CurrencyType';
import { TokenSymbol } from './tokenSymbol';

export const CurrencySymbol: FC<{
	currency: CurrencyType | CurrencyTokenType;
	classes?: string;
}> = ({ currency, classes }) => {
	if (!currency) {
		return null;
	}
	return (
		<TokenSymbol
			classes={classes}
			token={mapApiCurrencyToLayoutCurrency(currency)}
		/>
	);
};
