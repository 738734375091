import { getVendorUserData } from '@common/methods/getVendorUserData';
import { SUPPORT_APP_KEY } from '@modules/support/contants/SUPPORT_APP_KEY';

export const SUPPORT_APP_ID = 'himmcmo9';

export const isSupportLoaded = () => {
	return window[SUPPORT_APP_KEY] && !window[SUPPORT_APP_KEY]?.q;
};

export const initSupport = async (state, callback) => {
	console.log('init', window[SUPPORT_APP_KEY]?.q, window[SUPPORT_APP_KEY]);
	if (isSupportLoaded()) {
		return;
	}
	const userData = getVendorUserData(state);
	await startSupport(userData, callback);
};

export const startSupport = async (userData, callback) => {
	if (!process.browser) {
		return;
	}
	// console.log(
	// 	'start',
	// 	userData,
	// 	window[SUPPORT_APP_KEY]?.q,
	// 	window[SUPPORT_APP_KEY],
	// );

	window['intercomSettings'] = {
		api_base: 'https://api-iam.intercom.io',
		app_id: SUPPORT_APP_ID,
		...userData,
	};
	const options = window['intercomSettings'];
	// const ic = window[SUPPORT_APP_KEY];
	// if (typeof ic === 'function') {
	// 	ic('reattach_activator');
	// 	ic('update', options);
	// } else {
	// 	const i = function () {
	// 		// eslint-disable-next-line prefer-rest-params
	// 		i.c(arguments);
	// 	};
	// 	i.q = [];
	// 	i.c = function (args) {
	// 		i.q.push(args);
	// 	};
	// 	window[SUPPORT_APP_KEY] = i;
	// }

	// console.log('startSupport', window[SUPPORT_APP_KEY]);

	// const url = `https://widget.intercom.io/widget/${APP_ID}`;
	// await fetchScript_(url, {
	// 	type: 'text/javascript',
	// 	callback: () => {
	console.log('loaded', window[SUPPORT_APP_KEY]);
	window && window[SUPPORT_APP_KEY] && window[SUPPORT_APP_KEY]('boot', options);
	// window &&
	// 	window[SUPPORT_APP_KEY] &&
	// 	window[SUPPORT_APP_KEY]('reattach_activator');
	// window &&
	// 	window[SUPPORT_APP_KEY] &&
	// 	window[SUPPORT_APP_KEY]('update', options);
	callback();
	// },
	// onerror: (error) => {
	// 	sentryError(new Error(`fetchScript error: ${url}`), {
	// 		error,
	// 		Intercom: window[SUPPORT_APP_KEY],
	// 	});
	// },
	// });
};

export const showSupport = () => {
	if (!process.browser) {
		return;
	}
	console.log('show', window[SUPPORT_APP_KEY]);
	window[SUPPORT_APP_KEY] && window[SUPPORT_APP_KEY]('show');
};

export const hideSupport = () => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] && window[SUPPORT_APP_KEY]('hide');
};

export const onShowSupport = (callback) => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] &&
		window[SUPPORT_APP_KEY]('onShow', function () {
			if (process.browser) {
				document.body.classList.add('support-open');
			}
			if (callback) {
				callback();
			}
		});
};

export const onHideSupport = (callback) => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] &&
		window[SUPPORT_APP_KEY]('onHide', function () {
			if (process.browser) {
				document.body.classList.remove('support-open');
			}
			if (callback) {
				callback();
			}
		});
};

export const onUnreadCountChange = (callback) => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] &&
		window[SUPPORT_APP_KEY]('onUnreadCountChange', (e) => {
			if (callback) {
				callback(e);
			}
		});
};

export const onError = (callback) => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] &&
		window[SUPPORT_APP_KEY]('onError', (response) => {
			if (callback) {
				callback(response);
			}
		});
};

export const showNewMessage = (message: string) => {
	if (!process.browser) {
		return;
	}
	window[SUPPORT_APP_KEY] && window[SUPPORT_APP_KEY]('showNewMessage', message);
};
