import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Chat = ({ fill }: IconProps) => {
	return Svg({
		content: (
			<>
				<path
					d="M9.35625 12.2437L8.43125 13.7875C8.38595 13.8612 8.32253 13.922 8.24703 13.9642C8.17154 14.0064 8.08649 14.0286 8 14.0286C7.91351 14.0286 7.82846 14.0064 7.75297 13.9642C7.67747 13.922 7.61405 13.8612 7.56875 13.7875L6.64375 12.2437C6.60024 12.1697 6.53815 12.1082 6.46362 12.0654C6.38908 12.0227 6.30468 12.0001 6.21875 12H2.5C2.36739 12 2.24021 11.9473 2.14645 11.8536C2.05268 11.7598 2 11.6326 2 11.5V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H13.5C13.6326 3 13.7598 3.05268 13.8536 3.14645C13.9473 3.24021 14 3.36739 14 3.5V11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H9.78125C9.69532 12.0001 9.61092 12.0227 9.53638 12.0654C9.46185 12.1082 9.39976 12.1697 9.35625 12.2437V12.2437Z"
					stroke={fill}
					strokeLinecap="round"
					fill="transparent"
					strokeLinejoin="round"
				/>
				<path
					d="M8 8.25C8.41421 8.25 8.75 7.91421 8.75 7.5C8.75 7.08579 8.41421 6.75 8 6.75C7.58579 6.75 7.25 7.08579 7.25 7.5C7.25 7.91421 7.58579 8.25 8 8.25Z"
					fill={fill}
				/>
				<path
					d="M5 8.25C5.41421 8.25 5.75 7.91421 5.75 7.5C5.75 7.08579 5.41421 6.75 5 6.75C4.58579 6.75 4.25 7.08579 4.25 7.5C4.25 7.91421 4.58579 8.25 5 8.25Z"
					fill={fill}
				/>
				<path
					d="M11 8.25C11.4142 8.25 11.75 7.91421 11.75 7.5C11.75 7.08579 11.4142 6.75 11 6.75C10.5858 6.75 10.25 7.08579 10.25 7.5C10.25 7.91421 10.5858 8.25 11 8.25Z"
					fill={fill}
				/>
			</>
		),
		width: 16,
		height: 16,
	});
};
