import {
	fetchLocal,
	parseProps,
} from '@legacyApp/client/store/fetch/fetch.thunk';
import { generateUrl, ROUTE } from '@common/routes';
import { checkIsNewFeatures } from '@legacyApp/methods/app/checkIsNewFeatures';
import {
	notificationDisable,
	notificationEnable,
} from '@legacyApp/client/store/notification/notification.actions';
import { NOTIFICATION_ID } from '@legacyApp/client/store/notification/notification.constants';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { minute } from '@legacyApp/client/modules/app/time';

export type NewFeaturesData = Array<{ description: string; date: string }>;

export type FetchNewFeaturesData = {
	data: NewFeaturesData;
};

export const getNewFeaturesThunk = (
	language: string,
): AppThunk<Promise<FetchNewFeaturesData>> => {
	return (dispatch) => {
		if (!language) {
			return Promise.resolve({ data: [] });
		}

		const data = {
			url: `${generateUrl(ROUTE.apiNewFeatures)}?locale=${language}`,
			fromState: 2 * minute,
		};

		return dispatch(fetchLocal(...parseProps(data)));
	};
};

export const handleGetNewFeaturesWithNotificationThunk = (
	onlyCheck: boolean,
	language: string,
): AppThunk => {
	return async (dispatch) => {
		const data = await dispatch(getNewFeaturesThunk(language));

		const isEnabled = checkIsNewFeatures(onlyCheck, data.data);

		dispatch(
			isEnabled
				? notificationEnable(NOTIFICATION_ID.NEW_FEATURES)
				: notificationDisable(NOTIFICATION_ID.NEW_FEATURES),
		);
	};
};
