import React, { FC } from 'react';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { VipIcon } from '@ui/vip-icon';
import { CircularProgressbarProps } from 'react-circular-progressbar/dist/types';
import { getLevelColor } from '@legacyApp/client/modules/style/mixins';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { useVipLevel, useVipStatus } from '../store/selectors/vip.selectors';
import { useNextLevel } from '../hooks/useNextLevel';

export const VipIconProgress: FC<{
	nextLevelIcon?: boolean;
	fulfilProgress?: boolean;
	progressbarProps?: Partial<CircularProgressbarProps>;
	size?: number;
}> = ({ nextLevelIcon, fulfilProgress, progressbarProps, size }) => {
	const { nextLevel } = useNextLevel();
	const level = useVipLevel();
	const status = useVipStatus();
	const _size = size || 56;

	const nextLevelThreshold = nextLevel?.threshold || status;
	const nextLevelLevel = nextLevel?.level || level?.level;

	return (
		<VipIconWrapperStyled $size={_size} $level={level?.level}>
			<CircularProgressbar
				strokeWidth={5}
				value={fulfilProgress ? nextLevelThreshold : status || 0}
				maxValue={nextLevelThreshold}
				{...progressbarProps}
			/>
			<VipIconProgressStyled>
				<VipIcon
					size={roundNumber(_size * 0.65, 0)}
					nonLevel
					level={nextLevelIcon ? nextLevelLevel : level.level}
				/>
			</VipIconProgressStyled>
		</VipIconWrapperStyled>
	);
};

export const VipIconProgressStyled = styled.div`
	align-items: center;
	background-color: var(--color-dark-500);
	border-radius: 100%;
	display: flex;
	height: calc(100% - 8px);
	justify-content: center;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 8px);
`;

export const VipIconWrapperStyled = styled.div<{
	$level?: number;
	$size?: number;
}>`
	align-items: center;
	border-radius: 100%;
	height: ${(props) => props.$size}px;
	position: relative;
	width: ${(props) => props.$size}px;

	.CircularProgressbar {
		&-trail {
			stroke: transparent;
		}

		&-path {
			stroke: ${(props) => getLevelColor(props.$level)};
		}
	}
`;
