import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { button } from '../../modules/style/mixins/button';
import { buttonSubmit } from '../../modules/style/mixins/buttonSubmit';

const getColorByType = (theme, props) => {
	if (theme && theme[0] === '#') {
		return theme;
	}
	if (['clear', 'border'].indexOf(theme) > -1) {
		return 'transparent';
	}
	if (theme === 'input') {
		theme = 'inputBackgroundSolid';
	}
	if (theme === 'info') {
		theme = 'inputBackgroundActive';
	}
	return props.theme.colors[theme] || props.theme.colors.inputButton;
};

export const buttonStyle = (props) => css`
	${props.noClasses
		? ''
		: button(getColorByType(props.localTheme?.type, props))}
	${props.localTheme && props.localTheme.type === 'border'
		? css`
				border: 1px solid;
		  `
		: ''}
   ${props.localTheme && props.localTheme.toLeft
		? css`
				margin-left: 0;
				margin-top: 10px;
		  `
		: ''}
   ${props.localTheme && props.localTheme.toRight
		? css`
				margin-right: 0;
				margin-top: 10px;
		  `
		: ''}
   ${props.localTheme && props.localTheme.active
		? css`
				color: ${props.theme.colors.active}!important;
		  `
		: ''}
   ${props.localTheme && props.localTheme.submit
		? css`
				${buttonSubmit(
					props.localTheme.submit === true
						? props.theme.colors.positive
						: getColorByType(props.localTheme.submit, props),
				)};

				@media (min-width: ${props.theme.media.minWidthDesktop}) {
					width: auto;
				}

				@media (max-width: ${props.theme.media.maxWidthMobile}) {
					padding: 10px 15px;
				}
		  `
		: ''};

	${props.localTheme && props.localTheme.type === 'disabled'
		? css`
				background-color: ${darken(0.2, props.theme.colors.inputButton)};
				color: var(--color-white-white-50);
		  `
		: ''}
	${props.localTheme && props.localTheme.small
		? css`
				padding-left: 15px !important;
				padding-right: 15px !important;
		  `
		: ''}
`;

export const ButtonStyle = styled.button`
	${buttonStyle}
`;
