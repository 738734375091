import { setReducer } from '@legacyApp/methods/store/setReducer';
import { AUTOBET_SET_MODE } from '@modules/games/Game/store/gameAutobet/gameAutobet.constants';
import { isFunction } from '@common/methods/isFunction';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { RESET_USER_DATA } from '../../../store/user/user.constants';
import { APP_INITIALIZED } from '../../../store/app/app.constants';
import transactionService from '../../../../../modules/transactions/transactionsService';
import {
	getSessionIdFromBet,
	getSessionInitObject,
} from '../gameSessionService';
import { getPerGameInitObject } from '../getPerGameInitObject';
import {
	TRANSACTIONS_SET,
	TRANSACTIONS_UPDATE,
} from '../../../store/transactions/transactions.constants';
import { DIRECTION } from '../sortService';
import { setPerGame } from './setPerGame';

export const incrementReducer = (
	name,
	incrementAction,
	resetAction,
	initAction,
) => {
	return (
		state = getSessionInitObject(0, name),
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				return getSessionInitObject(0, name);
			}
			case incrementAction:
				return {
					...state,
					[getSessionIdFromBet(action.payload)]:
						state[getSessionIdFromBet(action.payload)] + 1,
					total: state.total + 1,
				};
			case resetAction:
				return getSessionInitObject(0);
			default:
				return state;
		}
	};
};

export const addReducer = (name, addAction, resetAction, initAction) => {
	return (
		state = getSessionInitObject(0, name),
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				return getSessionInitObject(0, name);
			}
			case addAction:
				return {
					...state,
					[action.payload.game]: roundNumber(
						state[action.payload.game] + action.payload.value,
					),
					total: state.total + action.payload.value,
				};
			case resetAction:
				return getSessionInitObject(0);
			default:
				return state;
		}
	};
};

export const streakReducer = (
	name,
	addAction,
	oppositeAction,
	resetAction,
	isAuto,
	initAction,
) => {
	return (
		state = getSessionInitObject(0, name),
		action = {
			type: '',
		},
	) => {
		// {
		//   auto: true,
		//   value: 0.024324,
		//   game: 'dice',
		// }
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				return getSessionInitObject(0, name);
			}
			case addAction:
				if (isAuto && !action.payload.auto) {
					return state;
				}
				return {
					...state,
					[action.payload.game]: roundNumber(
						state[action.payload.game] + action.payload.value,
					),
					total: state.total + action.payload.value,
				};
			case oppositeAction:
				if (isAuto && !action.payload.auto) {
					return state;
				}
				return {
					...state,
					[action.payload.game]: 0,
					total: 0,
				};
			case resetAction:
				return getSessionInitObject(0);
			default:
				return state;
		}
	};
};

export const setWithResetReducer = (name, setAction, reset, initAction) => {
	return (
		state = getSessionInitObject(0, name),
		action = {
			type: '',
		},
	) => {
		// {
		//   auto: true,
		//   value: 0.024324,
		//   game: 'dice',
		// }
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				return getSessionInitObject(0, name);
			}
			case setAction:
				return {
					...state,
					[action.payload.game]: action.payload.value,
					total: action.payload.value,
				};
			case reset:
				return getSessionInitObject(0);
			default:
				return state;
		}
	};
};

export const setPerGameUserReducer = (
	initValue,
	setAction,
	parseValue,
	initAction,
) => {
	return (
		state = getPerGameInitObject(initValue, false, false, true),
		action = {
			type: '',
		},
	) => {
		if (action.type === RESET_USER_DATA) {
			return getPerGameInitObject(initValue, false, false, true, parseValue);
		}
		return setPerGame({
			state,
			action,
			initValue,
			setAction,
			parseValue,
			initAction,
		});
	};
};

export const setPerGameArrayUserReducer = (
	initValue,
	setAction,
	initAction,
) => {
	return (
		state = getPerGameInitObject(initValue, false, false, true),
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				return getPerGameInitObject(initValue, false, false, true);
			}
			case setAction:
				if (!action.payload.game) {
					const value =
						typeof action.payload === 'object'
							? action.payload.value
							: action.payload;
					return getPerGameInitObject(
						[...(value || initValue)],
						false,
						false,
						true,
					);
				}
				return {
					...state,
					[action.payload.game]: [...(action.payload.value || initValue)],
				};
			case RESET_USER_DATA:
				return getPerGameInitObject(initValue, false, false, true);
			default:
				return state;
		}
	};
};

export const setUserReducerOld = (
	init,
	setAction,
	appInitCallback,
	initAction,
) => {
	return (
		state = init,
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				if (setAction === AUTOBET_SET_MODE) {
					console.log(AUTOBET_SET_MODE, appInitCallback(state));
				}
				return isFunction(appInitCallback) ? appInitCallback(state) : state;
			}
			case setAction:
				return action.payload;
			case RESET_USER_DATA:
				return init;
			default:
				return state;
		}
	};
};

export const callbackUserReducer = (
	init,
	setAction,
	callback,
	appInitCallback,
	initAction,
) => {
	return (
		state = init,
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case initAction || APP_INITIALIZED: {
				return isFunction(appInitCallback) ? appInitCallback(state) : state;
			}
			case setAction:
				return callback({
					action,
					state,
				});
			case RESET_USER_DATA:
				return init;
			default:
				return state;
		}
	};
};

export const transactionReducer = (id, userData) => {
	return (
		state = [],
		action = {
			type: '',
		},
	) => {
		switch (action.type) {
			case TRANSACTIONS_SET: {
				if (action.id !== id) {
					return state;
				}
				return transactionService.sortTransactions(
					action.payload,
					DIRECTION.DESC,
				);
			}
			case TRANSACTIONS_UPDATE: {
				if (action.id !== id) {
					return state;
				}
				return transactionService.addNewToList({
					label: id,
					element: action.payload,
					list: state,
				});
			}
			case RESET_USER_DATA:
				if (!userData) {
					return state;
				}
				return [];
			default: {
				return state;
			}
		}
	};
};

export const staticReducer = (data) => {
	return (state = data) => state;
};

export { setReducer };
