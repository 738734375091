import React, { FC } from 'react';
import { StyledVipIcon } from '@ui/vip-icon/VipIcon.styled';
import {
	FirstRankTrophy,
	SecondRankTrophy,
	ThirdRankTrophy,
} from '@icons/race/rank-trophy';
import { Image } from '../../default/Image/Image';

export const RaceBadge: FC<{
	data?: {
		position?: number;
		date?: string;
	};
	size?: number;
}> = ({ data, size }) => {
	if (!data?.position) {
		return null;
	}

	return (
		<StyledVipIcon $size={size} className="race-image">
			{data.position === 1 ? (
				<FirstRankTrophy />
			) : data.position === 2 ? (
				<SecondRankTrophy />
			) : data.position === 3 ? (
				<ThirdRankTrophy />
			) : (
				<Image src={'/img/race/medal.svg'} alt={`${data.position}`} />
			)}
		</StyledVipIcon>
	);
};
