import { TableIdType } from '@modules/transactions/constants/TableId';
import { fetchTransactionsThunk } from '@legacyApp/client/store/transactions/thunks/fetchTransactions.thunk';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { getTransactions } from '../transactions.actions';

export interface GetTransactionsThunkProps {
	id: TableIdType;
	page?: number;
	disable?: boolean;
	refresh?: boolean;
	props?: {
		type?: string;
		refreshAlways?: boolean;
		id?: string;
	};
}

export const getTransactionsThunk = (
	props_: GetTransactionsThunkProps,
): AppThunk => {
	return (dispatch) => {
		const { id, page, disable, refresh, props } = props_;
		dispatch(getTransactions(id, page, disable, refresh, props));
		return dispatch(fetchTransactionsThunk(props_));
	};
};
