export const initPwaServiceWorker = () => {
	// Register service worker to control making site work offline
	if (process.browser) {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/service-worker.js', { scope: '/' })
				.then((registration) => {
					console.log('Service Worker registered successfully.', registration);
					// registration.pushManager.subscribe({userVisibleOnly: true});
				})
				.catch((error) => {
					console.error('Service Worker registration failed:', error);
				});
		}
	}
};

export const handlePwaPrompt = (onActive, onLoad, onInstall) => {
	if (process.browser) {
		let deferredPrompt;

		if ('onappinstalled' in window) {
			// Code to handle install event
			window.addEventListener('appinstalled', () => {
				// Log install to analytics
				onInstall();
			});
		}

		// Code to detect PWA mode
		window.addEventListener('DOMContentLoaded', () => {
			let pwaMode = false;
			if (navigator.standalone) {
				pwaMode = 'standalone-ios';
			}
			if (window.matchMedia('(display-mode: standalone)').matches) {
				pwaMode = 'standalone';
			}
			// Log launch display mode to analytics
			console.log('DISPLAY_MODE_LAUNCH:', pwaMode);
			onLoad(pwaMode);
		});

		if ('onbeforeinstallprompt' in window) {
			// Code to handle install prompt on desktop
			window.addEventListener('beforeinstallprompt', (e) => {
				// Prevent Chrome 67 and earlier from automatically showing the prompt
				console.log('beforeinstallprompt', e);
				e.preventDefault();
				// Stash the event so it can be triggered later.
				deferredPrompt = e;
				// Update UI to notify the user they can add to home screen
				onActive((onAccept, onDecline) => {
					onInstall = onAccept;
					// Show the prompt
					deferredPrompt.prompt();
					// Wait for the user to respond to the prompt
					deferredPrompt.userChoice.then((choiceResult) => {
						if (choiceResult.outcome === 'accepted') {
							console.log('User accepted the A2HS prompt');
							onAccept();
						} else {
							console.log('User dismissed the A2HS prompt');
							onDecline();
						}
						deferredPrompt = null;
					});
				});
			});
		}
	}
};
