import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { PackageType } from '@legacyApp/client/store/registry/registry.constants';

export const useRegistryPackageLoaded = (module: PackageType) =>
	useAppSelector<boolean>(
		(state) =>
			state?.registry?.packages?.[module]
				? Object.keys(state.registry.packages[module]).every(Boolean)
				: false,
		shallowEqual,
	);
