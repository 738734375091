import { servicesConfig } from '@server/config/services.config';
import { getCaptcha } from './getCaptcha';

export const executeCaptcha = async (action) => {
	const captcha = await getCaptcha();
	if (!captcha) {
		return false;
	}
	return captcha
		.execute(servicesConfig.captchaSiteKey, {
			action,
		})
		.catch((error) => {
			console.error('grecaptcha.execute - error', error, {
				key: servicesConfig.captchaSiteKey,
				action,
			});
			return false;
		});
};
