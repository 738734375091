import { visibleSlice } from './slices/visible.slice';
import { roomSlice } from './slices/room.slice';
import { streamSlice } from './slices/stream.slice';
import { usersSlice } from './slices/users.slice';
import { messageSlice } from './slices/message.slice';
import { widthSlice } from './slices/width.slice';
import { contentTypeSlice } from './slices/contentType.slice';
import { privateActiveSlice } from './slices/privateActive.slice';
import { disabledSlice } from './slices/disabled.slice';

export const chatActions = {
	...visibleSlice.actions,
	...roomSlice.actions,
	...streamSlice.actions,
	...usersSlice.actions,
	...messageSlice.actions,
	...widthSlice.actions,
	...contentTypeSlice.actions,
	...privateActiveSlice.actions,
	...disabledSlice.actions,
};
