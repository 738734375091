import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useModalId } from '@modules/modals/store/modal.selectors';
import { closeModal } from '@modules/modals/store/modal.actions';
import { supportToggleThunk } from '@modules/support/store/thunks/toggleSupport.thunk';

export const useSupportToggle = () => {
	const dispatch = useDispatch();
	const modalId = useModalId();
	const toggle = useCallback(
		(isOpen?: boolean) => {
			if (modalId) {
				dispatch(closeModal());
			}
			dispatch(supportToggleThunk(isOpen));
		},
		[dispatch, modalId],
	);
	return {
		toggle,
	};
};
