import { createContext, FC, PropsWithChildren } from 'react';
import { CSP_PAGE_ID } from '@common/constants/config/csp';
import routingService from '../../../LegacyApp/client/modules/app/routingService';

export interface PageConfigContextProps {
	linksWithHardRedirect?: boolean;
}

export const PageConfigContext = createContext<PageConfigContextProps>({
	linksWithHardRedirect: null,
});

export const isPageStatic = (id: string) =>
	CSP_PAGE_ID.indexOf(id) > -1 || routingService.getRouteById(id)?.static;
export const PageConfigProvider: FC<
	PropsWithChildren<{
		id: string;
	}>
> = ({ children, id }) => {
	const linksWithHardRedirect = isPageStatic(id);
	return (
		<PageConfigContext.Provider
			value={{
				linksWithHardRedirect,
			}}
		>
			{children}
		</PageConfigContext.Provider>
	);
};
