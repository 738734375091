import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { DeveloperData } from '@common/components/default/DeveloperData/DeveloperData';
import {
	getComponentErrorName,
	handleComponentError,
} from '../store/app/app.actions';

const enhanceWithErrorHandling = (props) => {
	const keys = Object.keys(props);
	const isUpdated =
		keys.length === 1 &&
		!['displayName', 'propTypes'].some((el) => keys.indexOf(el) > -1);
	const componentName = isUpdated ? keys[0] : props.displayName || props.name;
	const WrappedComponent = isUpdated ? props[componentName] : props;

	// if (!isUpdated) console.log('enhanceWithErrorHandling', {
	// 	keys,
	//   componentName,
	//   WrappedComponent,
	//   props,
	// });

	class EnhancedComponent extends React.PureComponent {
		static propTypes = {
			handleComponentError: PropTypes.func,
		};

		state = {
			isError: false,
		};

		componentDidCatch(error, info) {
			this.props.handleComponentError({
				instance: this,
				error,
				info,
			});
			this.setState({
				error: {
					instance: this,
					error,
					info,
				},
			});
		}

		render() {
			if (this.state.isError) {
				return (
					<DeveloperData
						title={getComponentErrorName(
							`Component: ${this.state.error?.instance} - is broken`,
						)}
						data={this.state.error}
					/>
				);
			}
			return <WrappedComponent {...this.props} />;
		}
	}

	EnhancedComponent.displayName = `errorIn(${componentName})`;

	const mapDispatchToProps = (dispatch) => {
		return {
			handleComponentError: (data) => dispatch(handleComponentError(data)),
		};
	};

	return connect(null, mapDispatchToProps)(EnhancedComponent);
};

export default enhanceWithErrorHandling;
