import { useCallback, useRef } from 'react';
import { useOnMount } from '@common/hooks/useOnMount';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import {
	fetchApi,
	fetchLocal,
	parseProps,
} from '@legacyApp/client/store/fetch/fetch.thunk';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';

export interface FetchProps {
	url: string;
	method?: string;
	disableErrorHandler?: boolean;
	loaderId?: LOADING_IDS | string;
	fromState?: boolean | number;
	lockByModal?: boolean;
	twoFactorAuth?: boolean;
	parameters?: {
		headers?: Record<string, string>;
		Authorization?: boolean;
		body?: Record<string, any>;
		method?: string;
	};
}

export type FetchResponse<T extends {}> = T & {
	status: number;
	errors?: Record<string, Array<string>>;
	error?: string | Record<string, string | Record<string, string>>;
	message?: string;
	text?: string;
};

type Fetch = <T = any>(props: FetchProps) => Promise<FetchResponse<T>>;

export const useFetchApiPromise = (): Fetch => {
	const dispatch = useAppDispatch();
	const token = useRef({ cancel: null });

	const fetch = useCallback(
		(data) => dispatch(fetchApi({ ...data, token: token.current })),
		[dispatch],
	);

	useOnMount(() => () => {
		// console.log('useFetchApi CANCEL');
		if (token.current.cancel) {
			token.current.cancel();
		}
	});

	return fetch;
};

export const useFetchLocalApiPromise = (): Fetch => {
	const dispatch = useAppDispatch();
	const token = useRef({ cancel: null });

	const fetch = useCallback(
		(data) =>
			dispatch(fetchLocal(...parseProps({ ...data, token: token.current }))),
		[dispatch],
	);

	useOnMount(() => () => {
		// console.log('useFetchApi CANCEL');
		if (token.current.cancel) {
			token.current.cancel();
		}
	});

	return fetch;
};

export const useFetchApi = (): {
	fetch: Fetch;
	fetchLocal: Fetch;
	cancel: () => void;
	cancelLocal: () => void;
} => {
	const dispatch = useAppDispatch();
	const token = useRef({ cancel: null });
	const tokenLocal = useRef({ cancel: null });

	const fetch = useCallback(
		(data) => {
			return dispatch(fetchApi({ ...data, token: token.current }));
		},
		[dispatch],
	);
	const fetchLocal_ = useCallback(
		(data) => {
			return dispatch(fetchLocal(...parseProps(data)));
		},
		[dispatch],
	);

	const cancel = token?.current?.cancel || ((value) => value);
	const cancelLocal = tokenLocal?.current?.cancel || ((value) => value);

	useOnMount(() => () => {
		// console.log('useFetchApi CANCEL');
		cancel();
		cancelLocal();
	});

	return { fetch, cancel, fetchLocal: fetchLocal_, cancelLocal };
};
