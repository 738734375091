import { GameType } from '@modules/games/Game/constants/availableGames';
import { OptionsType } from '@legacyApp/client/store/options/options.constants';
import { NEW_MY_BET } from '@legacyApp/client/store/transactions/transactions.constants';

export const LocalStorageKeys = {
	CURRENCIES_SETTINGS: 'currencies_settings',
	CURRENCIES_SETTINGS_TOKENS: 'currencies_settings_tokens',
	CURRENCIES_SETTINGS_LIST: 'currencies_settings_list',
	WOL_REF: 'wol-ref',
	INCORRECT_WIN_CHANCE: 'incorrect_win_chance',
	OPEN_WELCOME_MODAL_THUNK: 'openWelcomeModalThunk',
	AUTOBET_MODE: 'autobetMode',
	MOBILE_DEVICE: 'mobile_device',
	LANGUAGE: 'lang',
	ACTIVE_CURRENCY: 'activeCurrency',
	DAILY_STREAK_TIMESTAMP: 'dailyStreakTimestamp',
	STATS: 'stats',
	OPTIONS: 'options',
	DISABLE_STATS: 'disableStats',
	DISABLE_SOUND: 'disableSound',
	DISABLE_MAX_BUTTON: 'disableMaxButton',
	DISABLE_HOTKEYS: 'disableHotkeys',
	DISABLE_CHART: 'disableChart',
	DISABLE_AUTOBET_SUMMARY: 'disableAutobetSummary',
	DISABLE_ANIMATIONS: 'disableAnimations',
	TOKEN: 'token',
	SEED_HASHED_LIST: 'wol_ssh',
	REMIND_TFA_COUNTER: 'tfc',
	LAST_REGISTER_TIMESTAMP: 'lrt',
	MISSING_TRANSLATIONS: 'missing_translation',
	VERSION_TIMESTAMP: 'versionTimestamp',
	NEW_MY_BETS: NEW_MY_BET,
	NEW_FEATURES_TIMESTAMP: 'new_features',
	CHAT_SHARE_DATA: 'share-data',
	CHAT_VISIBLE: 'chat-visible',
	CHAT_ROOM: 'chatRoom',
	EXPERT_CONFIRMED: 'expertConfirmed',
	DICE_BET_VALUE_DEFAULT: 'store.dice.betValue',
	AUTO_SESSIONS: 'autoSessions',
	MODAL_PROPS: 'modalProps',
	IS_LOW_PERFORMANCE: 'is-low-performance',
	SIDE_MENU_IS_OPEN: 'side-menu-is-open',
	SLOT_MODE: 'slotMode',
	SLOT_LOADING_GAME: 'slotLoadingGame',
	SPORTS_BETSLIP_MULTI_STAKE: 'sportsBetSlipMultiStake',
	SPORTS_BET_SLIP_BETS_DATA: 'sportsBetSlipBetsData',
	SPORTS_BET_SLIP_LIST: 'sportsBetSlipBetsList',
	MY_BETS_COPY_CLEARED: 'myBetsCopyCleared',
	MY_BETS_COPY: 'myBetsCopy',
	ADDRESS_BOOK_WALLET_USE: 'address-book-wallet-use',
	ADDRESS_BOOK_WALLET_USE_SELECTOR: 'address-book-wallet-use-selector',
	WALLET_MODAL_VAULT_CONTENT_TYPE: 'wallet-modal-vault-content-type',
	I18NEXT_LNG: 'i18nextLng',
	SPORTS_SIDE_CONTENT: 'sportsSideContent',
	WOLF_DEV_VIEW: 'wolf-dev-view',
	LOCATION_DATA: 'lodta',
	CURRENCY_CONVERTER: 'currencyConverter',
	ENABLE_CONVERTER: 'enableConverter',
	SPORTS_BET_SLIP_TYPE: 'sportsBetSlipType',
} as const;

type LocalStorageKeysKeys = keyof typeof LocalStorageKeys;
export type LocalStorageKeyType =
	(typeof LocalStorageKeys)[LocalStorageKeysKeys];

export const generateInitialStateKey = (
	name: string,
	key: string,
): `${string}_${string}_initial_state` => {
	return `${name}_${key}_initial_state`;
};

export const generateGameStrategyKey = (
	game: GameType,
): `strategy-${GameType}` => {
	return `strategy-${game}`;
};

export const generateConfirmOptionKey = (
	id: OptionsType,
): `confirm-${OptionsType}` => {
	return `confirm-${id}`;
};

export const generateCheckPathsKey = (
	sessionKey: string,
): `${string}:checkPaths` => {
	return `${sessionKey}:checkPaths`;
};

export const generateCheckPathsTimestampKey = (
	key: ReturnType<typeof generateCheckPathsKey>,
): `${ReturnType<typeof generateCheckPathsKey>}:timestamp` => {
	return `${key}:timestamp`;
};

export const generateCheckPathsCheckEnabledKey = (
	key: ReturnType<typeof generateCheckPathsKey>,
): `${ReturnType<typeof generateCheckPathsKey>}:checkEnabled` => {
	return `${key}:checkEnabled`;
};

export type LocalStorageKeysType =
	| LocalStorageKeyType
	| ReturnType<typeof generateInitialStateKey>
	| ReturnType<typeof generateGameStrategyKey>
	| ReturnType<typeof generateConfirmOptionKey>
	| ReturnType<typeof generateCheckPathsKey>
	| ReturnType<typeof generateCheckPathsTimestampKey>
	| ReturnType<typeof generateCheckPathsCheckEnabledKey>;
