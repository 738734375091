import { Loadable } from '../loader/loadable/loadable';

export const InfoModalContainer = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "infoModal.container" */ './infoModal.container'
			),
		ssr: false,
	},
	'./infoModal.container',
);
