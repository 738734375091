import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { stringToJson } from '@common/methods/stringToJson';
import { codeRequired } from '../twoFactorAuthentication/twoFactorAuthentication.actions';
import { config } from '../../config';
import {
	getStringApiInfo,
	parseApiInfo,
} from '../../modules/app/errorsService';
import { userSessionEnded } from '../user/user.actions';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import { sentryError } from '../../modules/app/sentryService';
import { getTimestamp } from '../../modules/app/timeService';
import routingService from '../../modules/app/routingService';
import { cloudflareError } from './methods/cloudflareError';
import { responseModal } from './methods/responseModal';

export const handleErrorsAction = (
	data,
	url,
	parameters,
	method,
	loaderId,
	isStatic,
	disable,
) => {
	return (dispatch) => {
		// console.log('handleErrorsAction', {
		//   data, url, parameters, method, loaderId, disable, isStatic
		// });
		if (parameters && parameters.body) {
			let body = {};
			try {
				body =
					typeof parameters.body === 'string'
						? stringToJson(parameters.body, {})
						: parameters.body;
			} catch (error) {
				// logger(parameters.body);
				throw new Error(error);
			}
			parameters.body = body;
		}
		const requestData = {
			message: `Response from (${loaderId}): ${method} -> '${url}'`,
			data,
			parameters,
			url,
			errorTimestamp: getTimestamp(),
		};
		return new Promise((resolve) => {
			const result = [];
			if (
				data.status &&
				data.text &&
				data.text.indexOf('403 Forbidden') > -1 &&
				data.status === 403
			) {
				routingService.redirect(`/${ROUTING_ID.ACCESS_DENIED}`, true, true);
				return resolve(false);
			}
			if (data.error) {
				if (data.error === config.errors.twoFactorAuth && !isStatic) {
					return dispatch(
						codeRequired(url, parameters, method, resolve, loaderId),
					);
				}
				if (data.error && data.error === config.errors.sessionEnded) {
					result.push(userSessionEnded());
				} else if (!disable) {
					result.push(dispatchError(data.error, requestData, isStatic));
				}
			}
			if (
				data.status &&
				data.text &&
				(data.text.indexOf('Cloudflare') > -1 ||
					data.text.indexOf('cloudflare') > -1) &&
				data.status !== 502
			) {
				dispatch(cloudflareError(data, requestData));
				return resolve(false);
			}
			if (!disable || data.text) {
				if (
					!data ||
					(data &&
						(Object.keys(data).length === 1 ||
							(Object.keys(data).length === 2 && data.text)) &&
						data.status &&
						data.status !== 200)
				) {
					if (!data) {
						data = {};
					}
					// sentryError(new Error(`${url} - ${message}`), requestData);
					let errorDetails = `${
						loaderId ||
						url.replace(`${config.domain}/api`, '').replace('https://', '')
					}/${data.status || '-'}`;
					let error = trans({
						label:
							'Connection problem, please refresh page or contact support ({{errorDetails}})',
						options: {
							errorDetails,
						},
					});
					if (data.status) {
						if (data.status === 502 && method === 'POST') {
							error = trans({
								label: 'Temporary server downtime, please try again in 10min',
								options: {
									errorDetails,
								},
							});
						}
						if (data.status === 'failed') {
							error = trans({
								label: 'Connection problem, please try again in 10min',
								options: {
									errorDetails,
								},
							});
						}
					}
					result.push(showAlert('error', error));
				}
				if (data?.message && !data?.room) {
					const type =
						data.message.toLowerCase().indexOf('success') > -1
							? 'success'
							: 'info';
					result.push(showAlert(type, parseApiInfo(data.message), isStatic));
				}
				if (data?.errors) {
					// sentryError(new Error(`${url} - Multiple errors`), requestData);
					result.push(dispatchError(data.errors, requestData, isStatic));
				}
				if (data?.modalInfo) {
					result.push(responseModal(data, requestData));
				}
			}
			if (result?.length) {
				dispatch(result);
			}
			return resolve(data);
		});
	};
};

export const dispatchError = (data, requestData, isStatic) => {
	// console.log('dispatchError', data);
	return (dispatch, getState) => {
		if (!data) {
			return;
		}
		const result = [];
		if (typeof data === 'object' && !data.length && !data.message) {
			const keys = Object.keys(data);
			if (keys) {
				keys.forEach((el) => {
					result.push(dispatchError(data[el], requestData, isStatic));
				});
			}
		} else if (typeof data === 'object' && data.length) {
			result.push(dispatchError(data[0], requestData, isStatic));
		} else {
			const dataString = getStringApiInfo(data);
			const replaceErrors = config.replaceErrorsToSentry;
			const replaceErrorKeys = Object.keys(replaceErrors);
			const replaceErrorIndex = replaceErrorKeys.findIndex((errorKey) => {
				return dataString.indexOf(errorKey) === 0;
			});
			if (replaceErrorIndex > -1) {
				// sentryError(new Error(`Replaced error: ${requestData.url} | ${dataString}`), requestData, getState());
				result.push(
					showAlert(
						'error',
						parseApiInfo(replaceErrors[replaceErrorKeys[replaceErrorIndex]]),
						isStatic,
					),
				);
			} else {
				const errorsArray = config.responseErrorsToSentry;
				const errorIndex = errorsArray.indexOf(dataString);
				if (errorIndex > -1) {
					sentryError(
						new Error(`${requestData.url} | ${errorsArray[errorIndex]}`),
						requestData,
						getState(),
					);
				}
				result.push(showAlert('error', parseApiInfo(data), isStatic));
			}
		}
		if (result.length) {
			dispatch(result);
		}
	};
};
