import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { NOTIFICATION_ID } from '../../store/notification/notification.constants';
import { checkMobileDevice } from './appService';

const config = {
	header: 'all',
	[MODAL_ID.NEW_FEATURES]: [NOTIFICATION_ID.NEW_FEATURES],
	[ROUTING_ID.USER_SETTINGS]: [
		NOTIFICATION_ID.HAS_EMAIL_TO_VERIFY,
		NOTIFICATION_ID.HAS_PASSWORD_TO_SET,
		NOTIFICATION_ID.HAS_KYC_TO_VERIFY,
	],
	[ROUTING_ID.USER_VIP]: [
		NOTIFICATION_ID.HAS_BONUS_CODE,
		NOTIFICATION_ID.HAS_VIP_WHEEL_TO_SPIN,
	],
};

export const isNotificationTypeActive = (type, data) => {
	// console.log('isNotificationTypeActive', {
	//   type,
	//   data,
	//   config,
	// });
	if (!config[type]) {
		return false;
	}
	return config[type].some((el) => data[el]);
};

export const hasEmailToVerify = (state) => {
	return state.user.hasEmailToVerify;
};

export const hasKycToVerify = (state) => {
	return state.user.hasKycToVerify;
};

export const hasPasswordToSet = (state) => {
	return !checkMobileDevice() && state.user.hasPasswordToSet;
};
