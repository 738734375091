import { TableIdType } from '@modules/transactions/constants/TableId';
import { capitalize } from '../../../modules/app/appService';

export enum TableQueryPropertyType {
	PAGE = 'page',
}

export const createTableQueryPropertyKey = (
	tableId: TableIdType,
	type: TableQueryPropertyType,
) => {
	return `table${capitalize(tableId)}${capitalize(type)}`;
};

export const createTableQueryPropertyKeyPage = (tableId: TableIdType) => {
	return createTableQueryPropertyKey(tableId, TableQueryPropertyType.PAGE);
};
