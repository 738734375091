import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AwesomeIcon } from '../icons/AwesomeIcon';

export const UserIcon = () => {
	return (
		<div className={'game-icon'}>
			<AwesomeIcon icon={faUser} />
		</div>
	);
};
