import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { updateBalance } from '@modules/balance/store/balance.actions';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { resetCampaignForm } from '../forms/forms.actions';
import { config } from '../../config';
import { appGoTo } from '../app/app.actions';
import { showAlert } from '../alerts/alerts.actions';
import { trans } from '../../modules/translation/translate';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import { getTransactionsThunk } from '../transactions/thunks/getTransactions.thunk';
import {
	SUBMIT_CAMPAIGN,
	WITHDRAW_AVAILABLE_COMMISSIONS,
} from './affiliate.constants';

const createCampaignEpic = epic('createCampaignEpic', {
	actions: (ofType) => ofType(SUBMIT_CAMPAIGN),
	callback: ({ store$ }) => {
		return of(
			fetchApiAction(
				{
					url: '/user/affiliate/create-campaign',
					parameters: {
						Authorization: true,
						body: {
							...store$.value.forms.campaign,
						},
					},
					method: 'POST',
					loadingId: 'createCampaign',
					lockByModal: true,
				},
				(data) => {
					if (data.name) {
						return [
							resetCampaignForm(),
							getTransactionsThunk({
								id: TABLE_ID.affiliateCampaigns,
								refresh: true,
							}),
							getTransactionsThunk({
								id: TABLE_ID.affiliateAnalytics,
								refresh: true,
							}),
							showAlert(
								'success',
								trans({
									label: 'Campaign {{name}} created',
									options: {
										name: data.name,
									},
								}),
							),
							appGoTo(`${ROUTING_ID.USER_AFFILIATE}?tab=campaigns`),
						];
					}
					return [];
				},
			),
		);
	},
});

const withdrawEpic = epic('withdrawEpic', {
	actions: (ofType) => ofType(WITHDRAW_AVAILABLE_COMMISSIONS),
	callback: ({ store$ }) => {
		if (
			!store$.value.transactions[TABLE_ID.affiliateFunds].filter((el) =>
				parseFloat(parseFloat(el.available).toFixed(config.decimals.bet)),
			).length
		) {
			return of(showAlert('error', 'No available commission funds to cashout'));
		}
		return of(
			fetchApiAction(
				{
					url: '/user/affiliate/withdraw',
					parameters: {
						Authorization: true,
					},
					method: 'POST',
					loaderId: 'withdrawAvailableCommissions',
					lockByModal: true,
				},
				(data) => {
					return [
						getTransactionsThunk({
							id: TABLE_ID.affiliateFunds,
							refresh: true,
						}),
						...(data.balance
							? [
									updateBalance(
										data.userBalances,
										'withdrawAvailableCommissions',
									),
							  ]
							: []),
					];
				},
			),
		);
	},
});

const affiliateEpic = combineEpics(createCampaignEpic, withdrawEpic);

export { affiliateEpic };
