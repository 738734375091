import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { getClientSize } from '@common/methods/getClientSize';
import { RootState } from '@legacyApp/client/store/configure.store';
import { hmac_sha256 } from '@legacyApp/client/modules/app/verificationServcie';
import { UpdateUserSupportProps } from '@modules/support/methods/updateUserSupport';

export interface VendorUserData {
	user_id?: string;
	name?: string;
	email?: string;
	created_at?: string;
	vip?: number;
}

export const getVendorUserData = (
	state: RootState,
	data?: VendorUserData,
): UpdateUserSupportProps => {
	const user_id = data?.user_id || state.user.id;
	if (!user_id) {
		return null;
	}
	const { width, height } = getClientSize();
	const user_hash = hmac_sha256(
		user_id,
		'_Pt_7AgfVcParKjoU19EW_sr28WZD5elO5VEKE9W',
	);
	return {
		name: data?.name || state.user.name,
		email: data?.email || state.user.email,
		user_id,
		user_hash,
		created_at: data?.created_at || state.user.joinedDate,
		custom_data: {
			vip: data?.vip || state.vip.level.level,
			// @ts-expect-error invalid type
			appversion: `${NEXT_CONFIG.GIT_INFO?.branch || ''}_${
				NEXT_CONFIG.RELEASE_VERSION
			}`,
			browser_dimension: process.browser ? `${width}x${height}` : '',
		},
	};
};
