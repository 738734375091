import React, { FC } from 'react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import {
	useNotificationsData,
	useNotificationsIsAny,
} from '@common/selectors/notifications.selectors';
import { isNotificationTypeActive } from '../../modules/app/notificationService';

export const NOTIFICATION_ICON_CLASS_NAME = 'notification-icon';

export const NotificationIcon: FC<{
	type?: string;
	notification?: string;
	types?: Array<string>;
	className?: string;
}> = (props) => {
	const isLogged = useUserIsLogged();
	const notifications = useNotificationsData();
	const isAny = useNotificationsIsAny();

	if (!isLogged) {
		return null;
	}

	const { type, notification, types, className } = props;

	let bool;
	if (!type && !notification && !types) {
		bool = isAny;
	} else if (notification) {
		bool = notifications[notification];
	} else if (types?.length) {
		bool = types.some((type) => {
			return isNotificationTypeActive(type, notifications);
		});
	} else {
		bool = isNotificationTypeActive(type, notifications);
	}

	if (!bool) {
		return null;
	}

	return (
		<AwesomeIcon
			icon={faExclamationCircle}
			className={classnames(NOTIFICATION_ICON_CLASS_NAME, className)}
		/>
	);
};
