import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LEVEL } from '../vip.constants';
import { VipLevel } from '../types/vip.types';

export type ValueType = VipLevel;
const NAME = 'level';
const initialState: ValueType = DEFAULT_LEVEL;

const setAction: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const levelSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		levelUpdate: setAction,
	},
});
