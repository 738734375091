import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { languageConfig } from '@server/config/language.config';
import { setLanguage } from '../../client/store/app/app.actions';
import { changeLanguage } from '../../client/modules/translation/translationService';
import { useAppDispatch } from '../store/useAppDispatch';

export const useLocalization = () => {
	const { i18n, ready } = useTranslation();
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (!ready) {
			return;
		}
		dispatch(setLanguage(i18n.language || languageConfig.default));
		i18n.on('languageChanged', (lng) => {
			if (languageConfig.available.some((el) => el.id === lng)) {
				return dispatch(setLanguage(lng));
			}
			changeLanguage(i18n, languageConfig.default);
		});
	}, [ready]);
};
