import { fetchApi } from '@legacyApp/client/store/fetch/fetch.thunk';
import { bindSocketPrivateChannelEvent } from '@legacyApp/client/store/websocket/websocket.actions';
import { WEBSOCKET_EVENTS } from '@legacyApp/client/store/websocket/websocket.constants';
import { setCurrency } from '@legacyApp/client/store/user/user.actions';
import { getInitialCurrency } from '@legacyApp/client/modules/app/currencyService';
import { AppThunk } from '@legacyApp/types/store/ThunkAction';
import { checkGameForm } from '../../../games/Game/store/gameValidation/gameValidation.actions';
import websocketService from '../../../../LegacyApp/client/modules/app/websocketService';
import { updateBalance } from '../balance.actions';

export const getBalancesThunk = (): AppThunk => {
	return async (dispatch, getState) => {
		const state = getState();
		const data = await dispatch(
			fetchApi({
				url: '/v2/user/balances',
				parameters: {
					Authorization: true,
				},
				loaderId: 'getBalances',
			}),
		).catch(() => {
			return null;
		});
		if (!data?.userBalances) {
			return;
		}
		dispatch(updateBalance(data.userBalances, 'getBalancesThunk'));
		dispatch(
			bindSocketPrivateChannelEvent(
				WEBSOCKET_EVENTS.USER_BALANCE,
				(data, dispatch) => {
					dispatch(
						updateBalance(
							websocketService.getMessage(data).userBalance,
							'websocketBalance',
						),
					);
				},
			),
		);
		if (state?.game?.activeGame) {
			dispatch(checkGameForm(state.game.activeGame, true));
		}
		if (state.user.activeCurrency === 'btc') {
			dispatch(setCurrency(getInitialCurrency(data.userBalances)));
		}
		return [];
	};
};
