import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { UserNameIconsStyled } from '@ui/user-name/user-name-icons';

export const StyledUserNameText = styled.span``;

interface StyledUserNameProps {
	$my: boolean;
	$inline: boolean;
	$noStyles: boolean;
	className: string;
}

export const StyledUserName = styled.a.attrs<StyledUserNameProps>((props) => ({
	className: classnames(props.className, {
		'user-name--my': props.$my,
	}),
}))<StyledUserNameProps>`
	${(props) =>
		props.$noStyles
			? ''
			: css`
					align-items: center;
					display: ${props.$inline ? 'inline' : 'inline-flex'}!important;

					span:not(${StyledUserNameText}):not(${UserNameIconsStyled}) {
						display: inline-flex;
					}
			  `}
`;
