import { shallowEqual } from 'react-redux';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';

export const useStatsPrice = (currency: CurrencyType) =>
	useAppSelector<number>(
		(state) => state.stats.price?.[currency],
		shallowEqual,
	);
export const useStatsOnline = () =>
	useAppSelector<number>((state) => state.stats.online, shallowEqual);
