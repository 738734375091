import ReduxToolkit, { createSlice } from '@reduxjs/toolkit';

export type ValueType = number;
const NAME = 'status';
const initialState: ValueType = null;

const statusUpdate: ReduxToolkit.CaseReducer<
	ValueType,
	ReduxToolkit.PayloadAction<ValueType>
> = (state, action) => {
	return action.payload;
};

export const statusSlice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		statusUpdate,
	},
});
