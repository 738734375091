import {
	ADD_PENDING_ALERT,
	CLOSE_ALERT,
	DELETE_ALERT,
	HANDLE_ALERT,
	SHOW_ALERT,
	TOGGLE_SERVER_SEED_ALERT,
} from './alerts.constants';

const handleAlert = (payload) => {
	return {
		type: HANDLE_ALERT,
		payload,
	};
};

const toggleServerSeedAlert = (bool) => {
	return {
		type: TOGGLE_SERVER_SEED_ALERT,
		bool,
	};
};

const showAlert = (alertType, message, noTimeout, id) => {
	return {
		type: SHOW_ALERT,
		alertType,
		message,
		noTimeout,
		id: id || new Date().getTime(),
	};
};

const closeAlert = (id, force, timeout) => {
	return {
		type: CLOSE_ALERT,
		id,
		force,
		timeout,
	};
};

const deleteAlert = (id, force) => {
	return {
		type: DELETE_ALERT,
		id,
		force,
	};
};

const addPendingAlert = (payload) => {
	return {
		type: ADD_PENDING_ALERT,
		payload,
	};
};

export {
	showAlert,
	closeAlert,
	deleteAlert,
	handleAlert,
	toggleServerSeedAlert,
	addPendingAlert,
};
