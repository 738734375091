import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { NOTIFICATION_ID } from '@legacyApp/client/store/notification/notification.constants';

export const useNotificationsData = () =>
	useAppSelector((state) => state.notification.data);
export const useNotification = (key: NOTIFICATION_ID) =>
	useAppSelector((state) => state.notification.data[key]);
export const useNotificationsIsAny = () =>
	useAppSelector<boolean>((state) => state.notification.isAny, shallowEqual);
