import styled, { css } from 'styled-components';
import { BetSlipDetailsProgressState } from '@modules/sports/types/BetSlipDetails/BetSlipDetailsType';
import { theme } from '@legacyApp/client/modules/style/theme';

export const getStateColor = (state: string, value: number) => {
	if (state) {
		if (state === 'win' || state === BetSlipDetailsProgressState.CASHOUT) {
			return theme.colors.positive;
		}
		if (state === BetSlipDetailsProgressState.PENDING) {
			return theme.colors.grey;
		}
		return theme.colors.text;
	}

	if (value >= 0) {
		return theme.colors.positive;
	}

	return theme.colors.text;
};

export const StyledProfit = styled.span<{
	$value: number;
	$disable?: boolean;
	$state?: string;
}>`
	${(props) =>
		!props.$disable
			? css`
					color: ${getStateColor(props.$state, props.$value)};
			  `
			: ''}

	div {
		display: inline-block;
		text-align: unset;
	}
`;
