import { useEffect } from 'react';

const WINDOW = process.browser ? window : null;
const DOCUMENT = process.browser ? document : null;
const SCREEN_ORIENTATION = process.browser ? screen.orientation : null;

export const useWindowEventListener = (
	eventName: string,
	handler: EventListenerOrEventListenerObject,
	options?: boolean | EventListenerOptions,
) => {
	useEventListener(WINDOW, eventName, handler, options);
};

export const useDocumentEventListener = (
	eventName: string,
	handler: EventListenerOrEventListenerObject,
	options?: boolean | EventListenerOptions,
) => {
	useEventListener(DOCUMENT, eventName, handler, options);
};

export const useEventListener = (
	element: HTMLElement | Window | Document | ScreenOrientation,
	eventName: string,
	handler: EventListenerOrEventListenerObject,
	options?: boolean | EventListenerOptions,
) => {
	useEffect(() => {
		if (!element || !element.addEventListener) {
			return;
		}
		element.addEventListener(eventName, handler, options);
		return () => {
			element.removeEventListener(eventName, handler, options);
		};
	}, [element, eventName, handler, options]);
};

export const triggerWindowEvent = (eventName: string, detail?: any) => {
	if (!WINDOW || !WINDOW.dispatchEvent) {
		return;
	}
	const event = new CustomEvent(eventName, {
		detail,
	});
	WINDOW.dispatchEvent(event);
};

export const useOrientationChangeEventListener = (
	handler: EventListenerOrEventListenerObject,
) => {
	useEventListener(
		SCREEN_ORIENTATION || WINDOW,
		SCREEN_ORIENTATION ? 'change' : 'orientationchange',
		handler,
	);
};
