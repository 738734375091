import { combineReducers } from 'redux';
import { cookieService } from '@common/controllers/cookieService';
import { RESET_USER_DATA } from '@legacyApp/client/store/user/user.constants';
import { APP_INITIALIZED } from '@legacyApp/client/store/app/app.constants';
import {
	SET_AFFILIATE_CODE,
	SET_AFFILIATE_UTM_SOURCE,
} from '@legacyApp/client/store/affiliate/affiliate.constants';
import { CookiesKeys } from '@modules/cookies/types/cookiesKeys.type';

const codeReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case APP_INITIALIZED: {
			return cookieService.get(CookiesKeys.AFFILIATE_CODE) || false;
		}
		case SET_AFFILIATE_CODE: {
			action.payload
				? cookieService.set(CookiesKeys.AFFILIATE_CODE, action.payload)
				: cookieService.clear(CookiesKeys.AFFILIATE_CODE);
			return action.payload;
		}
		case RESET_USER_DATA:
			return cookieService.get(CookiesKeys.AFFILIATE_CODE) || false;
		default: {
			return state;
		}
	}
};

const utmSourceReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case APP_INITIALIZED: {
			return cookieService.get(CookiesKeys.UTM_SOURCE) || false;
		}
		case SET_AFFILIATE_UTM_SOURCE: {
			action.payload
				? cookieService.set(CookiesKeys.UTM_SOURCE, action.payload)
				: cookieService.clear(CookiesKeys.UTM_SOURCE);
			return action.payload;
		}
		case RESET_USER_DATA:
			return cookieService.get(CookiesKeys.UTM_SOURCE) || false;
		default: {
			return state;
		}
	}
};

const affiliateReducer = combineReducers({
	code: codeReducer,
	utmSource: utmSourceReducer,
});

export { affiliateReducer };
