import {
	ButtonHTMLAttributes,
	FC,
	MutableRefObject,
	PropsWithChildren,
	ReactNode,
} from 'react';
import classnames from 'classnames';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { InputStyleType } from '@uiTypes/InputStyleType';
import styles from '@ui/button/Button.module.scss';

export interface ButtonProps
	extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
	label?: string | ReactNode;
	size?: Size;
	borderType?: InputStyleType;
	styleType?: ButtonStyleType;
	disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
	iconBefore?: ReactNode;
	iconAfter?: ReactNode;
	refElement?: MutableRefObject<any>;
	onlyIcon?: boolean;
}

export const CSSClassNameButton = 'styled-button';
export const CSSClassNameIcon = 'styled-icon';

export const Button: FC<ButtonProps> = (props) => {
	const {
		children,
		label,
		iconBefore,
		iconAfter,
		styleType,
		size,
		className,
		borderType,
		refElement,
		onlyIcon,
		disabled,
		...restProps
	} = props;

	const isLabel = onlyIcon ? false : !!label || !!children;

	return (
		<button
			ref={refElement}
			className={classnames(
				CSSClassNameButton,
				styleType,
				borderType,
				size,
				className,
				styles.styledButton,
				{
					'text-style-sm-medium': !!label,
					[styles['styledButton--disabled']]: disabled,
					[styles['styledButton--has-label']]: isLabel,
					[styles['styledButton--square']]:
						borderType === InputStyleType.SQUARE,
					[styles['styledButton--type-label']]:
						styleType === ButtonStyleType.LABEL,
					[styles['styledButton--type-clear']]:
						styleType === ButtonStyleType.CLEAR,
					[styles['styledButton--type-outline']]:
						styleType === ButtonStyleType.OUTLINE,
					[styles['styledButton--type-primary']]:
						styleType === ButtonStyleType.PRIMARY,
					[styles['styledButton--type-secondary']]:
						styleType === ButtonStyleType.SECONDARY,
					[styles['styledButton--small']]: size === Size.SMALL,
					[styles['styledButton--medium']]: size === Size.MEDIUM,
					[styles['styledButton--large']]: size === Size.LARGE,
				},
			)}
			{...restProps}
		>
			{iconBefore && (
				<div
					className={classnames(
						CSSClassNameIcon,
						'text-style-sm-medium',
						styles.styledButtonIcon,
						{
							[styles['styledButtonIcon--icon-after']]: !!iconAfter,
						},
					)}
				>
					{iconBefore}
				</div>
			)}

			{label && (
				<span className="float-left max-w-full whitespace-nowrap">{label}</span>
			)}

			{children}

			{iconAfter && (
				<div
					className={classnames(
						CSSClassNameIcon,
						'text-style-sm-medium',
						styles.styledButtonIcon,
						{
							[styles['styledButtonIcon--icon-after']]: !!iconAfter,
						},
					)}
				>
					{iconAfter}
				</div>
			)}
		</button>
	);
};

Button.defaultProps = {
	size: Size.MEDIUM,
	styleType: ButtonStyleType.PRIMARY,
};
